.workspaceSwitch__list {
  max-height: 40vh;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  overflow-y: auto;
}

.workspaceSwitch__actions {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}
