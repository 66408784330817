.activityForm {
  padding-left: 20px;
  padding-right: 20px;
}

.activityForm__part {
  border-bottom: 1px solid #e9e8e8;
  margin-bottom: 24px;
  padding-bottom: 24px;
}

.activityForm__part:last-child {
  border-width: 0;
  margin-bottom: 0;
}

.activityForm__partHeader {
  justify-content: space-between;
  align-items: baseline;
  display: flex;
}

.activityForm__partTitle {
  letter-spacing: -.4px;
  color: #787676;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}

.activityForm__partAction {
  margin-left: auto;
}

.activityForm__partBody {
  flex-wrap: wrap;
  align-items: flex-end;
  margin: -3px -12px -15px;
  display: flex;
}

.activityForm__partBody_amount .activityForm__partItem_units {
  margin-left: 0;
}

.activityForm__partBody_amount .activityForm__partItem_target {
  margin-left: auto;
}

.activityForm__partBody_isHidden {
  display: none;
}

.activityForm__partItem {
  width: calc(50% - 24px);
  margin: 15px 12px;
}

.activityForm__partItem_size_s {
  width: 92px;
}

.activityForm__partItem_size_full {
  width: calc(100% - 24px);
}

.activityForm__partSection {
  margin-bottom: 24px;
}

.activityForm__partSection_gallery {
  border-top: 1px solid #e9e8e8;
  margin-bottom: 0;
  padding-top: 24px;
}

.activityForm :global .app-task-progress {
  background-color: rgba(30, 27, 27, .05);
  border-radius: 4px;
  height: 8px;
  margin-top: 42px;
  margin-bottom: 24px;
  position: relative;
}

.activityForm :global .app-task-progress__bar {
  border-radius: inherit;
  background-color: #21a032;
}

.activityForm :global .app-task-progress__dot {
  width: 6px;
  height: 6px;
  box-shadow: none;
  z-index: 1;
  background-color: #d9f2dc;
  border: 1px solid #73c47e;
  top: auto;
  bottom: calc(100% + 3px);
  transform: translate3d(-50%, 0, 0);
}

.activityForm :global .app-task-progress__number {
  box-sizing: border-box;
  z-index: 1;
  letter-spacing: -.4px;
  background-color: #21a032;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 11px;
  line-height: 1.33333;
  display: flex;
  bottom: calc(100% + 12px);
}

.activityForm :global .app-task-progress__number:before {
  content: none;
}

.activityForm :global .app-task-progress__min_number {
  letter-spacing: -.4px;
  color: #787676;
  font-size: 12px;
  line-height: 1.33333;
  position: absolute;
}

.activityForm :global .app-task-progress__min_text {
  letter-spacing: -.4px;
  color: #787676;
  font-size: 12px;
  line-height: 1.33333;
  position: absolute;
}

.activityForm :global .app-task-progress__max_number {
  letter-spacing: -.4px;
  color: #787676;
  font-size: 12px;
  line-height: 1.33333;
  position: absolute;
}

.activityForm :global .app-task-progress__max_text {
  letter-spacing: -.4px;
  color: #787676;
  font-size: 12px;
  line-height: 1.33333;
  position: absolute;
}

.activityForm :global .app-task-progress__min_number {
  left: 0;
}

.activityForm :global .app-task-progress__min_text {
  left: 0;
}

.activityForm :global .app-task-progress__max_number {
  right: 0;
}

.activityForm :global .app-task-progress__max_text {
  right: 0;
}

.activityForm :global .app-task-progress__min_number {
  bottom: calc(100% + 5px);
}

.activityForm :global .app-task-progress__max_number {
  bottom: calc(100% + 5px);
}

.activityForm :global .app-task-progress__min_text {
  top: calc(100% + 8px);
}

.activityForm :global .app-task-progress__max_text {
  top: calc(100% + 8px);
}
