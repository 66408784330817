.ctrlColorPicker__header {
  border-bottom: 4px solid #dedede;
  align-items: center;
  padding: 12px 16px;
  display: flex;
}

.ctrlColorPicker__buttonSave {
  flex-shrink: 0;
  margin-left: 4px;
}

.ctrlColorPicker__body {
  grid-template-rows: repeat(4, 20px);
  grid-template-columns: repeat(7, 20px);
  grid-auto-flow: column;
  gap: 24px 12px;
  padding: 12px 16px;
  display: grid;
}

.ctrlColorPicker__footer {
  border-top: 1px solid #dedede;
  padding: 12px 16px;
}
