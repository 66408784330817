.inputContainer {
  border: .0625rem solid #ccc;
  border-radius: .25rem;
  align-items: center;
  gap: .5rem;
  padding: .5rem;
  display: flex;
  position: relative;
}

.checkboxContainer {
  justify-content: flex-end;
  align-items: center;
  margin: 0 .25rem .25rem 0;
  font-size: .875rem;
  font-weight: 500;
  display: flex;
}

.textInput {
  border: none;
  flex-grow: 1;
  padding: .5rem;
}

.textInput::placeholder {
  padding-top: 0;
}

.textInput:focus {
  outline: none;
}

.fileInputLabel {
  cursor: pointer;
  align-items: center;
  display: flex;
}

.fileInputLabel input[type="file"] {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.dateInputWrapper {
  max-width: 9.375rem;
}

.calendar {
  background-color: #f4f3f3;
  border: .0625rem solid rgba(5, 63, 227, .25);
  border-radius: .25rem;
  padding: .25rem;
  box-shadow: .3125rem .3125rem .3125rem #d3d3d3;
}

:global .progressReportfilteredDate {
  background-color: rgba(33, 160, 50, .1);
  font-weight: 500;
  color: #21a032 !important;
}

.dateInput {
  background-color: rgba(0, 0, 0, 0) !important;
  border: none !important;
}

.imageFileSizeErrorMessage {
  color: #fa380d;
  margin-top: .25rem;
  font-size: .625rem;
}

.imageList {
  width: 100%;
  margin-top: .5rem;
  font-size: .75rem;
  list-style-type: none;
  display: flex;
  overflow-x: auto;
}

.imageList li {
  margin-right: .5rem;
}

.imageItem {
  margin-top: .5rem;
  list-style-type: none;
  display: flex;
}
