.MemberInvite {
  background-color: #fff;
}

.MemberInvite__header {
  border-bottom: 1px solid #e9e8e8;
  padding: 24px 20px;
}

.MemberInvite__title {
  letter-spacing: -.4px;
  color: #787676;
  margin-bottom: 18px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}

.MemberInvite__form {
  padding: 24px 20px;
}

.MemberInvite__formPart {
  border-bottom: 1px solid #e9e8e8;
  margin-bottom: 24px;
  padding-bottom: 24px;
}

.MemberInvite__formPart:last-child {
  border-bottom-width: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.MemberInvite__formPart_lastEl_field {
  padding-bottom: 12px;
}

.MemberInvite__formItem {
  margin-bottom: 12px;
}

.MemberInvite__formItem:last-child {
  margin-bottom: 0;
}

.MemberInvite__formButton {
  width: 100%;
}

.MemberInvite__roles {
  margin-bottom: 24px;
}

.MemberInvite__roles_last {
  margin-bottom: 0;
}

.MemberInvite__phoneCode {
  width: 83px;
}
