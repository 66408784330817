.ctrlBtnOption {
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 9px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
  padding: 10px 7px;
  transition-property: color, background-color, border-color;
  transition-duration: .125s;
  transition-timing-function: ease-in-out;
  display: flex;
}

.ctrlBtnOption_sizeM {
  font-size: inherit;
}

.ctrlBtnOption_sizeS {
  padding: 8px 5px;
  font-size: 14px;
}

.ctrlBtnOption_sizeS .ctrlBtnOption__icon {
  width: 20px;
  height: 20px;
}

.ctrlBtnOption__icon {
  color: #a5a4a4;
  width: 24px;
  height: 24px;
  transition-property: color;
  transition-timing-function: inherit;
  transition-duration: inherit;
  border-radius: 50%;
}

.ctrlBtnOption__icon_main {
  margin-right: 8px;
}

.ctrlBtnOption__text {
  text-align: left;
  margin-right: auto;
  padding-top: 2px;
}

.ctrlBtnOption__previewSelected {
  text-align: center;
  color: rgba(5, 63, 227, .5);
  padding-top: 2px;
  font-size: 16px;
}

.ctrlBtnOption__selected {
  box-sizing: border-box;
  text-align: center;
  letter-spacing: -.4px;
  color: #053fe3;
  background-color: #cdd9f9;
  border-radius: 50%;
  justify-content: center;
  width: 18px;
  height: 18px;
  margin-top: 1px;
  padding-top: 1px;
  font-size: 10px;
  font-weight: 600;
  line-height: 1.6;
  display: flex;
}

.ctrlBtnOption__preview {
  border-bottom: 1px solid #e8e8e8;
  padding: 0 1px 8px;
  position: relative;
}

.ctrlBtnOption__preview_isDisabled:after {
  content: "";
  opacity: .6;
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ctrlBtnOption_isSelected, .ctrlBtnOption:hover:not(:disabled) {
  background-color: #e6ecfc;
  border-color: #e6ecfc;
}

.ctrlBtnOption_isSelected .ctrlBtnOption__icon, .ctrlBtnOption:hover:not(:disabled) .ctrlBtnOption__icon {
  color: #698cee;
}

.ctrlBtnOption:active:not(:disabled) {
  color: #fff;
  background-color: #3765e9;
  border-color: #3765e9;
}

.ctrlBtnOption:active:not(:disabled) .ctrlBtnOption__icon {
  color: #a5a4a4;
}

.container {
  position: relative;
}

.nestedContent {
  box-sizing: border-box;
  z-index: 100;
  background-color: #fff;
  border: 1px solid #e9e8e8;
  border-radius: 12px;
  min-width: 100%;
  padding: 12px 6px 6px;
  display: none;
  position: absolute;
  top: -11px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, .08);
}

.nestedContent__isActive {
  display: block;
}

.nestedContent__isActive--positionTop {
  top: auto;
  bottom: 0;
}

.nestedContent__isActive--positionLeftTop, .nestedContent__isActive--positionLeft {
  right: 100%;
}

.nestedContent__isActive--positionLeftBottom {
  top: auto;
  bottom: -11px;
  right: 100%;
}

.nestedContent__isActive--positionRightTop, .nestedContent__isActive--positionRight {
  left: 100%;
}

.nestedContent__isActive--positionRightBottom {
  top: auto;
  bottom: -11px;
  left: 100%;
}
