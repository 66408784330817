.gantt__task-context-menu.react-popper {
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0) !important;
  padding: 0 !important;
}

.is-hidden {
  opacity: 0;
  pointer-events: none;
}

.gantt__task-context-menu.ctrl-drop__viewport {
  max-height: none;
}

.gantt_row .more_info {
  opacity: 0;
  background-color: #fff;
  padding: 10px;
}

.gantt__context-menu_disable-item {
  opacity: .4;
  pointer-events: none;
}

.date-editor-popper .react-datepicker__triangle {
  left: 16px;
}

.gantt_row_task .gantt__assigners-icon {
  text-align: left;
  color: #c6c6c6;
  align-items: center;
  display: flex;
}

.gantt_row_task .gantt__assigners-icon--empty {
  color: #0145f4;
}

.gantt_row_task.gantt_selected .gantt__assigners-icon {
  color: gray;
}

.gantt__context-menu_group-start {
  border-top: 1px solid #ebebeb;
}

.gantt__context-menu_group-end {
  border-bottom: 1px solid #ebebeb;
}

.gantt__context-menu + .gantt__context-menu {
  border-top: none;
}

.gantt__context-menu:first-child, .gantt__context-menu:last-child {
  border: none;
}

.gantt_select-control {
  transform: translate(10px, 10px);
}

.gantt__context-menu__lookahead-color {
  margin: -6px;
}

.gantt__context-menu__override-date-square-color {
  width: 244px;
  margin: -16px;
}
