.progressbar__container {
  width: 100%;
  margin-left: .625rem;
}

.custom-column-heading {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.custom-column-heading--cta {
  padding: .3125rem .625rem;
}

.custom-column-heading--cta-icon {
  fill: #fff;
}

.custom-column-heading--cta-icon--disabled {
  fill: #b5b5b5;
}

.custom-column-item {
  position: relative;
}

.custom-column-item:not(:last-child) {
  border-bottom: .0625rem solid rgba(0, 0, 0, .1);
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
}

.custom-column-label {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.custom-column-input {
  background: #fff;
  border: .0625rem solid rgba(5, 63, 227, .25);
  border-radius: .3125rem;
  outline: none;
  flex-grow: 1;
  padding-left: .625rem;
}

.custom-column-select-wrapper {
  flex-grow: 1;
}

.custom-column-delete {
  fill: #fa380d;
  margin-right: .625rem;
}

.custom-column-delete--is-submitting {
  fill: rgba(5, 63, 227, .1);
}

.tableHeader {
  background-color: #fff;
  border-bottom: .0625rem solid #fff;
  width: -webkit-fit-content;
  width: fit-content;
}

.tableBody {
  width: 100%;
  min-width: -webkit-fit-content;
  min-width: fit-content;
}

.table__cell--default {
  width: 6.25rem;
}

.footer {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.tableScrollContainer {
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}

.aka-table__th-content--start {
  justify-content: flex-start;
  display: flex;
}
