.ctrlDates {
  background-color: rgba(5, 63, 227, .1);
  border: 1px solid rgba(5, 63, 227, .1);
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  padding: 6px 8px;
  display: flex;
}

.ctrlDates__button {
  margin-left: 8px;
}

.ctrlDates__button_action {
  padding: 0;
}

.ctrlDates__button_text {
  min-width: 100px;
  padding-top: 0;
  padding-bottom: 0;
}

.ctrlDates__button:first-child {
  margin-left: 0;
}
