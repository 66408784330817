.activitiesList {
  border-top: 1px solid #e9e8e8;
  padding: 24px 0 0;
}

.activitiesList__header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.activitiesList__title {
  color: #787676;
}

.activitiesList__list {
  padding-top: 24px;
}

.activitiesList__loader {
  height: 80px;
  position: relative;
}
