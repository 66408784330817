.slidePanel__body {
  flex: 1;
  align-items: flex-start;
  display: flex;
}

.slidePanel__body_fullHeight {
  align-items: stretch;
}

.slidePanel__content {
  flex: 1;
  min-width: 0;
}
