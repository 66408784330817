.formControlInline {
  position: relative;
}

.formControlInline__label {
  letter-spacing: -.4px;
  color: #787676;
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 1.33333;
  display: inline-block;
}

.formControlInline__body_error input, .formControlInline__body_error textarea {
  border-color: #fa380d !important;
}

.formControlInline_isError .formControlInline__body input, .formControlInline_isError .formControlInline__body textarea {
  background-color: rgba(0, 0, 0, 0);
  border-color: #d2d1d1;
  outline-width: 3px;
  outline-color: #ffebe7;
  position: relative;
}

.formControlInline_isError .formControlInline__body input:focus, .formControlInline_isError .formControlInline__body textarea:focus {
  background-color: #fff;
}

.formControlInline_labelIsHidden .formControlInline__label {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.formControlInline_labelSize_m .formControlInline__label {
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 1;
}
