.panelSection {
  background-color: #fff;
  border-top: 1px solid #e9e8e8;
  padding: 0 20px 24px;
}

.panelSection__header {
  z-index: 1;
  background: #fff;
  align-items: center;
  margin-bottom: 12px;
  padding-top: 24px;
  padding-bottom: 12px;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.panelSection__title {
  letter-spacing: -.4px;
  color: #787676;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}

.panelSection__headerActions {
  margin-left: auto;
}

.panelSection__description {
  letter-spacing: -.4px;
  color: #787676;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.375;
}

.panelSection__mainAction {
  margin-top: 6px;
}

.panelSection__more {
  text-align: center;
  margin-top: 18px;
}

.panelSection_width_narrow {
  margin-left: 20px;
  margin-right: 20px;
  padding-left: 0;
  padding-right: 0;
}
