.infoSubcontractor {
  align-items: center;
  gap: 32px;
  margin-left: 10px;
  display: inline-flex;
}

.infoSubcontractor__color {
  margin-right: 32px;
}

.infoSubcontractor__text {
  letter-spacing: -.4px;
  font-size: 16px;
  line-height: 1;
}
