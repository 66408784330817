.header {
  box-sizing: border-box;
  color: #fff;
  z-index: 10;
  background-color: #000;
  width: 100%;
  font-family: Noto Sans, sans-serif;
  transition-property: background-color;
  transition-duration: .125s;
  transition-timing-function: ease;
  position: fixed;
  top: 0;
  left: 0;
}

.header__downloadApp_white {
  color: #000 !important;
}

.header__switcherLanguage__button, .header__switcherLanguage__dropdown {
  background-color: #000;
}

.header__switcherLanguage_white {
  color: #000 !important;
}

.header__container {
  justify-content: space-between;
  align-items: center;
  height: 62px;
  display: flex;
}

@media only screen and (min-width: 576px) {
  .header__container {
    height: 72px;
  }
}

.header__logo {
  color: var(--logo-color);
  flex-shrink: 0;
  width: 50px;
}

@media only screen and (min-width: 576px) {
  .header__logo {
    width: 80px;
  }
}

.header__actions {
  align-items: center;
  display: flex;
}

.header__login {
  color: inherit;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  margin-left: 4px;
  padding: 8px;
  font-size: 14px;
  font-weight: 500;
  transition-property: color;
  transition-duration: .125s;
  transition-timing-function: ease;
}

.header__login:hover {
  color: #b91c1c;
}

@media only screen and (min-width: 576px) {
  .header__login {
    margin-left: 16px;
  }
}

.header__download {
  margin-left: 12px;
}

@media only screen and (min-width: 576px) {
  .header__download {
    margin-left: 24px;
  }
}

.header__button {
  color: #ef4444;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #ef4444;
  border-radius: 6px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.header__button:hover {
  background-color: rgba(153, 27, 27, .3);
  box-shadow: 0 3px 15px rgba(255, 255, 255, .2);
}

@media only screen and (min-width: 576px) {
  .header__button {
    margin-left: 24px;
  }
}

.header_isColored {
  --logo-color: #000;
  color: #000;
  background-color: #fff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1);
}
