.subcontractorMember__description {
  letter-spacing: -.4px;
  color: #787676;
  margin-top: 12px;
  font-size: 16px;
  line-height: 1.375;
}

.subcontractorMember__actions {
  justify-content: space-between;
  margin-top: 6px;
  display: flex;
}

.subcontractorMember__actions__action--delete {
  border-radius: 60px;
  padding: 4px 15px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
}
