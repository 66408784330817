.issuePanelForm {
  flex-direction: column;
  padding: 24px 20px 0 24px;
  display: flex;
}

.issuePanelForm__row {
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  margin: 12px 0;
  display: flex;
}

.issuePanelForm__item {
  margin: 12px 0;
}

.issuePanelForm__item_date {
  width: 100%;
}

.issuePanelForm__headerActions {
  margin-top: 16px;
  margin-bottom: 48px;
}
