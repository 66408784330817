.screen {
  min-width: unset !important;
}

.dailies-container {
  background: #fff;
  border: .125rem solid #f5f5f5;
  border-radius: .5rem;
  margin: .875rem 0 0;
  overflow: hidden;
}

.no-data-message {
  justify-content: center;
  align-items: center;
  height: 85vh;
  display: flex;
}

.no-data-message h2 {
  color: #787676;
  font-size: .875rem;
  font-weight: 400;
}

@media only screen and (min-width: 576px) {
  .no-data-message h2 {
    font-size: 1.5rem;
  }
}

.dailies-card {
  page-break-inside: avoid;
  box-sizing: border-box;
  background: #fff;
  border: .125rem solid #e5e4e2;
  border-radius: .5rem;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 1rem;
  padding: .5rem;
  display: flex;
}

@media only screen and (min-width: 576px) {
  .dailies-card {
    margin: 1.5rem 0 1.25rem;
    padding: 2rem;
  }
}

.dailies-card-header {
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  font-size: .875rem;
  display: flex;
}

.dailies-card-header.full {
  margin-bottom: 1.5rem;
}

.dailies-card-header.minimal {
  margin-bottom: 0;
}

.dailies-card-header__title, .dailies-card-header__subtitle {
  font-weight: 500;
}

.dailies-card-header__subtitle {
  font-size: .75rem;
}

@media only screen and (min-width: 576px) {
  .dailies-card-header {
    flex-direction: row;
    justify-content: space-between;
  }

  .dailies-card-header__title {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 800px) {
  .dailies-card-header__subtitle {
    text-align: right;
  }
}

.minimal-header {
  display: flex;
}

.hr-divider {
  border: 0;
  border-top: .0625rem solid rgba(0, 0, 0, .1);
  border-bottom: .0625rem solid rgba(255, 255, 255, .3);
  width: 100%;
  height: 0;
}

.hr-divider:last-of-type {
  display: none;
}

.daily-report-card {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: .625rem 0;
  display: flex;
}

.daily-report-card__report-cta {
  text-align: center;
  font: inherit;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  text-decoration: none;
  display: inline-block;
}

.daily-report-card__report-cta svg {
  color: #053fe3;
}

.daily-report-card__section {
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.daily-report-card__section--header {
  flex: 1;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.25rem;
  display: flex;
}

.daily-report-card__section:last-of-type {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  margin-right: 0;
  display: flex;
}

@media only screen and (min-width: 1300px) {
  .daily-report-card__section {
    margin-right: 1.875rem;
  }
}

.daily-report-card__progress-bar {
  flex: 1;
  width: 100%;
  margin: .625rem 0;
  display: flex;
}

.daily-report-card__progress-bar .progressbar {
  border-radius: unset;
  box-shadow: none;
  padding: unset;
  flex: 1;
  display: flex;
}

.daily-report-card__progress-bar .progressbar .progressbar__container {
  background: #dedede;
  flex: 1;
  height: .25rem;
  display: flex;
}

.daily-report-card__progress-bar .progressbar__line {
  background-color: #007bff;
}

.daily-report-card__crew-metrics {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: .75rem;
  display: flex;
}

.daily-report-card__crew-metrics--amount--less-than-reported {
  color: #fa380d;
}

.daily-report-card__crew-metrics--manpower, .daily-report-card__crew-metrics--crewsize {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.daily-report-card__crew-metrics--manpower .worker-icon, .daily-report-card__crew-metrics--crewsize .worker-icon {
  display: none;
}

@media only screen and (min-width: 650px) {
  .daily-report-card__crew-metrics--manpower .worker-icon, .daily-report-card__crew-metrics--crewsize .worker-icon {
    width: .75rem;
    height: .75rem;
    margin-bottom: .125rem;
    margin-left: .25rem;
    margin-right: .25rem;
    display: inline-block;
  }
}

@media only screen and (min-width: 576px) {
  .daily-report-card__crew-metrics {
    flex-direction: row;
    justify-content: space-between;
    font-size: .875rem;
  }

  .daily-report-card__crew-metrics--manpower, .daily-report-card__crew-metrics--crewsize {
    align-items: center;
    justify-content: unset;
    width: unset;
    display: flex;
  }
}

.daily-report-card__report-metrics {
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-top: .625rem;
  font-size: .625rem;
  display: flex;
}

.daily-report-card__report-metrics--timeframe, .daily-report-card__report-metrics--wbs {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.daily-report-card__report-metrics-wbs--title {
  margin-right: .25rem;
  display: inline-block;
}

.daily-report-card__report-metrics-submitter {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: .5rem;
  margin-bottom: .5rem;
  font-size: .625rem;
  display: flex;
}

.daily-report-card__report-metrics-submitter--title {
  margin-right: .25rem;
}

.daily-report-card__report-metrics-submitter-name {
  color: #053fe3;
  margin-right: .25rem;
  font-weight: 500;
}

@media only screen and (min-width: 576px) {
  .daily-report-card__report-metrics-submitter {
    flex-direction: row;
    font-size: .75rem;
  }
}

@media only screen and (min-width: 800px) {
  .daily-report-card__report-metrics-submitter {
    font-size: .875rem;
  }
}

@media only screen and (min-width: 1300px) {
  .daily-report-card__report-metrics-submitter {
    justify-content: flex-end;
  }
}

@media only screen and (min-width: 576px) {
  .daily-report-card__report-metrics {
    flex-direction: row;
    justify-content: space-between;
    font-size: .875rem;
  }
}

@media only screen and (min-width: 800px) {
  .daily-report-card__report-metrics {
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}

@media only screen and (min-width: 1300px) {
  .daily-report-card__report-metrics {
    max-width: 50%;
  }

  .daily-report-card {
    flex-direction: row;
    font-size: .875rem;
  }
}

@media print {
  html, body {
    height: 100%;
    font-size: .75rem;
    padding: 0 !important;
  }
}

.dailies-card-comments {
  flex-direction: column;
  justify-content: space-between;
  margin: 1rem 0 .5rem;
  font-weight: 500;
  display: flex;
}

.dailies-card-comments p {
  margin-bottom: .5rem;
  font-size: .875rem;
}

.dailies-card-comments .add_activity_btn {
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
}

@media only screen and (min-width: 576px) {
  .dailies-card-comments .add_activity_btn {
    margin-top: unset;
  }
}

.dailies-card-comments--add-comment-action {
  width: 100%;
}

.dailies-card-comments--add-comment-action .add_activity_btn {
  justify-content: center;
  width: 100%;
}

@media only screen and (min-width: 576px) {
  .dailies-card-comments--add-comment-action {
    width: unset;
  }
}

.dailies-card-comments-issue--submitter {
  display: flex;
}

.dailies-card-comments-issue--submitter p {
  margin-right: .25rem;
}

@media only screen and (min-width: 420px) {
  .dailies-card-comments {
    justify-content: space-between;
    font-size: .875rem;
  }
}

@media only screen and (min-width: 576px) {
  .dailies-card-comments {
    flex-direction: row;
    align-items: center;
  }
}

.dailies-card-comments {
  flex-direction: column;
  justify-content: space-between;
  font-size: .75rem;
  font-weight: 100;
  display: flex;
}

.dailies-card-comments-image-container {
  width: 18.75rem;
}

.dailies-card-comments-comment-header {
  justify-content: flex-start;
  gap: .25rem;
  height: -webkit-fit-content;
  height: fit-content;
  display: flex;
  align-items: center !important;
}

.dailies-card-comments-comment-header--heading {
  padding-top: .125rem;
  font-weight: 500;
}

.dailies-card-comments-comment-header--icon {
  fill: #053fe3;
}

.dailies-card-comments--submitter {
  flex-direction: column;
  margin: .5rem 0;
  font-size: .75rem;
  display: flex;
}

.dailies-card-comments--submitter span {
  display: inline-block;
}

@media only screen and (min-width: 420px) {
  .dailies-card-comments--submitter {
    flex-direction: row;
    align-items: center;
    font-size: .875rem;
  }
}

.dailies-card-comments--error {
  color: #fff;
  text-align: center;
  background: #fa380d;
  border-radius: 2.75rem;
  align-self: flex-start;
  gap: .25rem;
  width: 100%;
  height: 1rem;
  padding: .0625rem;
  font-size: .75rem;
  line-height: 1rem;
}

@media only screen and (min-width: 420px) {
  .dailies-card-comments--error {
    width: 6.1875rem;
    margin-left: .25rem;
  }
}

.dailies-card-comments--add-issue-action {
  width: 100%;
}

.dailies-card-comments--add-issue-action .add_activity_btn {
  justify-content: center;
  width: 100%;
}

@media only screen and (min-width: 576px) {
  .dailies-card-comments--add-issue-action {
    width: unset;
  }
}

.dailies-card-comments-image-thumbnails {
  margin-top: .5rem;
  display: flex;
}

.dailies-card-comments-image-thumbnails img {
  width: 2rem;
  height: 2rem;
}

@media only screen and (min-width: 420px) {
  .dailies-card-comments {
    justify-content: space-between;
    margin: .5rem 0 0;
    font-size: .875rem;
  }
}

@media only screen and (min-width: 576px) {
  .dailies-card-comments {
    flex-direction: row;
  }
}

.force-display {
  display: block !important;
}
