.statusColumn {
  letter-spacing: -.4px;
  background-color: #d3d3d3;
  border-radius: 9px;
  padding: 4px 7px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33333;
}

.statusColumn_active {
  color: #21a032;
  background-color: #d9f2dc;
}

.statusColumn_invited {
  color: #3765e9;
  background-color: #e6ecfc;
}

.statusColumn_removed {
  color: #787676;
  background-color: rgba(30, 27, 27, .05);
}
