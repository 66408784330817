.infoGallery__label {
  letter-spacing: -.4px;
  color: #787676;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}

.infoGallery__body {
  scroll-snap-type: x mandatory;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}

.infoGallery__grid {
  flex-wrap: wrap;
  width: 100%;
  margin: -6px;
  display: flex;
}

.infoGallery__item {
  border-radius: 9px;
  flex: 0 calc(33% - 12px);
  height: 87px;
  margin: 6px;
  display: block;
  overflow: hidden;
}

.infoGallery__image {
  object-fit: cover;
  object-position: 50%;
  width: 100%;
  height: 100%;
  display: block;
}

.infoGallery__moreActions {
  text-align: center;
  margin-top: 16px;
}
