.landingLangs {
  position: relative;
}

.landingLangs__button {
  width: 100%;
  padding: var(--button-vertical-indent, 8px) 8px;
  color: var(--button-color, #dedede);
  background-color: var(--button-bg, transparent);
  border-width: 0;
  align-items: center;
  font-size: 14px;
  text-decoration: none;
  transition-property: color, background-color;
  transition-duration: .125s;
  transition-timing-function: ease;
  display: flex;
}

.landingLangs__buttonText {
  margin-top: var(--lh-fix);
  text-align: center;
  flex-grow: 1;
}

.landingLangs__buttonIcon {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
}

.landingLangs__buttonIcon_globe {
  margin-right: 4px;
}

.landingLangs__buttonIcon_arrow {
  margin-left: 4px;
}

.landingLangs__button_toggle {
  --lh-fix: 1px;
}

.landingLangs__button_current {
  opacity: .5;
  pointer-events: none;
}

.landingLangs__viewport {
  min-width: 100%;
  opacity: var(--viewport-opacity, 0);
  visibility: var(--viewport-visibility, hidden);
  background-color: #1e1b1b;
  transition-property: opacity, visibility;
  transition-duration: .125s;
  transition-timing-function: ease;
  position: absolute;
  top: 100%;
  right: 0;
}

.landingLangs__list {
  --button-vertical-indent: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  list-style-type: none;
}

.landingLangs_isActive {
  --button-color: #fff;
  --button-bg: #1e1b1b;
  --viewport-opacity: 1;
  --viewport-visibility: visible;
}
