.social {
  list-style-type: none;
  display: flex;
}

.social__item {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 24px;
  overflow: hidden;
}

.social__item:last-child {
  margin-right: 0;
}

.social__link {
  color: inherit;
  text-decoration: none;
  transition-property: color;
  transition-duration: .125s;
  transition-timing-function: ease-in-out;
}

.social__link:hover {
  color: #dc2626;
}

.social__linkText {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}
