.card {
  background-color: #fff;
  border: .0625rem solid rgba(118, 118, 118, .1);
  border-radius: 1rem;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  min-height: 3.75rem;
  margin-bottom: 1rem;
  padding: 1rem;
  transition: box-shadow .3s;
  display: flex;
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .05);
}

@media only screen and (min-width: 1024px) {
  .card {
    flex-direction: row;
  }
}

.row {
  align-items: center;
  display: flex;
}

.section {
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  gap: 3.75rem;
  width: 100%;
  display: flex;
  position: relative;
}

@media only screen and (min-width: 1024px) {
  .section {
    width: unset;
    gap: 0;
  }
}

.separator {
  background-color: rgba(5, 63, 227, .25);
  width: 100%;
  height: .0625rem;
}

@media only screen and (min-width: 1024px) {
  .separator {
    clip: rect(0 0 0 0);
    border: 0;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }
}

.text {
  letter-spacing: -.03125rem;
  text-align: left;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.25rem;
}

.text .date {
  color: #053fe3;
}

.text .not_active {
  color: #dedede;
  fill: #dedede;
  -webkit-user-select: none;
  user-select: none;
}

.text .bold {
  font-weight: 500;
}

.horizontal_divider {
  background-color: #dedede;
  width: .4375rem;
  height: .0625rem;
  margin-left: .25rem;
  margin-right: .25rem;
}

.large_horizontal_divider {
  background-color: #dedede;
  width: 2.125rem;
  height: .0625rem;
}

.box {
  max-width: 10rem;
  min-width: none;
  border: .0625rem solid #f5f5f5;
  border-radius: .5rem;
  gap: .5rem;
  padding: .5rem;
}

.box .box_text {
  overflow-wrap: break-word;
  word-break: break-word;
}

.element {
  width: 11.25rem;
}

.date {
  width: 7.5rem;
}

.row {
  flex-direction: row;
  display: flex;
}

.center {
  align-items: center;
}

.title {
  letter-spacing: -.03125rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.subsection {
  letter-spacing: -.03125rem;
  flex-flow: wrap;
  flex: 1;
  justify-content: space-between;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.25rem;
  display: flex;
}

.subsection .subtitle {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.subsection .subtitle .light_text {
  color: rgba(30, 27, 27, .5);
}

.container {
  flex-direction: column;
  display: flex;
}

.button {
  width: 100%;
}

.button:active {
  opacity: .9;
}

.button._small {
  width: 1.5625rem;
}

.skeleton {
  background-color: #fff;
  border: 1px solid rgba(118, 118, 118, .1);
  border-radius: 1rem;
  flex: 1;
  min-height: 3.75rem;
  margin-bottom: 1rem;
  padding: 1rem;
  display: flex;
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .05);
}

.dailyCardsContainer {
  max-height: 20.625rem;
  overflow-y: auto;
}

.dailyCardsContainer::-webkit-scrollbar {
  display: none;
}

.subsection, .header {
  flex-shrink: 0;
}
