.EmptyTimeline {
  text-align: center;
  color: #787676;
  max-width: 240px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 24px;
  padding-bottom: 24px;
}

.EmptyTimeline__title {
  letter-spacing: -1px;
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.55556;
}

.EmptyTimeline__description {
  letter-spacing: -.4px;
  font-size: 16px;
  line-height: 1.375;
}
