.progressbar {
  box-sizing: border-box;
  padding: unset;
  background: unset;
  box-shadow: unset;
  border-radius: .875rem;
  width: 100%;
}

.progressbar__container {
  background: #fbfbfb;
  border-radius: .25rem;
  display: block;
  overflow: hidden;
}

.progressbar__line {
  background-color: #21a032;
  height: .5rem;
  display: block;
}
