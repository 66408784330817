.slide-panel {
  --appear-duration: 10s;
}

.slide-panel-enter, .slide-panel-appear {
  opacity: 0;
}

.slide-panel-enter .slide-panel__item, .slide-panel-appear .slide-panel__item {
  transform: translateX(100%);
}

.slide-panel-enter-active, .slide-panel-appear-active {
  opacity: 1;
  transition: opacity var(--appear-duration);
  transform: translateX(0);
}

.slide-panel-enter-active .slide-panel__item, .slide-panel-appear-active .slide-panel__item {
  transition: transform var(--appear-duration);
  transform: translateX(0);
}

.slide-panel-exit {
  opacity: 1;
}

.slide-panel-exit .slide-panel__item {
  transform: translateX(0);
}

.slide-panel-exit-active {
  opacity: 0;
  transition: opacity var(--appear-duration);
}

.slide-panel-exit-active .slide-panel__item {
  transition: transform var(--appear-duration);
  transform: translateX(100%);
}
