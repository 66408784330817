.ReAuthPopup {
  max-width: 500px !important;
}

.ReAuthPopup :global .popup__header {
  text-align: center;
}

.ReAuthPopup__box {
  flex-direction: column;
  padding: 10px;
  display: flex;
}
