.orgsColumn__grid {
  flex-wrap: wrap;
  gap: 6px;
  display: flex;
}

.orgsColumn__item {
  letter-spacing: -.4px;
  color: #4b4949;
  border: 1px solid #e9e8e8;
  border-radius: 9px;
  flex-shrink: 0;
  padding: 3px 6px;
  font-size: 16px;
  line-height: 1.33333;
}
