.ActivityPanel {
  border-left: .0625rem solid #cecece;
  flex-shrink: 0;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.ActivityPanel__viewport {
  background-color: #fff;
  flex-direction: column;
  width: 24.625rem;
  display: flex;
}

.ActivityPanel__header {
  border-bottom: 1px solid #e9e8e8;
  flex-shrink: 0;
  padding: 1.5rem 1.25rem;
}

.ActivityPanel__headerSubcontractor {
  border-top: 1px solid #e9e8e8;
  border-right: .0625rem solid #e9e8e8;
  border-top-right-radius: .5625rem;
}

.ActivityPanel__headerActions {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.125rem;
  display: flex;
}

.ActivityPanel__headerSubcontractor {
  border-top: 0;
  margin-bottom: 0;
}

.ActivityPanel__body {
  scrollbar-width: none;
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
}

.ActivityPanel__body_isHidden {
  display: none;
}

.ActivityPanel__body :global .form-default {
  padding: 0;
}

.ActivityPanel__body :global .task-bind {
  padding: 0;
}

.ActivityPanel__body :global .form-default__container {
  box-shadow: none;
  padding: 1.5rem 1.25rem;
}

.ActivityPanel__body :global .task-bind__container {
  box-shadow: none;
  padding: 1.5rem 1.25rem;
}

.ActivityPanel__body :global .form-default__header {
  flex-direction: column;
  align-items: flex-start;
}

.ActivityPanel__body :global .slide-panel__tab {
  margin-bottom: 0;
}

.ActivityPanel__body :global .slide-panel__tab > * {
  margin-bottom: 0;
}

.ActivityPanel__tab {
  margin-bottom: 1.5rem;
}

.ActivityPanel__history {
  margin: 0;
  padding: 0;
}

.ActivityPanel__historyItem {
  background-color: #fff;
  padding: 1.5rem 1.25rem;
}

.ActivityPanel__historyTitle {
  letter-spacing: -.0625rem;
  border-bottom: .0625rem solid #dedede;
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: .5rem;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.75rem;
  display: flex;
}

.ActivityPanel__historyBody {
  margin-top: 1rem;
}
