.toolbar {
  background-color: #fff;
  border-radius: 1rem;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: .25rem;
  padding: .625rem;
  display: flex;
  position: relative;
}

.toolbar__read_only-container {
  position: absolute;
  top: 4.375rem;
  left: 7.5rem;
}

@media (orientation: landscape) {
  .toolbar__read_only-container {
    top: 4.0625rem;
  }
}

@media (orientation: portrait) {
  .toolbar__read_only-container {
    top: 4.6875rem;
  }
}

.toolbar__read_only-chip {
  text-align: center;
  background: #fff9c3;
  border-radius: .625rem;
  padding: .5rem .9375rem .625rem;
  font-size: .875rem;
}

.toolbar .static-items-content {
  flex-wrap: wrap;
  display: flex;
}

.toolbar .static-items-section {
  margin-left: auto;
}

.toolbar .static-items-section.has_wrapped {
  margin-left: 0;
}

.toolbar .section .separator:after {
  content: "";
  background-color: rgba(5, 63, 227, .25);
  min-width: .0625rem;
  height: 1rem;
}

.toolbar .section .content {
  background-color: inherit;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  display: flex;
  position: relative;
}

.toolbar .item__hidden {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}
