.comment-card {
  background: #fff;
  border: .125rem solid #e9e8e8;
  border-radius: .5rem;
  margin: 0 0 1rem;
  padding: .5rem;
}

.comment-card:last-of-type {
  margin-bottom: 0;
}

.comment-card .comment-card-header {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: .25rem;
  font-size: .75rem;
  display: flex;
}

.comment-card .comment-card-header--heading {
  align-items: center;
  font-weight: 500;
  display: flex;
}

.comment-card .comment-card-header--heading svg {
  fill: #053fe3;
  color: #053fe3;
  height: .75rem;
  margin-left: -.125rem;
}

.comment-card .comment-card-header--reporter svg {
  fill: #6c91fc;
  cursor: pointer;
  height: .75rem;
}

.comment-card .comment-card-header--submitter {
  color: #053fe3;
}

.comment-card__progress-bar {
  flex: 1;
  width: 100%;
  margin: .5rem 0;
  display: flex;
}

.comment-card__progress-bar :global .progressbar {
  border-radius: unset;
  box-shadow: none;
  padding: unset;
  flex: 1;
  display: flex;
}

.comment-card__progress-bar :global .progressbar .progressbar__container {
  background: #dedede;
  flex: 1;
  height: .25rem;
  display: flex;
}

.comment-card__progress-bar :global .progressbar__line {
  background-color: #537ffb;
}

.comment-card .comment-card-report {
  flex-direction: column;
  font-size: .75rem;
  display: flex;
}

.comment-card .comment-card-report .comment-card-report--heading {
  justify-content: space-between;
  margin: .25rem 0 .5rem;
  display: flex;
}

.comment-card .comment-card-report .comment-card-report--report {
  justify-content: space-between;
  margin: .125rem 0;
  display: flex;
}

.image-comment-container {
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  gap: 0 .25rem;
  display: grid;
}

.image-comment-container img {
  object-fit: cover;
  border-radius: .5rem;
  width: 100%;
  height: 100%;
  max-height: 9.375rem;
}

.forceDisplay {
  border: 0;
  border-top: .0625rem solid rgba(0, 0, 0, .1);
  border-bottom: .0625rem solid rgba(255, 255, 255, .3);
  width: 100%;
  height: 0;
  display: block !important;
}

.hr-divider {
  border: 0;
  border-top: .0625rem solid rgba(0, 0, 0, .1);
  border-bottom: .0625rem solid rgba(255, 255, 255, .3);
  width: 100%;
  height: 0;
}

.hr-divider:last-of-type {
  display: none;
}

.pill-override {
  padding-left: .5rem;
  padding-right: .5rem;
}

.pill-override > span > span {
  font-size: .625rem;
}

.pill-override svg {
  margin-left: .125rem;
}

.pill-override svg:hover {
  cursor: default;
}

.task-row {
  flex-flow: wrap;
  font-size: .75rem;
  display: flex;
}

.mention-container__title {
  font-size: .75rem;
  font-weight: 500;
}

.mention-container__title-container {
  flex-direction: row;
  align-items: center;
  margin-bottom: .3125rem;
  display: flex;
}

.mention-container__title-container--loading {
  width: 12.5rem;
  display: inline-block;
}

.mention-container__icon {
  fill: #053fe3;
  width: .5625rem;
  height: .75rem;
}

.mention-container__icon-container {
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
  display: flex;
}

.mention-container__outer {
  background-color: #f2f5fe;
  padding: .625rem;
}

.mention-container__inner {
  background-color: #fff;
  border-radius: .3125rem;
  padding: .625rem;
  font-size: .75rem;
}

.mention-container__inner__worker-info {
  justify-content: flex-end;
  margin-bottom: .3125rem;
  display: flex;
}

.mention-container__inner__worker-info--highlighted {
  white-space: pre-wrap;
  color: #053fe3;
}

.mention-container__inner-gallery {
  align-items: flex-start;
  display: flex;
}

.mention-container__inner-gallery--expanded {
  flex-direction: column;
  width: 100%;
  margin-bottom: .3125rem;
}

.mention-container__inner-gallery-content {
  cursor: pointer;
  margin-bottom: .3125rem;
}

.mention-container__inner-gallery-content span[data-bycore-task-id], .mention-container__inner-gallery-content span[data-bycore-issue-id] {
  color: #053fe3;
  padding: 0 .125rem;
  font-weight: 500;
}

.mention-container__inner-gallery-content span[data-bycore-issue-id] {
  color: #f96;
}

.mention-container__inner-gallery-content a[data-matrix-mention] {
  color: #21a032;
  padding: 0 .125rem;
}

.mention-container__inner-gallery-content a[data-matrix-mention]:before {
  content: "@";
}

.mention-container__inner-gallery-content a {
  pointer-events: none;
  text-decoration: none;
}

.mention-container__inner-message-container {
  align-items: flex-start;
  margin-bottom: .3125rem;
  transition: all .3s;
  display: flex;
}

.mention-container__inner-message-container.expanded {
  flex-direction: column;
}

.mention-container__inner-message-container.expanded .mention-container__inner-image-container {
  width: 100%;
  height: 18.75rem;
  margin-bottom: .625rem;
}

.mention-container__inner-card-container {
  flex-direction: column;
  gap: .3125rem;
  display: flex;
}

.mention-container__inner-card-item {
  cursor: pointer;
  white-space: nowrap;
  background-color: #e8e8e8;
  border: .0625rem solid #f7f7f7;
  border-radius: .25rem;
  justify-content: space-between;
  align-items: center;
  max-height: 1.75rem;
  padding: .25rem .5rem;
  list-style-type: none;
  display: flex;
  overflow: hidden;
}

.mention-container__inner-card-task-icon, .mention-container__inner-card -arrow-icon {
  flex-shrink: 0;
}

.mention-container__inner-card-task-icon {
  margin-right: .5rem;
}

.mention-container__inner-card-arrow-icon {
  margin-left: .5rem;
}

.mention-container__inner-card-content {
  text-align: left;
  text-overflow: ellipsis;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
  font-size: .6875rem;
  display: inline-block;
  overflow: hidden;
}

.mention-container__inner-card-content-activity {
  direction: rtl;
}

.blinking {
  animation: 1s step-end 3 forwards blink;
}

@keyframes blink {
  0% {
    background-color: #e8e8e8;
  }

  70% {
    background-color: rgba(0, 0, 0, 0);
  }

  100% {
    background-color: #e8e8e8;
  }
}
