.CommentsAction {
  letter-spacing: -.4px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 1.33333;
  display: flex;
}

.CommentsAction__workerName {
  margin-right: 6px;
}

.CommentsAction__actionText {
  color: #787676;
}

.CommentsAction__status {
  margin-left: 6px;
}
