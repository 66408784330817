.slider {
  justify-content: center;
  align-items: center;
  display: flex;
}

.slider__wrap {
  justify-content: center;
  align-items: center;
  width: 1275px;
  display: flex;
  position: relative;
}

.slider__controlContainer {
  z-index: 4;
  justify-content: space-between;
  width: calc(100% - 20px);
  max-width: 546px;
  transition: all .2s;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slider__control {
  background-color: #fff;
  border: 1px solid #e5e7eb;
  border-radius: 50%;
  width: 42px;
  height: 42px;
}

.slider__control__icon {
  padding: 5px 2px 0 0;
}

.slider__control__icon_right {
  padding: 5px 0 0 2px;
}

.slider__prev {
  opacity: .6;
  max-width: 400px;
  max-height: 262px;
  font-size: 12px;
  display: flex;
  position: absolute;
  left: 0;
  scale: .75;
}

.slider__next {
  opacity: .6;
  max-width: 400px;
  max-height: 262px;
  font-size: 12px;
  display: flex;
  position: absolute;
  right: 0;
  scale: .75;
}

.slider__active {
  z-index: 3;
  max-width: 576px;
  height: 262px;
  font-size: 12px;
  display: flex;
}

.slider__active:hover .slider__controlContainer {
  display: flex;
}

.slider__item {
  background-color: #fff;
  width: 330px;
}
