.CtrlChipGroup__title {
  letter-spacing: -.4px;
  color: #a5a4a4;
  margin-bottom: 12px;
  margin-left: 8px;
  font-size: 12px;
  line-height: 1.33333;
}

.CtrlChipGroup__grid {
  flex-wrap: wrap;
  align-items: center;
  margin: -3px;
  display: flex;
}

.CtrlChipGroup__item {
  margin: 3px;
}
