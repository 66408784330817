@keyframes avatar-bounse {
  0%, 100% {
    animation-timing-function: cubic-bezier(.8, 0, 1, 1);
    transform: translateY(0);
  }

  50% {
    animation-timing-function: cubic-bezier(0, 0, .2, 1);
    transform: translateY(-15px);
  }
}

.mediaBlocks {
  width: 100%;
  max-width: 500px;
  margin-top: 24px;
  position: relative;
}

@media only screen and (min-width: 1024px) {
  .mediaBlocks {
    max-width: 500px;
    margin-top: 0;
  }
}

@media only screen and (min-width: 1300px) {
  .mediaBlocks {
    max-width: 750px;
  }
}

.mediaBlocks__image {
  border-radius: 6px;
  width: 100%;
  max-width: 100%;
  height: auto;
  display: block;
}

@media only screen and (min-width: 1024px) {
  .mediaBlocks__image {
    text-align: left;
  }
}

.mediaBlocks__image_shadow {
  box-shadow: 0 25px 20px -12px rgba(0, 0, 0, .25);
}

.mediaBlocks__image_qrcode {
  width: 22.763%;
  position: absolute;
  top: 29.1667%;
  left: 88.6185%;
}

.mediaBlocks__image_notify {
  width: 45.2119%;
  position: absolute;
  top: 25%;
  left: 77.394%;
}

.mediaBlocks__image_notifyBottom {
  top: 38.5417%;
}

.mediaBlocks__imageItem {
  border-radius: 6px;
  width: 100%;
  max-width: 100%;
  height: auto;
  transition-property: transform;
  transition-duration: .3s;
  transition-timing-function: ease;
  display: block;
}

.mediaBlocks__imageItem:hover {
  transform: scale(1.25);
}

.mediaBlocks__realTime {
  max-width: 220px;
}

@media only screen and (min-width: 420px) {
  .mediaBlocks__realTime {
    max-width: 280px;
  }
}

@media only screen and (min-width: 576px) {
  .mediaBlocks__realTime {
    max-width: 400px;
  }
}

@media only screen and (min-width: 1024px) {
  .mediaBlocks__realTime {
    max-width: 500px;
  }
}

@media only screen and (min-width: 1300px) {
  .mediaBlocks__realTime {
    max-width: 750px;
  }
}

.mediaBlocks__streamlineWork {
  justify-content: center;
  align-items: flex-start;
  width: 200px;
  display: flex;
  position: relative;
}

@media only screen and (min-width: 1024px) {
  .mediaBlocks__streamlineWork {
    margin-right: 200px;
  }
}

.mediaBlocks__streamlineWork__phone {
  filter: drop-shadow(2px 4px 7px rgba(102, 102, 85, .333));
  width: 120px;
}

@media only screen and (min-width: 576px) {
  .mediaBlocks__streamlineWork__phone {
    width: 170px;
  }
}

@media only screen and (min-width: 1024px) {
  .mediaBlocks__streamlineWork__phone {
    width: 240px;
  }
}

.mediaBlocks__streamlineWork__checklist {
  filter: drop-shadow(2px 4px 7px rgba(102, 102, 85, .333));
  width: 80px;
  display: none;
  position: absolute;
  top: 30px;
  left: -320px;
}

@media only screen and (min-width: 800px) {
  .mediaBlocks__streamlineWork__checklist {
    width: 120px;
    display: block;
    top: 70px;
    left: -220px;
  }
}

@media only screen and (min-width: 1024px) {
  .mediaBlocks__streamlineWork__checklist {
    width: 160px;
    top: 30px;
    left: -320px;
  }
}

.mediaBlocks__streamlineWork__worker3 {
  filter: drop-shadow(2px 4px 7px rgba(102, 102, 85, .333));
  border-radius: 0 0 0 6px;
  width: 61px;
  position: absolute;
  left: -50px;
}

@media only screen and (min-width: 576px) {
  .mediaBlocks__streamlineWork__worker3 {
    width: 90px;
    left: -100px;
  }
}

@media only screen and (min-width: 1024px) {
  .mediaBlocks__streamlineWork__worker3 {
    width: 112px;
    left: -154px;
  }
}

.mediaBlocks__streamlineWork__worker2 {
  filter: drop-shadow(2px 4px 7px rgba(102, 102, 85, .333));
  border-radius: 0 0 0 6px;
  width: 80px;
  position: absolute;
  top: 130px;
  left: -65px;
}

@media only screen and (min-width: 576px) {
  .mediaBlocks__streamlineWork__worker2 {
    width: 100px;
    top: 170px;
    left: -100px;
  }
}

@media only screen and (min-width: 1024px) {
  .mediaBlocks__streamlineWork__worker2 {
    width: 160px;
    top: 260px;
    left: -202px;
  }
}

.mediaBlocks__streamlineWork__worker1 {
  filter: drop-shadow(2px 4px 7px rgba(102, 102, 85, .333));
  border-radius: 0 0 0 6px;
  width: 80px;
  animation: 1s infinite avatar-bounse;
  position: absolute;
  right: -65px;
}

@media only screen and (min-width: 576px) {
  .mediaBlocks__streamlineWork__worker1 {
    width: 110px;
    right: -130px;
  }
}

@media only screen and (min-width: 1024px) {
  .mediaBlocks__streamlineWork__worker1 {
    width: 160px;
    right: -203px;
  }
}

.mediaBlocks__streamlineWork__document {
  filter: drop-shadow(2px 4px 7px rgba(102, 102, 85, .333));
  width: 64px;
  position: absolute;
  top: 150px;
  right: -50px;
}

@media only screen and (min-width: 576px) {
  .mediaBlocks__streamlineWork__document {
    width: 100px;
    top: 220px;
    right: -120px;
  }
}

@media only screen and (min-width: 1024px) {
  .mediaBlocks__streamlineWork__document {
    width: 128px;
    top: 300px;
    right: -210px;
  }
}
