.CtrlCheckOption {
  box-sizing: border-box;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 9px;
  align-items: center;
  width: 100%;
  padding: 10px 7px;
  transition-property: color, background-color, border-color;
  transition-duration: .125s;
  transition-timing-function: ease-in-out;
  display: flex;
  position: relative;
}

.CtrlCheckOption_hidden {
  display: none;
}

.CtrlCheckOption__field {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.CtrlCheckOption__field:checked ~ .CtrlCheckOption__visualBox .CtrlCheckOption__visual {
  background-color: #053fe3;
  border-color: #053fe3;
}

.CtrlCheckOption__visualBox {
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  display: flex;
}

.CtrlCheckOption__visual {
  box-sizing: border-box;
  color: #fff;
  background-color: #fff;
  border: 1px solid #a5a4a4;
  border-radius: 5px;
  width: 16px;
  height: 16px;
  transition-property: background-color, border-color;
  transition-duration: .125s;
  transition-timing-function: ease-in-out;
  display: block;
}

.CtrlCheckOption__visualIcon {
  width: 14px;
  height: 14px;
  display: block !important;
}

.CtrlCheckOption__label {
  padding-top: 4px;
  font-size: 100%;
  line-height: 1.15;
}

.CtrlCheckOption:hover, .CtrlCheckOption:focus {
  background-color: #e6ecfc;
  border-color: #e6ecfc;
}

.CtrlCheckOption:active {
  color: #fff;
  background-color: #3765e9;
  border-color: #3765e9;
}
