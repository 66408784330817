.laptopMedia {
  width: 346px;
  height: 346px;
  margin: 60px auto 70px;
  position: relative;
}

.laptopMedia:before {
  content: "";
  background-color: #3c3939;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.laptopMedia__desktop {
  width: 383px;
  height: 289px;
  position: absolute;
  top: 51px;
  right: 32px;
}

.laptopMedia__desktop:after {
  content: "";
  position: inherit;
  background-image: linear-gradient(270deg, #1e1b1b 21.11%, rgba(30, 27, 27, 0) 100%);
  width: 309px;
  top: -63px;
  bottom: -39px;
  right: -100px;
}

.laptopMedia__mobile {
  width: 199px;
  height: 221px;
  position: absolute;
  top: 119px;
  right: -8px;
}

.laptopMedia__screenshot {
  width: 100%;
  max-width: 100%;
  height: auto;
  display: block;
}
