.ctrlColor {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  position: relative;
}

.ctrlColor__field {
  opacity: 0;
  cursor: inherit;
  position: absolute;
  top: 0;
  left: 0;
}

.ctrlColor__field:checked ~ .ctrlColor__visualBox {
  border-color: #73c47e;
}

.ctrlColor__field:checked ~ .ctrlColor__iconBox {
  opacity: 1;
}

.ctrlColor__visualBox {
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 7px;
  width: 40px;
  height: 40px;
  padding: 2px;
  transition-property: border-color;
  transition-duration: .125s;
  transition-timing-function: ease-in-out;
  display: block;
}

.ctrlColor__visual {
  background-color: #d3d3d3;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

.ctrlColor__text {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.ctrlColor__iconBox {
  opacity: 0;
  background-color: #21a032;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 14px;
  transition-property: opacity;
  transition-duration: .125s;
  transition-timing-function: ease-in-out;
  display: flex;
  position: absolute;
  bottom: -3px;
  left: calc(50% - 10px);
}

.ctrlColor__icon {
  color: #fff;
  width: 12px;
  height: 12px;
  margin-top: 1px;
}

.ctrlColor:hover .ctrlColor__field:not(:checked) ~ .ctrlColor__visualBox, .ctrlColor:focus .ctrlColor__field:not(:checked) ~ .ctrlColor__visualBox {
  border-color: #d2d1d1;
}
