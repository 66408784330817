.creationPopup {
  max-width: 340px !important;
}

.creationPopup :global .popup__body {
  overflow: unset;
}

.form {
  padding: 15px;
}

.form__formControl {
  margin-top: 10px;
}

.form__formControl:first-child {
  margin-top: 0;
}

.form__title {
  letter-spacing: -1px;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

.form__title_block {
  margin-bottom: 10px;
}

.form__formActions {
  justify-content: space-between;
  margin-top: 15px;
  display: flex;
}

.form__submitButton {
  margin-left: auto;
}
