.laptopSteps__steps {
  counter-reset: step;
  text-align: center;
  background-color: #fff;
  border-radius: 16px;
  padding: 16px;
  box-shadow: 24px 24px 36px rgba(0, 0, 0, .07);
}

.laptopSteps__step {
  color: #1e1b1b;
  text-align: center;
  background-color: #fff;
  border: 1px solid rgba(5, 63, 227, .25);
  border-radius: 8px;
  margin-top: 8px;
  padding: 45px 16px;
  font-size: 16px;
  line-height: 1.5;
}

.laptopSteps__step:before {
  counter-increment: step;
  content: counter(step);
  letter-spacing: -1.5px;
  color: rgba(5, 63, 227, .25);
  font-size: 24px;
  font-weight: 500;
  line-height: 1.08333;
}

.laptopSteps__step:first-child {
  margin-top: 0;
}

.laptopSteps__stepText {
  font-weight: 400;
  font-size: inherit;
  margin-top: 8px;
}

.laptopSteps__address {
  margin-top: 8px;
  font-weight: 500;
}

.laptopSteps__address_web {
  color: #053fe3;
}

.laptopSteps__address_email {
  word-wrap: break-word;
}

.laptopSteps__next {
  color: rgba(5, 63, 227, .25);
  margin-top: 8px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  transform: rotate(-90deg);
}

.laptopSteps__note {
  text-align: center;
  color: rgba(30, 27, 27, .75);
  background-color: #fff;
  border-radius: 16px;
  margin-top: 24px;
  padding: 16px;
  font-size: 16px;
  line-height: 1.5;
  box-shadow: 24px 24px 36px rgba(0, 0, 0, .07);
}

.laptopSteps__actions {
  margin-top: 24px;
}

.laptopSteps__button {
  width: 100%;
  box-shadow: 18px 18px 24px rgba(5, 63, 227, .15);
}
