.infoMember {
  align-items: center;
  display: flex;
}

.infoMember__avatar {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
}

.infoMember__name {
  letter-spacing: -.4px;
  color: #1e1b1b;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  overflow: hidden;
}

.infoMember_size_m .infoMember__avatar {
  width: 32px;
  height: 32px;
}
