@media print {
  .header {
    display: none;
  }
}

.header__booking {
  margin-top: 16px;
  margin-left: -4px;
  display: flex;
}
