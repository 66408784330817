.workspaceSwitcher {
  background-color: #fff;
  border-radius: .75rem;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: .625rem;
  display: flex;
}

.workspaceSwitcher__dropdown {
  flex-direction: column;
  display: flex;
}

.workspaceSwitcher__item {
  border-radius: .5rem;
  padding: .3125rem .9375rem;
}

.workspaceSwitcher__item :global .icon {
  width: 1rem;
  height: 1rem;
}
