.ctrlForm {
  width: 100%;
  display: block;
}

.ctrlForm__label {
  letter-spacing: -.025rem;
  color: #787676;
  margin-bottom: .25rem;
  font-size: .75rem;
  line-height: 1.33333;
  display: inline-block;
}

.ctrlForm__labelStar {
  color: #fa380d;
  margin-left: .125rem;
}

.ctrlForm__body {
  padding-bottom: 1.125rem;
  position: relative;
}

.ctrlForm__body_error {
  --validation-color: c. $ error;
  border: .0625rem solid #fa380d;
  border-radius: .625rem;
}

.ctrlForm__body :global .react-select__control {
  box-sizing: border-box;
  letter-spacing: -.025rem;
  background-color: #f4f3f3;
  border: .0625rem solid #f4f3f3;
  border-radius: .5625rem;
  width: 100%;
  padding: .4375rem .5625rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition-property: color, border-color, outline-color;
  transition-duration: .125s;
  transition-timing-function: ease-in-out;
}

.ctrlForm__body :global .react-select__control:hover {
  border-color: #053fe3;
  box-shadow: 0 0 0 .1875rem #e6ecfc;
}

.ctrlForm__body :global .react-select__control--is-focused {
  border-color: #053fe3;
  box-shadow: 0 0 0 .1875rem #e6ecfc;
}

.ctrlForm__body :global .react-select__clear-indicator svg {
  color: #787676;
}

.ctrlForm__body :global .react-select__dropdown-indicator svg {
  color: #787676;
}

.ctrlForm__body :global .react-select__indicators {
  margin-right: 0;
}

.ctrlForm textarea {
  box-sizing: border-box;
  letter-spacing: -.025rem;
  background-color: #f4f3f3;
  border: .0625rem solid #f4f3f3;
  border-radius: .5625rem;
  width: 100%;
  padding: .4375rem .5625rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition-property: color, border-color, outline-color;
  transition-duration: .125s;
  transition-timing-function: ease-in-out;
  display: block;
}

.ctrlForm input[type="text"] {
  box-sizing: border-box;
  letter-spacing: -.025rem;
  background-color: #f4f3f3;
  border: .0625rem solid #f4f3f3;
  border-radius: .5625rem;
  width: 100%;
  padding: .4375rem .5625rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition-property: color, border-color, outline-color;
  transition-duration: .125s;
  transition-timing-function: ease-in-out;
  display: block;
}

.ctrlForm input[type="email"] {
  box-sizing: border-box;
  letter-spacing: -.025rem;
  background-color: #f4f3f3;
  border: .0625rem solid #f4f3f3;
  border-radius: .5625rem;
  width: 100%;
  padding: .4375rem .5625rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition-property: color, border-color, outline-color;
  transition-duration: .125s;
  transition-timing-function: ease-in-out;
  display: block;
}

.ctrlForm input[type="number"] {
  box-sizing: border-box;
  letter-spacing: -.025rem;
  background-color: #f4f3f3;
  border: .0625rem solid #f4f3f3;
  border-radius: .5625rem;
  width: 100%;
  padding: .4375rem .5625rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition-property: color, border-color, outline-color;
  transition-duration: .125s;
  transition-timing-function: ease-in-out;
  display: block;
}

.ctrlForm input[type="phone"] {
  box-sizing: border-box;
  letter-spacing: -.025rem;
  background-color: #f4f3f3;
  border: .0625rem solid #f4f3f3;
  border-radius: .5625rem;
  width: 100%;
  padding: .4375rem .5625rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition-property: color, border-color, outline-color;
  transition-duration: .125s;
  transition-timing-function: ease-in-out;
  display: block;
}

.ctrlForm input[type="date"] {
  box-sizing: border-box;
  letter-spacing: -.025rem;
  background-color: #f4f3f3;
  border: .0625rem solid #f4f3f3;
  border-radius: .5625rem;
  width: 100%;
  padding: .4375rem .5625rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition-property: color, border-color, outline-color;
  transition-duration: .125s;
  transition-timing-function: ease-in-out;
  display: block;
}

.ctrlForm input[type="text"]::placeholder {
  opacity: 1;
  color: #787676;
}

.ctrlForm input[type="email"]::placeholder {
  opacity: 1;
  color: #787676;
}

.ctrlForm input[type="number"]::placeholder {
  opacity: 1;
  color: #787676;
}

.ctrlForm input[type="phone"]::placeholder {
  opacity: 1;
  color: #787676;
}

.ctrlForm input[type="date"]::placeholder {
  opacity: 1;
  color: #787676;
}

.ctrlForm textarea::placeholder {
  opacity: 1;
  color: #787676;
}

.ctrlForm textarea:hover, .ctrlForm textarea:focus {
  border-color: #053fe3;
  box-shadow: 0 0 0 .1875rem #e6ecfc;
}

.ctrlForm input[type="text"]:hover {
  border-color: #053fe3;
  box-shadow: 0 0 0 .1875rem #e6ecfc;
}

.ctrlForm input[type="text"]:focus {
  border-color: #053fe3;
  box-shadow: 0 0 0 .1875rem #e6ecfc;
}

.ctrlForm input[type="email"]:hover {
  border-color: #053fe3;
  box-shadow: 0 0 0 .1875rem #e6ecfc;
}

.ctrlForm input[type="email"]:focus {
  border-color: #053fe3;
  box-shadow: 0 0 0 .1875rem #e6ecfc;
}

.ctrlForm input[type="number"]:hover {
  border-color: #053fe3;
  box-shadow: 0 0 0 .1875rem #e6ecfc;
}

.ctrlForm input[type="number"]:focus {
  border-color: #053fe3;
  box-shadow: 0 0 0 .1875rem #e6ecfc;
}

.ctrlForm input[type="phone"]:hover {
  border-color: #053fe3;
  box-shadow: 0 0 0 .1875rem #e6ecfc;
}

.ctrlForm input[type="phone"]:focus {
  border-color: #053fe3;
  box-shadow: 0 0 0 .1875rem #e6ecfc;
}

.ctrlForm input[type="date"]:hover {
  border-color: #053fe3;
  box-shadow: 0 0 0 .1875rem #e6ecfc;
}

.ctrlForm input[type="date"]:focus {
  border-color: #053fe3;
  box-shadow: 0 0 0 .1875rem #e6ecfc;
}

.ctrlForm input[type="date"]::-webkit-datetime-edit {
  margin-bottom: -.25rem;
  padding: 0;
  line-height: 1;
}

.ctrlForm textarea {
  resize: vertical;
  height: 2.5rem;
  min-height: 2.5rem;
}

.ctrlForm :global .ctrl-textfield {
  box-sizing: border-box;
  letter-spacing: -.025rem;
  background-color: #f4f3f3;
  border: .0625rem solid #f4f3f3;
  border-radius: .5625rem;
  width: 100%;
  padding: .4375rem .5625rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition-property: color, border-color, outline-color;
  transition-duration: .125s;
  transition-timing-function: ease-in-out;
  display: block;
}

.ctrlForm :global .ctrl-textfield::placeholder {
  opacity: 1;
  color: #787676;
}

.ctrlForm :global .ctrl-textfield:hover {
  border-color: #053fe3;
  box-shadow: 0 0 0 .1875rem #e6ecfc;
}

.ctrlForm :global .ctrl-textfield:focus {
  border-color: #053fe3;
  box-shadow: 0 0 0 .1875rem #e6ecfc;
}

.ctrlForm__icon {
  color: #787676;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: .4375rem;
  right: .5625rem;
}

.ctrlForm__button {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: .4375rem;
  right: .5625rem;
}

.ctrlForm__validation {
  letter-spacing: -.025rem;
  color: var(--validation-color, #787676);
  font-size: .75rem;
  line-height: 1.33333;
  position: absolute;
  top: 2.625rem;
  left: 0;
}

.ctrlForm_label_isHidden .ctrlForm__label {
  display: none;
}

.ctrlForm_withIcon input[type="text"] {
  padding-right: 2.5rem;
}

.ctrlForm_withIcon input[type="email"] {
  padding-right: 2.5rem;
}

.ctrlForm_withIcon input[type="number"] {
  padding-right: 2.5rem;
}

.ctrlForm_withIcon input[type="date"] {
  padding-right: 2.5rem;
}

.ctrlForm_withIcon input[type="phone"] {
  padding-right: 2.5rem;
}

.ctrlForm_icon_start .ctrlForm__icon {
  left: .5625rem;
  right: auto;
}

.ctrlForm_icon_start.ctrlForm_withIcon input[type="text"] {
  padding-left: 2.5rem;
}

.ctrlForm_icon_start.ctrlForm_withIcon input[type="email"] {
  padding-left: 2.5rem;
}

.ctrlForm_icon_start.ctrlForm_withIcon input[type="number"] {
  padding-left: 2.5rem;
}

.ctrlForm_icon_start.ctrlForm_withIcon input[type="date"] {
  padding-left: 2.5rem;
}

.ctrlForm_icon_start.ctrlForm_withIcon input[type="phone"] {
  padding-left: 2.5rem;
}

.ctrlForm_withoutValidation .ctrlForm__body {
  padding-bottom: 0;
}

.ctrlForm_height_h36 input[type="text"] {
  padding-top: .3125rem;
  padding-bottom: .3125rem;
}

.ctrlForm_height_h36 input[type="email"] {
  padding-top: .3125rem;
  padding-bottom: .3125rem;
}

.ctrlForm_height_h36 input[type="number"] {
  padding-top: .3125rem;
  padding-bottom: .3125rem;
}

.ctrlForm_height_h36 input[type="date"] {
  padding-top: .3125rem;
  padding-bottom: .3125rem;
}

.ctrlForm_height_h36 input[type="phone"] {
  padding-top: .3125rem;
  padding-bottom: .3125rem;
}

.ctrlForm_height_h36 .ctrlForm__icon, .ctrlForm_height_h36 .ctrlForm__button {
  top: .375rem;
}

.ctrlForm_isError {
  --validation-color: c. $ error;
}

.ctrlForm_isSuccess {
  --validation-color: c. $ success;
}
