.CtrlCheck {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.CtrlCheck__field {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.CtrlCheck__field:focus ~ .CtrlCheck__visualBox .CtrlCheck__visual {
  border-color: #698cee;
  box-shadow: 0 0 0 2px #cdd9f9;
}

.CtrlCheck__field:active ~ .CtrlCheck__visualBox .CtrlCheck__visual, .CtrlCheck__field:checked ~ .CtrlCheck__visualBox .CtrlCheck__visual {
  background-color: #053fe3;
  border-color: #053fe3;
}

.CtrlCheck__visualBox {
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-right: 7px;
  display: flex;
}

.CtrlCheck__visual {
  box-sizing: border-box;
  color: #fff;
  background-color: #fff;
  border: 1px solid #a5a4a4;
  border-radius: 5px;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  transition-property: background-color, border-color, outline-color, outline-width;
  transition-duration: .125s;
  transition-timing-function: ease-in-out;
}

.CtrlCheck__visualIcon {
  width: 14px;
  height: 14px;
  display: block !important;
}

.CtrlCheck__body {
  min-width: 0;
  margin-top: 4px;
}

.CtrlCheck__label {
  letter-spacing: -.4px;
  color: #1e1b1b;
  -webkit-user-select: none;
  user-select: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  display: flex;
}

.CtrlCheck__description {
  letter-spacing: -.4px;
  color: #787676;
  margin-top: 4px;
  font-size: 12px;
  line-height: 1.33333;
  display: block;
}

.CtrlCheck:hover .CtrlCheck__visualBox .CtrlCheck__visual {
  border-color: #698cee;
  box-shadow: 0 0 0 2px #cdd9f9;
}

.CtrlCheck_labelIsHidden .CtrlCheck__visualBox {
  margin-right: 0;
}

.CtrlCheck_labelIsHidden .CtrlCheck__label {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.CtrlCheck_radio .CtrlCheck__visual {
  border-radius: 50%;
  position: relative;
}

.CtrlCheck_radio .CtrlCheck__visual:before {
  content: "";
  border-radius: inherit;
  background-color: #e9e8e8;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.CtrlCheck_radio .CtrlCheck__field:active ~ .CtrlCheck__visualBox .CtrlCheck__visual:before, .CtrlCheck_radio .CtrlCheck__field:checked ~ .CtrlCheck__visualBox .CtrlCheck__visual:before {
  background-color: #fff;
}

.CtrlCheck_labelSize_s .CtrlCheck__body {
  margin-top: 3px;
}

.CtrlCheck_labelSize_s .CtrlCheck__label {
  font-size: 14px;
  line-height: 1.14286;
}

.CtrlCheck_labelSize_m .CtrlCheck__body {
  margin-top: 3px;
}

.CtrlCheck_labelSize_m .CtrlCheck__label {
  font-size: 16px;
  line-height: 1.125;
}

.CtrlCheck_disable {
  color: #b5b5b5;
  pointer-events: none;
}

.CtrlCheck_view_circle .CtrlCheck__visual {
  border-radius: 50%;
}

.CtrlCheck_reverse {
  flex-direction: row-reverse;
}

.CtrlCheck_reverse .CtrlCheck__visualBox {
  align-items: center;
  margin-left: 7px;
  margin-right: 0;
}
