.pointer {
  cursor: pointer;
}

.icon {
  vertical-align: middle;
  color: #000;
  fill: currentColor;
  width: 28px;
  height: 28px;
  margin-bottom: 2px;
  display: inline-block;
}

.icon_color_fill {
  fill: currentColor;
}

@keyframes border-loop {
  to {
    stroke-dashoffset: 720px;
  }
}
