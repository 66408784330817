.ctrlSelector {
  justify-content: center;
  align-items: center;
  display: flex;
}

.ctrlSelector__title {
  letter-spacing: -.4px;
  margin-right: 8px;
  font-size: 14px;
  line-height: 1.14286;
}

.ctrlSelector__container {
  background-color: #fff;
  border: 1px solid #e9e8e8;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 2px;
  display: flex;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .08);
}

.ctrlSelector__button {
  margin-right: 4px;
}

.ctrlSelector__button:last-child {
  margin-right: 0;
}

.ctrlSelector_view_block {
  display: block;
}

.ctrlSelector_view_block .ctrlSelector__button {
  flex-grow: 1;
  flex-basis: 20%;
}
