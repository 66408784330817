.bulkWatcher__addGroup {
  justify-content: center;
  margin-top: 5px;
  display: flex;
}

.subcontractorInfo {
  align-items: center;
  display: inline-flex;
}

.subcontractorInfo__color {
  margin-right: 32px;
}

.subcontractorInfo__text {
  letter-spacing: -.4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.subcontractorInfo__numbers {
  font-size: 12px;
  font-weight: 500;
}

.subcontractorInfo__preview {
  box-sizing: border-box;
  letter-spacing: -1px;
  color: #fff;
  background-color: #d3d3d3;
  border-radius: 9px;
  justify-content: center;
  align-items: center;
  width: 36px;
  min-width: 36px;
  height: 36px;
  min-height: 36px;
  margin-right: 12px;
  padding: 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.55556;
  display: flex;
}

.selectLabel {
  display: flex;
}

.selectLabel__name {
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 2px;
  overflow: hidden;
}
