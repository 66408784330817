.confirmation {
  box-sizing: border-box;
  z-index: 42002;
  background: rgba(30, 27, 27, .4);
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 34px;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.confirmation__item {
  width: 100%;
  max-width: 350px;
  max-width: var(--item-width, 350px);
  background-color: #fff;
  border-radius: 16px;
  flex-direction: column;
  margin: auto;
  display: flex;
  position: relative;
}

.confirmation__header {
  text-align: center;
  padding: 24px 24px 16px;
  position: relative;
}

.confirmation__title {
  text-align: left;
  letter-spacing: -1px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

.confirmation :global .confirmation__description {
  text-align: left;
  color: #4b4949;
  letter-spacing: -.4px;
  align-items: flex-start;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
}

.confirmation__footer {
  background: rgba(30, 27, 27, .05);
  border-top: 1px solid #f5f5f5;
  flex-direction: row;
  justify-content: center;
  padding: 16px;
  display: flex;
}

.confirmation__footer--actions {
  flex-direction: row;
  align-items: center;
  gap: 12px;
  width: 100%;
  padding: 0;
  display: flex;
}

.confirmation__footer--actions :global .ctrl-btn {
  border-radius: 9px;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 400;
}

.confirmation .confirmation__button {
  flex: 50%;
  max-width: 30%;
}

.confirmation .confirmation__button:first-child {
  margin-left: 0;
  margin-right: auto;
}

.confirmation .confirmation__button--cancel {
  color: #000;
  background-color: #fff;
  border: 1px solid #d2d1d1;
}

.confirmation__button-close {
  color: #fff;
  opacity: .9;
  position: absolute;
  bottom: 100%;
  left: 100%;
}
