.error-boundary__wrapper {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.error-boundary__inner {
  opacity: .5;
}
