.CommentsNew {
  letter-spacing: -.4px;
  color: #698cee;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  line-height: 1.33333;
  display: flex;
}

.CommentsNew:before, .CommentsNew:after {
  content: "";
  background: #cdd9f9;
  border-radius: 1px;
  flex-grow: 1;
  flex-basis: 10px;
  height: 1px;
}

.CommentsNew:before {
  margin-right: 12px;
}

.CommentsNew:after {
  margin-left: 12px;
}
