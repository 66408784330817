.tableWorkers {
  letter-spacing: -.4px;
  color: #1e1b1b;
  background-color: #fff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  flex-grow: 1;
  margin-bottom: -24px;
  padding: 14px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  display: flex;
  min-width: 0 !important;
}

.tableWorkers__table {
  background: #f4f3f3;
  border: 1px solid #d2d1d1;
  border-radius: 9px;
  flex-direction: column;
  width: 100%;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
}

.tableWorkers__line {
  display: flex;
}

.tableWorkers__line_header {
  -webkit-user-select: none;
  user-select: none;
  flex-grow: 0;
}

.tableWorkers__line_header .tableWorkers__cell:last-child .tableWorkers__thResizer {
  display: none;
}

.tableWorkers__line:last-child .TableWorkers__cell {
  border-bottom-width: 0;
}

.tableWorkers__body {
  display: flex;
}

.tableWorkers__cell {
  background-color: #fff;
  border-bottom: 1px solid #d2d1d1;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  padding: 24px 18px;
  display: flex;
}

.tableWorkers__cell_th {
  color: #787676;
  background-color: #f4f3f3;
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 12px;
  line-height: 1.33333;
  position: relative;
}

.tableWorkers__cell_firstName, .tableWorkers__cell_subcontractor {
  z-index: 1;
  border-right: 1px solid #d2d1d1;
  width: 220px;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
}

.tableWorkers__cell_trade, .tableWorkers__cell_role, .tableWorkers__cell_orgMappingIds, .tableWorkers__cell_status, .tableWorkers__cell_phone, .tableWorkers__celldesignated_person, .tableWorkers__cellworkers_count, .tableWorkers__cell_email {
  width: 150px;
}

.tableWorkers__cell_status {
  display: flex;
}

.tableWorkers__status, .tableWorkers__orgsList {
  margin-top: -4px;
  margin-bottom: -4px;
}

.tableWorkers__firstName {
  margin-top: -8px;
  margin-bottom: -8px;
  margin-left: -4px;
}

.tableWorkers__thResizer {
  z-index: 10;
  width: 21px;
  height: 16px;
  position: absolute;
  top: calc(50% - 8px);
  right: -10.5px;
}

.tableWorkers__thResizer:after {
  content: "";
  background: #d2d1d1;
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: calc(50% - 1px);
}
