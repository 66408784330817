.ProjectCollabPopup {
  max-width: 650px !important;
}

.ProjectCollabPopup :global .ctrlBtnOption {
  margin-bottom: 0;
}

.ProjectCollabPopup_header {
  padding: 36px 20px 20px;
}

.ProjectCollabPopup_box {
  flex-direction: column;
  justify-content: space-between;
  min-height: 250px;
  padding: 20px;
  display: flex;
}

.ProjectCollabPopup_box .collabNote {
  margin-top: 15px;
}

.ProjectCollabPopup_box .collabNote__title {
  font-size: 17px;
  font-weight: 400;
}

.ProjectCollabPopup_box .collabNote__textarea {
  background-color: #f5f5f5;
  min-height: 96px;
}

.ProjectCollabPopup_box .collabNote__charCounter {
  float: right;
  padding: .1rem 0 0;
  font-size: .875rem;
}

.ProjectCollabPopup_box .selectWorkers {
  box-sizing: border-box;
}

.ProjectCollabPopup_box .selectedWorkers {
  margin-top: 30px;
}

.ProjectCollabPopup_box .selectedWorkers__placeholder {
  text-align: center;
  color: #7a7a7a;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: auto;
  display: flex;
}

.ProjectCollabPopup_box .selectedWorkers__header {
  justify-content: space-between;
  display: flex;
}

.ProjectCollabPopup_box .selectedWorkers__title {
  font-size: 17px;
  font-weight: 400;
}

.ProjectCollabPopup_box .selectedWorkers_list {
  flex-direction: column;
  min-height: 84px;
  max-height: 165px;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  overflow-y: auto;
}

.ProjectCollabPopup_box .selectedWorkers_list__item {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  display: inline-flex;
}

.ProjectCollabPopup_box .selectedWorkers_list__item:last-child {
  margin-bottom: 0;
}

.ProjectCollabPopup_box .selectedWorkers_list__item__userInfo {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

.ProjectCollabPopup_box .selectedWorkers_list__item__userInfo--email {
  color: #cecece;
  font-size: 12px;
}

.ProjectCollabPopup_box .selectedWorkers_list__item_actions {
  display: flex;
}

.ProjectCollabPopup_box_actions {
  flex-flow: row;
  justify-content: space-between;
  margin-top: 20px;
  display: flex;
}
