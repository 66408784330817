.collapse {
  grid-template-rows: 0fr;
  display: grid;
}

.collapse.isOpen {
  grid-template-rows: 1fr;
}

.collapse .inner {
  overflow: hidden;
}

.collapse.isOpen .inner {
  overflow: unset;
}
