.review {
  background-color: #fff;
  margin: 0;
  padding: 40px 55px;
  box-shadow: 1px 1px 20px 11px #f6f7f8;
}

.review__text {
  -webkit-line-clamp: 5;
  text-align: center;
  -webkit-box-orient: vertical;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  display: -webkit-box;
  overflow: hidden;
}

.review__author {
  text-align: center;
  align-items: center;
  max-width: 400px;
  margin: 20px auto 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.42857;
  display: flex;
}

.review__photo {
  border-radius: 50%;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  overflow: hidden;
  box-shadow: 0 4px 6px -4px rgba(0, 0, 0, .1);
}

.review__photo img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

.review__slash {
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  margin: 0 5px;
}

.review__name {
  color: #111827;
}

.review__position {
  color: #6b7280;
}
