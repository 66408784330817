.issueForm {
  flex-direction: column;
  display: flex;
}

.issueForm__row {
  flex-direction: row;
  margin-top: 4px;
  display: flex;
}

.issueForm__item:first-child {
  margin-left: 0;
}

.issueForm .fullwidth {
  width: 100%;
}

.issueForm__item {
  flex-grow: 1;
  width: 50%;
  margin-left: 6px;
}

.issueForm__item_asignee {
  flex-grow: 1;
}

.issueForm__item_date {
  flex-shrink: 0;
  width: 140px;
  margin-left: 6px;
}
