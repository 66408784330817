.SubcontractorsPanel {
  border: 1px solid #e9e8e8;
  border-top-right-radius: 9px;
  flex-shrink: 0;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.SubcontractorsPanel__viewport {
  background-color: #fff;
  flex-direction: column;
  width: 394px;
  display: flex;
}

.SubcontractorsPanel__header {
  border-bottom: 1px solid #e9e8e8;
  flex-shrink: 0;
  padding: 24px 20px;
}

.SubcontractorsPanel__headerActions {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.SubcontractorsPanel__body {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
}
