.root {
  color: #1e1b1b;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: #e6ecfc;
  border-radius: .75rem;
  height: 1.5rem;
  padding-left: .75rem;
  padding-right: .75rem;
  font-size: .75rem;
  font-weight: 500;
  line-height: 1.5625rem;
  display: inline-block;
  overflow: hidden;
}

.xs {
  height: 1.125rem;
  font-size: .625rem;
  line-height: 1.125rem;
}

.xs .iconWrap svg {
  margin: 0 0 0 .125rem;
}

.iconWrap {
  width: 1rem;
  height: 1rem;
  overflow: hidden;
}

.iconWrap:hover {
  cursor: pointer;
}

.iconWrap svg {
  fill: currentColor;
  display: block;
}

.content {
  align-items: center;
  display: flex;
}

.pillContent {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
}

.pillGreen {
  color: #21a032;
  background-color: #a1ecab;
}

.pillRed {
  color: #fa380d;
  background: #fedcd5;
}

.pillBlue {
  color: #053fe3;
  background: #b7c9fd;
}
