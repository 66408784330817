.ctrlTabs :global .ctrl-tabs__list {
  background-color: #fff;
}

.ctrlTabs :global .ctrl-tabs__button {
  letter-spacing: -.4px;
  color: #1e1b1b;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
}

.ctrlTabs :global .ctrl-tabs__button.is-active {
  color: #053fe3;
  box-shadow: none;
  background: #e6ecfc;
  border-radius: 7px;
  font-weight: 400;
}

.ctrlTabs :global .ctrl-tabs__button[disabled] {
  opacity: .5;
  cursor: initial;
}

.ctrlTabs:global.ctrl-tabs {
  background: #fff;
  border: 1px solid #e9e8e8;
  border-radius: 10px;
  padding: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .08);
}
