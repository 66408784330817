.toolbar {
  background-color: #fff;
  border-radius: 12px;
  padding: 14px;
  display: flex;
}

.toolbar__part {
  border-left: 1px solid #e9e8e8;
  align-items: center;
  margin-left: 12px;
  padding-left: 12px;
  display: flex;
}

.toolbar__part_without_border {
  border-width: 0;
  margin-left: 0;
  padding-left: 0;
}

.toolbar__part_abyss {
  margin-right: auto;
}

.toolbar__part_abyss + .toolbar__part {
  margin-left: 20px;
}

.toolbar__part_search {
  width: 194px;
}

.toolbar__item {
  margin-right: 8px;
}

.toolbar__item:last-child {
  margin-right: 0;
}
