.error, .logging_in {
  text-align: center;
  width: 100%;
  margin-top: 30%;
  font-size: 40px;
}

.loading:after {
  content: "   ";
  animation: 1s infinite progress-ellipsis;
}

@keyframes progress-ellipsis {
  0% {
    content: "   ";
  }

  30% {
    content: ".  ";
  }

  60% {
    content: ".. ";
  }

  90% {
    content: "...";
  }
}
