.ctrlForm {
  box-sizing: border-box;
  width: 100%;
  display: block;
}

.ctrlForm_labelIsHidden .ctrlFrom__label {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}
