.ctrlBtn {
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: var(--btn-bc);
  color: var(--btn-c);
  background-color: var(--btn-bgc);
  outline-width: 0;
  outline-style: solid;
  outline-color: var(--btn-oc, transparent);
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  --btn-marker-bc-active: #fa380d;
  border-radius: 9px;
  justify-content: center;
  align-items: center;
  padding: 7px 9px;
  transition-property: color, border-color, background-color, outline-color, outline-width;
  transition-duration: .125s;
  transition-timing-function: ease-in-out;
  display: inline-flex;
}

.ctrlBtn__iconBox {
  flex-shrink: 0;
  margin-right: 4px;
}

.ctrlBtn__icon {
  color: var(--btn-icon-c, var(--btn-c));
  transition-property: color;
  transition-duration: inherit;
  transition-timing-function: inherit;
}

.ctrlBtn__iconSize, .ctrlBtn__iconDropdownSize {
  width: 24px;
  height: 24px;
}

.ctrlBtn__iconWrapper {
  margin-right: 4px;
  display: inline-flex;
}

.ctrlBtn__text {
  letter-spacing: -.4px;
  padding-top: 2px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.375;
}

.ctrlBtn:hover, .ctrlBtn:focus {
  color: var(--btn-c-hover, var(--btn-c));
  border-color: var(--btn-bc-hover, var(--btn-bc));
  background-color: var(--btn-bgc-hover, var(--btn-bgc));
  outline-width: 3px;
  outline-color: var(--btn-oc-hover, #e6ecfc);
}

.ctrlBtn:hover .ctrlBtn__icon, .ctrlBtn:focus .ctrlBtn__icon {
  color: var(--btn-icon-c-hover, var(--btn-icon-c));
}

.ctrlBtn:active {
  color: var(--btn-c-active, var(--btn-c));
  border-color: var(--btn-bc-active, var(--btn-bc));
  background-color: var(--btn-bgc-active, var(--btn-bgc));
  outline-width: 0;
  outline-color: var(--btn-oc-active, transparent);
}

.ctrlBtn:active .ctrlBtn__icon {
  color: var(--btn-icon-c-active, var(--btn-icon-c));
}

.ctrlBtn:disabled {
  opacity: .2;
}

.ctrlBtn_color_default {
  --btn-c: #fff;
  --btn-bc: #053fe3;
  --btn-bgc: #053fe3;
  --btn-icon-c: var(--btn-c);
  --btn-bc-hover: #3765e9;
  --btn-bgc-hover: #3765e9;
  --btn-bgc-focus: #3765e9;
  --btn-bc-active: #0432b6;
  --btn-bgc-active: #0432b6;
}

.ctrlBtn_color_second {
  --btn-c: #1e1b1b;
  --btn-bc: #d2d1d1;
  --btn-bgc: #fff;
  --btn-icon-c: #a5a4a4;
  --btn-bc-hover: #3765e9;
  --btn-icon-c-hover: #698cee;
  --btn-icon-c-focus: #698cee;
  --btn-bc-active: #3765e9;
  --btn-bgc-active: #e6ecfc;
  --btn-icon-c-active: #698cee;
}

.ctrlBtn_color_second.ctrlBtn_active {
  border-color: var(--btn-bc-active, var(--btn-bc));
  --btn-icon-c: var(--btn-bc-active);
  --btn-c: var(--btn-bc-active);
}

.ctrlBtn_color_tertiary {
  --btn-c: #1e1b1b;
  --btn-bc: #fff;
  --btn-bgc: #fff;
  --btn-icon-c: #a5a4a4;
  --btn-bc-hover: #cdd9f9;
  --btn-icon-c-hover: #698cee;
  --btn-icon-c-focus: #698cee;
  --btn-bc-active: #e6ecfc;
  --btn-bgc-active: #e6ecfc;
  --btn-icon-c-active: #698cee;
}

.ctrlBtn_color_action {
  --btn-c: #053fe3;
  --btn-bc: #fff;
  --btn-bgc: #fff;
  --btn-icon-c: var(--btn-c);
  --btn-bc-hover: #e6ecfc;
  --btn-bgc-hover: #e6ecfc;
  --btn-bc-focus: #e6ecfc;
  --btn-bgc-focus: #e6ecfc;
  --btn-bc-active: #698cee;
  --btn-bgc-active: #e6ecfc;
  outline: 0;
}

.ctrlBtn_color_actionWarning {
  --btn-c: #fa380d;
  --btn-bc: #fff;
  --btn-bgc: #fff;
  --btn-icon-c: var(--btn-c);
  --btn-bc-hover: #ffd0c7;
  --btn-bgc-hover: #ffd0c7;
  --btn-bc-focus: #ffd0c7;
  --btn-bgc-focus: #ffd0c7;
  --btn-bc-active: #fa380d;
  --btn-bgc-active: #ffd0c7;
  outline: 0;
}

.ctrlBtn_color_clear {
  --btn-c: #053fe3;
  --btn-bc: transparent;
  --btn-bgc: transparent;
  --btn-icon-c: var(--btn-c);
  --btn-bc-hover: #d1dafb;
  --btn-bgc-hover: #d1dafb;
  --btn-bgc-focus: #d1dafb;
  --btn-bc-active: #d1dafb;
  --btn-bgc-active: #d1dafb;
  outline: none;
}

.ctrlBtn_iconPosition_end .ctrlBtn__iconBox {
  order: 42;
  margin-left: 4px;
  margin-right: 0;
}

.ctrlBtn_iconOnly {
  padding-left: 7px;
  padding-right: 7px;
}

.ctrlBtn_iconOnly .ctrlBtn__iconWrapper {
  margin-right: 0;
}

.ctrlBtn_iconOnly .ctrlBtn__text {
  display: none;
}

.ctrlBtn_iconOnly.ctrlBtn_circleView {
  border-radius: 50%;
}

.ctrlBtn_positionInGroup_first {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ctrlBtn_positionInGroup_center {
  border-radius: 0;
}

.ctrlBtn_positionInGroup_last {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ctrlBtn_view_selector {
  outline: 0;
  padding: 3px;
}

.ctrlBtn_view_selector:hover, .ctrlBtn_view_selector:focus {
  border-color: rgba(0, 0, 0, 0);
}

.ctrlBtn_view_selector.ctrlBtn_isSelected {
  --btn-c: #053fe3;
  --btn-icon-c: #053fe3;
  --btn-bgc: #e6ecfc;
  --btn-bc: transparent;
}

.ctrlBtn_view_description {
  flex-direction: column;
  min-width: 80px;
  padding: 5px;
}

.ctrlBtn_view_description .ctrlBtn__iconBox {
  margin-right: 0;
}

.ctrlBtn_view_description .ctrlBtn__icon {
  width: 32px;
  height: 32px;
}

.ctrlBtn_view_description .ctrlBtn__text {
  margin-top: 2px;
  line-height: 1;
}

.ctrlBtn_activeDot {
  position: relative;
}

.ctrlBtn_view_link {
  padding: 0;
}

.ctrlBtn_view_link .ctrlBtn__icon {
  margin-right: 2px;
}

.ctrlBtn_view_link.ctrlBtn_iconPosition_end .ctrlBtn__icon {
  margin-left: 2px;
  margin-right: 0;
}

.ctrlBtn_view_link.ctrlBtn_color_default {
  color: #053fe3;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}

.ctrlBtn_view_link.ctrlBtn_color_default .ctrlBtn__icon {
  color: #053fe3;
}

.ctrlBtn_view_link.ctrlBtn_color_default:hover, .ctrlBtn_view_link.ctrlBtn_color_default:focus {
  color: #698cee;
  outline: none;
}

.ctrlBtn_view_link.ctrlBtn_color_default:hover .ctrlBtn__icon, .ctrlBtn_view_link.ctrlBtn_color_default:focus .ctrlBtn__icon {
  color: #698cee;
}

.ctrlBtn_view_link.ctrlBtn_color_default:active, .ctrlBtn_view_link.ctrlBtn_color_default:active .ctrlBtn__icon {
  color: #053fe3;
}

.ctrlBtn_view_link.ctrlBtn_color_second {
  color: #1e1b1b;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}

.ctrlBtn_view_link.ctrlBtn_color_second .ctrlBtn__icon {
  color: #a5a4a4;
}

.ctrlBtn_view_link.ctrlBtn_color_second:hover, .ctrlBtn_view_link.ctrlBtn_color_second:focus {
  color: #787676;
  outline: none;
}

.ctrlBtn_view_link.ctrlBtn_color_second:active, .ctrlBtn_view_link.ctrlBtn_color_second:active .ctrlBtn__icon {
  color: #787676;
}

.ctrlBtn_size_xs {
  border-radius: 4px;
  padding: 3px;
}

.ctrlBtn_size_xs .ctrlBtn__icon {
  width: 20px;
  height: 20px;
}

.ctrlBtn_size_xs .ctrlBtn__text {
  padding-top: 0;
  font-size: 12px;
  line-height: 1.33333;
}

.ctrlBtn_size_s {
  padding: 5px 9px;
}

.ctrlBtn_size_s.ctrlBtn_iconOnly {
  padding-left: 5px;
  padding-right: 5px;
}

.ctrlBtn_size_l {
  padding: 8px 12px;
}

.ctrlBtn_size_l .ctrlBtn__text {
  font-size: 20px;
  line-height: 1.25;
}

.ctrlBtn_shadow.ctrlBtn_color_second {
  box-shadow: 0 1px 2px rgba(0, 0, 0, .08);
}

.ctrlBtn_activeDot, .ctrlBtn__iconWrapper_activeDot {
  position: relative;
}

.ctrlBtn_activeDot:before, .ctrlBtn__iconWrapper_activeDot:before {
  content: "";
  background-color: var(--btn-marker-bc-active);
  border-radius: 50%;
  width: 6px;
  height: 6px;
  display: inline-flex;
  position: absolute;
  top: 0;
  right: 0;
}
