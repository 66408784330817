.footer {
  color: #9ca3af;
  background-color: #000;
  border-top: 1px solid rgba(0, 0, 0, 0);
  padding-bottom: 50px;
}

.footer__form {
  margin-top: -150px;
}

.footer__nav {
  margin-top: 105px;
}

.footer__navList {
  justify-content: center;
  list-style-type: none;
  display: flex;
}

.footer__navItem {
  margin-right: 40px;
}

.footer__navItem:last-child {
  margin-right: 0;
}

.footer__navLink {
  color: inherit;
  text-decoration: none;
  transition-property: color;
  transition-duration: .125s;
  transition-timing-function: ease-in-out;
}

.footer__navLink:hover {
  color: #dc2626;
}

.footer__social {
  justify-content: center;
  margin-top: 32px;
}

.footer__copy {
  text-align: center;
  margin-top: 32px;
  font-size: 16px;
  line-height: 24px;
}
