.laptopActions__title {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.5;
}

.laptopActions__actions {
  margin-top: 16px;
}

.laptopActions__button {
  width: 100%;
  margin-top: 16px;
}

.laptopActions__button:first-child {
  margin-top: 0;
}

.laptopActions__more {
  justify-content: center;
  align-items: baseline;
  margin-top: 16px;
  display: flex;
}

.laptopActions__moreText {
  color: rgba(30, 27, 27, .5);
  margin-right: 4px;
}
