@font-face {
  font-family: Noto Sans;
  src: url("/src/assets/fonts/NotoSans-Regular/NotoSans-Regular.woff") format("woff");
  src: url("/src/assets/fonts/NotoSans-Regular/NotoSans-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Noto Sans;
  src: url("/src/assets/fonts/NotoSans-Medium/NotoSans-Medium.woff") format("woff");
  src: url("/src/assets/fonts/NotoSans-Medium/NotoSans-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Noto Sans;
  src: url("/src/assets/fonts/NotoSans-Bold/NotoSans-Bold.woff") format("woff");
  src: url("/src/assets/fonts/NotoSans-Bold/NotoSans-Bold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
