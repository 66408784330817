.gantt-container--lookahead .weekend {
  background-color: rgba(30, 27, 27, .05);
}

.gantt-container--lookahead .non {
  display: none;
}

.gantt-container--lookahead .lookahead__badge {
  color: #000;
  text-shadow: none;
  text-align: center;
  z-index: 10;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  padding: 4px;
  font-size: 11px;
  font-weight: 500;
  position: absolute;
  bottom: -8px;
  right: -5px;
}

.gantt-container--lookahead .lookahead_element {
  box-sizing: border-box;
  cursor: pointer;
  background-clip: content-box;
  border-width: 1px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.gantt-container--lookahead .lookahead_element:hover {
  border-style: dashed;
  border-color: #e9e8e8;
}

.gantt-container--lookahead .lookahead_element_empty:hover, .gantt-container--lookahead .gantt_task_cell.lookahead_task_cell:before:hover {
  border-color: gray;
}

.gantt-container--lookahead .lookahead_element_filled {
  text-shadow: 1px 1px 2px #000;
  color: #fff;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 500;
  box-shadow: 2px 5px 12px rgba(0, 0, 0, .16);
}

.gantt-container--lookahead .lookahead_task_cell:before {
  content: "";
  box-sizing: border-box;
  cursor: pointer;
  border: 1px dashed #e9e8e8;
  border-radius: 5px;
  width: 38px;
  height: 38px;
  display: inline-block;
  position: absolute;
  top: 2px;
  left: 50%;
  transform: translateX(-50%);
}

.gantt-container--lookahead .gantt_task_row--milestone .lookahead_task_cell:before {
  cursor: initial;
}

.gantt-container--lookahead .gantt_selected .lookahead_task_cell:before, .gantt-container--lookahead .weekend.lookahead_task_cell:before {
  border: 1px dashed #d2d1d1;
}

.gantt-container--lookahead gantt_task_row:not(.gantt_task_row--milestone) .weekend.lookahead_task_cell:hover:before {
  border: 2px dashed #000;
}

.gantt-container--lookahead .lookahead_container {
  box-sizing: border-box;
  align-items: center;
  display: flex;
  position: absolute;
}

.gantt-container--lookahead .wbs_container {
  box-sizing: content-box;
  background-color: #e9e8e8;
  background-clip: content-box;
  border-radius: 2px 2px 0 0;
  position: absolute;
}

.gantt-container--lookahead .wbs_container:before {
  content: "";
  background-image: url("data:image/svg+xml,<svg width=\"9px\" height=\"17px\" viewBox=\"0 0 9 17\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"> <path d=\"M2.89644 15.6149L9 0.0526428H0V15.0673C0 16.7245 2.29133 17.1578 2.89644 15.6149Z\" fill=\"%23E9E8E8\"/></svg>");
  width: 9px;
  height: 17px;
  margin-top: 12px;
  display: block;
}

.gantt-container--lookahead .wbs_container:after {
  content: "";
  background-image: url("data:image/svg+xml,<svg width=\"9px\" height=\"17px\" viewBox=\"0 0 9 17\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"> <path d=\"M0 0L6.10356 15.5623C6.70867 17.1051 9 16.6719 9 15.0146V0H0Z\" fill=\"%23E9E8E8\"/></svg>");
  width: 9px;
  height: 17px;
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
}

.gantt-container--lookahead .gantt_layout_content .gantt_task_row {
  background-color: #f6f6f6;
  border-bottom: none;
}

.gantt-container--lookahead .gantt_layout_content .gantt_task_cell {
  border-right: none;
}

.activity-details_wrap {
  z-index: 100;
  background-color: #f5f5f5;
  border-top: 1px solid #cecece;
}

.lookahead__action_unassigned {
  color: #fa390d;
}

.lookahead_tooltip_title {
  text-overflow: ellipsis;
  color: rgba(30, 27, 27, .7);
  max-width: 250px;
  margin-bottom: 1rem;
  padding-right: 5px;
  font-size: 1rem;
  font-weight: 600;
  overflow: hidden;
}

.gantt_tooltip {
  z-index: 100;
  border-left: 4px solid #3bbd02;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.lookahead-tooltip {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 2px 10px 0;
  display: flex;
}

.lookahead-tooltip_item {
  text-overflow: ellipsis;
  max-width: 200px;
  margin-bottom: 5px;
  font-size: 13px;
  overflow: hidden;
}

.lookahead-tooltip_item_value {
  color: rgba(30, 27, 27, .7);
  font-weight: 600;
}

.gantt_row.wbs, .gantt_row.odd.wbs {
  background-color: #ececec;
}

.gantt__task-name {
  color: #053fe3;
}

.gantt_row .gantt__assigners-icon {
  text-align: left;
  color: #c6c6c6;
  align-items: center;
  display: flex;
}

.gantt_row .gantt__assigners-icon--empty {
  color: #0145f4;
}

.gantt_row.gantt_selected .gantt__assigners-icon {
  color: gray;
}
