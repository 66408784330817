.selectGroup {
  margin-top: 20px;
  margin-bottom: 20px;
}

.selectGroup__label {
  letter-spacing: -.5px;
  opacity: .5;
  margin-bottom: 2px;
  font-size: 10px;
  line-height: 16px;
}

.selectGroup__add {
  justify-content: right;
  margin-top: 5px;
  display: flex;
}
