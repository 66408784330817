.input {
  color: #053fe3;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  width: 3.75rem;
  font-size: .875rem;
  font-weight: 500;
}

.input_error {
  color: #fa380d;
}

.input::placeholder {
  color: #053fe3;
}

.input.input_placeholder_empty::placeholder {
  color: rgba(211, 211, 211, .9);
}

.input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.input .editable-input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.input:disabled {
  pointer-events: none;
  color: rgba(5, 63, 227, .1);
  font-weight: 100;
}

.input.disabled_placeholder::placeholder {
  color: rgba(5, 63, 227, .1);
}

.button {
  cursor: pointer;
  color: rgba(5, 63, 227, .25);
  -webkit-user-select: none;
  user-select: none;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  align-items: center;
  min-height: 10px;
  padding: 5px;
  display: flex;
  position: relative;
}

.button_readonly {
  pointer-events: none;
}

.button:active:after {
  content: "";
  background-color: rgba(5, 63, 227, .25);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button:disabled {
  pointer-events: none;
}

@keyframes floatAway {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

@keyframes sinkAway {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

.floatAwayAnimation {
  color: #21a032;
  animation: .2s ease-out forwards floatAway;
  position: absolute;
  top: 0;
  left: 46%;
  transform: translateX(-50%);
}

.sinkAwayAnimation {
  color: #fa380d;
  animation: .2s ease-out forwards sinkAway;
  position: absolute;
  bottom: 0;
  left: 46%;
  transform: translateX(-50%);
}
