.daily_status {
  padding: 50px;
  position: relative;
}

.daily_status .activity_sub {
  width: 200px;
}

.daily_status .date_label {
  margin: auto 6px auto 0;
}

.daily_status .date_select {
  width: 280px;
}

.daily_status .back_btn {
  margin: 7px 0;
  position: absolute;
  top: 20px;
  left: 50px;
}

@media print {
  .daily_status .back_btn {
    display: none;
  }
}

.daily_status .print_btn {
  padding: 7px 9px;
  position: absolute;
  right: 50px;
}

@media print {
  .daily_status .print_btn {
    display: none;
  }
}

.daily_status .print_btn_top {
  top: 20px;
}

.daily_status h3 {
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: normal;
}

.daily_status .show_more_container {
  justify-content: right;
  width: 100%;
  margin-top: 6px;
  display: flex;
}

.daily_status .show_more_bt {
  color: #053fe3;
  opacity: 1;
  cursor: pointer;
  border-color: rgba(5, 63, 227, .25);
  height: 30px;
  font-size: 14px;
  line-height: 20px;
}

.daily_status .show_more_bt:hover {
  color: #053fe3;
}

.daily_status .status_header {
  border-bottom: 1px solid #000;
  margin-top: 20px;
  margin-bottom: 10px;
}

@media print {
  .daily_status .status_header {
    margin-top: 0;
  }
}

.daily_status .status_header .title {
  text-transform: uppercase;
  font-size: 30px;
  font-weight: bold;
}

.daily_status .status_header .metasection {
  flex-direction: row;
  display: flex;
}

.daily_status .status_header .metasection .metadata {
  font: 18px;
  margin: 10px 0;
  line-height: 1.8em;
}

.daily_status .status_header .metasection .weather {
  flex-direction: row;
  align-items: center;
  margin-left: 30px;
  display: flex;
}

.daily_status .status_header .metasection .weather img {
  width: 64px;
  height: 64px;
}

.daily_status .status_header .metasection .weather span {
  margin-top: 15px;
}

.daily_status .status_header .logo {
  float: right;
}

.daily_status .section_title {
  text-transform: uppercase;
  padding: 18px 0 6px;
  font-size: 20px;
}

.daily_status table {
  border-collapse: collapse;
  border: 1px solid #000;
  width: 100%;
}

.daily_status table th {
  text-align: left;
  background: #c9daf8;
  border: 1px solid #000;
  padding: 8px;
}

.daily_status table td {
  text-align: left;
  border: 1px solid #000;
  padding: 8px;
}

.daily_status table td .metadata {
  padding: 8px 0 0;
  font-size: 10px;
}

.daily_status table td .more {
  float: right;
}

.daily_status table td .icon_block svg {
  width: 24px;
  height: 24px;
  margin-left: 4px;
}

.daily_status table .icon_cell {
  width: 100px;
}

.daily_status table .name_cell {
  width: 60%;
  position: relative;
}

.daily_status .legend {
  margin-top: 30px;
}

.daily_status .legend svg {
  width: 24px;
  height: 24px;
  margin-bottom: 2px;
  margin-right: 10px;
}

.daily_status .legend .legend_row {
  margin-bottom: 10px;
}

.daily_status .weather_text {
  margin-left: 15px;
}
