.icon {
  vertical-align: middle;
  width: 24px;
  height: 24px;
  color: inherit;
  display: inline-block;
}

.icon_color_fill {
  fill: currentColor;
}
