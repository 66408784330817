.popup {
  max-width: 37.5rem;
  max-height: 43.75rem;
  padding: 1rem;
}

@media only screen and (min-width: 1024px) {
  .popup {
    max-width: 60rem;
  }
}
