.TwoFactorAuthSettings {
  flex-direction: column;
  display: flex;
}

.TwoFactorAuthSettings :global .ctrl-form__button-link:disabled {
  opacity: .25 !important;
}

.TwoFactorAuthSettings :global .ctrl_btn__text {
  padding-top: initial;
}

.TwoFactorAuthSettings__box {
  width: 100%;
  padding: 5px;
}

.TwoFactorAuthSettings__header {
  justify-content: space-between;
  font-size: 16px;
  display: flex;
}

.TwoFactorAuthSettings__body {
  flex-direction: column;
  display: flex;
}

.TwoFactorAuthSettings__phoneInput--disabled {
  opacity: .25;
  pointer-events: none;
}
