.gantt-grid {
  background: #fff;
  border-radius: 12px 12px 0 0;
  max-height: calc(100vh - 152px);
  margin: 6px 24px 0;
  padding: 14px 14px 0;
  overflow: hidden;
}

.gantt_layout_root {
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  overflow: hidden;
}

.gantt_grid_scale, .gantt_task_scale, .gantt_grid_head_add.custom_add {
  background-color: rgba(30, 27, 27, .05);
}

.gantt_add, .gantt_grid_head_add, .ctrl-btn-clear__icon.more_info:hover {
  opacity: 1;
}

.gantt_grid_head_cell {
  text-align: left;
  padding-left: 18px;
}

.gantt_grid_column_resize_wrap .gantt_grid_column_resize {
  height: 16px;
  margin-top: 16px;
}

.gantt_grid .gantt_grid_head_name {
  border-right: 1px solid #d2d1d1 !important;
}

.gantt_grid_head_name {
  text-indent: 34px;
}

.gantt_grid_data.gantt_cell {
  border-right: none;
}

.gantt_grid_data .gantt_cell {
  border-right: 1px solid #d2d1d1;
}

.gantt_row, .gantt_task_row {
  border-bottom: 1px solid #d2d1d1;
}

.gantt__row_has-child .gantt__task-name {
  margin-left: 8px;
}

.gantt__row_is_pending {
  opacity: .3;
  pointer-events: none;
  cursor: not-allowed;
}

.spinner {
  border: .125rem solid #fff;
  border-top-color: #000;
  border-radius: 50%;
  width: .625rem;
  height: .625rem;
  margin-left: .625rem;
  animation: 1s linear infinite spinner;
}

@keyframes spinner {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.gantt__cell.gantt_cell_tree {
  padding-left: 0;
}

.lookahead-view .gantt_layout_content .gantt_task_row {
  background-color: #fff;
}

.gantt_scale_cell {
  width: 42px;
}

.gantt_scale_line {
  border-top: none;
}

.gantt_grid_editor_placeholder input[type="text"] {
  border-width: 0;
  outline: none;
  width: calc(100% - 1px);
  height: calc(100% - 1px);
  margin-left: 2px;
  font-size: 13px;
}

.gantt_grid_editor_placeholder input[type="number"] {
  border-width: 0;
  outline: none;
  width: calc(100% - 1px);
  height: calc(100% - 1px);
  margin-left: 2px;
  font-size: 13px;
}

.gantt_grid_editor_placeholder input[type="date"] {
  border-width: 0;
  outline: none;
  width: calc(100% - 1px);
  height: calc(100% - 1px);
  margin-left: 2px;
  font-size: 13px;
}

.gantt_grid_editor_placeholder input[type="text"]:focus {
  box-shadow: 0 0 0 2px #015fcc;
}

.gantt_grid_editor_placeholder input[type="number"]:focus {
  box-shadow: 0 0 0 2px #015fcc;
}

.gantt_grid_editor_placeholder input[type="date"]:focus {
  box-shadow: 0 0 0 2px #015fcc;
}

.gantt_grid_editor_placeholder :-webkit-any([name="name"], input[name="taskDuration"], input[name="predecessor_predecessor"], input[name="location"], input[name="start_date"], input[name="end_date"], input[name="actual_start"], input[name="actual_end"]) {
  padding-left: 20px;
}

.gantt_grid_editor_placeholder :is([name="name"], input[name="taskDuration"], input[name="predecessor_predecessor"], input[name="location"], input[name="start_date"], input[name="end_date"], input[name="actual_start"], input[name="actual_end"]) {
  padding-left: 20px;
}

.gantt_grid_editor_placeholder :-webkit-any(input[name="taskDuration"], input[name="start_date"], input[name="end_date"], input[name="actual_start"], input[name="actual_end"]) {
  padding-right: 8px;
}

.gantt_grid_editor_placeholder :is(input[name="taskDuration"], input[name="start_date"], input[name="end_date"], input[name="actual_start"], input[name="actual_end"]) {
  padding-right: 8px;
}

.gantt_grid_editor_placeholder :-webkit-any([data-column-name="name"], [data-column-name="taskDuration"], [data-column-name="predecessor"], [data-column-name="location"]) {
  padding-left: 0;
}

.gantt_grid_editor_placeholder :is([data-column-name="name"], [data-column-name="taskDuration"], [data-column-name="predecessor"], [data-column-name="location"]) {
  padding-left: 0;
}

.gantt_grid_editor_placeholder .react-select {
  background-color: tomato;
  width: calc(100% - 1px);
  height: calc(100% - 1px);
  margin-left: 2px;
  font-size: 13px;
}

.gantt_grid_editor_placeholder .react-select__control {
  border-color: rgba(0, 0, 0, 0);
  border-radius: 0;
  height: 100%;
}

.gantt_grid_editor_placeholder .react-select__control input[type="text"] {
  margin-left: 0;
}

.gantt_grid_editor_placeholder .react-select__control input[type="number"] {
  margin-left: 0;
}

.gantt_grid_editor_placeholder .react-select__control input[type="date"] {
  margin-left: 0;
}

.gantt_grid_editor_placeholder .react-select__control input[type="text"]:focus {
  box-shadow: none;
}

.gantt_grid_editor_placeholder .react-select__control input[type="number"]:focus {
  box-shadow: none;
}

.gantt_grid_editor_placeholder .react-select__control input[type="date"]:focus {
  box-shadow: none;
}

.gantt_grid_editor_placeholder .react-select__control--is-focused {
  box-shadow: 0 0 0 2px #015fcc;
}

.gantt_grid_editor_placeholder .react-select__menu {
  border-radius: 0;
  margin-top: 2px;
  box-shadow: 0 0 0 2px #015fcc;
}

.gantt_grid_editor_placeholder .react-select__menu-notice {
  font-size: 13px;
}

.gantt_grid_editor_placeholder .react-select--size-xs .react-select__control {
  border-radius: 0;
  font-size: 13px;
}

.gantt_grid_editor_placeholder .react-select--size-xs .react-select__option {
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 13px;
}

.gantt_grid_editor_placeholder .react-select--size-xs .react-select__menu {
  border-radius: 0;
  box-shadow: 0 0 0 2px #015fcc;
}

:-webkit-any(.gantt_grid_head_cell, .gantt_cell):not(:-webkit-any([data-column-name="custom_checkbox"], [data-column-name="custom_add"], [data-column-name="task_status"], [data-column-name="estimated_labor_abbr"], [data-column-name="average_labor_abbr"], [data-column-name="progress"], [data-column-name="completion_unit"], [data-column-name="completion_target"])) {
  padding-left: 20px;
}

:is(.gantt_grid_head_cell, .gantt_cell):not(:is([data-column-name="custom_checkbox"], [data-column-name="custom_add"], [data-column-name="task_status"], [data-column-name="estimated_labor_abbr"], [data-column-name="average_labor_abbr"], [data-column-name="progress"], [data-column-name="completion_unit"], [data-column-name="completion_target"])) {
  padding-left: 20px;
}

.gantt_grid_head_cell.gantt_grid_head_custom_add.gantt_last_cell, .gantt_cell.gantt_last_cell[data-column-name="custom_add"] {
  background-color: rgba(0, 0, 0, 0);
}

.gantt_row:hover .more_info {
  color: #a5a4a4;
  background-color: #fff;
  border: 1px solid #d2d1d1;
  border-radius: 9px;
  padding: 7px 2px;
}

.gantt_row:hover [data-column-name="custom_add"] {
  opacity: 1;
}

[data-column-name="custom_add"] {
  opacity: 0;
}

.gantt_row.gantt_row_task.gantt__row_has-child {
  background-color: #e8e8e8;
}

.gantt_row.gantt_row_task.gantt__row_is-child {
  background-color: #fbfafa;
}

.gantt_open {
  margin: 8px 0 0;
  position: absolute;
}

.gantt_close {
  box-sizing: border-box;
  margin: 8px 0 0;
  position: absolute;
}

.gantt-grid_table .gantt_layout_cell.timeline_cell {
  display: none;
}

.gantt_grid_scale [data-column-name="name"] {
  border: none;
}

.gantt_data_area .gantt_task_row {
  border-bottom: none;
}

.gantt_task_bg {
  border-bottom: 1px solid #d2d1d1;
}

.gantt_task_line {
  border: none;
  border-radius: 5px;
  margin-top: 5px;
  height: 27px !important;
  line-height: 27px !important;
}

.gantt_task_line__summary-type {
  box-sizing: content-box;
  background-color: #e9e8e8;
  background-clip: content-box;
  border-radius: 2px 2px 0 0;
  height: 14px !important;
}

.gantt_task_line__summary-type:before {
  content: "";
  background-image: url("data:image/svg+xml,<svg width=\"9px\" height=\"17px\" viewBox=\"0 0 9 17\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"> <path d=\"M2.89644 15.6149L9 0.0526428H0V15.0673C0 16.7245 2.29133 17.1578 2.89644 15.6149Z\" fill=\"%23E9E8E8\"/></svg>");
  width: 9px;
  height: 17px;
  margin-top: 12px;
  display: block;
}

.gantt_task_line__summary-type:after {
  content: "";
  background-image: url("data:image/svg+xml,<svg width=\"9px\" height=\"17px\" viewBox=\"0 0 9 17\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"> <path d=\"M0 0L6.10356 15.5623C6.70867 17.1051 9 16.6719 9 15.0146V0H0Z\" fill=\"%23E9E8E8\"/></svg>");
  width: 9px;
  height: 17px;
  display: block;
  position: absolute;
  top: 11px;
  right: 0;
}

.gantt_task_line .gantt_task_content {
  overflow: hidden;
}

.gantt_link_point {
  margin-top: -14.5px;
}

.gantt_cell .gantt_tree_content {
  justify-content: start;
}

.gantt__checkbox {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline-style: none;
  margin: 0 0 10px;
}

.gantt__checkbox_check {
  margin: 12px 0 0;
}

.gantt__checkbox_uncheck {
  margin: -4px 0 0 4px;
  position: absolute;
}

.gantt_row_task:hover .gantt__checkbox_uncheck {
  border: 1px solid #cdd9f9;
  border-radius: 5px;
  margin-top: -5px;
  margin-left: 3px;
}

.first-weekend {
  border-top-left-radius: 9px;
}

.only-one-weekend {
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}

.second-weekend {
  border-top-right-radius: 9px;
}

.gantt_task .gantt_task_scale .gantt_scale_cell.is-today {
  color: #fff;
  position: relative;
}

.is-today:before {
  content: "";
  z-index: -1;
  background-color: #fa380d;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.today:before {
  content: "";
  background-image: url("data:image/svg+xml,<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 1 50\" width=\"8px\" height=\"12px\"> <circle id='c1' cx=\"0\" cy=\"40\" r=\"7\" fill=\"red\" /> <circle id='c2' cx=\"0\" cy=\"5\" r=\"25\" fill=\"white\" opacity=\"0\" /><circle id='c3' cx=\"0\" cy=\"10\" r=\"5\" fill=\"white\" opacity=\"0\" /> </svg>");
  background-repeat: repeat-y;
  background-size: contain;
  height: 100%;
  display: block;
}

.one-day-filter-area:after {
  content: "";
  border-right: 3px solid #4050ec;
  height: 100%;
  margin: 0 0 0 20px;
  display: block;
}

.is-one-day-filter:after {
  content: "";
  z-index: -1;
  background-color: #4050ec;
  border-radius: 60px;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.is-one-day-filter.gantt_scale_cell {
  color: #fff;
  position: relative;
}

.gantt_marker {
  background-color: rgba(0, 0, 0, 0);
  width: 6px;
}

.gantt_scale_line {
  z-index: 0;
}

.gantt_task_scale {
  font-size: 11px;
}

.gantt_grid_head_cell .gantt_grid_head_custom_checkbox {
  border-right: red;
}

.gantt_task_row.gantt_selected .gantt_task_cell {
  border-right: 1px solid #ebebeb;
}

.gantt_link_control div {
  top: 10px;
}

.gantt_grid_data .gantt_row.gantt_selected, .gantt_grid_data .gantt_row.odd.gantt_selected, .gantt_task_row.gantt_selected, .gantt_grid_data .gantt_row.odd:hover, .gantt_grid_data .gantt_row:hover, .lookahead-view .gantt_layout_content .gantt_task_row.gantt_selected {
  background-color: #e6ecfc;
}

[data-column-name="name"] .gantt_tree_content {
  padding-left: 34px;
}

[data-column-name="name"] .gantt_load + .gantt_tree_content, [data-column-name="name"] .gantt_tree_icon + .gantt_tree_content {
  padding-left: 26px;
}

.gantt_tree_icon.gantt_open {
  background-image: url("data:image/svg+xml,<svg width=\"24px\" height=\"24px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"> <rect x=\"0.5\" y=\"0.5\" width=\"23px\" height=\"23px\" rx=\"8.5\" /> <path d=\"M9 11L12 14L15 11\" stroke=\"%23A5A4A4\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/> <rect x=\"0.5\" y=\"0.5\" width=\"23px\" height=\"23px\" rx=\"8.5\" /> </svg>");
  width: 24px;
  height: 24px;
}

.gantt_tree_icon.gantt_close {
  background-image: url("data:image/svg+xml,<svg width=\"24px\" height=\"24px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"> <rect x=\"0.5\" y=\"0.5\" width=\"23px\" height=\"23px\" rx=\"8.5\" /> <path d=\"M9 12H15\" stroke=\"%23A5A4A4\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/> <rect x=\"0.5\" y=\"0.5\" width=\"23px\" height=\"23px\" rx=\"8.5\" /> </svg>");
  width: 24px;
  height: 24px;
}

.gantt_row.gantt_row_task.gantt__row_has-child.selected-task_custom, .gantt_row.odd.selected-task_custom, .gantt_task_row.odd.selected-task_custom, .gantt_row.selected-task_custom, .gantt_task_row.selected-task_custom, .gantt_grid_data .gantt_row.gantt_selected.selected-task_custom, .gantt_grid_data .gantt_row.odd.gantt_selected.selected-task_custom, .gantt_task_row.gantt_selected.selected-task_custom, .gantt_row.gantt_row_task.gantt__row_is-child.selected-task_custom {
  background-color: #c9d6f8;
}

.gantt_grid_head_resizable:not(.gantt_grid_head_name):after {
  content: "";
  background-color: #d2d1d1;
  width: 1px;
  height: 16px;
  margin: 0 7.5px;
  position: absolute;
  top: 50%;
  right: -7.5px;
  transform: translateY(-50%);
}

.gantt_grid_column_resize {
  opacity: 0;
}

.gantt-grid_head_collapse {
  color: #a5a4a4;
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 21px;
  transform: translateY(-50%);
}

.gantt-grid_head_collapse:hover {
  color: #698cee;
}

.gantt-grid_head_collapse svg {
  fill: currentColor;
  width: 24px;
  height: 24px;
}

.gantt-grid_head_collapse:empty, .gantt-grid_head_collapse [data-all-collapsed=""] {
  display: none;
}

.gantt__custom_checkbox_id:checked {
  width: 24px;
  height: 24px;
}

.custom_checkbox_id {
  z-index: 1;
  opacity: 0;
  width: 16px;
  height: 16px;
  margin: 16px 0 0 -2px;
  padding: 0;
  position: absolute;
}

.custom_checkbox_id + label {
  opacity: 1;
  background-image: url("data:image/svg+xml, <svg width=\"16px\" height=\"16px\" viewBox=\"0 0 16 16\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\" class=\"gantt__checkbox_uncheck\"> <rect x=\"0.5\" y=\"0.5\" width=\"15px\" height=\"15px\" rx=\"4.5\" stroke=\"%23A5A4A4\"/></svg>");
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  display: block;
  position: relative;
  top: 16px;
  left: -2px;
}

.custom_checkbox_id:checked + label {
  opacity: 1;
  background-image: url("data:image/svg+xml, <svg width=\"24px\" height=\"24px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\" class=\"gantt__checkbox_check\"> <rect x=\"4.5\" y=\"4.5\" width=\"15px\" height=\"15px\" rx=\"4.5\" fill=\"%23053FE3\" stroke=\"%23053FE3\"/> <path d=\"M9.5 12L11.5 14.5L15 10\" stroke=\"white\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/> </svg>");
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  display: block;
  position: relative;
  top: 12px;
  left: -6px;
}

.gantt__indent {
  flex-grow: 0;
  flex-shrink: 0;
  width: 25px;
  height: 100%;
  display: block;
}

.gantt-grid__area .gantt-grid__resize {
  z-index: 3;
  position: relative;
  left: -8px;
  right: auto;
}

.gantt-grid__area {
  overflow: initial !important;
}

.gantt-grid__legend {
  z-index: 3;
  width: 286px;
}

.gantt-grid__content {
  border: 1px solid #cecece;
  border-bottom-width: 0;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.gantt-grid__content .gantt_layout_root {
  border-width: 0;
  border-radius: 0;
}

.gantt-container .gantt_add {
  opacity: 0;
}

.gantt_grid_head_add:hover {
  opacity: 1;
}

.gantt-grid {
  border: none !important;
}

.gantt__task-name_link:hover {
  color: #0145f4;
  cursor: pointer;
}

.gantt__assigners-icon svg:hover {
  fill: #0145f4;
}

.gantt__assigners-icon:hover {
  cursor: pointer;
}

.gantt_grid_dnd_marker_folder {
  box-shadow: inset 0 0 0 1px #235dff;
}

.gantt_grid_dnd_marker_line {
  background-color: #235dff;
  width: 250px;
  height: 1px;
  margin-left: -56px;
}

.gantt_row.gantt_transparent .gantt_tree_icon.gantt_file {
  background-image: url("/assets/images/icon-drag.png");
}

.gantt__actual-check {
  vertical-align: middle;
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.gantt__actual-check_disabled {
  opacity: .7;
  pointer-events: none;
}

.gantt__actual-check_actualized {
  fill: #00f;
  margin-left: auto;
}

.gantt__actual-check_actualized:hover {
  fill: gray;
}

.gantt__actual-check_unactualized {
  fill: gray;
  opacity: 0;
  margin-left: auto;
}

.gantt_row:hover .gantt__actual-check_unactualized {
  opacity: 1;
}

.gantt_drag_marker {
  opacity: .9;
}

.gantt_drag_marker .gantt_grid_dnd_marker_line {
  height: 2px;
  margin-left: unset;
  width: unset;
}

.gantt_drag_marker .gantt_grid_dnd_marker_line:before {
  display: none;
}

.gantt_drag_marker .gantt_drag_marker .gantt_tree_icon.gantt_file {
  background-image: url("/assets/images/icon-drag.png");
}

.gantt_tree_content {
  justify-content: center;
  align-items: center;
  display: flex;
}

.gantt_row.gantt_selected .more_info {
  opacity: 1;
}

.gantt__task-name {
  align-items: center;
  display: flex;
  overflow: hidden;
}

.gantt__task-name_link {
  text-overflow: ellipsis;
  overflow: hidden;
}

.gantt__task-name_edit {
  opacity: 0;
  cursor: pointer;
  min-width: 16px;
  min-height: 16px;
  margin-left: 7px;
}

.gantt_grid_head_cell div[data-column-name="custom_add"], div[data-column-name="custom_add"] {
  background: var(--gantt-plus-cell-color);
  right: var(--gantt-frozen-column-offset);
  position: relative;
}

.gantt_row .gantt__task-name_open {
  opacity: 0;
  cursor: pointer;
  min-width: 16px;
  min-height: 16px;
  margin-left: 7px;
}

.gantt_row .gantt__activity-name_open {
  opacity: 1;
  cursor: pointer;
  min-width: 16px;
  min-height: 16px;
  margin-left: 7px;
}

.gantt__task-name_open_wrap {
  display: flex;
}

.gantt_row:hover {
  --gantt-plus-cell-color: #fff3a1;
}

.gantt_row:hover .more_info {
  opacity: 1;
}

.gantt_row:hover .gantt__task-name_open {
  opacity: 1;
  min-width: 1.5rem;
  padding: .625rem;
}

.gantt_row.gantt_selected {
  --gantt-plus-cell-color: #fff3a1;
}

.gantt_grid_head_cell {
  --gantt-plus-cell-color: white;
}

.gantt_task_line {
  background-color: gray;
}

.gantt_task_line__summary-type:not(.gantt_task_line__past_due):not(.has-sub-color) {
  background-color: #e9e8e8;
}

.gantt_task_line__summary-type:not(.gantt_task_line__past_due):not(.has-sub-color) .gantt_task_content {
  color: #454545;
}

.gantt_task_line__past_due {
  background-color: #fa380d;
}

.gantt_task_line.prevent-move, .gantt_task_line.prevent-resize-start .gantt_task_drag.task_left, .gantt_task_line.prevent-resize-end .gantt_task_drag.task_right {
  cursor: none;
  pointer-events: none;
}

.gantt-status {
  width: 125px;
}

.gantt-popper .bulk-input_gantt {
  opacity: 0;
  padding: 0;
}

.gantt_tree_content .te-workers__icon--size {
  width: 24px;
}

.gantt__edit-task-type {
  width: 125px;
  padding: 1px 7px;
  font-size: 12px !important;
}

.gantt_grid_data .gantt_row_placeholder .gantt_cell:not([data-column-name="name"]) > * {
  display: none;
}

.gantt_grid_data .gantt_row_placeholder .gantt_cell {
  border: none;
  overflow: visible;
}

.gantt_grid_data .gantt_row_placeholder .gantt_tree_content {
  color: #454545;
  opacity: 1;
  padding-left: 0;
  font-size: 1.15em;
}

.gantt-grid_state-deleted .gantt_row_placeholder .gantt_tree_content {
  display: none !important;
}

.gantt__edit-subcontractor > div, .gantt__edit-task-type > div {
  width: 125px;
  padding: 1px 7px;
  font-size: 12px !important;
}

.gantt__edit-subcontractor .react-select__menu .react-select__menu-list .react-select__option, .gantt__edit-task-type .react-select__menu .react-select__menu-list .react-select__option {
  padding: 6px 7px;
  font-size: 12px;
  line-height: 12px;
}

.gantt_cell.disabled {
  opacity: .7;
  cursor: auto;
}

.weekend {
  background-color: rgba(30, 27, 27, .05);
}

.gantt-container--lookahead .non {
  display: none;
}

.gantt-container--lookahead .lookahead_element {
  box-sizing: border-box;
  cursor: pointer;
  background-clip: content-box;
  border-width: 2px;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.gantt-container--lookahead .lookahead_element:hover {
  border-style: dashed;
  border-color: #000;
}

.gantt-container--lookahead .lookahead_element_empty:hover {
  border-color: inherit;
}

.gantt-container--lookahead .lookahead_element_filled {
  text-shadow: 1px 1px 2px #000;
  color: #fff;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 500;
  box-shadow: 2px 5px 12px rgba(0, 0, 0, .16);
}

.gantt-container--lookahead .gantt_task_row:not(.gantt_task_row--milestone) .lookahead_task_cell:hover:before {
  content: "";
  box-sizing: border-box;
  cursor: pointer;
  border: 2px dashed #000;
  border-radius: 3px;
  display: inline-block;
  position: absolute;
}

.gantt-container--lookahead .lookahead_container {
  box-sizing: border-box;
  align-items: center;
  display: flex;
  position: absolute;
}

.gantt-container--lookahead .wbs_container {
  box-sizing: content-box;
  background-color: #e9e8e8;
  background-clip: content-box;
  border-radius: 2px 2px 0 0;
  position: absolute;
}

.gantt-container--lookahead .wbs_container:before {
  content: "";
  background-image: url("data:image/svg+xml,<svg width=\"9px\" height=\"17px\" viewBox=\"0 0 9 17\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"> <path d=\"M2.89644 15.6149L9 0.0526428H0V15.0673C0 16.7245 2.29133 17.1578 2.89644 15.6149Z\" fill=\"%23E9E8E8\"/></svg>");
  width: 9px;
  height: 17px;
  margin-top: 12px;
  display: block;
}

.gantt-container--lookahead .wbs_container:after {
  content: "";
  background-image: url("data:image/svg+xml,<svg width=\"9px\" height=\"17px\" viewBox=\"0 0 9 17\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"> <path d=\"M0 0L6.10356 15.5623C6.70867 17.1051 9 16.6719 9 15.0146V0H0Z\" fill=\"%23E9E8E8\"/></svg>");
  width: 9px;
  height: 17px;
  display: block;
  position: absolute;
  top: 11px;
  right: 0;
}

.gantt-container--lookahead .gantt_layout_content .gantt_task_row {
  border-bottom: none;
}

.gantt-container--lookahead .gantt_layout_content .gantt_task_cell {
  border-right: none;
}

.gantt-container--lookahead .gantt_task_line.gantt_milestone {
  justify-content: center;
  align-items: center;
  margin-top: 0;
  display: flex;
}

.gantt-container--lookahead .gantt_task_line.gantt_milestone .gantt_task_content {
  margin-top: 0;
  width: 27px !important;
  height: 27px !important;
}

.activity-details_wrap {
  z-index: 100;
  background-color: #fff;
  border-top-width: 0;
}

.aka-table__cell--disable-cursor {
  cursor: default;
  background: #fff;
}

.aka-table__background--disable {
  background: #f5f5f5;
}

.lookahead__action_unassigned {
  color: #fa390d;
}

.lookahead_tooltip_title {
  text-overflow: ellipsis;
  color: rgba(30, 27, 27, .7);
  max-width: 250px;
  margin-bottom: 1rem;
  padding-right: 5px;
  font-size: 1rem;
  font-weight: 600;
  overflow: hidden;
}

.gantt_tooltip {
  z-index: 100;
  border-left: 4px solid #3bbd02;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.gantt_tooltip.header_tooltip {
  color: #fff;
  white-space: normal;
  background-color: #1e1b1b;
  border-width: 0;
  border-radius: 0 5px 5px;
  width: 200px;
  font-size: small;
  font-weight: 500;
}

.header_tooltip_text {
  word-wrap: break-word;
}

.gantt_tooltip.header_tooltip:before {
  content: "";
  border-top: 5px solid rgba(0, 0, 0, 0);
  border-bottom: 5px solid #1e1b1b;
  border-left: 0 solid rgba(0, 0, 0, 0);
  border-right: 5px solid rgba(0, 0, 0, 0);
  margin-top: -5px;
  position: absolute;
  bottom: 100%;
  left: 0;
}

.info_icon {
  vertical-align: middle;
  color: #a6a6a6;
  fill: currentColor;
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin-bottom: 2px;
  display: inline-block;
}

.lookahead-tooltip {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 2px 10px 0;
  display: flex;
}

.lookahead-tooltip_item {
  text-overflow: ellipsis;
  max-width: 200px;
  margin-bottom: 5px;
  font-size: 13px;
  overflow: hidden;
}

.lookahead-tooltip_item_value {
  color: rgba(30, 27, 27, .7);
  font-weight: 600;
}

.gantt_row.wbs, .gantt_row.odd.wbs {
  background-color: #ececec;
}

.gantt_layout_cell.gantt_layout_no_bottom_border {
  border-bottom: 0;
}

.gantt_row:not(.gantt__row_copy) + .gantt_row.gantt__row_copy, .gantt_row.gantt__row_copy + .gantt_row:not(.gantt__row_copy) {
  border-top: 1px solid red;
}

.gantt_row.gantt__row_copy, .task_row_selected {
  opacity: .7;
  background-color: #e6ecfc !important;
}

.gantt_row.gantt__row_copy *, .task_row_selected * {
  color: gray;
}

.gantt_task_line.gantt_milestone {
  margin-top: 0;
}

.gantt_row.gantt_row_task.gantt__row_has-child.wbs-level__0, .gantt_row.odd.gantt_row_task.gantt__row_has-child.wbs-level__0, .lookahead-view .gantt_layout_content .gantt_task_row.wbs-level__0 {
  background-color: #2d3436;
}

.gantt_row.gantt_row_task.gantt__row_has-child.wbs-level__0 .gantt__task-name_link:hover, .gantt_row.odd.gantt_row_task.gantt__row_has-child.wbs-level__0 .gantt__task-name_link:hover, .lookahead-view .gantt_layout_content .gantt_task_row.wbs-level__0 .gantt__task-name_link:hover, .gantt_row.gantt_row_task.gantt__row_has-child.wbs-level__0 .status-element__name, .gantt_row.odd.gantt_row_task.gantt__row_has-child.wbs-level__0 .status-element__name, .lookahead-view .gantt_layout_content .gantt_task_row.wbs-level__0 .status-element__name {
  color: #fff;
}

.gantt_row.gantt_row_task.gantt__row_has-child.wbs-level__0 .status-element__icon, .gantt_row.odd.gantt_row_task.gantt__row_has-child.wbs-level__0 .status-element__icon, .lookahead-view .gantt_layout_content .gantt_task_row.wbs-level__0 .status-element__icon {
  background-color: #fff;
}

.gantt_row.gantt_row_task.gantt__row_has-child.wbs-level__0 .gantt_task_cell, .gantt_row.odd.gantt_row_task.gantt__row_has-child.wbs-level__0 .gantt_task_cell, .lookahead-view .gantt_layout_content .gantt_task_row.wbs-level__0 .gantt_task_cell {
  border-right: none;
}

.gantt_row.gantt_row_task.gantt__row_has-child.wbs-level__0 .gantt_tree_content, .gantt_row.gantt_row_task.gantt__row_has-child.wbs-level__0 .gantt__task-name, .gantt_row.gantt_row_task.gantt__row_has-child.wbs-level__0.gantt_row .gantt__assigners-icon--empty, .gantt_row.gantt_row_task.gantt__row_has-child.wbs-level__0.gantt_row .gantt__assigners-icon, .gantt_row.odd.gantt_row_task.gantt__row_has-child.wbs-level__0 .gantt_tree_content, .gantt_row.odd.gantt_row_task.gantt__row_has-child.wbs-level__0 .gantt__task-name, .gantt_row.odd.gantt_row_task.gantt__row_has-child.wbs-level__0.gantt_row .gantt__assigners-icon--empty, .gantt_row.odd.gantt_row_task.gantt__row_has-child.wbs-level__0.gantt_row .gantt__assigners-icon, .lookahead-view .gantt_layout_content .gantt_task_row.wbs-level__0 .gantt_tree_content, .lookahead-view .gantt_layout_content .gantt_task_row.wbs-level__0 .gantt__task-name, .lookahead-view .gantt_layout_content .gantt_task_row.wbs-level__0.gantt_row .gantt__assigners-icon--empty, .lookahead-view .gantt_layout_content .gantt_task_row.wbs-level__0.gantt_row .gantt__assigners-icon {
  color: #fff;
  font-weight: 600;
}

.gantt_row.gantt_row_task.gantt__row_has-child.wbs-level__1, .gantt_row.odd.gantt_row_task.gantt__row_has-child.wbs-level__1, .lookahead-view .gantt_layout_content .gantt_task_row.wbs-level__1 {
  background-color: #636e72;
}

.gantt_row.gantt_row_task.gantt__row_has-child.wbs-level__1 .gantt__task-name_link:hover, .gantt_row.odd.gantt_row_task.gantt__row_has-child.wbs-level__1 .gantt__task-name_link:hover, .lookahead-view .gantt_layout_content .gantt_task_row.wbs-level__1 .gantt__task-name_link:hover, .gantt_row.gantt_row_task.gantt__row_has-child.wbs-level__1 .status-element__name, .gantt_row.odd.gantt_row_task.gantt__row_has-child.wbs-level__1 .status-element__name, .lookahead-view .gantt_layout_content .gantt_task_row.wbs-level__1 .status-element__name {
  color: #fff;
}

.gantt_row.gantt_row_task.gantt__row_has-child.wbs-level__1 .status-element__icon, .gantt_row.odd.gantt_row_task.gantt__row_has-child.wbs-level__1 .status-element__icon, .lookahead-view .gantt_layout_content .gantt_task_row.wbs-level__1 .status-element__icon {
  background-color: #fff;
}

.gantt_row.gantt_row_task.gantt__row_has-child.wbs-level__1 .gantt_task_cell, .gantt_row.odd.gantt_row_task.gantt__row_has-child.wbs-level__1 .gantt_task_cell, .lookahead-view .gantt_layout_content .gantt_task_row.wbs-level__1 .gantt_task_cell {
  border-right: none;
}

.gantt_row.gantt_row_task.gantt__row_has-child.wbs-level__1 .gantt_tree_content, .gantt_row.gantt_row_task.gantt__row_has-child.wbs-level__1 .gantt__task-name, .gantt_row.gantt_row_task.gantt__row_has-child.wbs-level__1.gantt_row .gantt__assigners-icon--empty, .gantt_row.gantt_row_task.gantt__row_has-child.wbs-level__1.gantt_row .gantt__assigners-icon, .gantt_row.odd.gantt_row_task.gantt__row_has-child.wbs-level__1 .gantt_tree_content, .gantt_row.odd.gantt_row_task.gantt__row_has-child.wbs-level__1 .gantt__task-name, .gantt_row.odd.gantt_row_task.gantt__row_has-child.wbs-level__1.gantt_row .gantt__assigners-icon--empty, .gantt_row.odd.gantt_row_task.gantt__row_has-child.wbs-level__1.gantt_row .gantt__assigners-icon, .lookahead-view .gantt_layout_content .gantt_task_row.wbs-level__1 .gantt_tree_content, .lookahead-view .gantt_layout_content .gantt_task_row.wbs-level__1 .gantt__task-name, .lookahead-view .gantt_layout_content .gantt_task_row.wbs-level__1.gantt_row .gantt__assigners-icon--empty, .lookahead-view .gantt_layout_content .gantt_task_row.wbs-level__1.gantt_row .gantt__assigners-icon {
  color: #fff;
  font-weight: 600;
}

.gantt_row.gantt_row_task.gantt__row_has-child.wbs-level__2, .gantt_row.odd.gantt_row_task.gantt__row_has-child.wbs-level__2, .lookahead-view .gantt_layout_content .gantt_task_row.wbs-level__2 {
  background-color: #b2bec3;
}

.gantt_row.gantt_row_task.gantt__row_has-child.wbs-level__2 .gantt__task-name_link:hover, .gantt_row.odd.gantt_row_task.gantt__row_has-child.wbs-level__2 .gantt__task-name_link:hover, .lookahead-view .gantt_layout_content .gantt_task_row.wbs-level__2 .gantt__task-name_link:hover, .gantt_row.gantt_row_task.gantt__row_has-child.wbs-level__2 .status-element__name, .gantt_row.odd.gantt_row_task.gantt__row_has-child.wbs-level__2 .status-element__name, .lookahead-view .gantt_layout_content .gantt_task_row.wbs-level__2 .status-element__name {
  color: #000;
}

.gantt_row.gantt_row_task.gantt__row_has-child.wbs-level__2 .status-element__icon, .gantt_row.odd.gantt_row_task.gantt__row_has-child.wbs-level__2 .status-element__icon, .lookahead-view .gantt_layout_content .gantt_task_row.wbs-level__2 .status-element__icon {
  background-color: #fff;
}

.gantt_row.gantt_row_task.gantt__row_has-child.wbs-level__2 .gantt_task_cell, .gantt_row.odd.gantt_row_task.gantt__row_has-child.wbs-level__2 .gantt_task_cell, .lookahead-view .gantt_layout_content .gantt_task_row.wbs-level__2 .gantt_task_cell {
  border-right: none;
}

.gantt_row.gantt_row_task.gantt__row_has-child.wbs-level__2 .gantt_tree_content, .gantt_row.gantt_row_task.gantt__row_has-child.wbs-level__2 .gantt__task-name, .gantt_row.gantt_row_task.gantt__row_has-child.wbs-level__2.gantt_row .gantt__assigners-icon--empty, .gantt_row.gantt_row_task.gantt__row_has-child.wbs-level__2.gantt_row .gantt__assigners-icon, .gantt_row.odd.gantt_row_task.gantt__row_has-child.wbs-level__2 .gantt_tree_content, .gantt_row.odd.gantt_row_task.gantt__row_has-child.wbs-level__2 .gantt__task-name, .gantt_row.odd.gantt_row_task.gantt__row_has-child.wbs-level__2.gantt_row .gantt__assigners-icon--empty, .gantt_row.odd.gantt_row_task.gantt__row_has-child.wbs-level__2.gantt_row .gantt__assigners-icon, .lookahead-view .gantt_layout_content .gantt_task_row.wbs-level__2 .gantt_tree_content, .lookahead-view .gantt_layout_content .gantt_task_row.wbs-level__2 .gantt__task-name, .lookahead-view .gantt_layout_content .gantt_task_row.wbs-level__2.gantt_row .gantt__assigners-icon--empty, .lookahead-view .gantt_layout_content .gantt_task_row.wbs-level__2.gantt_row .gantt__assigners-icon {
  color: #000;
  font-weight: 600;
}

.gantt_row.gantt_row_task.gantt__row_has-child.wbs-level__3, .gantt_row.odd.gantt_row_task.gantt__row_has-child.wbs-level__3, .lookahead-view .gantt_layout_content .gantt_task_row.wbs-level__3 {
  background-color: #dfe6e9;
}

.gantt_row.gantt_row_task.gantt__row_has-child.wbs-level__3 .gantt__task-name_link:hover, .gantt_row.odd.gantt_row_task.gantt__row_has-child.wbs-level__3 .gantt__task-name_link:hover, .lookahead-view .gantt_layout_content .gantt_task_row.wbs-level__3 .gantt__task-name_link:hover, .gantt_row.gantt_row_task.gantt__row_has-child.wbs-level__3 .status-element__name, .gantt_row.odd.gantt_row_task.gantt__row_has-child.wbs-level__3 .status-element__name, .lookahead-view .gantt_layout_content .gantt_task_row.wbs-level__3 .status-element__name {
  color: #000;
}

.gantt_row.gantt_row_task.gantt__row_has-child.wbs-level__3 .status-element__icon, .gantt_row.odd.gantt_row_task.gantt__row_has-child.wbs-level__3 .status-element__icon, .lookahead-view .gantt_layout_content .gantt_task_row.wbs-level__3 .status-element__icon {
  background-color: #fff;
}

.gantt_row.gantt_row_task.gantt__row_has-child.wbs-level__3 .gantt_task_cell, .gantt_row.odd.gantt_row_task.gantt__row_has-child.wbs-level__3 .gantt_task_cell, .lookahead-view .gantt_layout_content .gantt_task_row.wbs-level__3 .gantt_task_cell {
  border-right: none;
}

.gantt_row.gantt_row_task.gantt__row_has-child.wbs-level__3 .gantt_tree_content, .gantt_row.gantt_row_task.gantt__row_has-child.wbs-level__3 .gantt__task-name, .gantt_row.gantt_row_task.gantt__row_has-child.wbs-level__3.gantt_row .gantt__assigners-icon--empty, .gantt_row.gantt_row_task.gantt__row_has-child.wbs-level__3.gantt_row .gantt__assigners-icon, .gantt_row.odd.gantt_row_task.gantt__row_has-child.wbs-level__3 .gantt_tree_content, .gantt_row.odd.gantt_row_task.gantt__row_has-child.wbs-level__3 .gantt__task-name, .gantt_row.odd.gantt_row_task.gantt__row_has-child.wbs-level__3.gantt_row .gantt__assigners-icon--empty, .gantt_row.odd.gantt_row_task.gantt__row_has-child.wbs-level__3.gantt_row .gantt__assigners-icon, .lookahead-view .gantt_layout_content .gantt_task_row.wbs-level__3 .gantt_tree_content, .lookahead-view .gantt_layout_content .gantt_task_row.wbs-level__3 .gantt__task-name, .lookahead-view .gantt_layout_content .gantt_task_row.wbs-level__3.gantt_row .gantt__assigners-icon--empty, .lookahead-view .gantt_layout_content .gantt_task_row.wbs-level__3.gantt_row .gantt__assigners-icon {
  color: #000;
  font-weight: 600;
}

[data-column-name="comments"] .gantt_tree_content {
  color: #053fe3;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  display: flex;
}

[data-column-name="comments"] .gantt_tree_content .edit_icon {
  cursor: pointer;
  padding: .625rem;
}

[data-column-name="estimated_labor_abbr"] .gantt_tree_content {
  color: #053fe3;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  display: flex;
}

[data-column-name="estimated_labor_abbr"] .gantt_tree_content .edit_icon {
  cursor: pointer;
  padding: .625rem;
}

[data-column-name="average_labor_abbr"] .gantt_tree_content {
  color: #053fe3;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  display: flex;
}

[data-column-name="average_labor_abbr"] .gantt_tree_content .edit_icon {
  cursor: pointer;
  padding: .625rem;
}

[data-column-name="progress"] .gantt_tree_content .progress_cell {
  color: #053fe3;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  display: flex;
}

[data-column-name="progress"] .gantt_tree_content .progress_cell .edit_icon {
  cursor: pointer;
  padding: .625rem;
}

[data-column-name="zone"] .gantt_tree_content {
  color: #053fe3;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  display: flex;
}

[data-column-name="zone"] .gantt_tree_content .edit_icon {
  cursor: pointer;
  padding: .625rem;
}

[data-column-name="crew"] .gantt_tree_content {
  color: #053fe3;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  display: flex;
}

[data-column-name="crew"] .gantt_tree_content .edit_icon {
  cursor: pointer;
  padding: .625rem;
}

[data-column-name="completion_unit"] .gantt_tree_content {
  color: #053fe3;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  display: flex;
}

[data-column-name="completion_unit"] .gantt_tree_content .edit_icon {
  cursor: pointer;
  padding: .625rem;
}

[data-column-name="completion_target"] .gantt_tree_content {
  color: #053fe3;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  display: flex;
}

[data-column-name="completion_target"] .gantt_tree_content .edit_icon {
  cursor: pointer;
  padding: .625rem;
}

.gantt_cell[data-column-name="progress"] {
  width: 100%;
  height: 100%;
  padding: 0;
  position: relative;
  overflow: hidden;
}

.gantt_cell[data-column-name="progress"] .progress_text {
  padding-left: .375rem;
}

.gantt_cell[data-column-name="progress"] .progress_bar {
  pointer-events: none;
  background-color: rgba(5, 63, 227, .1);
  height: 100%;
  position: absolute;
}

.gantt-container_baseline .gantt_task_line {
  border-radius: 3px;
  margin-top: -8px;
  font-size: 10px;
  height: 14px !important;
  line-height: 14px !important;
}

.gantt-container_baseline .gantt_line_wrapper {
  margin-top: -8px;
}

.gantt-container_baseline .gantt_link_arrow {
  margin-top: -11px;
}

.gantt-container_baseline .gantt_link_control {
  margin-top: -3px;
}

.gantt-container_baseline .gantt_task_content {
  font-size: 10px;
  line-height: 14px;
}

.gantt-container_baseline .baseline-task {
  background-color: #ffe3b3;
  border-radius: 3px;
  height: 14px;
  margin-top: -6px;
  position: absolute;
}

.gantt-container_baseline .baseline-milestone {
  background-color: #ffe3b3;
  border-radius: 3px;
  width: 11px;
  height: 11px;
  margin-top: -6px;
  margin-left: -6px;
  position: absolute;
  transform: rotate(45deg);
}

.gantt_grid__frozen-1 .gantt_grid_head_cell:nth-child(-n+1) {
  left: var(--gantt-frozen-column-scroll-left);
  z-index: 2;
  background: #fff;
  position: relative;
}

.gantt_grid__frozen-1 .gantt_row:not(.gantt_row_placeholder).gantt__row_has-child .gantt_cell:first-child, .gantt_grid__frozen-1 .gantt_row:not(.gantt_row_placeholder).gantt__row_is-child .gantt_cell:first-child {
  box-shadow: inset 2px 0 #053fe3;
}

.gantt_grid__frozen-1 .gantt_row:not(.gantt_row_placeholder) .gantt_cell:nth-child(-n+1) {
  left: var(--gantt-frozen-column-scroll-left);
  z-index: 2;
  background: #fff;
  position: relative;
  box-shadow: .5px 1px #d2d1d1;
}

.gantt_grid__frozen-1 .gantt_row:not(.gantt_row_placeholder).gantt__row_has-child .gantt_cell:nth-child(-n+1) {
  background: inherit;
}

.gantt_grid__frozen-1 .gantt_grid_head_cell:nth-child(-n+1) {
  background: #f5f5f5;
}

.gantt_grid__frozen-1 .gantt_row:not(.gantt_row_placeholder):not(.gantt__row_has-child):hover .gantt_cell:nth-child(-n+1), .gantt_grid__frozen-1 .gantt_row:not(.gantt_row_placeholder):not(.gantt__row_has-child).gantt_selected .gantt_cell:nth-child(-n+1) {
  background: #e6ecfc !important;
}

.gantt_grid__frozen-1 .gantt_row:not(.gantt_row_placeholder):not(.gantt__row_has-child).selected-task_custom .gantt_cell:nth-child(-n+1) {
  background: #c9d6f8 !important;
}

.gantt_grid__frozen-2 .gantt_grid_head_cell:nth-child(-n+2) {
  left: var(--gantt-frozen-column-scroll-left);
  z-index: 2;
  background: #fff;
  position: relative;
}

.gantt_grid__frozen-2 .gantt_row:not(.gantt_row_placeholder).gantt__row_has-child .gantt_cell:first-child, .gantt_grid__frozen-2 .gantt_row:not(.gantt_row_placeholder).gantt__row_is-child .gantt_cell:first-child {
  box-shadow: inset 2px 0 #053fe3;
}

.gantt_grid__frozen-2 .gantt_row:not(.gantt_row_placeholder) .gantt_cell:nth-child(-n+2) {
  left: var(--gantt-frozen-column-scroll-left);
  z-index: 2;
  background: #fff;
  position: relative;
  box-shadow: .5px 1px #d2d1d1;
}

.gantt_grid__frozen-2 .gantt_row:not(.gantt_row_placeholder).gantt__row_has-child .gantt_cell:nth-child(-n+2) {
  background: inherit;
}

.gantt_grid__frozen-2 .gantt_grid_head_cell:nth-child(-n+2) {
  background: #f5f5f5;
}

.gantt_grid__frozen-2 .gantt_row:not(.gantt_row_placeholder):not(.gantt__row_has-child):hover .gantt_cell:nth-child(-n+2), .gantt_grid__frozen-2 .gantt_row:not(.gantt_row_placeholder):not(.gantt__row_has-child).gantt_selected .gantt_cell:nth-child(-n+2) {
  background: #e6ecfc !important;
}

.gantt_grid__frozen-2 .gantt_row:not(.gantt_row_placeholder):not(.gantt__row_has-child).selected-task_custom .gantt_cell:nth-child(-n+2) {
  background: #c9d6f8 !important;
}

.gantt_grid__frozen-3 .gantt_grid_head_cell:nth-child(-n+3) {
  left: var(--gantt-frozen-column-scroll-left);
  z-index: 2;
  background: #fff;
  position: relative;
}

.gantt_grid__frozen-3 .gantt_row:not(.gantt_row_placeholder).gantt__row_has-child .gantt_cell:first-child, .gantt_grid__frozen-3 .gantt_row:not(.gantt_row_placeholder).gantt__row_is-child .gantt_cell:first-child {
  box-shadow: inset 2px 0 #053fe3;
}

.gantt_grid__frozen-3 .gantt_row:not(.gantt_row_placeholder) .gantt_cell:nth-child(-n+3) {
  left: var(--gantt-frozen-column-scroll-left);
  z-index: 2;
  background: #fff;
  position: relative;
  box-shadow: .5px 1px #d2d1d1;
}

.gantt_grid__frozen-3 .gantt_row:not(.gantt_row_placeholder).gantt__row_has-child .gantt_cell:nth-child(-n+3) {
  background: inherit;
}

.gantt_grid__frozen-3 .gantt_grid_head_cell:nth-child(-n+3) {
  background: #f5f5f5;
}

.gantt_grid__frozen-3 .gantt_row:not(.gantt_row_placeholder):not(.gantt__row_has-child):hover .gantt_cell:nth-child(-n+3), .gantt_grid__frozen-3 .gantt_row:not(.gantt_row_placeholder):not(.gantt__row_has-child).gantt_selected .gantt_cell:nth-child(-n+3) {
  background: #e6ecfc !important;
}

.gantt_grid__frozen-3 .gantt_row:not(.gantt_row_placeholder):not(.gantt__row_has-child).selected-task_custom .gantt_cell:nth-child(-n+3) {
  background: #c9d6f8 !important;
}

.gantt_side_content, .gantt_task_content, .gantt_task_progress {
  overflow: inherit;
}

.gantt__badge {
  color: #000;
  text-shadow: none;
  text-align: center;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 50%;
  width: 19px;
  height: 19px;
  font-size: 11px;
  font-weight: 500;
  line-height: 21px;
  display: block;
  position: relative;
  bottom: -14px;
  left: -34px;
  right: -11px;
}

.gantt__badge_offset {
  bottom: -21px;
  left: -40px;
}

.gantt__badge_baselineMode {
  width: 15px;
  height: 15px;
  font-size: 8px;
  line-height: unset;
  bottom: -8px;
}

.gantt__badge_offset.gantt__badge_baselineMode {
  bottom: -17px;
  left: -32px;
}

.gantt_scale_line .gantt_scale_cell {
  cursor: pointer;
}
