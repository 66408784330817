.ctrlCard {
  background-color: rgba(30, 27, 27, .02);
  border: 1px solid #d2d1d1;
  border-radius: 9px;
}

.ctrlCard__header {
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 11px 13px;
  display: flex;
}

.ctrlCard__check {
  flex-shrink: 0;
  margin-right: 5px;
}

.ctrlCard__titleGroup {
  flex-grow: 1;
  margin-right: 8px;
}

.ctrlCard__id {
  letter-spacing: -.4px;
  color: #787676;
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 1.33333;
}

.ctrlCard__titleField {
  width: 100%;
}

.ctrlCard__actions {
  flex-shrink: 0;
}

.ctrlCard__body {
  border-top-width: inherit;
  border-top-style: inherit;
  border-top-color: inherit;
  padding: 13px;
}
