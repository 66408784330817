.dependenciesList__item {
  margin-top: 18px;
}

.dependenciesList__item:first-child {
  margin-top: 0;
}

.dependenciesList__item_isHidden {
  display: none;
}
