.react-tooltip {
  --bgColor: rgba(30, 27, 27, .9);
  --bgErrorColor: #fa380d;
  --bgEditColor: #053fe3;
  background-color: var(--bgColor);
  color: #fff;
  z-index: 9999;
  border-radius: .3125rem;
  padding: .5rem;
  font-size: .75rem;
  position: absolute;
  box-shadow: 0 .25rem .375rem rgba(0, 0, 0, .1);
}

.react-tooltip--error {
  background-color: var(--bgErrorColor);
}

.react-tooltip--edit {
  background-color: var(--bgEditColor);
}

.react-tooltip:after {
  content: "";
  background-color: inherit;
  z-index: -1;
  width: .625rem;
  height: .625rem;
  position: absolute;
  transform: rotate(45deg);
}

.react-tooltip[data-popper-placement^="right"]:after {
  top: 50%;
  left: -.3125rem;
  transform: translateY(-50%)rotate(45deg);
}

.react-tooltip[data-popper-placement^="left"]:after {
  top: 50%;
  right: -.3125rem;
  transform: translateY(-50%)rotate(45deg);
}

.react-tooltip[data-popper-placement^="top"]:after {
  bottom: -.3125rem;
  left: 50%;
  transform: translateX(-50%)rotate(45deg);
}

.react-tooltip[data-popper-placement^="bottom"]:after {
  top: -.3125rem;
  left: 50%;
  transform: translateX(-50%)rotate(45deg);
}
