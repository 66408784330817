.dateControl {
  margin-bottom: 10px;
}

.dateControl__datepicker {
  box-sizing: border-box;
  background-color: #fbfbfb;
  border: 1px solid #e9e8e8;
  border-radius: 12px;
  padding: 6px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, .08);
}

.dateControl__disable {
  opacity: .4;
}

.dateControl__rangeWrap {
  margin-top: 5px;
}

.dateControl__rangeWrap input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  margin: 10px 0;
}

.dateControl__rangeWrap input[type="range"]:focus {
  outline: none;
}

.dateControl__rangeWrap input[type="range"]::-webkit-slider-runnable-track {
  cursor: pointer;
  animate: .2s;
  background: #053fe3;
  border: 0 solid #0f0e0c;
  border-radius: 4px;
  width: 100%;
  height: 8px;
}

.dateControl__rangeWrap input[type="range"]::-webkit-slider-thumb {
  cursor: pointer;
  -webkit-appearance: none;
  background: #fff;
  border: 4px solid rgba(5, 63, 227, .25);
  border-radius: 16px;
  width: 24px;
  height: 24px;
  margin-top: -8px;
}

.dateControl__rangeWrap input[type="range"]::-moz-range-track {
  cursor: pointer;
  animate: .2s;
  background: #053fe3;
  border: 0 solid #0f0e0c;
  border-radius: 4px;
  width: 100%;
  height: 8px;
}

.dateControl__rangeWrap input[type="range"]::-moz-range-thumb {
  cursor: pointer;
  background: #fff;
  border: 4px solid #f3c846;
  border-radius: 16px;
  width: 24px;
  height: 24px;
  box-shadow: 2px 2px 10px #353535;
}

.dateControl__rangeWrap input[type="range"]::-ms-track {
  cursor: pointer;
  animate: .2s;
  color: rgba(0, 0, 0, 0);
  background: none;
  border-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 8px;
}

.dateControl__rangeWrap input[type="range"]::-ms-fill-lower {
  background: #053fe3;
  border: 0 solid #0f0e0c;
  border-radius: 16px;
}

.dateControl__rangeWrap input[type="range"]::-ms-fill-upper {
  background: #053fe3;
  border: 0 solid #0f0e0c;
  border-radius: 16px;
}

.dateControl__rangeWrap input[type="range"]::-ms-thumb {
  cursor: pointer;
  background: #fff;
  border: 4px solid rgba(5, 63, 227, .25);
  border-radius: 16px;
  width: 24px;
  height: 24px;
  margin-top: 1px;
}

.dateControl__rangeWrap input[type="range"]:focus::-ms-fill-lower {
  background: #053fe3;
}

.dateControl__rangeWrap input[type="range"]:focus::-ms-fill-upper {
  background: #053fe3;
}
