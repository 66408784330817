.suggestion {
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #e9e8e8;
  border-radius: 12px;
  width: 350px;
  height: 76px;
  margin: 0;
  position: absolute;
  overflow: hidden;
  box-shadow: 0 6px 16px rgba(0, 0, 0, .08);
}

.suggestion__header {
  justify-content: space-between;
  display: flex;
}

.suggestion__close {
  padding: 12px 14px 0 0;
}

.suggestion__text {
  letter-spacing: -.4px;
  color: #a5a4a4;
  margin: 12px 0 16px 14px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.suggestion__orgWrap {
  flex-wrap: wrap;
  margin: 0 0 0 14px;
  display: flex;
}

.suggestion__org {
  color: #4b4949;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid #e9e8e8;
  border-radius: 9px;
  flex-shrink: 0;
  align-self: flex-start;
  max-width: 270px;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 4px 7px;
  font-size: 12px;
  overflow: hidden;
}
