.ctrlTab {
  box-sizing: border-box;
  color: #6b7280;
  background-color: #fff;
  border-width: 0;
  border-radius: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 8px 10px;
  font-size: 16px;
  line-height: 1.5;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media only screen and (min-width: 576px) {
  .ctrlTab {
    flex-direction: row;
  }
}

.ctrlTab:before {
  content: "";
  background-color: #e5e7eb;
  width: 100%;
  height: 4px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.ctrlTab__icon {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  display: block;
}

@media only screen and (min-width: 576px) {
  .ctrlTab__icon {
    margin-bottom: 2px;
    margin-right: 6px;
  }
}

.ctrlTab_isActive {
  color: #b91c1c;
}

.ctrlTab_isActive:before {
  background-color: #b91c1c;
}
