.daily-report {
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: .5rem;
  display: flex;
}

.daily-report-report-title {
  align-self: flex-start;
}

.daily-report__header {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
}

@media print {
  .daily-report__header {
    margin: .0625rem 0 1.25rem;
  }
}

.daily-report__title, .daily-report__date {
  margin: 0 0 1rem;
  font-size: .875rem;
  font-weight: bold;
}

.daily-report__add-comment-btn-wrapper {
  margin: 0 0 1rem;
}

.daily-report__add-comment-btn {
  padding: .1875rem .5rem .1875rem .1875rem;
}

.daily-report__title {
  color: #1e1b1b;
}

.daily-report__date {
  color: #053fe3;
  margin-left: .25rem;
}

.daily-report__content {
  flex-direction: column;
  align-items: center;
  gap: .5rem;
  font-size: .875rem;
  display: flex;
}

.daily-report__content--item {
  align-items: center;
  gap: .25rem;
  padding: .03125rem .5rem 0 0;
  font-size: .75rem;
  display: flex;
}

.daily-report__content--item__text {
  color: #666;
  font-size: .875rem;
}

@media only screen and (min-width: 800px) {
  .daily-report__content--item:not(:last-of-type) {
    border-right: .0625rem solid #666;
  }

  .daily-report__content--item:last-of-type {
    padding: .03125rem 0 0;
  }

  .daily-report__title, .daily-report__date, .daily-report__content {
    align-items: unset;
    flex-direction: row;
  }
}

@media only screen and (min-width: 1024px) {
  .daily-report {
    margin: 1.5rem 2.5rem 0;
    padding: 0 0 1rem;
  }

  .daily-report__title {
    color: #1e1b1b;
    font-size: 1.5rem;
    font-weight: bold;
  }

  .daily-report__date {
    color: #053fe3;
    font-size: 1.5rem;
    font-weight: bold;
  }

  .daily-report__content {
    flex-direction: row;
  }
}

@media only screen and (min-width: 1300px) {
  .daily-report__header {
    flex-direction: row;
    position: relative;
  }

  .daily-report__add-comment-btn-wrapper {
    position: absolute;
    top: 2rem;
  }
}

.issues-table-card {
  box-sizing: border-box;
  background: #fff;
  border: .125rem solid #e5e4e2;
  border-radius: .5rem;
  flex-direction: row;
  flex: 1;
  width: 100%;
  margin-bottom: 1rem;
  padding: .5rem;
  display: flex;
}

@media only screen and (min-width: 576px) {
  .issues-table-card {
    margin: 1.5rem 0 1.25rem;
    padding: 1rem;
  }
}

.issues-table-header {
  flex: 1;
  justify-content: flex-start;
  width: 100%;
  display: flex;
}

.issues-table-header h3 {
  text-align: left;
}

.issues-table-header_title {
  font-size: 1rem;
}

.issues-table-header_title.minimal {
  margin-top: 1.5rem;
}

.issues-table-header_title.full {
  margin-top: 0;
}

.tables-container {
  flex-flow: row;
  gap: 1rem;
  width: 100%;
  display: flex;
}

@media only screen and (max-width: 800px) {
  .tables-container {
    flex-direction: column;
    gap: 0;
  }
}

.issues-table {
  border-collapse: collapse;
  flex: 1;
  width: 100%;
  font-size: .75rem;
}

.issues-table caption {
  font-size: 1rem;
  font-weight: bold;
}

.issues-table caption.activity_caption {
  font-size: .875rem;
  font-weight: 400;
}

.issues-table caption, .issues-table th {
  text-align: left;
}

.issues-table tr:nth-of-type(2n) {
  background-color: rgba(211, 211, 211, .2);
}

.issues-table .totalIssueDays {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.issues-table td, .issues-table th {
  padding: .5rem;
}

.issues-table__description {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
}

@media only screen and (max-width: 576px) {
  .issues-table {
    margin: 1.5rem 0 1.25rem;
    padding: 1rem;
  }

  .issues-table tr {
    position: relative;
  }

  .issues-table th {
    display: none;
  }

  .issues-table td {
    padding: .5rem 1rem;
    display: block;
  }

  .issues-table td:first-child {
    padding-top: 1rem;
  }

  .issues-table td:last-child {
    padding-bottom: 1rem;
  }

  .issues-table td:before {
    content: attr(data-cell) ": ";
    font-weight: 700;
  }

  .issues-table td:nth-of-type(4):before {
    content: none;
  }

  .issues-table td:nth-of-type(6) {
    padding: .5rem;
    position: absolute;
    top: calc(100% - 3rem);
    left: calc(100% - 6.5rem);
  }

  .issues-table td:nth-of-type(6):before {
    content: none;
    padding: 0;
  }
}

.add_activity_btn {
  color: #053fe3;
  fill: #053fe3;
  background-color: rgba(229, 228, 226, .5);
  border: none;
  border-radius: .6875rem;
  justify-content: space-around;
  align-items: center;
  min-width: 5.5rem;
  margin-right: auto;
  padding: .375rem .5rem;
  font-size: .75rem;
  transition: background-color .1s ease-in-out;
  display: flex;
}

.add_activity_btn-icon {
  margin-left: .625rem;
}

.add_activity_btn:active {
  background-color: rgba(229, 228, 226, .7);
}

.generalCommentPopup {
  max-width: 31.25rem;
  min-height: 25%;
  padding: 1rem;
}

.popup__header {
  text-align: center;
  padding: .75rem;
}

.generalCommentsInputContainer {
  margin: .625rem 0;
}

.generalCommentsInputContainerinput[name="comment"] {
  background-color: #fff;
}

.generalCommentsInputContainerinput[name="comment"]:hover, .generalCommentsInputContainerinput[name="comment"]:focus {
  background: #fff;
}

.fileListContainer {
  flex-wrap: wrap;
  justify-content: space-between;
  gap: .5rem;
  margin: .625rem 0 0;
  display: flex;
}

.imageUploadBtn {
  width: 100%;
  margin-bottom: 10px;
}

.generalCommentFormInput {
  resize: none;
  min-height: 1rem;
  overflow: hidden;
}

.commentSubmitFormBtn {
  width: 100%;
}

.error-message {
  background: unset;
  left: unset;
  bottom: unset;
  color: #fa380d;
  font-size: .625rem;
}

.error-message:before, .error-message-hidden {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}
