.task-responsible-select :global .react-select__input {
  line-height: 45px;
}

.task-responsible-select :global .react-select__option {
  padding-top: 6px;
  padding-bottom: 6px;
}

.task-responsible-select :global .worker-accepted__info {
  align-items: center;
}

.task-responsible-select :global .worker-accepted__info-part--avatar {
  width: 28px;
  height: 28px;
}

.task-responsible-select :global .react-select__menu-list--is-multi {
  padding: 0;
  display: block;
}

.task-responsible-select :global .react-select__menu-list--is-multi .react-select__option {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  letter-spacing: -.5px;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid rgba(0, 0, 0, 0);
  outline: none;
  padding: 11px;
  font-size: 14px;
  line-height: 20px;
  display: block;
}

.task-responsible-select :global .react-select__menu-list--is-multi .react-select__option--is-focused {
  color: #fff;
  background-color: #235dff;
}
