.ctrlColor {
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  align-items: center;
  width: -webkit-fit-content;
  width: fit-content;
  padding: 0;
  display: flex;
}

.ctrlColor__color {
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 8px;
  width: 20px;
  height: 20px;
  transition-property: border-color, box-shadow;
  transition-duration: .125s;
  transition-timing-function: ease-in-out;
  position: relative;
}

.ctrlColor__text {
  color: #053fe3;
  margin-left: 12px;
  padding-top: 1px;
  font-size: 14px;
  line-height: 1.28571;
}

.ctrlColor_reset .ctrlColor__color {
  border-color: #8e8d8d;
  overflow: hidden;
}

.ctrlColor_reset .ctrlColor__color:before {
  content: "";
  border-style: solid;
  border-width: 1px;
  border-color: inherit;
  transform-origin: 100% 0;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0;
  right: 0;
  transform: rotate(-45deg);
}

.ctrlColor_isActive .ctrlColor__color {
  border-color: #fff;
  box-shadow: 0 0 0 4px rgba(5, 63, 227, .25);
}
