.reviews {
  padding-top: 50px;
  padding-bottom: 250px;
}

.reviews__title {
  color: #111827;
  text-align: center;
  margin-bottom: 24px;
  font-size: 48px;
  font-weight: 700;
}

@media only screen and (min-width: 650px) {
  .reviews__title {
    margin-bottom: 50px;
  }
}
