.subcontractorCreating__title {
  letter-spacing: -.4px;
  color: #787676;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}

.subcontractorCreating__previewBox {
  justify-content: center;
  align-items: flex-end;
  margin-top: 24px;
  display: flex;
}

.subcontractorCreating__abbr {
  width: 72px;
  margin-left: 24px;
}

.subcontractorCreating__name {
  margin-top: 20px;
  margin-bottom: -18px;
}

.subcontractorCreating__similar {
  margin-top: 30px;
}
