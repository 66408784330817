.Subcontractors__grid {
  box-sizing: border-box;
  background: #fff;
  border-radius: 12px 12px 0 0;
  flex-grow: 1;
  height: calc(100vh - 6%);
  max-height: calc(100vh - 162px);
  margin: 6px 24px 0;
  padding: 14px;
  display: flex;
  position: relative;
  overflow: hidden;
  border: none !important;
}

.Subcontractors__grid_content {
  border-bottom-width: 0;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  width: 100%;
  margin-bottom: 23px;
  display: flex;
}

.Subcontractors__panel {
  border-top: 1px solid #cecece;
  border-right: 1px solid #cecece;
  border-top-right-radius: 9px;
}

.Subcontractors__panelWrap {
  margin-bottom: -36px;
}

.Subcontractors__table {
  border-width: 1px 1px 0;
  border-color: #cecece;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  width: 100%;
  min-width: 400px;
  padding: 0;
  display: flex;
  overflow: hidden;
}

.Subcontractors__table_CustomHeader {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.Subcontractors__table_CustomContent {
  border-top-right-radius: 0;
}
