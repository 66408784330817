.dependencyForm__body {
  flex-wrap: wrap;
  display: flex;
}

.dependencyForm__item {
  flex-shrink: 0;
  width: 50%;
}

.dependencyForm__item_mb {
  margin-bottom: 12px;
}

.dependencyForm__item_type {
  width: 100%;
}

.dependencyForm__item_mode {
  flex-grow: 1;
}

.dependencyForm__item_time {
  flex-shrink: 0;
  width: 140px;
  margin-left: 6px;
}
