.ctrlButton {
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
  padding: 8px 32px;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  transition-property: color, border-color, background-color, transform;
  transition-duration: .125s;
  transition-timing-function: ease;
  display: inline-block;
  transform: translateY(0);
}

.ctrlButton:hover {
  transform: translateY(-1px);
}

.ctrlButton_view_fill {
  color: #111827;
  background-color: #f9fafb;
  border-color: #f9fafb;
}

.ctrlButton_view_fill:hover {
  background-color: #d1d5db;
  border-color: #d1d5db;
}

.ctrlButton_view_fill.ctrlButton_color_second {
  color: #fff;
  background-color: #b91c1c;
  border-color: #b91c1c;
}

.ctrlButton_view_fill.ctrlButton_color_second:hover {
  background-color: #000;
  border-color: #000;
}

.ctrlButton_view_stroke {
  color: #f9fafb;
  background-color: rgba(0, 0, 0, 0);
  border-color: #f9fafb;
}

.ctrlButton_view_stroke.ctrlButton_color_second {
  color: #f87171;
  border-color: #ef4444;
}

.ctrlButton_view_stroke.ctrlButton_color_second:hover {
  background-color: rgba(153, 27, 27, .2);
}

.ctrlButton_textView_uppercase {
  text-transform: uppercase;
}
