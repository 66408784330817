.autoCompleteResults {
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
}

.autoCompleteResults > * {
  margin-top: 4px;
}

.autoCompleteResults > :first-child {
  margin-top: 0;
}

.autoCompleteResults__noResult {
  text-align: center;
  letter-spacing: -.4px;
  color: #787676;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.14286;
}

.autoCompleteResults .isSelected {
  color: #fff;
  background-color: #3765e9;
  border-color: #3765e9;
}
