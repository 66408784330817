.easyToUse {
  justify-content: center;
  max-width: 100vw;
  display: flex;
  overflow: hidden;
}

.easyToUse__title {
  color: #111827;
  text-transform: capitalize;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
}

.easyToUse__description {
  color: #374151;
  text-align: center;
  margin-top: 24px;
  font-size: 16px;
  line-height: 32px;
}

.easyToUse__actions {
  text-align: center;
  margin: 48px 0;
}

.easyToUse__scheme {
  width: 1260px;
  height: 500px;
  margin-top: 0;
  position: relative;
}

@media only screen and (min-width: 1300px) {
  .easyToUse__scheme {
    margin-top: 120px;
  }
}

.easyToUse__scheme__wrap {
  display: none;
}

@media only screen and (min-width: 1024px) {
  .easyToUse__scheme__wrap {
    display: block;
    scale: .7;
  }
}

@media only screen and (min-width: 1300px) {
  .easyToUse__scheme__wrap {
    scale: 1;
  }
}

.easyToUse__scheme__oracle {
  width: 76px;
  position: absolute;
  top: -25px;
  left: 35px;
}

.easyToUse__scheme__secondLine {
  position: absolute;
  top: 8px;
  left: 175px;
}

.easyToUse__scheme__oraclePoint {
  z-index: 2;
  position: absolute;
  top: 4px;
  left: 167px;
}

.easyToUse__scheme__oraclePoint_animate {
  width: 32px;
  height: 32px;
  transform: translate(-25%, -25%);
}

.easyToUse__scheme__oraclePoint_animate circle {
  stroke: #00f;
  stroke-width: 2px;
  fill: none;
  r: 8;
  opacity: 1;
  animation: 3s ease-in-out 3s infinite expand;
}

.easyToUse__scheme__procore {
  width: 160px;
  position: absolute;
  top: 153px;
}

.easyToUse__scheme__procorePoint {
  z-index: 2;
  position: absolute;
  top: 155px;
  left: 169px;
}

.easyToUse__scheme__firdLine {
  position: absolute;
  top: 8px;
  left: 175px;
}

.easyToUse__scheme__p {
  width: 76px;
  position: absolute;
  top: 265px;
  left: 64px;
}

.easyToUse__scheme__fourthLine {
  z-index: 2;
  position: absolute;
  top: 294px;
  left: 172px;
}

.easyToUse__scheme__dataSync {
  width: 152px;
  height: 36px;
  position: absolute;
  top: -15px;
  left: 334px;
}

.easyToUse__scheme__dataSync__topBorder {
  position: absolute;
  top: 0;
  left: -7px;
}

.easyToUse__scheme__dataSync__bottomBorder {
  position: absolute;
  top: 24px;
  left: -7px;
}

.easyToUse__scheme__fifthLine {
  position: absolute;
  top: 7px;
  left: 477px;
}

.easyToUse__scheme__desktopPoint {
  position: absolute;
  top: 66px;
  left: 730px;
}

.easyToUse__scheme__sixthLine {
  position: absolute;
  top: 34px;
  left: 400px;
}

.easyToUse__scheme__notebookPoint {
  position: absolute;
  top: 221px;
  left: 587px;
}

.easyToUse__scheme__devices {
  width: 740px;
  position: absolute;
  top: -20px;
  left: 500px;
}

.easyToUse__wave_1 {
  stroke-dasharray: 20 150;
  stroke-dashoffset: 20px;
  animation: 3s ease-in-out 1s infinite wave1;
}

.easyToUse__line_1 {
  stroke-dasharray: 20 150;
  stroke-dashoffset: 20px;
  animation: 9s ease-in-out infinite line1;
}

.easyToUse__line_2 {
  stroke-dasharray: 20 150;
  stroke-dashoffset: 20px;
  animation: 9s ease-in-out 3s infinite line2;
}

.easyToUse__line_3 {
  stroke-dasharray: 20 150;
  stroke-dashoffset: 20px;
  animation: 9s ease-in-out 6s infinite line3;
}

.easyToUse__totalLine_1 {
  stroke-dasharray: 20 160;
  stroke-dashoffset: 20px;
  animation: 3s ease-in-out 2s infinite totalline1;
}

.easyToUse__totalLine_2 {
  stroke-dasharray: 20 160;
  stroke-dashoffset: 20px;
  animation: 3s ease-in-out 2s infinite totalline2;
}

.easyToUse__path {
  position: absolute;
}

.easyToUse__path_bg {
  z-index: 0;
}

.easyToUse__path_wave {
  z-index: 1;
}

.easyToUse__text {
  color: #272838;
  text-align: center;
  background-image: linear-gradient(90deg, #fff 0% 50%, #272838 50% 100%);
  background-size: 200%;
  border-radius: 18px;
  margin-top: 4px;
  font-size: 18px;
  font-weight: 700;
  line-height: 48px;
  animation: 3s ease-in-out 1s infinite text;
}

@keyframes wave1 {
  0% {
    stroke-dashoffset: 20px;
  }

  33.33%, 100% {
    stroke-dashoffset: -60px;
  }
}

@keyframes line1 {
  0%, 5.56% {
    stroke-dashoffset: 20px;
  }

  11.12%, 100% {
    stroke-dashoffset: -50px;
  }
}

@keyframes line2 {
  0% {
    stroke-dashoffset: 20px;
  }

  11.12%, 100% {
    stroke-dashoffset: -100px;
  }
}

@keyframes line3 {
  0% {
    stroke-dashoffset: 20px;
  }

  11.12%, 100% {
    stroke-dashoffset: -130px;
  }
}

@keyframes totalline1 {
  0% {
    stroke-dashoffset: 20px;
  }

  33.33%, 100% {
    stroke-dashoffset: -95px;
  }
}

@keyframes totalline2 {
  0% {
    stroke-dashoffset: 20px;
  }

  33.33%, 100% {
    stroke-dashoffset: -95px;
  }
}

@keyframes text {
  0% {
    color: #272838;
    background-position: 0 0;
  }

  33.33% {
    color: #ef4444;
    background-position: -100% 100%;
  }

  66.66%, 100% {
    color: #272838;
    background-position: -200% 0;
  }
}

@keyframes expand {
  0% {
    r: 8;
    opacity: 1;
  }

  16.67%, 100% {
    r: 20;
    opacity: 0;
  }
}
