.Responsible {
  background-color: rgba(30, 27, 27, .02);
  padding: 24px 20px;
  margin-bottom: 0 !important;
}

.Responsible__header {
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 12px;
  display: flex;
}

.Responsible__title {
  letter-spacing: -1.5px;
  color: #4b4949;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.5;
}
