.container {
  color: #053fe3;
  box-sizing: border-box;
  border: 1px solid rgba(5, 63, 227, .25);
  border-radius: .5rem;
  justify-content: space-between;
  align-items: center;
  width: 10rem;
  height: 2.25rem;
  padding: .5rem .5rem .5rem .75rem;
  font-size: .875rem;
  font-weight: 500;
  display: flex;
  position: relative;
}

.container_error {
  color: #fa380d;
  border: 2px solid #fa380d;
}

.container .progress_bar {
  pointer-events: none;
  background-color: rgba(5, 63, 227, .1);
  height: 100%;
  position: absolute;
  left: 0;
  overflow: hidden;
}

.container .progress_bar:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  border-top-left-radius: .4375rem;
  border-bottom-left-radius: .4375rem;
}

.container .progress_bar:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  border-top-left-radius: .4375rem;
  border-bottom-left-radius: .4375rem;
}

.container .progress_bar:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-right-radius: .4375rem;
  border-bottom-right-radius: .4375rem;
}

.container .progress_bar:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-right-radius: .4375rem;
  border-bottom-right-radius: .4375rem;
}

.container .progress_bar_cumulative {
  pointer-events: none;
  background-color: rgba(211, 211, 211, .3);
  height: 100%;
  position: absolute;
  left: 0;
  overflow: hidden;
}

.container .progress_bar_cumulative:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  border-top-left-radius: .4375rem;
  border-bottom-left-radius: .4375rem;
}

.container .progress_bar_cumulative:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  border-top-left-radius: .4375rem;
  border-bottom-left-radius: .4375rem;
}

.container .progress_bar_cumulative:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-right-radius: .4375rem;
  border-bottom-right-radius: .4375rem;
}

.container .progress_bar_cumulative:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-right-radius: .4375rem;
  border-bottom-right-radius: .4375rem;
}

.container[aria-disabled="true"] {
  color: rgba(5, 63, 227, .1);
  border: 1px solid rgba(5, 63, 227, .1);
  font-weight: 100;
}

.error {
  color: #fa380d;
  width: 100%;
  word-wrap: none;
  font-size: .625rem;
  font-weight: 500;
}
