.CtrlChip {
  vertical-align: middle;
  display: inline-block;
  position: relative;
}

.CtrlChip__field {
  opacity: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}

.CtrlChip__field:checked + .CtrlChip__label {
  color: #fff;
  background-color: #3765e9;
  border-color: #3765e9;
  outline-width: 0;
}

.CtrlChip__label {
  letter-spacing: -.4px;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  white-space: nowrap;
  background-color: #f4f3f3;
  border: 1px solid #f4f3f3;
  border-radius: 9px;
  padding: 7px 11px;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  transition-property: color, border-color, background-color, outline-width;
  transition-duration: .125s;
  transition-timing-function: ease-in-out;
  display: block;
}

.CtrlChip__label:hover, .CtrlChip__field:focus + .CtrlChip__label {
  border-color: #698cee;
  outline: 3px solid #e6ecfc;
}

.CtrlChip__label:active {
  background-color: #cdd9f9;
  border-color: #cdd9f9;
  outline-width: 0;
}
