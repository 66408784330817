.Comments {
  background-color: rgba(30, 27, 27, .02);
  flex-grow: 1;
  padding: 24px 20px;
}

.Comments__item {
  margin-bottom: 18px;
}

.Comments__item:last-child {
  margin-bottom: 0;
}
