.issueUpdate {
  flex-direction: column;
  width: 250px;
  display: flex;
  position: relative;
}

.issueUpdate__header {
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 2px 4px;
  display: flex;
}

.issueUpdate__closeButton {
  position: absolute;
  top: -10px;
  right: -10px;
}

.issueUpdate__titleBox {
  flex-direction: column;
  align-items: center;
  max-width: 176px;
  display: flex;
}

.issueUpdate__title {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
}

.issueUpdate__title--small {
  font-size: 14px;
}

.issueUpdate__subtitle {
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.25;
}

.issueUpdate__text {
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
}

.issueUpdate__text--block {
  margin-bottom: 14px;
}

.issueUpdate__text--center {
  text-align: center;
}

.issueUpdate__body {
  flex-direction: column;
  margin-bottom: 24px;
  display: flex;
}

.issueUpdate__body .issueUpdate__text {
  margin-bottom: 10px;
}

.issueUpdate__body .issueUpdate__text:last-child {
  margin-bottom: 0;
}

.issueUpdate__button:disabled {
  pointer-events: none;
  opacity: .2;
}
