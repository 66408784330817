.infoWorker {
  align-items: center;
  display: flex;
}

.infoWorker__avatarBox {
  border-radius: 50%;
  flex-shrink: 0;
  overflow: hidden;
}

.infoWorker__avatarBox_noAvatar {
  background-color: #f5f5f5;
  display: flex;
}

.infoWorker__avatar {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

.infoWorker__icon {
  color: #c7c6c6;
  width: 68.75%;
  height: 68.75%;
  margin: auto;
  display: block;
}

.infoWorker__box {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.infoWorker_size_xs {
  letter-spacing: -.4px;
  font-size: 12px;
  line-height: 1.33333;
}

.infoWorker_size_xs .infoWorker__avatarBox {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
