.box {
  padding-left: 18px;
  padding-right: 18px;
}

@media only screen and (min-width: 1024px) {
  .box {
    max-width: 1280px;
    margin: auto;
  }
}
