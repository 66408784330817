.splitdropdown {
  margin-left: 0;
}

.collaborate {
  height: 2.25rem;
}

.collaborate span {
  font-size: .75rem;
}

.lock_screen_section.hidden {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.filter_section {
  justify-self: end;
}

.filter_section > div {
  flex-wrap: wrap;
  display: flex;
}
