.panelInfo__header {
  background-color: rgba(30, 27, 27, .02);
  margin-bottom: 24px;
  padding: 24px 20px;
}

.panelInfo__title {
  margin-left: -9px;
  margin-right: -9px;
}

.panelInfo__headerActions {
  margin-top: 16px;
}

.panelInfo__headerButton {
  margin-right: 12px;
}

.panelInfo__item {
  border-top: 1px solid #e9e8e8;
}

.panelInfo__item_color_second {
  background-color: rgba(30, 27, 27, .02);
}
