.wbs__id {
  letter-spacing: -.4px;
  color: #787676;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.14286;
}

.wbs__title {
  letter-spacing: -.4px;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
}
