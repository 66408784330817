.MembersAdd {
  z-index: 100;
  background-color: #fff;
  border-bottom: 1px solid #e9e8e8;
  padding: 24px 20px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.MembersAdd__title {
  letter-spacing: -.4px;
  color: #787676;
  margin-bottom: 18px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}

.MembersAdd__actions {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  display: flex;
}
