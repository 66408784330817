.activityListItem {
  border: 1px solid #e9e8e8;
  border-radius: 9px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding: 10px 10px 10px 14px;
  display: flex;
}

.activityListItem__name {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
}

.activityListItem__close {
  margin-left: 7px;
}

.activityListItem__delete {
  margin-bottom: 0;
}
