.actionsList__item {
  margin-top: 18px;
}

.actionsList__item:first-child {
  margin-top: 0;
}

.actionsList__item_isHidden {
  display: none;
}
