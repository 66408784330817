.issuesList__item {
  border-top: 1px solid #e9e8e8;
  margin-top: 1.125rem;
}

.issuesList__item_color_second {
  background-color: rgba(30, 27, 27, .02);
}

.issuesList__item:first-child {
  margin-top: 0;
}

.issuesList__item_isHidden {
  display: none;
}
