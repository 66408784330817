.react-datepicker-popper {
  z-index: 50000 !important;
}

.react-datepicker__input-container {
  position: relative;
}

.react-datepicker__input-container .ctrl-form__button {
  color: #a5a4a4;
  top: 50%;
  transform: translateY(-50%);
}

.react-datepicker__input-container .ctrl-form__button .ctrl-btn-clear__icon {
  width: 1.5rem;
  height: 1.5rem;
}

.react-datepicker {
  background-color: #fff;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__navigation {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width=%2724%27 height=%2724%27 xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%231E1B1B%27%3E%3Cpath d=%27M8.22 12.72a.749.749 0 0 1-.22-.53v-.38a.77.77 0 0 1 .22-.53l5.14-5.13a.5.5 0 0 1 .71 0l.71.71a.49.49 0 0 1 0 .7L10.33 12l4.45 4.44a.5.5 0 0 1 0 .71l-.71.7a.5.5 0 0 1-.71 0l-5.14-5.13z%27/%3E%3C/svg%3E");
  background-position: 50%;
  background-size: 1.125rem;
  top: 0;
}

.react-datepicker__navigation--previous {
  left: .375rem;
}

.react-datepicker__navigation--next {
  right: .375rem;
  transform: scaleX(-1);
}

.react-datepicker__header {
  flex-direction: column;
}

.react-datepicker__day-names {
  margin: -.1875rem;
  padding: 0;
}

.react-datepicker__day-name {
  text-align: center;
  letter-spacing: -.025rem;
  color: #787676;
  text-transform: none;
  margin: .1875rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.14286;
}

.react-datepicker__current-month {
  text-align: center;
  letter-spacing: -.025rem;
  color: #1e1b1b;
  background-color: rgba(0, 0, 0, 0);
  margin-bottom: 1.375rem;
  padding: .25rem 1.875rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
}

.react-datepicker__month {
  margin-top: .75rem;
  padding: 0;
}

.react-datepicker__week {
  margin-bottom: .375rem;
}

.react-datepicker__week:last-child {
  margin-bottom: 0;
}

.react-datepicker__day {
  box-sizing: border-box;
  letter-spacing: -.025rem;
  color: #1e1b1b;
  -webkit-user-select: none;
  user-select: none;
  background-color: #fff;
  border: .0625rem solid #e9e8e8;
  border-radius: .5625rem;
  margin: 0 .375rem 0 0;
  font-size: .875rem;
  line-height: 2rem;
  transition-property: background-color, color, border-color, border-radius;
  transition-duration: .125s;
  transition-timing-function: ease-in-out;
}

.react-datepicker__day--today {
  border-color: #053fe3;
  border-radius: 50%;
}

.react-datepicker__day--outside-month {
  opacity: 1;
  color: #787676;
  background-color: rgba(30, 27, 27, .02);
  border-color: rgba(0, 0, 0, 0);
}

.react-datepicker__day--selected {
  background-color: #e6ecfc;
  border-color: #e6ecfc;
  border-radius: 50%;
}

.react-datepicker__day:last-child {
  margin-right: 0;
}
