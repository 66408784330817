.actionsForm {
  display: flex;
}

.actionsForm__item {
  width: 50%;
}

.actionsForm__item_asignee {
  flex-grow: 1;
}

.actionsForm__item_date {
  flex-shrink: 0;
  width: 140px;
  margin-left: 6px;
}
