.subcontractorSimilar__label {
  letter-spacing: -.4px;
  color: #787676;
  font-size: 14px;
  line-height: 1.14286;
}

.subcontractorSimilar__body {
  margin-top: 8px;
}

.subcontractorSimilar__grid {
  flex-wrap: wrap;
  margin: -2px;
  display: flex;
}

.subcontractorSimilar__item {
  box-sizing: border-box;
  letter-spacing: -.4px;
  color: #4b4949;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid rgba(30, 27, 27, .05);
  border-radius: 9px;
  flex-shrink: 0;
  max-width: calc(100% - 4px);
  margin: 2px;
  padding: 3px 6px;
  font-size: 12px;
  line-height: 1.33333;
  overflow: hidden;
}

.subcontractorSimilar__item_isFullSimilar {
  background-color: #ffebe7;
  border-color: #fed7cf;
}
