@font-face {
  font-family: NotoSans;
  src: url("/assets/fonts/NotoSans-Regular/NotoSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: NotoSans;
  src: url("/assets/fonts/NotoSans-Medium/NotoSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: NotoSans;
  src: url("/assets/fonts/NotoSans-Bold/NotoSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

.startedSignup {
  box-sizing: border-box;
  background-image: url("/assets/images/backgrounds/C4Lookahead.png");
  background-size: cover;
  background-attachment: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  font-family: NotoSans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  display: flex;
  position: relative;
}

@media screen and (min-width: 650px) {
  .startedSignup {
    padding: 20px;
  }
}

.startedSignup:before {
  content: "";
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: rgba(107, 114, 128, .3);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.startedSignup :global .form-onboarding-z {
  position: relative;
}

.iconEye {
  color: #df413b;
}

.acceptTerms__input {
  color: #dc2626;
  -webkit-appearance: none;
  appearance: none;
  background-color: rgba(0, 0, 0, 0);
  background-origin: border-box;
  border: 1px solid #c0c3d1;
  border-radius: .25rem;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin: 0;
}

.acceptTerms__input:checked {
  background-color: #dc2626;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: rgba(0, 0, 0, 0);
}

.acceptTerms__input:focus {
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #ef4444, 0 0 rgba(0, 0, 0, 0);
}

.acceptTerms__label {
  text-align: justify;
  --tw-text-opacity: 1;
  color: #374151;
  text-transform: capitalize;
  margin-left: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  display: block;
}

.acceptTerms__label a {
  color: #dc2626;
  text-decoration: none;
}

.formOnboarding__downloadApp {
  border-radius: 0 !important;
}

.formOnboarding {
  background-color: #fff;
  width: 100%;
  max-width: 420px;
  margin: auto;
  position: relative;
}

@media screen and (min-width: 650px) {
  .formOnboarding {
    border-radius: 10px;
    max-width: 600px;
    overflow: hidden;
  }
}

@media screen and (min-width: 800px) {
  .formOnboarding {
    max-width: 900px;
  }
}

.formOnboarding__footer {
  justify-content: flex-end;
  width: 100%;
  margin-top: 15px;
  display: flex;
}

.formOnboarding__actionBtns {
  font-family: inherit;
  font-size: 100%;
  font-weight: 500;
  line-height: inherit;
  border: 1px solid #e5e7eb;
  border-radius: .375rem;
  margin-left: 16px;
  padding: .5rem 1rem;
  transition-property: color, background-color, border-color, text-decoration-coor, fill, stroke;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.formOnboarding__actionBtns.submitBtn {
  color: #fff;
  background-color: #000;
  margin-right: 7px;
  font-size: 16px;
}

.formOnboarding__actionBtns.submitBtn:hover {
  background-color: red;
}

.formOnboarding__actionBtns.submitBtn:disabled {
  pointer-events: none;
  cursor: not-allowed;
  background-color: #e5e5e5;
}

.formOnboarding__actionBtns.cancelBtn {
  color: #000;
  background-color: rgba(0, 0, 0, 0);
  font-size: 16px;
}

.formOnboarding__actionBtns.cancelBtn:hover {
  background-color: #f3f4f6;
}

.formOnboarding__actionBtns.cancelBtn:disabled {
  pointer-events: none;
  cursor: not-allowed;
  color: #fff;
  background-color: #e5e5e5;
}

.formOnboarding__input {
  box-sizing: border-box;
  color: #1e1b1b;
  letter-spacing: -.5px;
  background-color: #f5f5f5;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  outline: none;
  width: 100%;
  margin-top: 4px;
  padding: 11px;
  font-size: 14px;
  line-height: 20px;
  display: block;
}

.formOnboarding__input:-webkit-autofill {
  -webkit-text-fill-color: #000;
  transition: background-color 5000s ease-in-out;
  box-shadow: inset 0 0 0 1000px rgba(255, 0, 0, .21);
}

.formOnboarding__input :-webkit-autofill:focus {
  -webkit-text-fill-color: #000;
  transition: background-color 5000s ease-in-out;
  box-shadow: inset 0 0 0 1000px rgba(255, 0, 0, .21);
}

.formOnboarding__input :-webkit-autofill:hover {
  -webkit-text-fill-color: #000;
  transition: background-color 5000s ease-in-out;
  box-shadow: inset 0 0 0 1000px rgba(255, 0, 0, .21);
}

.formOnboarding__label {
  color: #374151;
  font-size: 14px;
  font-weight: 500;
}

.formOnboarding__container {
  padding: 30px 16px;
}

@media screen and (min-width: 650px) {
  .formOnboarding__container {
    padding: 30px 72px;
  }
}

@media screen and (min-width: 800px) {
  .formOnboarding__container {
    box-sizing: border-box;
    width: 100%;
    max-width: 600px;
  }

  .formOnboarding__containerGrid {
    display: flex;
  }

  .formOnboarding__image {
    background-image: url("/assets/images/backgrounds/signup_hero1.jpeg");
    background-size: cover;
    width: 100%;
    max-width: 320px;
  }
}

.formOnboarding__header {
  text-align: left;
  flex-direction: column;
  padding-top: 20px;
  padding-left: 8px;
  display: flex;
}

.formOnboarding__title {
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
}

.formOnboarding__description {
  color: #6b7280;
  margin-top: 8px;
  margin-bottom: 32px;
  font-size: 14px;
  line-height: 20px;
}

.formOnboarding__body {
  flex-wrap: wrap;
  width: 100%;
  display: flex;
}

.formOnboarding__item {
  width: calc(100% - 16px);
  margin: 12px 8px;
}

.formOnboarding__item__inline {
  grid-column: span 12 / span 12;
  display: inline-flex;
}

.formOnboarding__group {
  display: flex;
}

.formOnboarding__phone {
  flex-grow: 1;
  margin-left: 8px;
}

.formOnboarding__code {
  flex-shrink: 0;
  width: 80px;
}

.formOnboarding__code :global .react-select__control {
  border: 1px solid #d1d5db;
  margin-top: 4px;
}
