.companies {
  margin-top: 0;
  padding-top: 50px;
  padding-bottom: 50px;
}

@media only screen and (min-width: 1024px) {
  .companies {
    margin-top: 36px;
  }
}

.companies__container {
  background-color: #f5f5f5;
  border: 1px solid #dedede;
  border-radius: 8px;
  padding: 20px;
}

@media only screen and (min-width: 1024px) {
  .companies__container {
    width: 1278px;
    max-width: 1278px;
    max-height: 88px;
    padding: 8px 0;
  }
}

.companies__wrap {
  justify-content: center;
  display: flex;
}

.companies__grid {
  flex-wrap: wrap;
  max-width: 360px;
  margin: -8px;
  display: flex;
}

@media only screen and (min-width: 800px) {
  .companies__grid {
    flex-wrap: nowrap;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.companies__item {
  width: calc(50% - 16px);
  margin: 8px;
  display: block;
}

.companies__image {
  width: 100%;
  max-width: 186px;
  height: auto;
  display: block;
}
