.ctrlBooking {
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  border-color: var(--bdrc, rgba(255, 255, 255, .1));
  color: #fff;
  background-color: var(--bgc, #141212);
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 8px;
  outline: none;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  font-weight: 500;
  text-decoration: none;
  transition-property: border-color, background-color;
  transition-duration: .125s;
  transition-timing-function: ease-in-out;
  display: inline-flex;
}

.ctrlBooking__icon {
  flex-shrink: 0;
  margin-right: 8px;
  overflow: hidden;
}

.ctrlBooking__text {
  white-space: nowrap;
  overflow: hidden;
}

.ctrlBooking_isCollapse .ctrlBooking__icon {
  margin-right: 0;
}

.ctrlBooking_isCollapse .ctrlBooking__text {
  width: 0;
}

.ctrlBooking:hover {
  --bdrc: rgba(255, 255, 255, .5);
  --bgc: rgba(255, 255, 255, .1);
}
