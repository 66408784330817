.infoHistory {
  align-items: flex-start;
  display: flex;
}

.infoHistory__avatar {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin-top: 4px;
  margin-right: 12px;
}

.infoHistory__line {
  margin-bottom: 2px;
}

.infoHistory__line:last-child {
  margin-bottom: 0;
}

.infoHistory__workerName {
  letter-spacing: -.4px;
  margin-right: 8px;
  font-size: 16px;
  line-height: 1;
}

.infoHistory__status {
  vertical-align: bottom;
  display: inline-flex !important;
}

.infoHistory__status :global .status-element__icon {
  width: 17px;
  height: 17px;
}

.infoHistory__time {
  letter-spacing: -.4px;
  color: #a5a4a4;
  font-size: 12px;
  line-height: 1.33333;
}
