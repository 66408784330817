.subscribe {
  color: #fff;
  background-color: #dc2626;
  border-radius: 24px;
  max-width: 898px;
  margin-left: auto;
  margin-right: auto;
  padding: 40px 24px;
}

@media only screen and (min-width: 650px) {
  .subscribe {
    flex-direction: column;
    align-items: center;
    display: flex;
  }
}

.subscribe__title {
  letter-spacing: -.9px;
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
}

.subscribe__description {
  margin-top: 24px;
  font-size: 18px;
  line-height: 27px;
}

@media only screen and (min-width: 650px) {
  .subscribe__description {
    text-align: center;
  }
}

.subscribe__body {
  margin-top: 48px;
  position: relative;
}

@media only screen and (min-width: 650px) {
  .subscribe__body {
    align-items: center;
    display: flex;
  }

  .subscribe__field {
    width: 330px;
    margin-right: 16px;
  }
}

.subscribe__button {
  width: 100%;
  margin-top: 16px;
  display: block;
}

@media only screen and (min-width: 650px) {
  .subscribe__button {
    width: 269px;
    margin-top: 0;
  }
}
