.hero {
  color: #fff;
  background-color: #000;
  padding-left: 5%;
  padding-right: 5%;
  font-family: Noto Sans, sans-serif;
}

.hero__container {
  box-sizing: border-box;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 72px 32px;
  display: flex;
}

.hero__container_mr32 {
  margin-right: 32px;
}

.hero__container_hide {
  display: none;
}

@media only screen and (min-width: 1300px) {
  .hero__container_hide {
    width: 50%;
    padding: 0;
    display: flex;
  }
}

.hero__wrap {
  justify-content: center;
  display: flex;
}

@media only screen and (min-width: 1300px) {
  .hero__imagesBlock {
    margin: 0 48px;
    position: relative;
  }

  .hero__descriptionBlock {
    text-align: left;
    align-items: flex-start;
    display: flex;
  }
}

.hero__screenshot {
  width: 711px;
  height: 400px;
}

.hero__screenshotItem {
  border-radius: 6px;
  width: 100%;
  max-width: 100%;
  height: auto;
  transition-property: transform;
  transition-duration: .3s;
  transition-timing-function: ease;
  display: block;
}

.hero__screenshotItem:hover {
  transform: scale(1.5);
}

.hero__screenshot__members {
  z-index: 3;
  width: 210px;
  position: absolute;
  top: 160px;
  left: 25px;
}

.hero__screenshot__share {
  z-index: 3;
  border-radius: 6px;
  width: 240px;
  position: absolute;
  top: 50px;
  right: 15px;
}

.hero__screenshot__mobile {
  width: 288px;
  position: absolute;
  top: 230px;
  right: 0;
}

.hero__title {
  font-size: 48px;
  font-weight: 800;
  line-height: 58px;
}

@media only screen and (min-width: 1300px) {
  .hero__title {
    font-size: 60px;
    line-height: 74px;
  }
}

.hero__description {
  margin-top: 24px;
  font-size: 14px;
  line-height: 20px;
}

@media only screen and (min-width: 1300px) {
  .hero__description {
    font-size: 18px;
    line-height: 28px;
  }
}

.hero__actions {
  margin-top: 40px;
}

.hero__note {
  margin-top: 6px;
  font-size: 11px;
  line-height: 16px;
}
