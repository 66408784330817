.MembersClear {
  background-color: #fff;
  padding: 24px 20px;
}

.MembersClear__title {
  letter-spacing: -.4px;
  color: #787676;
  margin-bottom: 18px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}

.MembersClear__description {
  letter-spacing: -.4px;
  color: #787676;
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.375;
}

.MembersClear_view_responsible {
  background-color: rgba(30, 27, 27, .02);
}

.MembersClear_view_responsible .MembersClear__title {
  letter-spacing: -1.5px;
  color: #4b4949;
  font-size: 24px;
  line-height: 1.5;
}
