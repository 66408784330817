html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: rgba(0, 0, 0, 0);
}

abbr[title] {
  text-decoration: underline;
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button {
  -webkit-appearance: button;
}

[type="button"] {
  -webkit-appearance: button;
}

[type="reset"] {
  -webkit-appearance: button;
}

[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

h1, h2, h3, h4, h5, h6, p {
  margin-top: 0;
  margin-bottom: 0;
}

ul, ol {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

figure {
  margin: 0;
}

input, textarea {
  background-clip: padding-box;
}

button {
  cursor: pointer;
}

button[disabled] {
  cursor: default;
}

@font-face {
  font-family: TungstenNarrow;
  src: url("../fonts/TungstenNarrow-Semibold/TungstenNarrow-Semibold.woff") format("woff"), url("../fonts/TungstenNarrow-Semibold/TungstenNarrow-Semibold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: TungstenNarrow;
  src: url("../fonts/TungstenNarrow-Bold/TungstenNarrow-Bold.woff") format("woff"), url("../fonts/TungstenNarrow-Bold/TungstenNarrow-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Graphik LCG;
  src: url("../fonts/GraphikLCG-Regular/GraphikLCG-Regular.woff") format("woff"), url("../fonts/GraphikLCG-Regular/GraphikLCG-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Graphik LCG;
  src: url("../fonts/GraphikLCG-Medium/GraphikLCG-Medium.woff") format("woff"), url("../fonts/GraphikLCG-Medium/GraphikLCG-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

:root {
  color: #1e1b1b;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Graphik LCG, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
}

.page:after {
  clip: rect(0 0 0 0);
  content: "XS";
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.page.popup-is-open {
  overflow: hidden;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.loader-container {
  min-height: 100px;
  position: relative;
}

.loader {
  z-index: 1000;
  background-color: rgba(255, 255, 255, .8);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.loader__figure {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-origin: 50%;
  flex-shrink: 0;
  width: 100px;
  height: 100px;
  position: relative;
}

.loader__figure span {
  transform-origin: 3px 26px;
  background-color: #fa380d;
  width: 6px;
  height: 12px;
  animation: 1.2s linear infinite blink;
  position: absolute;
  top: 24px;
  left: 47px;
}

.loader__figure span:first-child {
  animation-delay: -1.1s;
  transform: rotate(0);
}

.loader__figure span:nth-child(2) {
  animation-delay: -1s;
  transform: rotate(30deg);
}

.loader__figure span:nth-child(3) {
  animation-delay: -.9s;
  transform: rotate(60deg);
}

.loader__figure span:nth-child(4) {
  animation-delay: -.8s;
  transform: rotate(90deg);
}

.loader__figure span:nth-child(5) {
  animation-delay: -.7s;
  transform: rotate(120deg);
}

.loader__figure span:nth-child(6) {
  animation-delay: -.6s;
  transform: rotate(150deg);
}

.loader__figure span:nth-child(7) {
  animation-delay: -.5s;
  transform: rotate(180deg);
}

.loader__figure span:nth-child(8) {
  animation-delay: -.4s;
  transform: rotate(210deg);
}

.loader__figure span:nth-child(9) {
  animation-delay: -.3s;
  transform: rotate(240deg);
}

.loader__figure span:nth-child(10) {
  animation-delay: -.2s;
  transform: rotate(270deg);
}

.loader__figure span:nth-child(11) {
  animation-delay: -.1s;
  transform: rotate(300deg);
}

.loader__figure span:nth-child(12) {
  animation-delay: 0s;
  transform: rotate(330deg);
}

.loader--field {
  border-radius: 8px;
  justify-content: flex-end;
}

.loader--field .loader__figure {
  transform-origin: 100%;
  margin-right: 10px;
  transform: translateZ(0)scale(.2);
}

.react-popper {
  box-sizing: border-box;
  letter-spacing: -.5px;
  color: #777;
  text-align: center;
  background-color: currentColor;
  max-width: 289px;
  padding: 8px 16px;
  font-size: 12px;
  line-height: 18px;
  box-shadow: 18px 18px 24px rgba(0, 0, 0, .1);
}

.react-popper__title {
  color: #fff;
  margin-bottom: 10px;
  font-weight: 500;
}

.react-popper__text {
  color: #fff;
}

.react-popper [data-popper-arrow] {
  width: 16px;
  height: 16px;
}

.react-popper [data-popper-arrow]:before {
  content: "";
  background-color: currentColor;
  width: 100%;
  height: 100%;
  display: block;
  transform: rotate(45deg);
}

.react-popper--error {
  color: #fa380d;
  box-shadow: 18px 18px 24px rgba(250, 56, 13, .3);
}

.react-popper--edit {
  color: #053fe3;
}

.react-popper--help {
  color: #fff;
  text-align: left;
  background-color: rgba(30, 27, 27, .9);
  border: 1px solid #fff;
  padding: 15px;
  position: relative;
  box-shadow: 18px 18px 24px rgba(0, 0, 0, .1);
}

.react-popper--help [data-popper-arrow] {
  display: none;
}

.react-popper--with-button-close {
  padding-top: 43px;
  position: relative;
}

.react-popper--with-button-close .react-popper__title {
  margin-top: -28px;
  margin-right: 26px;
}

.react-popper--with-button-close .react-popper__button-close {
  color: #fa380d;
  position: absolute;
  top: 16px;
  right: 16px;
}

.react-popper--with-button-close .react-popper__button-close .ctrl-btn-clear__icon {
  width: 16px;
  height: 16px;
}

.box {
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.logo {
  vertical-align: middle;
  color: inherit;
  text-decoration: none;
  display: inline-block;
}

.logo__text {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.logo__image {
  fill: currentColor;
  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
}

.icon {
  vertical-align: middle;
  display: inline-block;
}

.icon--color-fill {
  fill: currentColor;
}

.icon--color-stroke {
  fill: none;
  stroke: currentColor;
}

.nav__list {
  list-style-type: none;
}

.nav__item {
  letter-spacing: -1px;
  text-align: left;
  white-space: nowrap;
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  -o-transition-property: color, background-color, opacity;
  -o-transition-duration: .125s;
  -o-transition-timing-function: ease-in-out;
  opacity: .5;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  outline: none;
  align-items: center;
  width: 100%;
  padding: 16px 24px;
  font-size: 18px;
  line-height: 28px;
  transition-property: color, background-color, opacity;
  transition-duration: .125s;
  transition-timing-function: ease-in-out;
  display: flex;
}

html.no-touchevents .nav__item:hover {
  opacity: 1;
  background-color: rgba(20, 18, 18, .5);
}

.nav__item-icon {
  color: #dedede;
  opacity: .5;
  flex-shrink: 0;
  margin-right: 8px;
}

.nav__item[disabled] {
  opacity: .1;
}

html.no-touchevents .nav__item[disabled]:hover {
  opacity: .1;
  background-color: rgba(0, 0, 0, 0);
}

.nav__item.is-active {
  color: #fa380d;
  opacity: 1;
}

.nav__item.is-active + .nav__sublist {
  max-height: 216px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.nav__sublist {
  box-sizing: border-box;
  -o-transition-property: max-height, padding;
  -o-transition-duration: .125s;
  -o-transition-timing-function: ease-in-out;
  scrollbar-width: thin;
  background-color: #141212;
  max-height: 0;
  padding-left: 32px;
  padding-right: 5px;
  list-style-type: none;
  transition-property: max-height, padding;
  transition-duration: .125s;
  transition-timing-function: ease-in-out;
  overflow-x: hidden;
  overflow-y: auto;
}

.nav__sublist::-webkit-scrollbar {
  width: 11px;
}

.nav__sublist::-webkit-scrollbar-button {
  height: 16px;
}

.nav__sublist::-webkit-scrollbar-thumb {
  border-left: 4px solid #959494;
}

.nav__sublist-item {
  align-items: center;
  margin-top: 16px;
  display: flex;
}

.nav__sublist-item:first-child {
  margin-top: 0;
}

.nav__sublist-item:hover .nav__subitem {
  opacity: 1;
}

.nav__sublist-item:hover .nav__button-tasks {
  display: block;
}

.nav__subitem {
  letter-spacing: -.5px;
  text-align: left;
  white-space: nowrap;
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  -o-transition-property: color, opacity;
  -o-transition-duration: .125s;
  -o-transition-timing-function: ease-in-out;
  opacity: .5;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  outline: none;
  align-items: center;
  min-width: 0;
  margin-right: auto;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
  transition-property: color, opacity;
  transition-duration: .125s;
  transition-timing-function: ease-in-out;
  display: flex;
}

.nav__subitem-icon {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-top: -2px;
  margin-right: 8px;
}

.nav__subitem-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
  overflow: hidden;
}

.nav__subitem.is-active {
  color: #fa380d;
  opacity: 1;
}

.nav__button-tasks {
  letter-spacing: -.5px;
  color: #dedede;
  white-space: nowrap;
  background-color: rgba(30, 27, 27, .9);
  border-width: 0;
  border-radius: 10px;
  flex-shrink: 0;
  margin-left: 15px;
  padding: 2px 8px;
  font-size: 10px;
  line-height: 16px;
  display: none;
}

.ctrl-btn-clear {
  -webkit-user-select: none;
  user-select: none;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  outline: none;
  padding: 0;
}

.ctrl-btn-clear__text {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.ctrl-btn-clear__icon {
  display: block;
}

.ctrl-btn-clear[disabled] {
  opacity: .25;
}

.ctrl-btn-clear.is-active {
  color: #4050ec;
}

.info-alert {
  -o-transition-property: padding;
  -o-transition-duration: .125s;
  -o-transition-timing-function: ease-in-out;
  background-color: #141212;
  border: 1px solid rgba(255, 255, 255, .1);
  border-radius: 4px;
  padding: 8px 16px;
  transition-property: padding;
  transition-duration: .125s;
  transition-timing-function: ease-in-out;
  position: relative;
  overflow: hidden;
}

.info-alert__header {
  align-items: center;
  display: flex;
}

.info-alert__title {
  box-sizing: border-box;
  letter-spacing: -.5px;
  flex-shrink: 0;
  width: 172px;
  margin-top: 2px;
  padding-right: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.info-alert__button-toggle {
  color: #fff;
  opacity: .5;
  margin-left: auto;
}

.info-alert__button-toggle .ctrl-btn-clear__icon {
  width: 16px;
  height: 16px;
}

.info-alert__container {
  position: relative;
}

.info-alert__body {
  letter-spacing: -.5px;
  -o-transition-property: max-height;
  -o-transition-duration: .125s;
  -o-transition-timing-function: ease-in-out;
  max-height: 0;
  font-size: 12px;
  line-height: 18px;
  transition-property: max-height;
  transition-duration: .125s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
}

.info-alert__body a {
  color: #fa380d;
  text-decoration: none;
}

.info-alert__list {
  margin-top: 8px;
  list-style-type: none;
}

.info-alert__list-item {
  align-items: flex-start;
  margin-top: 8px;
  display: flex;
}

.info-alert__list-icon {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.info-alert--help:before {
  content: "";
  opacity: 0;
  -o-transition-property: opacity;
  -o-transition-duration: .125s;
  -o-transition-timing-function: ease-in-out;
  background-image: url("../images-temp/alerts-bg/alert-bg-2@2x.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  transition-property: opacity;
  transition-duration: .125s;
  transition-timing-function: ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.info-alert--help.is-active:before {
  opacity: 1;
}

.info-alert.is-active {
  padding: 16px;
}

.info-alert.is-active .info-alert__body {
  max-height: 200px;
}

.workspace-switch {
  position: relative;
}

.workspace-switch__dropdown {
  color: #1e1b1b;
  background-color: #fff;
  border-radius: 4px;
  width: 222px;
  position: absolute;
  top: calc(100% + 3px);
  right: 0;
}

.workspace-switch__dropdown.is-hidden {
  display: none;
}

.workspace-switch__title {
  letter-spacing: -.5px;
  text-align: center;
  white-space: nowrap;
  border-bottom: 1px solid #dedede;
  padding-top: 11px;
  padding-bottom: 11px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.workspace-switch__list {
  list-style-type: none;
}

.workspace-switch__button {
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  outline: none;
  align-items: center;
  width: 100%;
  padding: 7px 9px 7px 6px;
  display: inline-flex;
}

.workspace-switch__button-abbr {
  letter-spacing: -.5px;
  text-transform: uppercase;
  color: #fff;
  background-color: #1e1b1b;
  border-radius: 2px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 27px;
  height: 27px;
  padding-top: 1px;
  padding-right: 1px;
  font-size: 10px;
  line-height: 16px;
  display: flex;
}

.workspace-switch__button-name {
  letter-spacing: -.5px;
  color: #053fe3;
  white-space: nowrap;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 8px;
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
}

.workspace-switch__button-icon {
  color: #dedede;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-left: auto;
}

.workspace-switch__button--toggle {
  background-color: #3c3939;
  border-radius: 4px;
  padding: 2px 4px 2px 2px;
  box-shadow: 18px 18px 24px rgba(0, 0, 0, .1);
}

.workspace-switch__button--toggle .workspace-switch__button-name {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.workspace-switch__button--toggle .workspace-switch__button-icon {
  color: #dedede;
  margin-left: 12px;
}

.workspace-switch__button.is-active .workspace-switch__button-icon {
  color: #053fe3;
}

.workspace-switch__actions {
  padding: 7px 6px;
}

.workspace-switch__button-add {
  width: 100%;
}

.avatar {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: block;
  position: relative;
}

.avatar__image {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  border-radius: inherit;
  width: 100%;
  height: 100%;
  display: block;
}

.avatar__addition {
  box-sizing: content-box;
  color: #fff;
  background-color: #053fe3;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  padding: 3px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.avatar__addition .ctrl-btn-clear__icon {
  width: 100%;
  height: 100%;
}

.avatar__addition--responsible {
  background-color: #01964e;
  width: 12px;
  height: 12px;
  padding: 2px;
  bottom: -4px;
  right: -4px;
}

.person-preview {
  align-items: center;
  display: flex;
}

.person-preview__avatar {
  flex-shrink: 0;
  margin-right: 12px;
}

.person-preview__info {
  min-width: 0;
}

.person-preview__name {
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 2px;
  overflow: hidden;
}

.person-preview__trade {
  color: #c8c8c8;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 2px;
  overflow: hidden;
}

.person-preview__status {
  color: #fff;
  border-radius: 50%;
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  margin-left: auto;
  padding: 8px;
}

.person-preview__status--verified {
  background-color: green;
}

.lang-global {
  align-items: center;
  display: flex;
}

.lang-global__icon {
  flex-shrink: 0;
  margin-right: 8px;
}

.lang-global__text {
  flex-grow: 1;
  padding-top: 1px;
}

.ctrl-dropmenu {
  background-color: #1e1b1b;
}

.ctrl-dropmenu__button {
  letter-spacing: -.5px;
  text-align: left;
  color: #fff;
  -o-transition-property: background-color, color;
  -o-transition-timing-function: ease;
  -o-transition-duration: .125s;
  background-color: #1e1b1b;
  border-width: 0;
  width: 100%;
  padding: 16px 24px;
  font-size: 14px;
  line-height: 20px;
  transition-property: background-color, color;
  transition-duration: .125s;
  transition-timing-function: ease;
  display: block;
}

.ctrl-dropmenu__button--color-second {
  color: #fa380d;
}

.ctrl-dropmenu__button--toggle {
  align-items: center;
  display: flex;
}

html.no-touchevents .ctrl-dropmenu__button:hover {
  background-color: #3c3939;
}

.ctrl-dropmenu__button-content {
  flex-grow: 1;
  min-width: 0;
}

.ctrl-dropmenu__button-icon {
  -webkit-transform: rotate(none);
  -ms-transform: rotate(none);
  transform: rotate(none);
  -webkit-transform: rotate(var(--icon-rotate, none));
  -ms-transform: rotate(var(--icon-rotate, none));
  transform: rotate(var(--icon-rotate, none));
  -o-transition-property: transform;
  -o-transition-timing-function: ease;
  -o-transition-duration: .125s;
  flex-shrink: 0;
  margin-left: 20px;
  transition-property: transform;
  transition-duration: .125s;
  transition-timing-function: ease;
}

.ctrl-dropmenu__viewport {
  max-height: 0;
  max-height: var(--maxheight, 0);
  -o-transition-property: max-height;
  -o-transition-duration: .125s;
  -o-transition-timing-function: ease;
  transition-property: max-height;
  transition-duration: .125s;
  transition-timing-function: ease;
  overflow: hidden;
}

.ctrl-dropmenu__list {
  list-style-type: none;
}

.ctrl-dropmenu--view-transparent .ctrl-dropmenu__button {
  color: rgba(255, 255, 255, .5);
}

html.no-touchevents .ctrl-dropmenu--view-transparent .ctrl-dropmenu__button:hover, .ctrl-dropmenu--view-transparent .ctrl-dropmenu__button.is-active {
  color: #fff;
}

.ctrl-dropmenu.is-active {
  --maxheight: 500px;
  --icon-rotate: 180deg;
}

.ctrl-dropmenu.is-active .ctrl-dropmenu__button--toggle {
  color: #fff;
  background-color: #3c3939;
}

.header {
  -o-transition-property: width;
  -o-transition-timing-function: ease;
  -o-transition-duration: .25s;
  width: 72px;
  transition-property: width;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.header__box {
  -o-transition-property: width;
  -o-transition-timing-function: ease;
  -o-transition-duration: .25s;
  width: 72px;
  transition-property: width;
  transition-duration: .25s;
  transition-timing-function: ease;
  position: relative;
}

.header__container {
  box-sizing: border-box;
  color: #fff;
  background-color: #1e1b1b;
  flex-direction: column;
  height: 100vh;
  padding: 24px 24px 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.header__panel {
  align-items: center;
  display: flex;
}

.header__logo {
  -o-transition-property: margin-left;
  -o-transition-timing-function: ease;
  -o-transition-duration: .25s;
  flex-shrink: 0;
  width: 95px;
  height: 28px;
  margin-left: -4px;
  transition-property: margin-left;
  transition-duration: .25s;
  transition-timing-function: ease;
  overflow: hidden;
}

.header__logo .logo__image {
  -o-transition-property: margin-left;
  -o-transition-timing-function: ease;
  -o-transition-duration: .25s;
  margin-left: -62px;
  transition-property: margin-left;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.header__workspace {
  z-index: 1;
  margin-left: auto;
}

.header__nav {
  margin: 24px -24px auto;
}

.header__nav .nav__item-text {
  opacity: 0;
  -o-transition-property: opacity;
  -o-transition-timing-function: ease;
  -o-transition-duration: .25s;
  transition-property: opacity;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.header__informer {
  margin-top: 24px;
  margin-left: -4px;
}

.header__informer .info-alert__title {
  opacity: 0;
  -o-transition-property: opacity, margin-left;
  -o-transition-timing-function: ease;
  -o-transition-duration: .25s;
  margin-left: -180px;
  transition-property: opacity, margin-left;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.header__alert {
  box-sizing: border-box;
  -o-transition-property: width;
  -o-transition-timing-function: ease;
  -o-transition-duration: .25s;
  width: 0;
  margin-top: 8px;
  transition-property: width;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.header__alert:first-child {
  margin-top: 0;
}

.header__actions {
  margin-top: 24px;
  margin-left: -24px;
  margin-right: -24px;
}

.header__actions-item {
  align-items: flex-end;
  display: flex;
  position: relative;
}

.header__actions-item--user {
  -o-transition-property: margin-left;
  -o-transition-timing-function: ease;
  -o-transition-duration: .25s;
  height: 72px;
  margin-left: -6px;
  transition-property: margin-left;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.header__actions-item--user .person-preview__info, .header__actions-item--user .ctrl-dropmenu__button-icon {
  opacity: 0;
  -o-transition-property: opacity;
  -o-transition-timing-function: ease;
  -o-transition-duration: .25s;
  transition-property: opacity;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.header__actions-item--lang {
  height: 56px;
}

.header__actions-item--lang .lang-global__text, .header__actions-item--lang .ctrl-dropmenu__button-icon {
  opacity: 0;
  -o-transition-property: opacity;
  -o-transition-timing-function: ease;
  -o-transition-duration: .25s;
  transition-property: opacity;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.header__menu {
  width: 100%;
}

.header__button-collapse {
  color: #fff;
  -o-transition-property: transform;
  -o-transition-timing-function: ease;
  -o-transition-duration: .25s;
  background-color: #1e1b1b;
  border-radius: 50%;
  transition-property: transform;
  transition-duration: .25s;
  transition-timing-function: ease;
  position: absolute;
  top: 60px;
  right: -7px;
}

.header.is-hover .header__box, .header.is-open .header__box {
  width: 270px;
}

.header.is-hover .header__alert, .header.is-open .header__alert {
  width: 100%;
}

.header.is-hover .header__nav .nav__item-text, .header.is-hover .header__actions-item--user .person-preview__info, .header.is-hover .header__actions-item--user .ctrl-dropmenu__button-icon, .header.is-hover .header__actions-item--lang .lang-global__text, .header.is-hover .header__actions-item--lang .ctrl-dropmenu__button-icon, .header.is-hover .header__informer .info-alert__title, .header.is-open .header__nav .nav__item-text, .header.is-open .header__actions-item--user .person-preview__info, .header.is-open .header__actions-item--user .ctrl-dropmenu__button-icon, .header.is-open .header__actions-item--lang .lang-global__text, .header.is-open .header__actions-item--lang .ctrl-dropmenu__button-icon, .header.is-open .header__informer .info-alert__title {
  opacity: 1;
}

.header.is-hover .header__logo, .header.is-hover .header__logo .logo__image, .header.is-hover .header__informer .info-alert__title, .header.is-hover .header__actions-item--user, .header.is-open .header__logo, .header.is-open .header__logo .logo__image, .header.is-open .header__informer .info-alert__title, .header.is-open .header__actions-item--user {
  margin-left: 0;
}

.header.is-open {
  width: 270px;
}

.header.is-open .header__button-collapse {
  transform: scaleX(-1);
}

@keyframes icon-rotate {
  0% {
    transform: rotate(0);
  }

  70% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.ctrl-btn {
  box-sizing: border-box;
  vertical-align: middle;
  letter-spacing: -.5px;
  text-align: center;
  white-space: nowrap;
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  -o-transition-property: border-color, background-color;
  -o-transition-duration: .125s;
  -o-transition-timing-function: ease-in-out;
  background-color: #053fe3;
  border: 1px solid #053fe3;
  border-radius: 8px;
  outline: none;
  justify-content: center;
  align-items: center;
  padding: 11px 14px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
  transition-property: border-color, background-color;
  transition-duration: .125s;
  transition-timing-function: ease-in-out;
  display: inline-flex;
  position: relative;
}

.ctrl-btn__icon {
  margin-right: 10px;
}

.ctrl-btn__text {
  padding-top: 4px;
  padding-bottom: 1px;
}

.ctrl-btn[disabled], .ctrl-btn.is-disabled {
  color: #b5b5b5;
  background-color: rgba(181, 181, 181, .4);
  border-color: #b5b5b5;
}

.ctrl-btn--icon-only .ctrl-btn__icon {
  margin-right: 0;
}

.ctrl-btn--icon-only .ctrl-btn__text {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.ctrl-btn--shadow {
  box-shadow: 18px 18px 24px rgba(5, 63, 227, .3);
}

.ctrl-btn--view-border {
  color: #053fe3;
  background-color: #fff;
}

.ctrl-btn--view-border.ctrl-btn--color-dark {
  color: #1e1b1b;
  border-color: #dedede;
}

.ctrl-btn--size-m {
  letter-spacing: -.5px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 12px;
  line-height: 18px;
}

.ctrl-btn--size-m .ctrl-btn__text {
  padding-top: 3px;
  padding-bottom: 2px;
}

.ctrl-btn--size-m .ctrl-btn__icon {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.ctrl-btn--size-m.ctrl-btn--rounded {
  border-radius: 18px;
}

.ctrl-btn--size-xs {
  padding: 7px 11px;
  font-weight: 400;
}

.ctrl-btn--size-xs .ctrl-btn__text {
  margin-bottom: -2px;
  padding-top: 0;
  padding-bottom: 0;
}

.ctrl-btn--size-xs .ctrl-btn__icon {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}

.ctrl-btn--color-second {
  background-color: #fa380d;
  border-color: #fa380d;
}

.ctrl-btn--color-second.ctrl-btn--shadow {
  box-shadow: 18px 18px 24px rgba(250, 56, 13, .2);
}

.ctrl-btn--color-third {
  background-color: rgba(0, 0, 0, 0);
  border-color: #fff;
}

.ctrl-btn--color-light {
  color: #053fe3;
  background-color: #fff;
  border-color: #fff;
}

.ctrl-btn--color-light.ctrl-btn--shadow {
  box-shadow: 18px 18px 24px rgba(0, 0, 0, .1);
}

.ctrl-btn--color-back {
  color: #fa380d;
  background-color: #fff;
  border-color: #fff;
}

.ctrl-btn--color-back.ctrl-btn--shadow {
  box-shadow: 18px 18px 24px rgba(0, 0, 0, .1);
}

.ctrl-btn--color-success {
  color: #fff;
  background-color: #01964e;
  border-color: #01964e;
}

.ctrl-btn--color-success.ctrl-btn--shadow {
  box-shadow: 18px 18px 24px rgba(0, 94, 37, .15);
}

.ctrl-btn--color-success[disabled], .ctrl-btn--color-success.is-disabled {
  opacity: .25;
  background-color: #01964e;
  border-color: #01964e;
}

.ctrl-btn--color-danger {
  color: #fff;
  background-color: #fa380d;
  border-color: #fa380d;
}

.ctrl-btn--color-danger.ctrl-btn--shadow {
  box-shadow: 18px 18px 24px rgba(250, 56, 13, .3);
}

.ctrl-btn--color-black {
  background-color: #1e1b1b;
  border-color: #1e1b1b;
}

.ctrl-btn--color-discard {
  color: #1e1b1b;
  background-color: #dedede;
  border-color: #dedede;
}

.ctrl-btn--rounded {
  border-radius: 24px;
}

.ctrl-btn--rem {
  letter-spacing: -.0625rem;
  border-radius: 1.5rem;
  padding-top: .6875rem;
  padding-bottom: .6875rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.ctrl-btn--rem .ctrl-btn__icon {
  margin-right: .25rem;
}

.ctrl-btn--rem .ctrl-btn__text {
  padding-top: 0;
  padding-bottom: 0;
}

.ctrl-btn--rem.ctrl-btn--rounded {
  border-radius: 1.5rem;
}

.ctrl-btn.is-processing .ctrl-btn__icon {
  animation-name: icon-rotate;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

.ctrl-textfield {
  box-sizing: border-box;
  letter-spacing: -.5px;
  color: #1e1b1b;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  outline: none;
  width: 100%;
  padding: 11px;
  font-size: 14px;
  line-height: 20px;
  display: block;
}

.ctrl-textfield:focus {
  background-color: #fff;
  border-color: #dedede;
}

.ctrl-textfield::-webkit-input-placeholder {
  color: #a5a5a5;
  opacity: 1;
}

.ctrl-textfield::placeholder {
  color: #a5a5a5;
  opacity: 1;
}

.ctrl-textfield:-ms-input-placeholder {
  color: #a5a5a5;
  opacity: 1;
}

.ctrl-textfield::placeholder {
  color: #a5a5a5;
  opacity: 1;
}

.ctrl-textfield[disabled] {
  opacity: .25;
}

.ctrl-textfield[readonly] {
  opacity: .75;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

html.touchevents .ctrl-textfield {
  font-size: 16px;
}

.ctrl-textfield[type="number"] {
  -moz-appearance: textfield;
}

.ctrl-textfield[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ctrl-textfield[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ctrl-textfield--size-xs {
  border-radius: 8px;
  padding: 3px 7px;
  font-size: 12px;
  line-height: 18px;
}

.ctrl-textfield--size-xs + .ctrl-form__button {
  top: calc(50% - 10px);
}

.ctrl-textfield--size-xs + .ctrl-form__button .ctrl-btn-clear__icon {
  width: 12px;
  height: 12px;
}

.ctrl-textarea {
  box-sizing: border-box;
  letter-spacing: -.5px;
  color: #1e1b1b;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  outline: none;
  width: 100%;
  padding: 11px;
  font-size: 14px;
  line-height: 20px;
  display: block;
}

.ctrl-textarea:focus {
  background-color: #fff;
  border-color: #dedede;
}

.ctrl-textarea::-webkit-input-placeholder {
  color: #a5a5a5;
  opacity: 1;
}

.ctrl-textarea::placeholder {
  color: #a5a5a5;
  opacity: 1;
}

.ctrl-textarea:-ms-input-placeholder {
  color: #a5a5a5;
  opacity: 1;
}

.ctrl-textarea::placeholder {
  color: #a5a5a5;
  opacity: 1;
}

.ctrl-textarea[disabled] {
  opacity: .25;
}

.ctrl-textarea[readonly] {
  opacity: .75;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

html.touchevents .ctrl-textarea {
  font-size: 16px;
}

.ctrl-textarea {
  resize: none;
  min-height: 135px;
}

.ctrl-textarea--view-line {
  height: 44px;
  min-height: 0;
}

.react-geocoder input {
  box-sizing: border-box;
  letter-spacing: -.5px;
  color: #1e1b1b;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  outline: none;
  width: 100%;
  padding: 11px;
  font-size: 14px;
  line-height: 20px;
  display: block;
}

.react-geocoder input:focus {
  background-color: #fff;
  border-color: #dedede;
}

.react-geocoder input::-webkit-input-placeholder {
  color: #a5a5a5;
  opacity: 1;
}

.react-geocoder input::placeholder {
  color: #a5a5a5;
  opacity: 1;
}

.react-geocoder input:-ms-input-placeholder {
  color: #a5a5a5;
  opacity: 1;
}

.react-geocoder input::placeholder {
  color: #a5a5a5;
  opacity: 1;
}

.react-geocoder input[disabled] {
  opacity: .25;
}

.react-geocoder input[readonly] {
  opacity: .75;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

html.touchevents .react-geocoder input {
  font-size: 16px;
}

.react-geocoder-results {
  box-sizing: border-box;
  z-index: 10;
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  max-height: 300px;
  font-size: 14px;
  line-height: 24px;
  position: absolute;
  top: calc(100% + 4px);
  overflow-y: auto;
  box-shadow: 24px 24px 36px rgba(0, 0, 0, .07);
}

.react-geocoder-item {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0);
  outline: none;
  width: 100%;
  padding: 9px 11px;
  font-size: 14px;
  line-height: 24px;
  display: block;
}

.react-geocoder-item:hover {
  color: #fff;
  background-color: #053fe3;
}

.status-element {
  box-sizing: border-box;
  align-items: center;
  display: flex;
}

.status-element__icon {
  border-radius: 50%;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-top: -2px;
  margin-right: 4px;
}

.status-element--tba, .status-element--created, .status-element--archived, .status-element--closed {
  color: #8e8c8c;
}

.status-element--assigned, .status-element--in-progress {
  color: #053fe3;
}

.status-element--blocked {
  color: #fa380d;
}

.status-element--re-work, .status-element--rework {
  color: #f90;
}

.status-element--done, .status-element--verified, .status-element--approved {
  color: #01964e;
}

.status-element--worker-invited, .status-element--finish-to-finish, .status-element--start-to-start {
  color: #053fe3;
}

.status-element--worker-active {
  color: #01964e;
}

.status-element--worker-removed, .status-element--worker-closed {
  color: #a5a5a5;
}

.phone-code {
  display: flex;
}

.phone-code__number {
  color: #053fe3;
  flex-shrink: 0;
  width: 38px;
  margin-right: 4px;
  font-weight: 500;
}

@keyframes dots {
  0% {
    opacity: 1;
  }

  100% {
    opacity: .5;
  }
}

.react-select {
  position: relative;
}

.react-select__control {
  box-sizing: border-box;
  letter-spacing: -.5px;
  color: #1e1b1b;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  align-items: center;
  padding: 9px 11px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  position: relative;
}

.react-select__control--is-focused {
  background-color: #fff;
  border-color: #dedede;
  outline: none;
}

html.touchevents .react-select__control {
  font-size: 16px;
}

.react-select__value-container {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
}

.react-select__value-container--is-multi {
  flex-wrap: wrap;
  align-items: center;
  margin: -3px -2px;
  display: flex;
}

.react-select__placeholder, .react-select__single-value {
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  max-width: 100%;
  padding-right: .0625em;
  position: absolute;
  top: 50%;
  overflow: hidden;
  transform: translateY(-50%);
}

.react-select__placeholder {
  color: #a5a5a5;
}

.react-select__multi-value {
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 4px;
  align-items: center;
  margin: 2px;
  display: flex;
}

.react-select__multi-value__label {
  letter-spacing: -.5px;
  color: #1e1b1b;
  opacity: .5;
  text-overflow: ellipsis;
  max-width: 120px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 7px;
  font-size: 12px;
  line-height: 18px;
  overflow: hidden;
}

.react-select__multi-value__remove {
  cursor: pointer;
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  padding: 6px 7px;
}

.react-select__multi-value__remove svg {
  fill: #fa380d;
  width: 100%;
  height: 100%;
  display: block;
}

.react-select__indicators {
  flex-shrink: 0;
  align-items: center;
  margin-left: 10px;
  margin-right: -8px;
  display: flex;
}

.react-select__loading-indicator {
  color: #fa380d;
  -ms-grid-row-align: center;
  align-self: center;
  margin-right: 8px;
  font-size: 4px;
  line-height: 1;
  display: flex;
}

.react-select__loading-indicator span {
  background-color: currentColor;
  border-radius: 1em;
  width: 1em;
  height: 1em;
  margin-left: 1em;
  animation: 1s ease-in-out infinite dots;
}

.react-select__loading-indicator span:first-child {
  -webkit-animation-delay: 0;
  animation-delay: 0;
}

.react-select__loading-indicator span:nth-child(2) {
  animation-delay: .16s;
}

.react-select__loading-indicator span:nth-child(3) {
  animation-delay: .32s;
}

.react-select__indicator-separator {
  display: none;
}

.react-select__dropdown-indicator, .react-select__clear-indicator {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  padding: 4px;
}

.react-select__dropdown-indicator svg, .react-select__clear-indicator svg {
  width: inherit;
  height: inherit;
  fill: currentColor;
  color: var(--indicator-color, #053fe3);
  display: block;
}

.react-select__menu {
  box-sizing: border-box;
  z-index: 20;
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  font-size: 16px;
  line-height: 1.2;
  position: absolute;
  top: calc(100% + 4px);
  overflow: hidden;
  box-shadow: 24px 24px 36px rgba(0, 0, 0, .07);
}

.react-select__menu-list {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  max-height: 308px;
  overflow-y: auto;
}

.react-select__menu-list--is-multi {
  flex-wrap: wrap;
  align-items: center;
  padding: 6px 11px;
  display: flex;
}

.react-select__menu-list--is-multi .react-select__option {
  letter-spacing: -.5px;
  color: rgba(30, 27, 27, .5);
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 4px;
  width: auto;
  margin: 2px;
  padding: 3px 7px;
  font-size: 12px;
  line-height: 18px;
}

.react-select__menu-list--is-multi .react-select__option--is-selected {
  color: #fff;
  background-color: #053fe3;
  border-color: #053fe3;
}

.react-select__menu-list--is-multi .react-select__option--is-create-new {
  color: #053fe3;
  border-color: #053fe3;
}

.react-select__option {
  box-sizing: border-box;
  color: inherit;
  letter-spacing: -.5px;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0);
  outline: none;
  width: 100%;
  padding: 11px;
  font-size: 14px;
  line-height: 20px;
  display: block;
}

.react-select__option--is-focused {
  color: #fff;
  background-color: #235dff;
}

.react-select__option--is-focused .status-element, .react-select__option--is-focused .phone-code__number {
  color: inherit;
}

.react-select__option--is-focused .status-element__icon {
  background-color: #fff;
  border: 1px solid #fff;
  box-shadow: 0 12px 16px rgba(0, 0, 0, .15);
}

.react-select__option--is-disabled {
  color: #a5a5a5;
  cursor: default;
  background-color: #fff;
}

.react-select__option--is-disabled .status-element, .react-select__option--is-disabled .phone-code__number {
  color: inherit;
}

.react-select__menu-notice {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0);
  outline: none;
  width: 100%;
  padding: 11px 15px;
  font-size: 16px;
  line-height: 20px;
  display: block;
}

.react-select__menu-notice--no-options {
  text-align: center;
}

.react-select--size-xs .react-select__control {
  border-radius: 8px;
  padding: 3px 7px;
  font-size: 12px;
  line-height: 18px;
}

.react-select--size-xs .react-select__dropdown-indicator, .react-select--size-xs .react-select__dropdown-indicator svg {
  width: 12px;
  height: 12px;
}

.react-select--size-xs .react-select__menu {
  border-radius: 8px;
  top: calc(100% + 2px);
}

.react-select--size-xs .react-select__option {
  padding: 3px 7px;
  font-size: 12px;
  line-height: 18px;
}

.react-select--status-tba, .react-select--status-archived, .react-select--status-closed {
  --indicator-color: #8e8c8c;
}

.react-select--status-assigned, .react-select--status-in-progress {
  --indicator-color: #053fe3;
}

.react-select--status-blocked {
  --indicator-color: #fa380d;
}

.react-select--status-re-work, .react-select--status-rework {
  --indicator-color: #f90;
}

.react-select--status-done, .react-select--status-verified, .react-select--status-approved {
  --indicator-color: #01964e;
}

.react-select--colored.react-select--status .react-select__control {
  border-color: #053fe3;
  border-color: var(--colored-status-bg, #053fe3);
  background-color: #053fe3;
  background-color: var(--colored-status-bg, #053fe3);
}

.react-select--colored.react-select--status .react-select__single-value .status-element__icon {
  background-color: #fff;
  border: 1px solid #fff;
  box-shadow: 0 12px 16px rgba(0, 0, 0, .15);
}

.react-select--colored.react-select--status .react-select__single-value .status-element__name, .react-select--colored.react-select--status .react-select__dropdown-indicator svg {
  color: #fff;
}

.react-select--colored.react-select--status-tba, .react-select--colored.react-select--status-archived, .react-select--colored.react-select--status-closed {
  --colored-status-bg: #8e8c8c;
}

.react-select--colored.react-select--status-assigned, .react-select--colored.react-select--status-in-progress {
  --colored-status-bg: #053fe3;
}

.react-select--colored.react-select--status-blocked {
  --colored-status-bg: #fa380d;
}

.react-select--colored.react-select--status-re-work, .react-select--colored.react-select--status-rework {
  --colored-status-bg: #f90;
}

.react-select--colored.react-select--status-done, .react-select--colored.react-select--status-verified, .react-select--colored.react-select--status-approved {
  --colored-status-bg: #01964e;
}

.react-select--phone-codes .react-select__single-value {
  color: #235dff;
  font-weight: 500;
}

.react-select--is-disabled {
  opacity: .25;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker {
  background-color: rgba(30, 27, 27, .9);
  position: relative;
}

.react-datepicker__triangle {
  content: "";
  border: 9px solid rgba(0, 0, 0, 0);
  position: absolute;
}

.react-datepicker__header {
  flex-direction: column-reverse;
  display: flex;
}

.react-datepicker__day-names {
  padding: 12px 6px;
  display: flex;
}

.react-datepicker__day-name {
  text-align: center;
  text-transform: uppercase;
  color: rgba(255, 255, 255, .5);
  flex-grow: 0;
  flex-shrink: 0;
  width: 32px;
  margin-left: 4px;
  margin-right: 4px;
  font-size: 8px;
}

.react-datepicker__current-month {
  text-align: center;
  color: #f5f5f5;
  background-color: #1e1b1b;
  padding: 13px 54px;
  font-size: 12px;
  font-weight: 500;
  line-height: 2;
}

.react-datepicker__navigation {
  white-space: nowrap;
  text-indent: 9999px;
  background-color: rgba(0, 0, 0, 0);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-width: 0;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 46px;
  overflow: hidden;
}

.react-datepicker__navigation--previous {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' fill='%23fa380d'%3E%3Cpath d='M8.22 12.72c-.141-.141-.22-.331-.22-.53v-.38c.002-.198.081-.388.22-.53l5.14-5.13c.094-.095.222-.148.355-.148.133 0 .261.053.355.148l.71.71c.094.092.147.218.147.35 0 .132-.053.258-.147.35l-4.45 4.44 4.45 4.44c.095.094.148.222.148.355 0 .133-.053.261-.148.355l-.71.7c-.094.095-.222.148-.355.148-.133 0-.261-.053-.355-.148l-5.14-5.13z'/%3E%3C/svg%3E");
  left: 16px;
}

.react-datepicker__navigation--next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' fill='%23fa380d'%3E%3Cpath d='M15.78 11.28c.141.141.22.331.22.53v.38c-.002.198-.081.388-.22.53l-5.14 5.13c-.094.095-.222.148-.355.148-.133 0-.261-.053-.355-.148l-.71-.71c-.094-.092-.147-.218-.147-.35 0-.132.053-.258.147-.35l4.45-4.44-4.45-4.44c-.095-.094-.148-.222-.148-.355 0-.133.053-.261.148-.355l.71-.7c.094-.095.222-.148.355-.148.133 0 .261.053.355.148l5.14 5.13z'/%3E%3C/svg%3E");
  right: 16px;
}

.react-datepicker__month {
  padding: 6px 6px 15px;
}

.react-datepicker__week {
  display: flex;
}

.react-datepicker__day {
  text-align: center;
  color: #fff;
  cursor: pointer;
  flex-grow: 0;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin: 1px 4px;
  font-size: 12px;
  line-height: 32px;
}

.react-datepicker__day--outside-month {
  opacity: .5;
}

.react-datepicker__day--disabled {
  opacity: .5;
  cursor: default;
}

.react-datepicker__day--selected {
  background-color: #fa380d;
  border-radius: 50%;
}

.react-datepicker__time {
  max-height: 190px;
  overflow-x: hidden;
  overflow-y: auto;
}

.react-datepicker__time::-webkit-scrollbar {
  width: 8px;
}

.react-datepicker__time::-webkit-scrollbar-track {
  border-radius: 4px;
}

.react-datepicker__time::-webkit-scrollbar-thumb {
  border: 1px solid tranparent;
  background-color: #1e1b1b;
  border-radius: 10px;
}

.react-datepicker__time-list-item {
  text-align: center;
  color: #fff;
  cursor: pointer;
  flex-shrink: 0;
  font-size: 12px;
  line-height: 32px;
}

.react-datepicker--time-only {
  max-width: 120px;
}

.react-datepicker-time__header {
  text-align: center;
  color: #f5f5f5;
  background-color: #1e1b1b;
  padding: 13px;
  font-size: 12px;
  font-weight: 500;
  line-height: 2;
}

.react-datepicker-popper {
  z-index: 100;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
  border-bottom-color: rgba(30, 27, 27, .9);
  top: 100%;
  transform: rotate(180deg);
}

.react-datepicker-popper[data-placement="right"] .react-datepicker__triangle {
  border-right-color: rgba(30, 27, 27, .9);
  right: 100%;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  border-bottom-color: rgba(30, 27, 27, .9);
  bottom: 100%;
}

.react-datepicker-popper[data-placement="left"] .react-datepicker__triangle {
  border-left-color: rgba(30, 27, 27, .9);
  left: 100%;
}

.ctrl-btn-link {
  letter-spacing: -.5px;
  color: #053fe3;
  -webkit-user-select: none;
  user-select: none;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  outline: none;
  padding: 0;
  font-size: 10px;
  line-height: 16px;
}

.ctrl-btn-link--color-second {
  color: #fa380d;
}

.ctrl-btn-link--size-m {
  letter-spacing: -.5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.ctrl-form {
  text-align: left;
  font-size: 16px;
}

.ctrl-form__header {
  position: relative;
}

.ctrl-form__label {
  letter-spacing: -.5px;
  opacity: .5;
  margin-bottom: 2px;
  font-size: 10px;
  line-height: 16px;
  display: inline-block;
}

.ctrl-form__button-help {
  color: #053fe3;
  position: absolute;
  top: calc(50% - 6px);
  right: 0;
}

.ctrl-form__button-help .ctrl-btn-clear__icon {
  width: 12px;
  height: 12px;
}

.ctrl-form__body {
  position: relative;
}

.ctrl-form__button, .ctrl-form .react-datepicker__close-icon {
  color: #053fe3;
  padding: 4px;
  position: absolute;
  top: calc(50% - 12px);
  right: 4px;
}

.ctrl-form__button .ctrl-btn-clear__icon, .ctrl-form .react-datepicker__close-icon .ctrl-btn-clear__icon {
  width: 16px;
  height: 16px;
}

.ctrl-form__icon {
  color: #053fe3;
  width: 16px;
  height: 16px;
  padding: 4px;
  position: absolute;
  top: calc(50% - 12px);
  right: 4px;
}

.ctrl-form .react-datepicker__close-icon {
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0);
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' fill='%23053FE3'%3E%3Cpath d='M17.85 16.44c.095.094.148.222.148.355 0 .133-.053.261-.148.355l-.7.7c-.094.095-.222.148-.355.148-.133 0-.261-.053-.355-.148l-4.44-4.44-4.44 4.44c-.094.095-.222.148-.355.148-.133 0-.261-.053-.355-.148l-.7-.7c-.095-.094-.148-.222-.148-.355 0-.133.053-.261.148-.355l4.44-4.44-4.44-4.44c-.095-.094-.148-.222-.148-.355 0-.133.053-.261.148-.355l.7-.7c.094-.095.222-.148.355-.148.133 0 .261.053.355.148l4.44 4.44 4.44-4.44c.094-.095.222-.148.355-.148.133 0 .261.053.355.148l.7.7c.095.094.148.222.148.355 0 .133-.053.261-.148.355l-4.44 4.44 4.44 4.44z'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  border-width: 0;
  outline: none;
  width: 24px;
  height: 24px;
}

.ctrl-form__button-link {
  padding: 4px;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}

.ctrl-form__success-text {
  letter-spacing: -.5px;
  color: #01964e;
  padding: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}

.ctrl-form__validation {
  box-sizing: border-box;
  z-index: 1;
  letter-spacing: 0;
  color: #fff;
  background-color: #fa380d;
  max-width: calc(100% - 32px);
  padding: 4px 8px;
  font-size: 10px;
  position: absolute;
  bottom: calc(100% - 1px);
  left: 12px;
}

.ctrl-form__validation:before {
  content: "";
  border: 6px solid rgba(0, 0, 0, 0);
  border-color: #fa380d rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fa380d;
  position: absolute;
  top: 100%;
  left: 0;
}

.ctrl-form__footer {
  text-align: right;
}

.ctrl-form__note {
  letter-spacing: -.5px;
  opacity: .5;
  font-size: 10px;
  line-height: 16px;
}

.ctrl-form--label-hidden .ctrl-form__label {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.ctrl-form--color-second .ctrl-form__label {
  opacity: 1;
}

.ctrl-form--color-second .ctrl-textfield {
  background-color: #fff;
  border-color: #fff;
}

.ctrl-form--color-second .ctrl-textfield:focus {
  border-color: #d8d8d8;
}

.ctrl-form--color-second .react-select__control {
  background-color: #fff;
  border-color: #fff;
}

.ctrl-form--color-second .react-select__control--is-focused {
  border-color: #d8d8d8;
}

.ctrl-form--button .ctrl-textfield, .ctrl-form--icon .ctrl-textfield {
  padding-right: 27px;
}

.ctrl-form--button-link .ctrl-textfield {
  padding-right: 56px;
}

.ctrl-form.is-error .ctrl-textfield, .ctrl-form.is-error .ctrl-textarea, .ctrl-form.is-error .react-select__control, .ctrl-form.is-error .react-geocoder input {
  border-color: #fa380d;
}

.ctrl-form--success .ctrl-textfield, .ctrl-form--success .ctrl-textarea, .ctrl-form--success .react-select__control, .ctrl-form--success .react-geocoder input {
  background-color: rgba(1, 150, 78, .1);
  border-color: #01964e;
}

.ctrl-form--success .ctrl-form__icon {
  color: #01964e;
}

.form-export-csv__item {
  margin-top: 16px;
}

.form-export-csv__item:first-child {
  margin-top: 0;
}

.form-export-csv__button {
  width: 100%;
}

.drop-panel {
  box-sizing: border-box;
  z-index: 10;
  background-color: #f5f5f5;
  background-image: linear-gradient(193.05deg, #fff 0%, #f5f5f5 100%);
  border: 1px solid #fff;
  border-radius: 8px;
  width: 240px;
  margin: 4px;
  padding: 16px;
  position: absolute;
  box-shadow: 24px 24px 36px rgba(0, 0, 0, .07);
}

.drop-panel--from-parent {
  top: 100%;
  left: 50%;
  transform: translateX(calc(-50% - 4px));
}

.ctrl-view {
  letter-spacing: -.5px;
  min-width: 230px;
  font-size: 14px;
  line-height: 20px;
  position: relative;
}

.ctrl-view__icon {
  color: #053fe3;
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.ctrl-view__toggle {
  box-sizing: border-box;
  width: 100%;
  line-height: inherit;
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 8px;
  align-items: center;
  padding: 13px 14px 12px;
  display: flex;
}

.ctrl-view__toggle-text {
  margin-top: 2px;
  margin-right: 10px;
}

.ctrl-view__toggle-icon {
  color: #053fe3;
  width: 16px;
  height: 16px;
  margin-left: auto;
}

.ctrl-view__viewport {
  box-sizing: border-box;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #dedede;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 100%;
  display: none;
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  overflow: hidden;
}

.ctrl-view__list {
  list-style-type: none;
}

.ctrl-view__list-item {
  border-top: 1px solid #dedede;
}

.ctrl-view__list-item:first-child {
  border-top-width: 0;
}

.ctrl-view__list-button {
  box-sizing: border-box;
  width: 100%;
  line-height: inherit;
  text-align: left;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  justify-content: space-between;
  align-items: center;
  padding: 12px 12px 12px 16px;
  display: flex;
}

.ctrl-view__list-text {
  margin-top: 2px;
  margin-right: 10px;
}

.ctrl-view__list-icon {
  color: #535353;
}

.ctrl-view__header {
  align-items: center;
  padding: 12px 8px;
  display: flex;
}

.ctrl-view__button-back {
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  align-items: center;
  padding: 0;
  display: flex;
}

.ctrl-view__button-back-text {
  font-size: inherit;
}

.ctrl-view__button-back-icon {
  width: 16px;
  height: 16px;
}

.ctrl-view__title {
  font-weight: 500;
  font-size: inherit;
  text-transform: uppercase;
  text-align: center;
  flex-grow: 1;
  padding-right: 50px;
}

.ctrl-view__sublist {
  list-style-type: none;
}

.ctrl-view__sublist-button {
  box-sizing: border-box;
  width: 100%;
  line-height: inherit;
  text-align: left;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  align-items: center;
  padding: 12px 12px 12px 16px;
  display: flex;
}

html.no-touchevents .ctrl-view__sublist-button:hover {
  color: #fff;
  background-color: #053fe3;
}

html.no-touchevents .ctrl-view__sublist-button:hover .ctrl-view__sublist-icon {
  color: inherit;
}

.ctrl-view__sublist-button.is-active .ctrl-view__sublist-icon--check {
  display: block;
}

.ctrl-view__sublist-button.is-active .ctrl-view__sublist-icon--clear {
  display: none;
}

.ctrl-view__sublist-text {
  margin-top: 2px;
}

.ctrl-view__sublist-checkbox {
  margin-right: 8px;
}

.ctrl-view__sublist-icon {
  color: #535353;
}

.ctrl-view__sublist-icon--check {
  display: none;
}

.ctrl-view.is-open .ctrl-view__toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.ctrl-view.is-open .ctrl-view__viewport {
  display: block;
}

.ctrl-badge {
  vertical-align: middle;
  letter-spacing: -.5px;
  color: #fff;
  background-color: #4050ec;
  border-radius: 2px;
  padding-left: 4px;
  padding-right: 5px;
  font-size: 12px;
  line-height: 18px;
  display: inline-block;
}

.panel {
  align-items: center;
  display: flex;
}

.panel__title {
  letter-spacing: -.026625em;
  font-size: 36px;
  font-weight: 400;
  line-height: 1.33333;
}

.panel__button-settings {
  color: #dedede;
  vertical-align: -2px;
  margin-left: 16px;
}

.panel__button-box {
  margin-left: 16px;
  display: inline-block;
  position: relative;
}

.panel__button-help {
  color: #dedede;
  vertical-align: -2px;
}

.panel__legend {
  z-index: 1;
  position: absolute;
  top: 100%;
  left: 0;
}

.panel__view {
  vertical-align: -2px;
  align-items: center;
  margin-left: 24px;
  display: inline-flex;
}

.panel__view-button-box {
  align-items: center;
  margin-left: 8px;
  display: flex;
}

.panel__view-button {
  color: #dedede;
  margin-left: 8px;
}

.panel__view-button--grid .ctrl-btn-clear__icon {
  transform: rotate(-90deg);
}

.panel__view-button:first-child {
  margin-left: 0;
}

.panel__view-badge {
  margin-top: -19px;
  margin-left: -3px;
}

.panel__actions {
  margin-left: auto;
  display: flex;
}

.panel__button {
  min-width: 168px;
  margin-left: 10px;
  position: relative;
}

.panel__button:first-child {
  margin-left: 0;
}

.panel__button .ctrl-drop__toggle {
  width: 100%;
}

.panel__button .ctrl-drop__content {
  min-width: 215px;
}

.panel__import {
  box-sizing: border-box;
  z-index: 10;
  width: 240px;
  position: absolute;
  top: calc(100% + 4px);
  left: calc(50% - 120px);
}

.panel-actions__item {
  margin-left: 12px;
}

.panel-actions__item:first-child {
  margin-left: 0;
}

.table-sorter {
  vertical-align: middle;
  flex-direction: column;
  display: inline-flex;
}

.table-sorter__icon {
  color: #1e1b1b;
  background-color: #fff;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  display: none;
}

.table-sorter__icon.is-active {
  display: block;
}

.icon-inline {
  vertical-align: middle;
  display: inline-block;
}

.icon-inline--color-fill {
  fill: currentColor;
}

.icon-inline--color-stroke {
  fill: none;
  stroke: currentColor;
}

.date-info {
  align-items: center;
  display: flex;
}

.date-info__icon {
  flex-shrink: 0;
  width: 1.33333em;
  height: 1.33333em;
  margin-top: -.125em;
  margin-left: .25em;
}

.date-info--status-fail {
  color: #fa380d;
}

.ctrl-status {
  position: relative;
}

.ctrl-status__control {
  box-sizing: border-box;
  letter-spacing: -.5px;
  color: #c7c6c6;
  background-color: #f5f5f5;
  border-radius: 14px;
  align-items: center;
  padding: 6px 8px 6px 0;
  font-size: 10px;
  line-height: 1.6;
  display: flex;
  position: relative;
}

.ctrl-status__control--is-focused {
  outline: none;
}

.ctrl-status__control--menu-is-open {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.ctrl-status__value-container {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
}

.ctrl-status__placeholder, .ctrl-status__single-value {
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  top: 50%;
  overflow: hidden;
  transform: translateY(-50%);
}

.ctrl-status__placeholder {
  opacity: .5;
}

.ctrl-status__single-value .status-element {
  padding-right: 0;
}

.ctrl-status__indicators {
  flex-shrink: 0;
  align-items: center;
  margin-left: 8px;
  display: flex;
}

.ctrl-status__loading-indicator {
  color: red;
  -ms-grid-row-align: center;
  align-self: center;
  margin-right: 8px;
  font-size: 4px;
  line-height: 1;
  display: flex;
}

.ctrl-status__loading-indicator span {
  background-color: currentColor;
  border-radius: 1em;
  width: 1em;
  height: 1em;
  margin-left: 1em;
  animation: 1s ease-in-out infinite dots;
}

.ctrl-status__loading-indicator span:first-child {
  -webkit-animation-delay: 0;
  animation-delay: 0;
}

.ctrl-status__loading-indicator span:nth-child(2) {
  animation-delay: .16s;
}

.ctrl-status__loading-indicator span:nth-child(3) {
  animation-delay: .32s;
}

.ctrl-status__indicator-separator {
  display: none;
}

.ctrl-status__dropdown-indicator {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' fill='%2301964E'%3E%3Cpath d='M15.78 11.28c.141.141.22.331.22.53v.38c-.002.198-.081.388-.22.53l-5.14 5.13c-.094.095-.222.148-.355.148-.133 0-.261-.053-.355-.148l-.71-.71c-.094-.092-.147-.218-.147-.35 0-.132.053-.258.147-.35l4.45-4.44-4.45-4.44c-.095-.094-.148-.222-.148-.355 0-.133.053-.261.148-.355l.71-.7c.094-.095.222-.148.355-.148.133 0 .261.053.355.148l5.14 5.13z'/%3E%3C/svg%3E");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  transform: rotate(90deg);
}

.ctrl-status__dropdown-indicator svg {
  display: none;
}

.ctrl-status__clear-indicator {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 19px 19px;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
}

.ctrl-status__clear-indicator svg {
  display: none;
}

.ctrl-status__menu {
  box-sizing: border-box;
  z-index: 20;
  background-color: #f5f5f5;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 100%;
  position: absolute;
  top: 100%;
  overflow: hidden;
}

.ctrl-status__menu-list {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  max-height: 300px;
  overflow-y: auto;
}

.ctrl-status__option {
  box-sizing: border-box;
  color: inherit;
  cursor: default;
  font-size: inherit;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 100%;
  display: block;
}

.ctrl-status__menu-notice {
  box-sizing: border-box;
  color: inherit;
  cursor: default;
  font-size: inherit;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 100%;
  padding: 14px 12px;
  display: block;
}

.ctrl-status__menu-notice--no-options {
  text-align: center;
}

.ctrl-btn-status {
  box-sizing: border-box;
  letter-spacing: -.0833333em;
  color: #01964e;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #f5f5f5;
  border-radius: 13px;
  outline: none;
  justify-content: center;
  align-items: center;
  padding: 3px 8px;
  font-size: 12px;
  line-height: 1.5;
  display: inline-flex;
}

.ctrl-btn-status__icon {
  width: 12px;
  height: 12px;
  margin-right: 4px;
}

.ctrl-btn-status__text {
  padding-right: .0833333em;
}

.ctrl-btn-status--assigned {
  color: #fff;
  background-color: #01964e;
  border-color: #01964e;
}

.ctrl-btn-status[disabled] {
  color: #c8c8c8;
  border-color: #c8c8c8;
}

.te-punchlist {
  align-items: center;
  display: inline-flex;
}

.te-punchlist__icon {
  flex-shrink: 0;
  width: 14px;
  height: 14px;
  margin-right: 4px;
}

.task-table {
  white-space: normal;
}

.task-table__name {
  font-weight: 500;
}

.task-table__info {
  opacity: .3;
  display: flex;
}

.task-table__icon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.task-table__punchlist, .task-table__subtask-qty {
  margin-left: 12px;
}

.task-table__hierarchy {
  opacity: .5;
  display: flex;
}

.task-table__hierarchy-number {
  color: #053fe3;
}

.task-table--subtask .task-table__info {
  opacity: .5;
}

.task-table--parenttask .task-table__info {
  opacity: .5;
  color: #053fe3;
}

.te-workers {
  letter-spacing: -.5px;
  color: #053fe3;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  align-items: center;
  padding: 0;
  font-size: 12px;
  line-height: 18px;
  display: flex;
}

.te-workers__icon {
  width: 16px;
  height: 16px;
  margin-top: -1px;
  margin-right: 4px;
}

.te-workers--numbers {
  color: #1e1b1b;
}

.te-workers--numbers .te-workers__icon {
  color: #dedede;
}

.te-worker-status {
  display: inline-block;
}

.te-worker-status--invited, .te-worker-status--pending {
  color: #053fe3;
}

.te-worker-status--active {
  color: #01964e;
}

.te-worker-status--removed {
  color: #fa380d;
}

.te-organization {
  vertical-align: middle;
  color: rgba(30, 27, 27, .5);
  border: 1px solid #dedede;
  border-radius: 4px;
  padding: 3px 8px;
  display: inline-block;
}

.te-orgs__item {
  margin-top: 3px;
}

.te-orgs__item:first-child {
  margin-top: 0;
}

.table-main {
  border-spacing: 0;
  letter-spacing: -.5px;
  width: 100%;
  font-size: 12px;
  line-height: 18px;
}

.table-main tr:last-child td {
  border-bottom-width: 0;
}

.table-main th, .table-main td {
  padding-left: 8px;
  padding-right: 8px;
}

.table-main th:first-child, .table-main td:first-child {
  padding-left: 16px;
}

.table-main th:last-child, .table-main td:last-child {
  padding-right: 16px;
}

.table-main th {
  text-align: left;
  letter-spacing: -.5px;
  background-color: #f5f5f5;
  padding-top: 9px;
  padding-bottom: 9px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.table-main th .icon {
  width: 16px;
  height: 16px;
}

.table-main th:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.table-main th:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.table-main th.is-active {
  letter-spacing: -.4px;
  font-weight: 500;
}

.table-main td {
  white-space: nowrap;
  vertical-align: top;
  background-color: #fff;
  border-bottom: 1px solid #f5f5f5;
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
}

.table-main td.table-main__user-status {
  text-align: right;
  width: 82px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 0;
}

.table-main td.table-main__user-status .ctrl-btn-status {
  width: 100%;
}

.table-main td.table-main__worker {
  padding-top: 10px;
  padding-bottom: 10px;
}

.table-main td.table-main__worker .person-preview {
  margin-left: -16px;
}

.table-main td.table-main__worker .person-preview__name {
  white-space: normal;
}

.table-main__sorter {
  margin-top: -2px;
  margin-left: 7px;
}

.table-main .task-table {
  margin-bottom: -12px;
}

.table-main .react-select--status {
  width: 120px;
  margin-top: -5px;
  margin-bottom: -5px;
}

.table-main .te-orgs, .table-main .te-organization {
  margin-top: -6px;
  margin-bottom: -4px;
}

.table-main .te-orgs .te-organization {
  margin-top: 0;
  margin-bottom: 0;
}

.table-main .person-preview__trade {
  letter-spacing: -.5px;
  font-size: 10px;
  line-height: 16px;
}

.table-main--projects th:last-child, .table-main--projects td:last-child {
  text-align: right;
}

.ctrl-check {
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.ctrl-check__label {
  letter-spacing: -.5px;
  margin-right: 8px;
  font-size: 12px;
  line-height: 18px;
}

.ctrl-check__label a {
  color: #053fe3;
  text-decoration: none;
}

.ctrl-check__box {
  flex-shrink: 0;
  margin-left: auto;
  position: relative;
  overflow: hidden;
}

.ctrl-check__field {
  transform-origin: 0 0;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(3);
}

.ctrl-check__field:checked + .ctrl-check__icon {
  color: var(--icon-color-check, #fff);
  border-color: #fa380d;
  border-color: var(--icon-border-check, #fa380d);
  background-color: #fa380d;
  background-color: var(--icon-bg-check, #fa380d);
}

.ctrl-check__field:disabled + .ctrl-check__icon {
  color: #d9d9d9;
  background-color: rgba(181, 181, 181, .4);
  border-color: #b5b5b5;
}

.ctrl-check__field:disabled:checked + .ctrl-check__icon {
  color: #b5b5b5;
}

.ctrl-check__icon {
  box-sizing: border-box;
  border: 1px solid #dedede;
  border-color: var(--icon-border, #dedede);
  color: var(--icon-color, #fff);
  width: 16px;
  height: 16px;
  background-color: #fff;
  background-color: var(--icon-bg, #fff);
  border-radius: 2px;
  display: block;
}

.ctrl-check__validation {
  box-sizing: border-box;
  letter-spacing: 0;
  color: #fff;
  background-color: #fa380d;
  max-width: calc(100% - 24px);
  padding: 4px 8px;
  font-size: 10px;
  position: absolute;
  bottom: calc(100% + 12px);
  right: 12px;
}

.ctrl-check__validation:before {
  content: "";
  border: 6px solid #fa380d;
  border-color: #fa380d #fa380d rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  position: absolute;
  top: 100%;
  right: 0;
}

.ctrl-check--reverse .ctrl-check__box {
  order: -1;
  margin-right: 8px;
}

.ctrl-check--reverse .ctrl-check__label {
  margin-right: 0;
}

.ctrl-check--color-second {
  --icon-border: #235dff;
  --icon-color: #f5f5f5;
  --icon-color-check: #fff;
  --icon-border-check: #235dff;
  --icon-bg-check: #235dff;
}

.ctrl-check.is-error .ctrl-check__label {
  color: red;
}

.z-ctrl-btn {
  letter-spacing: -.5px;
  color: #053fe3;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 12px;
  outline: none;
  justify-content: center;
  align-items: center;
  padding: 3px 8px;
  font-size: 12px;
  line-height: 18px;
  display: inline-flex;
  box-shadow: 18px 18px 24px rgba(0, 0, 0, .1);
}

.z-ctrl-btn__icon {
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  margin-right: 4px;
}

.z-ctrl-btn--size-xl {
  letter-spacing: -.5px;
  border-radius: 18px;
  padding: 5px 15px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.z-ctrl-btn--size-xl .z-ctrl-btn__icon {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}

.z-ctrl-btn--color-second {
  color: #fa380d;
}

.z-ctrl-btn--color-success {
  color: #01964e;
}

.z-ctrl-btn--view-border {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #053fe3;
  border-radius: 44px;
  padding: 5px 16px 5px 10px;
}

.info-header {
  border-bottom: 1px solid #dedede;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
}

.info-header__title {
  letter-spacing: -.0625em;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857;
}

.info-header__actions {
  margin-left: auto;
}

.info-header--size-xl {
  padding-top: 0;
  padding-bottom: 16px;
}

.info-header--size-xl .info-header__title {
  font-size: 18px;
  line-height: 1.55556;
}

.form-filter {
  background-color: #f5f5f5;
  border-radius: 16px;
  padding: 16px;
}

.form-filter__header {
  margin-bottom: 24px;
}

.form-filter__grid {
  flex-wrap: wrap;
  margin: -8px;
  display: flex;
}

.form-filter__item {
  width: calc(100% - 16px);
  margin: 8px;
}

.form-filter__item--date {
  width: calc(50% - 16px);
}

.form-filter__item--date-end {
  align-self: flex-end;
  position: relative;
}

.form-filter__item--date-end:before {
  content: "—";
  text-align: center;
  color: #dedede;
  width: 16px;
  font-size: 10px;
  line-height: 1.6;
  position: absolute;
  top: calc(50% - 8px);
  right: 100%;
}

.ctrl-message {
  z-index: 42000;
  letter-spacing: -.5px;
  color: #fff;
  background-color: rgba(5, 63, 227, .85);
  border: 1px solid #fff;
  border-radius: 8px;
  align-items: center;
  padding: 8px 8px 8px 16px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  position: relative;
  box-shadow: 18px 18px 24px rgba(0, 0, 0, .1);
}

.ctrl-message__text {
  margin-right: 20px;
}

.ctrl-message__actions {
  margin-left: auto;
}

.ctrl-message__button-dismiss {
  letter-spacing: -.5px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  outline: none;
  margin-right: 24px;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
  text-decoration: underline;
}

.ctrl-message__button-continue {
  border-radius: 4px;
}

.info-line {
  letter-spacing: -.0833333em;
  flex-wrap: wrap;
  font-size: 12px;
  line-height: 1.5;
  display: flex;
}

.info-line__status {
  box-sizing: border-box;
  width: 85px;
  min-width: 0;
  padding: 0;
}

.info-line__status .status-element__name {
  font-weight: 400;
}

.info-line__info {
  box-sizing: border-box;
  width: 85px;
  padding-right: 5px;
}

.info-line__label {
  opacity: .5;
}

.info-line__note {
  opacity: .5;
  margin-right: auto;
}

.info-line__date {
  opacity: .25;
}

.info-line__value, .info-line__user {
  margin-left: auto;
}

.info-line--members {
  letter-spacing: -.03125em;
  background-color: #fff;
  border-radius: 4px;
  padding: 8px;
  font-size: 10px;
  line-height: 1.6;
}

.task-card {
  box-sizing: border-box;
  text-align: left;
  background-color: #fff;
  background-image: linear-gradient(193.05deg, #fff 0%, #f5f5f5 100%);
  border: 1px solid #fff;
  border-radius: 8px;
  padding: 16px;
  position: relative;
  box-shadow: 24px 24px 36px rgba(0, 0, 0, .07);
}

.task-card__header {
  align-items: flex-end;
  margin-top: -8px;
  display: flex;
  position: relative;
}

.task-card__icon {
  color: #053fe3;
  opacity: .5;
  width: 12px;
  height: 12px;
  margin-bottom: 3px;
  margin-right: 2px;
}

.task-card__id {
  letter-spacing: -.5px;
  color: #053fe3;
  opacity: .5;
  font-size: 10px;
  line-height: 16px;
}

.task-card__status {
  letter-spacing: -.5px;
  background-color: #fff;
  border-radius: 8px;
  min-width: 115px;
  margin-left: auto;
  margin-right: -8px;
  padding: 4px 8px;
  font-size: 12px;
  line-height: 18px;
}

.task-card__name {
  letter-spacing: -.5px;
  color: #1e1b1b;
  margin-top: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  position: relative;
}

.task-card__name:before {
  content: "";
  background-color: #a7b4d7;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  width: 3px;
  height: 33px;
  position: absolute;
  top: -10px;
  right: calc(100% + 13px);
}

.task-card__dates {
  justify-content: space-between;
  margin-top: 4px;
  display: flex;
}

.task-card__date {
  letter-spacing: -.05em;
  opacity: .25;
  font-size: 10px;
  line-height: 1.6;
}

.task-card__body {
  margin-bottom: -12px;
}

.task-card__info-line {
  border-top: 1px solid #f5f5f5;
  padding-top: 12px;
  padding-bottom: 12px;
}

.task-card__info-line:first-child {
  border-top-width: 0;
}

.task-card--view-glass {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(193.05deg, rgba(255, 255, 255, .6) 0%, rgba(245, 245, 245, .6) 100%);
}

.empty {
  padding: 100px;
}

.empty__container {
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 50%;
  width: 320px;
  height: 320px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  position: relative;
}

.empty__container:before {
  content: "";
  border-radius: inherit;
  background-image: url("../images/backgrounds/background-4@2x.jpg");
  background-position: 50%;
  background-repeat: repeat;
  background-size: cover;
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
}

.empty__content {
  box-sizing: border-box;
  border-radius: inherit;
  text-align: center;
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  position: relative;
  box-shadow: 18px 18px 24px rgba(0, 0, 0, .1);
}

.empty__card {
  width: 239px;
  position: absolute;
  top: -20px;
  left: 45px;
  transform: rotate(-10deg);
}

.empty__card .task-card__name:before {
  background-color: #053fe3;
}

.empty__title {
  letter-spacing: -1px;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.empty__actions {
  text-align: center;
  margin-top: 13px;
}

.empty__button {
  min-width: 166px;
}

.ctrl-btn-more {
  letter-spacing: -.5px;
  color: #053fe3;
  align-items: center;
  font-size: 12px;
  line-height: 18px;
  text-decoration: none;
  display: inline-flex;
}

.ctrl-btn-more__icon {
  width: 16px;
  height: 16px;
  margin-left: 8px;
}

.report-qty-tasks__text {
  text-align: right;
  letter-spacing: -.5px;
  font-size: 12px;
  line-height: 18px;
}

.report-qty-tasks__text-number {
  opacity: .5;
  margin-left: 8px;
}

.report-qty-tasks__text-number--due {
  opacity: 1;
  color: #fa380d;
}

.report-qty-tasks__line {
  display: flex;
}

.report-qty-tasks__line-item {
  box-sizing: border-box;
  background-color: #f5f5f5;
  border: 1px solid #fff;
  height: 6px;
}

.report-qty-tasks__line-item--completed {
  background-color: #01964e;
}

.report-qty-tasks__line-item--inprogress {
  background-color: #053fe3;
}

.report-qty-tasks__line-item--issues {
  background-color: #f90;
}

.report-qty-tasks__line-item--due {
  background-color: #fa380d;
}

.report-qty-tasks__line-item:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.report-qty-tasks__line-item:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.report-workers__item {
  margin-top: 16px;
}

.report-workers__item:first-child {
  margin-top: 0;
}

.report-workers__worker .person-preview__avatar {
  width: 60px;
  height: 60px;
}

.report-workers__worker .person-preview__trade {
  font-size: 12px;
}

.report-workers__tasks {
  margin-top: -29px;
  margin-bottom: 5px;
  margin-left: 72px;
}

.rpt-tasks-target {
  align-items: center;
  display: flex;
}

.rpt-tasks-target__header {
  flex-shrink: 0;
  min-width: 130px;
  margin-right: 25px;
}

.rpt-tasks-target__name {
  letter-spacing: -.5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.rpt-tasks-target__date {
  letter-spacing: -.5px;
  opacity: .5;
  margin-top: -3px;
  font-size: 12px;
  line-height: 18px;
  display: block;
}

.rpt-tasks-target__actions {
  letter-spacing: -.5px;
  margin-top: -3px;
  font-size: 12px;
  line-height: 18px;
}

.rpt-tasks-target__link {
  color: #053fe3;
  text-decoration: none;
}

html.no-touchevents .rpt-tasks-target__link:hover {
  text-decoration: underline;
}

.rpt-tasks-target__body {
  flex-grow: 1;
}

.rpt-tasks-target__line {
  border: 1px solid #dedede;
  border-radius: 4px;
  height: 30px;
  margin-top: 20px;
  margin-bottom: 30px;
  position: relative;
}

.rpt-tasks-target__line-part {
  border-radius: inherit;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.rpt-tasks-target__line-part--target {
  color: #f90;
  background-color: #f5f5f5;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rpt-tasks-target__line-part--target:after {
  content: "";
  z-index: 1;
  background-color: currentColor;
  width: 2px;
  height: 100%;
  position: absolute;
  top: 0;
  right: -1px;
}

.rpt-tasks-target__line-part--complete {
  color: #01964e;
  background-color: currentColor;
}

.rpt-tasks-target__line-number {
  opacity: .5;
  font-size: 12px;
  position: absolute;
}

.rpt-tasks-target__line-number--start {
  padding-left: 3px;
  top: calc(100% + 15px);
  left: 0;
}

.rpt-tasks-target__line-number--start:before {
  content: "";
  opacity: .3;
  border-left: 1px dashed;
  height: 24px;
  position: absolute;
  bottom: 2px;
  left: 0;
}

.rpt-tasks-target__line-number--total {
  padding-right: 3px;
  top: calc(100% + 15px);
  right: 0;
}

.rpt-tasks-target__line-number--total:before {
  content: "";
  opacity: .3;
  border-left: 1px dashed;
  height: 24px;
  position: absolute;
  bottom: 2px;
  right: 0;
}

.rpt-tasks-target__line-number--target {
  opacity: 1;
  background-color: #fff;
  font-weight: 700;
  top: calc(100% + 3px);
  right: 0;
  transform: translateX(50%);
}

.rpt-tasks-target__line-number--complete {
  opacity: 1;
  background-color: #fff;
  font-weight: 700;
  bottom: calc(100% + 3px);
  right: 0;
  transform: translateX(50%);
}

.rpt-tasks-summary__actions-grid {
  margin: -4px;
  display: flex;
}

.rpt-tasks-summary__actions-item {
  flex-grow: 1;
  flex-basis: 10%;
  margin: 4px;
}

.rpt-tasks-summary__cost-grid {
  display: -ms-grid;
  grid-gap: 10px;
  background-color: #f5f5f5;
  border-radius: 8px;
  margin-top: 20px;
  padding: 10px 15px;
  display: grid;
}

.rpt-tasks-summary__cost-item--date {
  -ms-grid-column-span: 4;
  grid-column: span 4;
}

.rpt-tasks-summary__cost-label {
  letter-spacing: -.5px;
  opacity: .5;
  font-size: 12px;
  line-height: 18px;
}

.rpt-tasks-summary__cost-value {
  letter-spacing: -.5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.rpt-tasks-summary__grid {
  margin-top: 20px;
}

.rpt-tasks-summary__item {
  border-top: 1px solid #f5f5f5;
  padding-top: 15px;
  padding-bottom: 15px;
}

.rpt-tasks-summary__item:first-child {
  border-top-width: 0;
  padding-top: 0;
}

.ctrl-toggle {
  align-items: center;
  width: -webkit-fit-content;
  width: fit-content;
  display: flex;
  position: relative;
}

.ctrl-toggle__visual {
  box-sizing: border-box;
  border: 1px solid #053fe3;
  border-radius: 12px;
  width: 45px;
  height: 24px;
  margin-left: 6px;
  margin-right: 6px;
  display: block;
  position: relative;
}

.ctrl-toggle__visual:before {
  content: "";
  -o-transition-property: left;
  -o-transition-timing-function: ease;
  -o-transition-duration: .125s;
  background-color: #053fe3;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  transition-property: left;
  transition-duration: .125s;
  transition-timing-function: ease;
  position: absolute;
  top: 2px;
  left: 2px;
}

.ctrl-toggle__label {
  letter-spacing: -.5px;
  -o-transition-property: opacity;
  -o-transition-timing-function: ease;
  -o-transition-duration: .125s;
  margin-top: 2px;
  font-size: 14px;
  line-height: 20px;
  transition-property: opacity;
  transition-duration: .125s;
  transition-timing-function: ease;
}

.ctrl-toggle__label--default {
  order: -1;
}

.ctrl-toggle__label--second {
  opacity: .5;
}

.ctrl-toggle__field {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.ctrl-toggle__field:checked ~ .ctrl-toggle__visual:before {
  left: 23px;
}

.ctrl-toggle__field:checked ~ .ctrl-toggle__label--default {
  opacity: .5;
}

.ctrl-toggle__field:checked ~ .ctrl-toggle__label--second {
  opacity: 1;
}

.report {
  box-sizing: border-box;
  background-color: #dedede;
  border-radius: 8px;
  height: 100%;
  padding: 4px;
}

.report__container {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  height: inherit;
  background-color: #fff;
  border-radius: 4px;
  flex-direction: column;
  padding: 16px;
  display: flex;
  box-shadow: 18px 18px 24px rgba(0, 0, 0, .1);
}

.report__header {
  flex-shrink: 0;
  align-items: flex-start;
  display: flex;
}

.report__header-group {
  margin-right: 15px;
}

.report__header-actions {
  margin-left: auto;
  display: flex;
}

.report__title {
  letter-spacing: -1px;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

.report__date {
  letter-spacing: -.5px;
  opacity: .5;
  margin-top: -3px;
  font-size: 14px;
  line-height: 20px;
  display: block;
}

.report__body {
  flex-grow: 1;
  min-height: 280px;
  margin-top: 16px;
}

.report__no-data {
  opacity: .5;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.report__actions {
  flex-shrink: 0;
  align-items: center;
  margin-top: 16px;
  display: flex;
}

.report__field-settings {
  width: 120px;
}

.report__field-settings .react-select__control {
  border-radius: 14px;
  padding-left: 12px;
}

.report__field-settings .react-select__indicators {
  margin-right: -4px;
}

.report__button-settings {
  background-color: #f5f5f5;
  border-radius: 50%;
  margin-left: 8px;
  padding: 5px;
}

.report__button-settings .ctrl-btn-clear__icon {
  width: 18px;
  height: 18px;
}

.report__button-more {
  margin-left: auto;
}

.reports-grid__container {
  flex-wrap: wrap;
  margin: -12px;
  display: flex;
}

.reports-grid__item {
  box-sizing: border-box;
  margin: 12px;
}

.reports-grid__item--area-half {
  width: calc(50% - 24px);
}

.reports-grid__item--area-1 {
  width: calc(33.3333% - 24px);
}

.reports-grid__item--area-2 {
  width: calc(66.6667% - 24px);
}

.reports-grid__item--area-3 {
  width: calc(100% - 24px);
}

.project-menu__grid {
  justify-content: space-between;
  margin: -8px;
  display: flex;
}

.project-menu__item {
  box-sizing: border-box;
  color: #053fe3;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  flex-shrink: 0;
  width: 40px;
  padding: 8px 0 0;
}

.project-menu__icon {
  width: 16px;
  height: 16px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.project-menu__text {
  letter-spacing: -.5px;
  white-space: nowrap;
  margin-top: 12px;
  margin-left: -10px;
  margin-right: -10px;
  font-size: 10px;
  line-height: 16px;
  display: block;
  overflow: auto;
}

.project-preview {
  box-sizing: border-box;
  background-color: #dedede;
  border-radius: 8px;
  padding: 4px;
}

.project-preview__container {
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
  flex-direction: column;
  margin-top: 4px;
  padding: 16px;
  display: flex;
  box-shadow: 18px 18px 24px rgba(0, 0, 0, .1);
}

.project-preview__container:first-child {
  height: 150px;
  margin-top: 0;
}

.project-preview__actions {
  align-items: center;
  display: flex;
}

.project-preview__tasks, .project-preview__edit {
  box-sizing: border-box;
  letter-spacing: -.5px;
  color: #053fe3;
  background-color: #f5f5f5;
  border-radius: 11px;
  align-items: center;
  min-width: 88px;
  margin-right: auto;
  padding: 2px 4px 2px 8px;
  font-size: 12px;
  line-height: 18px;
  display: flex;
}

.project-preview__tasks-text, .project-preview__edit-text {
  margin-right: auto;
}

.project-preview__tasks-icon, .project-preview__edit-icon {
  width: 12px;
  height: 12px;
  margin-left: 10px;
}

.project-preview__time-zone {
  letter-spacing: -.5px;
  opacity: .5;
  font-size: 12px;
  line-height: 18px;
}

.project-preview__button-menu {
  color: #dedede;
  margin-left: 8px;
}

.project-preview__button-menu .ctrl-btn-clear__icon {
  width: 16px;
  height: 16px;
}

.project-preview__title {
  letter-spacing: -.5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: auto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  overflow: hidden;
}

.project-preview__title-icon {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-top: -2px;
  margin-right: 4px;
}

.project-preview__location {
  letter-spacing: -.5px;
  opacity: .5;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  line-height: 18px;
  overflow: hidden;
}

.project-add {
  box-sizing: border-box;
  background-color: #dedede;
  border-radius: 8px;
  padding: 4px;
}

.project-add__container {
  background-color: #fff;
  border-radius: 4px;
  padding: 16px;
  box-shadow: 18px 18px 24px rgba(0, 0, 0, .1);
}

.project-add {
  color: #053fe3;
  text-align: center;
  border-width: 0;
  height: 158px;
}

.project-add__container {
  box-sizing: border-box;
  border: 1px dashed #053fe3;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.project-add__text {
  letter-spacing: -.5px;
  margin-top: 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.projects-list__grid {
  flex-wrap: wrap;
  margin: -12px;
  display: flex;
}

.projects-list__item {
  flex-shrink: 0;
  width: calc(33.3333% - 24px);
  margin: 12px;
}

.aka-table {
  letter-spacing: -.5px;
  background-color: #fff;
  font-size: 12px;
  line-height: 18px;
}

.aka-table__tr {
  width: 100%;
  display: flex;
}

.aka-table__tr--new {
  color: #01964e;
  font-weight: 500;
}

.aka-table__tr--new .aka-table__value--id {
  font-weight: 400;
}

.aka-table__tr--edit {
  background-color: rgba(5, 63, 227, .1);
}

.aka-table__tr--error {
  color: #fa380d;
  background-color: rgba(250, 56, 13, .1);
}

.aka-table__cell {
  box-sizing: border-box;
  flex-shrink: 0;
  padding: 15px 8px;
}

.aka-table__cell--th {
  background-color: #f5f5f5;
  font-weight: 500;
}

.aka-table__cell--td {
  border-bottom: 1px solid #f5f5f5;
}

.aka-table__value--id {
  opacity: .3;
}

.aka-table__value--one-line {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.aka-table__value--error {
  color: #fa380d;
  opacity: 1;
}

.aka-table__value--edit {
  color: #053fe3;
  font-weight: 500;
}

.aka-table__value--edit .aka-table__value-button {
  box-sizing: border-box;
  color: #fff;
  background-color: #053fe3;
  border-radius: 50%;
  padding: 2px;
}

.aka-table__value--edit .aka-table__value-button .ctrl-btn-clear__icon {
  width: 10px;
  height: 10px;
}

.aka-table__value-button {
  color: inherit;
  vertical-align: top;
  margin-left: 3px;
}

.aka-table__value-button .ctrl-btn-clear__icon {
  width: 16px;
  height: 16px;
}

.table__scrollable-wrapper {
  --thumbBg: #dedede;
  --scrollTrackBg: white;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBg) var(--scrollTrackBg);
}

.table__scrollable-wrapper::-webkit-scrollbar {
  width: 1em;
}

.table__scrollable-wrapper::-webkit-scrollbar-thumb {
  background-color: var(--thumbBg);
  border-wisth: 3px;
  border-style: solid;
  border-color: var(--scrollTrackBg);
  border-radius: 6px;
}

.table__scrollable-wrapper::-webkit-scrollbar-track {
  background-color: var(--scrollTrackBg);
}

.aka-table--tasks-hierarchy .aka-table__button-view {
  z-index: 1;
  color: #053fe3;
  position: absolute;
  top: 7px;
  left: 5px;
}

.aka-table--tasks-hierarchy .aka-table__button-collapse {
  width: 24px;
  height: 24px;
  padding: 8px;
  position: absolute;
  top: 11px;
  left: -24px;
}

.aka-table--tasks-hierarchy .aka-table__button-collapse .ctrl-btn-clear__icon {
  transform: rotate(-90deg);
}

.aka-table--tasks-hierarchy .aka-table__group--with-childs {
  padding-left: 24px;
  position: relative;
  overflow: hidden;
}

.aka-table--tasks-hierarchy .aka-table__group--with-childs .aka-table__cell--name {
  padding-left: 0;
}

.aka-table--tasks-hierarchy .aka-table__group--with-childs:before {
  content: "";
  opacity: 0;
  background-color: #dedede;
  width: 2px;
  position: absolute;
  top: 34px;
  bottom: 0;
  left: 11px;
}

.aka-table--tasks-hierarchy .aka-table__group--with-childs.is-open:before {
  opacity: 1;
}

.aka-table--tasks-hierarchy .aka-table__group--with-childs.is-open > .aka-table__tr .aka-table__cell--name .aka-table__button-collapse .ctrl-btn-clear__icon {
  transform: rotate(0);
}

.aka-table--tasks-hierarchy .aka-table__group--with-childs.is-open > .aka-table__childs {
  display: block;
}

.aka-table--tasks-hierarchy .aka-table__childs {
  display: none;
}

.aka-table--tasks-hierarchy .aka-table__tr:first-child {
  position: relative;
}

.aka-table--tasks-hierarchy .aka-table__cell--th {
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 400;
}

.aka-table--tasks-hierarchy .aka-table__cell--th:first-of-type {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.aka-table--tasks-hierarchy .aka-table__cell--th:last-of-type {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.aka-table--tasks-hierarchy .aka-table__cell--name {
  flex-grow: 1;
  flex-basis: 100px;
  min-width: 0;
  padding-left: 24px;
  position: relative;
}

.aka-table--tasks-hierarchy .aka-table__cell--sub {
  width: 164px;
}

.aka-table--tasks-hierarchy .aka-table__cell--start, .aka-table--tasks-hierarchy .aka-table__cell--due {
  width: 100px;
}

.aka-table--tasks-hierarchy .aka-table__cell--workers {
  width: 70px;
}

.aka-table--tasks-hierarchy .aka-table__cell--status {
  width: 120px;
  padding-right: 8px;
}

.aka-table--tasks-hierarchy .aka-table__cell--status .react-select {
  margin-top: -5px;
  margin-bottom: -5px;
}

.aka-table--list {
  height: 100%;
}

.aka-table--gantt .aka-table__task {
  margin-top: 24px;
  margin-left: 36px;
  margin-right: 24px;
  position: relative;
}

.aka-table--gantt .aka-table__task--parent:before {
  content: "";
  background-color: #dedede;
  width: 1px;
  position: absolute;
  top: 0;
  bottom: 10px;
  left: 24px;
}

.aka-table--gantt .aka-table__task--child {
  margin-left: 24px;
  margin-right: 0;
}

.aka-table--gantt .aka-table__task--child .aka-table__cell--td {
  background-color: rgba(0, 0, 0, 0);
  padding-top: 0;
  padding-bottom: 0;
}

.aka-table--gantt .aka-table__task--child .aka-table__status-indicator {
  top: 4px;
}

.aka-table--gantt .aka-table__status-indicator {
  border-radius: 50%;
  width: 9px;
  height: 9px;
  position: absolute;
  top: 15px;
  left: -4px;
}

.aka-table--gantt .aka-table__status-indicator--tba, .aka-table--gantt .aka-table__status-indicator--assigned, .aka-table--gantt .aka-table__status-indicator--in-progress {
  background-color: #053fe3;
}

.aka-table--gantt .aka-table__status-indicator--done, .aka-table--gantt .aka-table__status-indicator--verified, .aka-table--gantt .aka-table__status-indicator--approved {
  background-color: #01964e;
}

.aka-table--gantt .aka-table__status-indicator--closed, .aka-table--gantt .aka-table__status-indicator--blocked, .aka-table--gantt .aka-table__status-indicator--re-work {
  background-color: #fa380d;
}

.aka-table--gantt .aka-table__tr {
  width: auto;
  position: relative;
}

.aka-table--gantt .aka-table__cell {
  padding-top: 11px;
  padding-bottom: 11px;
}

.aka-table--gantt .aka-table__cell--th {
  background-color: rgba(0, 0, 0, 0);
  align-items: center;
  height: 132px;
  font-weight: 400;
  display: flex;
}

.aka-table--gantt .aka-table__cell--th:first-child {
  margin-left: 36px;
  padding-left: 0;
}

.aka-table--gantt .aka-table__cell--th:last-child {
  margin-right: 24px;
  padding-right: 16px;
}

.aka-table--gantt .aka-table__cell--td {
  background-color: #f5f5f5;
  border-bottom-width: 0;
}

.aka-table--gantt .aka-table__cell--td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding-left: 16px;
}

.aka-table--gantt .aka-table__cell--td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-right: 16px;
}

.aka-table--gantt .aka-table__cell--name {
  flex-grow: 1;
  flex-basis: 100px;
  align-items: center;
  min-width: 0;
  display: flex;
}

.aka-table--gantt .aka-table__cell--sub {
  width: 164px;
}

.aka-table--gantt .aka-table__cell--start, .aka-table--gantt .aka-table__cell--due {
  width: 80px;
}

.aka-table--gantt .aka-table__body {
  box-sizing: border-box;
  border-top: 1px solid #dedede;
  height: calc(100vh - 234px);
  padding-top: 24px;
  padding-bottom: 24px;
  overflow-x: hidden;
  overflow-y: auto;
}

.aka-table--gantt .aka-table__body > .aka-table__task:first-child {
  margin-top: 0;
}

.aka-table--gantt .aka-table__button-collapse {
  color: #fff;
  background-color: #1e1b1b;
  border-radius: 50%;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-top: -2px;
  margin-right: 8px;
}

.aka-table--gantt .aka-table__button-collapse .ctrl-btn-clear__icon {
  width: 100%;
  height: 100%;
}

.aka-table--gantt .aka-table__button-collapse.is-active {
  transform: rotate(180deg);
}

.gantt-chart {
  letter-spacing: -.5px;
  font-size: 12px;
  line-height: 18px;
}

.gantt-chart__months {
  display: flex;
}

.gantt-chart__month {
  border: 0 solid #dedede;
  border-width: 0 0 1px 1px;
  flex-shrink: 0;
}

.gantt-chart__month:first-child {
  border-left-width: 0;
}

.gantt-chart__month-name {
  box-sizing: border-box;
  width: -webkit-fit-content;
  width: fit-content;
  height: 28px;
  padding: 6px 10px 4px;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
}

.gantt-chart__weeks {
  border-top: 1px solid #dedede;
  display: flex;
}

.gantt-chart__week {
  border-left: 1px solid #dedede;
  flex-shrink: 0;
}

.gantt-chart__week:first-child {
  border-left-width: 0;
}

.gantt-chart__week-name {
  box-sizing: border-box;
  width: -webkit-fit-content;
  width: fit-content;
  height: 28px;
  padding: 6px 10px 4px;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
}

.gantt-chart__days {
  border-top: 1px solid #dedede;
  display: flex;
}

.gantt-chart__day {
  border-left: 1px solid #dedede;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  display: flex;
}

.gantt-chart__day:first-child {
  border-left-width: 0;
}

.gantt-nav {
  justify-content: space-between;
  padding: 5px;
  display: flex;
}

.gantt-nav__button--prev {
  left: 0;
}

.gantt-nav__button--next {
  flex-direction: row-reverse;
}

.gantt-nav__button--next .ctrl-btn__icon {
  margin-left: 4px;
  margin-right: 0;
}

.gantt-grid {
  box-sizing: border-box;
  background-color: #fff;
  border-top: 1px solid #dedede;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.gantt-grid__list {
  background: #fff;
  flex-shrink: 0;
  width: 40%;
  position: relative;
  box-shadow: 24px 24px 36px rgba(0, 0, 0, .07);
}

.gantt-grid__resize {
  cursor: col-resize;
  background: #fff;
  border-width: 0;
  border-radius: 3px;
  padding: 6px 5px;
  position: absolute;
  top: calc(50% - 14px);
  right: -7px;
  box-shadow: 0 0 18px rgba(0, 0, 0, .1);
}

.gantt-grid__resize:before {
  content: "";
  border: 0 solid #1e1b1b;
  border-width: 0 1px;
  width: 2px;
  height: 16px;
  display: block;
}

.gantt-grid__area {
  flex-grow: 1;
  overflow: auto;
}

.gantt-grid__nav {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
}

.gantt-grid__legend {
  position: absolute;
  bottom: 20px;
  right: 0;
}

.gantt-grid__legend .gantt-legend__body {
  border-top-left-radius: 12px;
  box-shadow: -9px -18px 24px rgba(0, 0, 0, .1);
}

.screen-grid {
  align-items: flex-start;
  display: flex;
}

.screen-grid__content {
  -ms-grid-row-align: stretch;
  flex-grow: 1;
  align-self: stretch;
  min-width: 0;
  position: relative;
}

.screen-grid__aside {
  flex-shrink: 0;
  width: 250px;
  margin-left: 26px;
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
}

.screen-grid__message {
  margin-top: 20px;
  position: -webkit-sticky;
  position: sticky;
  bottom: 20px;
}

.ctrl-tabs {
  background-color: #dedede;
  border-radius: 8px;
  padding: 4px;
}

.ctrl-tabs__list {
  background-color: #f5f5f5;
  border-radius: 4px;
  list-style-type: none;
  display: flex;
}

.ctrl-tabs__item {
  flex-grow: 1;
  flex-basis: 10%;
}

.ctrl-tabs__button {
  box-sizing: border-box;
  letter-spacing: -.5px;
  color: #053fe3;
  -webkit-user-select: none;
  user-select: none;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  border-radius: 4px;
  outline: none;
  width: 100%;
  padding: 8px;
  font-size: 14px;
  line-height: 20px;
}

.ctrl-tabs__button.is-active {
  color: #1e1b1b;
  background-color: #fff;
  font-weight: 500;
  box-shadow: 18px 18px 24px rgba(0, 0, 0, .1);
}

.y-ctrl-btn {
  letter-spacing: -1px;
  color: #053fe3;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  background: none;
  border-width: 0;
  outline: none;
  align-items: center;
  padding: 10px 0;
  font-size: 18px;
  line-height: 28px;
  display: inline-flex;
}

.y-ctrl-btn__icon {
  margin-right: 8px;
}

.task-history__body {
  margin-top: 8px;
}

.task-history__item {
  flex-wrap: wrap;
  align-items: flex-start;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
}

.task-history__status {
  letter-spacing: -.4px;
  flex-grow: 1;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.task-history__date {
  letter-spacing: -.5px;
  opacity: .5;
  flex-shrink: 0;
  margin-left: 12px;
  font-size: 12px;
  line-height: 18px;
}

.task-history__user {
  letter-spacing: -.5px;
  opacity: .5;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-basis: 100%;
  max-width: 100%;
  font-size: 10px;
  line-height: 16px;
  overflow: hidden;
}

.task-assignees__body {
  margin-top: 16px;
}

.task-assignees__item {
  align-items: center;
  margin-top: 16px;
  display: flex;
}

.task-assignees__item:first-child {
  margin-top: 0;
}

.task-assignees__item .person-preview__name {
  letter-spacing: -.5px;
  font-size: 12px;
  line-height: 18px;
}

.task-assignees__item .person-preview__trade {
  letter-spacing: -.5px;
  font-size: 10px;
  line-height: 16px;
}

.task-assignees__item--assigned .task-assignees__icon {
  background-color: #01964e;
}

.task-assignees__item--removed .person-preview__avatar {
  opacity: .6;
}

.task-assignees__item--removed .person-preview__name {
  color: #c8c8c8;
}

.task-assignees__item--removed .task-assignees__icon {
  background-color: #c8c8c8;
}

.task-assignees__person {
  flex-grow: 1;
  margin-right: 10px;
}

.task-assignees__icon {
  color: #fff;
  background-color: #d3d3d3;
  border-radius: 50%;
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  margin-left: auto;
  padding: 8px;
}

.avatar-default {
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.avatar-default__icon {
  opacity: .25;
  width: 60%;
  height: 60%;
}

.profile-status {
  letter-spacing: -.5px;
  color: #1e1b1b;
  opacity: .5;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  align-items: center;
  padding: 2px 7px;
  font-size: 12px;
  line-height: 18px;
  display: inline-flex;
}

.profile-status__icon {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.profile-status__name {
  padding-right: 1px;
}

.profile-status--active {
  color: #01964e;
  opacity: 1;
}

.profile-status--invited {
  opacity: 1;
  border-color: #dedede;
}

.profile-status--invited .profile-status__icon, .profile-status--invited .profile-status__name {
  opacity: .5;
}

.profile-info {
  background-color: #dedede;
  border-radius: 8px;
  padding: 4px;
}

.profile-info__container {
  background-color: #fff;
  border-radius: 4px;
  padding: 16px;
  box-shadow: 18px 18px 24px rgba(0, 0, 0, .1);
}

.profile-info__header {
  align-items: flex-start;
  padding-top: 14px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
}

.profile-info__avatar {
  flex-shrink: 0;
  width: 90px;
  height: 90px;
  margin-top: -14px;
  margin-right: 8px;
}

.profile-info__data {
  flex-grow: 1;
}

.profile-info__phone-number {
  letter-spacing: -.0555556em;
  color: #053fe3;
  font-size: 18px;
  line-height: 1.55556;
  text-decoration: none;
}

.profile-info__status {
  margin-top: 8px;
}

.profile-info__actions {
  flex-shrink: 0;
  display: flex;
}

.profile-info__button {
  color: #dedede;
  flex-shrink: 0;
  margin-left: 16px;
}

.profile-info__item {
  border-top: 1px solid #f5f5f5;
  padding: 16px;
}

.profile-info__item:first-child {
  border-top-width: 0;
}

.profile-info__name {
  letter-spacing: -.0625em;
  color: #1e1b1b;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.5;
}

.profile-info__trade {
  letter-spacing: -.0555556em;
  opacity: .5;
  font-size: 18px;
  line-height: 1.55556;
}

.profile-info__number {
  letter-spacing: -.05em;
  opacity: .5;
  margin-top: 16px;
  font-size: 10px;
  line-height: 1.6;
}

.profile-info__projects {
  letter-spacing: -.0714286em;
  font-size: 14px;
  line-height: 1.42857;
  display: flex;
}

.profile-info__projects-title {
  font-weight: 400;
  font-size: inherit;
  line-height: inherit;
  flex-shrink: 0;
  margin-right: 20px;
}

.profile-info__projects-list {
  margin-left: auto;
  list-style-type: none;
}

.profile-info__projects-item {
  text-align: right;
  margin-top: 8px;
}

.profile-info__projects-item:first-child {
  margin-top: 0;
}

.profile-info__projects-name {
  color: #053fe3;
  text-decoration: none;
}

.profile-info__qr-box {
  box-sizing: border-box;
  width: 116px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
}

.profile-info__qr {
  width: 100%;
  max-width: 100%;
  height: auto;
  display: block;
}

.profile-info__orgs {
  flex-wrap: wrap;
  margin: 0;
  display: flex;
}

.profile-info__orgs-item {
  text-overflow: ellipsis;
  max-width: 100%;
  margin: 2px;
  overflow: hidden;
}

.profile-info--view-summary {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0;
  padding: 0;
}

.profile-info--view-summary .profile-info__container {
  background-image: linear-gradient(193.05deg, #fff 0%, #f5f5f5 100%);
  border: 1px solid #fff;
  padding: 16px;
  box-shadow: 24px 24px 36px rgba(0, 0, 0, .07);
}

.profile-info--view-summary .profile-info__header {
  padding-top: 4px;
  padding-left: 0;
  padding-right: 0;
}

.profile-info--view-summary .profile-info__avatar {
  width: 60px;
  height: 60px;
  margin-top: -4px;
}

.profile-info--view-summary .profile-info__phone-number {
  letter-spacing: -.5px;
  font-size: 10px;
  line-height: 16px;
}

.profile-info--view-summary .profile-info__phone-number--default {
  color: #dedede;
}

.profile-info--view-summary .profile-info__actions {
  margin-top: 1px;
}

.profile-info--view-summary .profile-info__button {
  margin-left: 8px;
}

.profile-info--view-summary .profile-info__button .ctrl-btn-clear__icon {
  width: 16px;
  height: 16px;
}

.profile-info--view-summary .profile-info__item {
  margin-left: -16px;
  margin-right: -16px;
}

.profile-info--view-summary .profile-info__item:only-child {
  padding-bottom: 8px;
}

.profile-info--view-summary .profile-info__item:last-child .profile-info__qr-box, .profile-info--view-summary .profile-info__item:last-child .profile-info__orgs {
  margin-bottom: -16px;
}

.profile-info--view-summary .profile-info__name {
  letter-spacing: -.0555556em;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.55556;
}

.profile-info--view-summary .profile-info__name-part {
  display: block;
}

.profile-info--view-summary .profile-info__name-indent {
  display: none;
}

.profile-info--view-summary .profile-info__trade, .profile-info--view-summary .profile-info__projects {
  letter-spacing: -.0833333em;
  font-size: 12px;
  line-height: 1.5;
}

.profile-info--view-summary .profile-info__qr-box {
  background-color: #fff;
  border-radius: 16px;
}

.profile-info--view-summary .profile-info__orgs {
  justify-content: center;
}

.ctrl-dnd {
  text-align: center;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 24px 24px 36px rgba(0, 0, 0, .07);
}

.ctrl-dnd__text {
  letter-spacing: -.5px;
  opacity: .25;
  font-size: 14px;
  line-height: 20px;
}

.ctrl-dnd__button {
  margin-top: 4px;
}

.ctrl-slider {
  background-color: #dedede;
  border-radius: 2px;
  height: 4px;
  position: relative;
}

.ctrl-slider__progress {
  background-color: #053fe3;
  border-radius: 2px;
  height: 100%;
  display: block;
}

.ctrl-slider__drag {
  box-sizing: border-box;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: #053fe3;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  box-shadow: 18px 18px 24px rgba(5, 63, 227, .3);
}

.ctrl-crop {
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 24px 24px 36px rgba(0, 0, 0, .07);
}

.ctrl-crop__header {
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 11px;
  display: flex;
}

.ctrl-crop__title {
  letter-spacing: -.5px;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.ctrl-crop__button-remove {
  color: #fa380d;
  margin-left: auto;
}

.ctrl-crop__button-remove .ctrl-btn-clear__icon {
  width: 16px;
  height: 16px;
}

.ctrl-crop__body {
  margin-top: 12px;
  display: flex;
}

.ctrl-crop__group {
  background-color: #f5f5f5;
  border-radius: 12px;
  flex-shrink: 0;
  align-items: center;
  padding: 4px 8px;
  display: flex;
}

.ctrl-crop__group--zoom {
  flex-grow: 1;
  margin-left: 4px;
}

.ctrl-crop__button {
  color: #053fe3;
  flex-shrink: 0;
  margin-left: 8px;
}

.ctrl-crop__button .ctrl-btn-clear__icon {
  width: 16px;
  height: 16px;
}

.ctrl-crop__button--zoom {
  color: #1e1b1b;
  opacity: .25;
}

.ctrl-crop__button:first-child {
  margin-left: 0;
}

.ctrl-crop__slider {
  flex-grow: 1;
  margin-left: 8px;
}

.avatar-add {
  color: #fff;
  background-color: #053fe3;
  border: 1px solid #fff;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 18px 18px 24px rgba(5, 63, 227, .3);
}

.avatar-add__icon {
  opacity: .25;
  width: 36px;
  height: 36px;
}

.avatar-add__text {
  letter-spacing: -.5px;
  font-size: 12px;
  line-height: 18px;
}

.avatar-add__field {
  opacity: 0;
  cursor: pointer;
  outline: none;
  font-size: 150px;
  position: absolute;
  top: 0;
  right: 0;
}

.ctrl-upload {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 16px;
  display: flex;
}

.ctrl-upload__avatar {
  flex-shrink: 0;
  width: 100px;
  height: 100px;
}

.ctrl-upload__body {
  flex-grow: 1;
  margin-left: 10px;
}

.ctrl-upload .cropper-container {
  border-radius: 50%;
  overflow: hidden;
}

.ctrl-upload--disabled {
  justify-content: center;
}

.ctrl-switcher {
  align-items: center;
  display: flex;
}

.ctrl-switcher__label {
  margin-right: 16px;
}

.ctrl-switcher__name {
  letter-spacing: -.4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  display: block;
}

.ctrl-switcher__description {
  letter-spacing: -.5px;
  opacity: .5;
  font-size: 10px;
  line-height: 16px;
  display: block;
}

.ctrl-switcher__visual-box {
  flex-shrink: 0;
  margin-left: auto;
  position: relative;
}

.ctrl-switcher__field {
  z-index: 1;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ctrl-switcher__field:checked + .ctrl-switcher__visual {
  background-color: #053fe3;
}

.ctrl-switcher__field:checked + .ctrl-switcher__visual:before {
  background-color: #fff;
  left: 23px;
}

.ctrl-switcher__field:disabled {
  cursor: default;
}

.ctrl-switcher__field:disabled + .ctrl-switcher__visual {
  border-color: #dedede;
}

.ctrl-switcher__field:disabled + .ctrl-switcher__visual:before, .ctrl-switcher__field:disabled:checked + .ctrl-switcher__visual {
  background-color: #dedede;
}

.ctrl-switcher__field:disabled:checked + .ctrl-switcher__visual:before {
  background-color: #fff;
}

.ctrl-switcher__visual {
  box-sizing: border-box;
  border: 1px solid #053fe3;
  border-radius: 12px;
  width: 45px;
  height: 24px;
  display: block;
  position: relative;
}

.ctrl-switcher__visual:before {
  content: "";
  -o-transition-property: left;
  -o-transition-timing-function: ease;
  -o-transition-duration: .125s;
  background-color: #053fe3;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  transition-property: left;
  transition-duration: .125s;
  transition-timing-function: ease;
  position: absolute;
  top: 2px;
  left: 2px;
}

.ctrl-switcher--reverse .ctrl-switcher__label {
  margin-left: 16px;
  margin-right: 0;
}

.ctrl-switcher--reverse .ctrl-switcher__visual-box {
  order: -1;
  margin-left: 0;
}

.ctrl-switcher--view-checkbox {
  align-items: flex-start;
}

.ctrl-switcher--view-checkbox .ctrl-switcher__visual {
  background-color: #fff;
  border: 1px solid #235dff;
  border-radius: 2px;
  width: 16px;
  height: 16px;
}

.ctrl-switcher--view-checkbox .ctrl-switcher__visual:before {
  background-color: rgba(0, 0, 0, 0);
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' fill='%23f5f5f5'%3E%3Cpath d='M18.336 6.237c-.322-.316-.837-.316-1.159 0l-7.85 7.85-2.484-2.468c-.15-.16-.36-.251-.58-.251-.22 0-.429.091-.58.251l-.878.878c-.157.155-.245.367-.245.588 0 .221.088.433.245.588l3.892 3.925c.15.16.36.251.58.251.22 0 .429-.091.58-.251l9.307-9.307c.157-.155.245-.367.245-.588 0-.221-.088-.432-.245-.588l-.828-.878z'/%3E%3C/svg%3E");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0;
  width: 14px;
  height: 14px;
  top: 0;
  left: 0;
}

.ctrl-switcher--view-checkbox .ctrl-switcher__field:checked + .ctrl-switcher__visual {
  background-color: #235dff;
  border-color: #235dff;
}

.ctrl-switcher--view-checkbox .ctrl-switcher__field:checked + .ctrl-switcher__visual:before {
  background-color: rgba(0, 0, 0, 0);
  left: 0;
}

.ctrl-switcher--view-radio {
  align-items: flex-start;
}

.ctrl-switcher--view-radio .ctrl-switcher__visual {
  background-color: #fff;
  border: 1px solid #235dff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
}

.ctrl-switcher--view-radio .ctrl-switcher__visual:before {
  background-color: rgba(5, 63, 227, .25);
  width: 6px;
  height: 6px;
  top: calc(50% - 3px);
  left: calc(50% - 3px);
}

.ctrl-switcher--view-radio .ctrl-switcher__field:checked + .ctrl-switcher__visual {
  background-color: #235dff;
  border-color: #235dff;
}

.ctrl-switcher--view-radio .ctrl-switcher__field:checked + .ctrl-switcher__visual:before {
  background-color: #fff;
  left: calc(50% - 3px);
}

.ctrl-phone {
  display: flex;
  position: relative;
}

.ctrl-phone__item--code {
  flex-shrink: 0;
  width: 80px;
}

.ctrl-phone__item--code .ctrl-form__label {
  white-space: nowrap;
}

.ctrl-phone__item--code .ctrl-form__body, .ctrl-phone__item--code .react-select {
  position: static;
}

.ctrl-phone__item--number {
  flex-grow: 1;
  margin-left: 8px;
}

.ctrl-phone__item--number .ctrl-form__label {
  opacity: 0;
}

.ctrl-phone__item--button {
  flex-shrink: 0;
  align-self: flex-end;
  min-width: 90px;
  margin-left: 8px;
}

.ctrl-phone__button-send {
  width: 100%;
  padding-top: 8.5px;
  padding-bottom: 8.5px;
}

.ctrl-location-full__header {
  border-bottom: 1px solid #dedede;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 8px;
  display: flex;
}

.ctrl-location-full__title {
  letter-spacing: -.5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.ctrl-location-full__description {
  letter-spacing: -.5px;
  opacity: .5;
  margin-left: auto;
  font-size: 10px;
  line-height: 16px;
}

.ctrl-location-full__body {
  flex-wrap: wrap;
  margin: -8px;
  display: flex;
}

.ctrl-location-full__item {
  flex-shrink: 0;
  width: calc(50% - 16px);
  margin: 8px;
}

.ctrl-project-time__header {
  border-bottom: 1px solid #dedede;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 8px;
  display: flex;
}

.ctrl-project-time__title {
  letter-spacing: -.5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.ctrl-project-time__description {
  letter-spacing: -.5px;
  opacity: .5;
  margin-left: auto;
  font-size: 10px;
  line-height: 16px;
}

.ctrl-project-time__body {
  flex-wrap: wrap;
  margin: -8px;
  display: flex;
}

.ctrl-project-time__item {
  flex-shrink: 0;
  width: calc(50% - 16px);
  margin: 8px;
}

.ctrl-completion {
  display: flex;
  position: relative;
}

.ctrl-completion__item--value {
  flex-grow: 1;
  margin-right: 8px;
}

.ctrl-completion__item--value .ctrl-form__label {
  white-space: nowrap;
}

.ctrl-completion__item--unit {
  flex-shrink: 0;
  width: 80px;
}

.ctrl-completion__item--unit .ctrl-form__label {
  opacity: 0;
}

.list-links__label {
  letter-spacing: -.5px;
  opacity: .5;
  margin-bottom: 2px;
  font-size: 10px;
  line-height: 16px;
  display: inline-block;
}

.list-links__group {
  letter-spacing: -.5px;
  color: #1e1b1b;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  padding: 11px;
  font-size: 14px;
}

.list-links__item {
  margin-top: 11px;
}

.list-links__item:first-child {
  margin-top: 0;
}

.list-links__link {
  color: #053fe3;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  text-decoration: none;
  display: inline-block;
  overflow: hidden;
}

html.no-touchevents .list-links__link:hover {
  text-decoration: underline;
}

.gallery-preview__title {
  letter-spacing: -.5px;
  opacity: .5;
  margin-bottom: 8px;
  font-size: 10px;
  line-height: 16px;
}

.gallery-preview__grid {
  margin: -8px;
  display: flex;
}

.gallery-preview__item {
  flex-shrink: 0;
  width: calc(25% - 16px);
  margin: 8px;
  padding-top: calc(25% - 16px);
  position: relative;
}

.gallery-preview__media {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.gallery-preview__media .picture__image {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  display: block;
}

.gallery-preview__actions {
  text-align: right;
  margin-top: 16px;
}

.ctrl-checklist__item {
  margin-top: 8px;
}

.ctrl-checklist__item:first-child {
  margin-top: 0;
}

.ctrl-checklist__check {
  width: -webkit-fit-content;
  width: fit-content;
  display: flex;
}

.ctrl-linklist__grid {
  margin-left: -16px;
  margin-right: -16px;
}

.ctrl-linklist__link {
  box-sizing: border-box;
  -o-transition-property: color, background-color;
  -o-transition-duration: .125s;
  -o-transition-timing-function: ease;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  align-items: center;
  width: 100%;
  padding: 8px 16px;
  transition-property: color, background-color;
  transition-duration: .125s;
  transition-timing-function: ease;
  display: flex;
}

html.no-touchevents .ctrl-linklist__link:hover, .ctrl-linklist__link.is-active {
  background-color: rgba(222, 222, 222, .3);
}

.ctrl-linklist__link.is-active {
  color: #4050ec;
}

.ctrl-linklist__icon {
  color: #9d9d9d;
  margin-right: 10px;
}

.ctrl-linklist__text {
  letter-spacing: -.5px;
  font-size: 12px;
  line-height: 18px;
}

.ctrl-linklist--size-xs .ctrl-linklist__icon {
  width: 16px;
  height: 16px;
  margin-top: -2px;
}

.ctrl-linklist--size-xs .ctrl-linklist__link {
  padding-top: 4px;
  padding-bottom: 4px;
}

.info-legend {
  list-style-type: none;
}

.info-legend__item {
  letter-spacing: -.5px;
  white-space: nowrap;
  align-items: flex-start;
  margin-top: 8px;
  padding-right: .5px;
  font-size: 12px;
  line-height: 18px;
  display: flex;
}

.info-legend__item:before {
  box-sizing: border-box;
  content: "";
  border: 1px solid;
  border-color: var(--border-color, currentcolor);
  color: var(--color, lightgray);
  width: 32px;
  height: 16px;
  background-color: currentColor;
  background-color: var(--bg-color, currentcolor);
  border-radius: 2px;
  flex-shrink: 0;
  margin-right: 8px;
}

.info-legend__item:first-child {
  margin-top: 0;
}

.info-legend__item--not-started {
  --color: #dedede;
  --border-color: #000;
}

.info-legend__item--in-progress {
  --color: #053fe3;
}

.info-legend__item--completed {
  --color: #01964e;
}

.info-legend__item--in-trouble {
  --color: #f90;
}

.info-legend__item--blocked {
  --color: #fa380d;
}

.info-legend__item--milestone {
  --color: #535353;
}

.ctrl-drop {
  width: -webkit-fit-content;
  width: fit-content;
  position: relative;
}

.ctrl-drop .x-ctrl-btn__icon-drop {
  -o-transition-property: transform;
  -o-transition-duration: .125s;
  -o-transition-timing-function: ease;
  transition-property: transform;
  transition-duration: .125s;
  transition-timing-function: ease;
}

.ctrl-drop__toggle {
  display: block;
}

.ctrl-drop__toggle.ctrl-btn-clear .ctrl-btn-clear__icon {
  color: #817f7f;
  width: 16px;
  height: 16px;
}

.ctrl-drop__viewport {
  z-index: 100;
  -o-transition-property: max-height;
  -o-transition-duration: .125s;
  -o-transition-timing-function: ease;
  border-radius: 8px;
  max-height: 0;
  transition-property: max-height;
  transition-duration: .125s;
  transition-timing-function: ease;
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  overflow: hidden;
  box-shadow: 18px 18px 24px rgba(0, 0, 0, .1);
}

.ctrl-drop__content {
  box-sizing: border-box;
  border-radius: inherit;
  background-color: #fff;
  border: 1px solid #dedede;
  min-width: 153px;
  padding: 16px;
}

.ctrl-drop__content .ctrl-linklist--size-xs {
  margin-top: -8px;
  margin-bottom: -8px;
}

.ctrl-drop.is-active .ctrl-drop__viewport {
  max-height: 500px;
}

.ctrl-drop.is-active .x-ctrl-btn__icon-drop {
  transform: rotate(180deg);
}

.x-ctrl-btn {
  letter-spacing: -.5px;
  color: #053fe3;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  align-items: center;
  padding: 4px 9px 4px 7px;
  font-size: 12px;
  line-height: 18px;
  display: flex;
}

.x-ctrl-btn__icon {
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  margin-right: 4px;
}

.x-ctrl-btn__icon-drop {
  -o-transition-property: transform;
  -o-transition-timing-function: ease;
  -o-transition-duration: .125s;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-left: 5px;
  margin-right: -4px;
  transition-property: transform;
  transition-duration: .125s;
  transition-timing-function: ease;
  transform: rotate(0);
}

.x-ctrl-btn.is-active .x-ctrl-btn__icon-drop {
  transform: rotate(180deg);
}

.ctrl-color-sub {
  align-items: center;
  display: flex;
}

.ctrl-color-sub__color {
  cursor: pointer;
  background-color: #f5f5f5;
  border-radius: 4px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  margin-right: 8px;
  display: flex;
}

.ctrl-color-sub__icon-check {
  color: #fff;
  opacity: 0;
  -o-transition-property: opacity;
  -o-transition-duration: .125s;
  -o-transition-timing-function: ease;
  transition-property: opacity;
  transition-duration: .125s;
  transition-timing-function: ease;
}

.ctrl-color-sub__field {
  cursor: pointer;
  flex-grow: 1;
}

.ctrl-color-sub__field .ctrl-textfield {
  cursor: inherit;
}

.ctrl-color-sub.is-active .ctrl-color-sub__icon-check {
  opacity: 1;
}

.colors-grid__container {
  flex-wrap: wrap;
  align-items: center;
  margin: -8px;
  display: flex;
}

.colors-grid__item {
  flex-shrink: 0;
  width: calc(100% - 16px);
  margin: 8px;
}

.ctrl-color-add__header {
  margin-bottom: 20px;
}

.ctrl-color-add__header-item {
  margin-bottom: 16px;
}

.ctrl-color-add__header-item:last-child {
  margin-bottom: 0;
}

.ctrl-color-add__body {
  margin-bottom: 20px;
}

.ctrl-color-add__grid {
  flex-wrap: wrap;
  margin: -2px;
  display: flex;
}

.ctrl-color-add__item {
  -o-transition-property: outline-color, border-color;
  -o-transition-duration: .125s;
  -o-transition-timing-function: ease;
  -webkit-user-select: none;
  user-select: none;
  border: 2px solid rgba(0, 0, 0, 0);
  border-radius: 6px;
  outline: 2px solid rgba(0, 0, 0, 0);
  justify-content: center;
  align-items: center;
  width: 43px;
  height: 43px;
  margin: 2px;
  padding: 0;
  transition-property: outline-color, border-color;
  transition-duration: .125s;
  transition-timing-function: ease;
  display: flex;
  position: relative;
}

.ctrl-color-add__item-text {
  color: #fff;
  text-transform: uppercase;
  margin-top: 2px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
}

.ctrl-color-add__item.is-active {
  border-color: #fff;
  outline-color: #053fe3;
}

.ctrl-color-add__icon-check {
  color: #fff;
  opacity: 0;
  -o-transition-property: opacity;
  -o-transition-duration: .125s;
  -o-transition-timing-function: ease;
  transition-property: opacity;
  transition-duration: .125s;
  transition-timing-function: ease;
}

.ctrl-color-add__actions {
  display: flex;
}

.ctrl-color-add__button {
  min-width: 100px;
}

.ctrl-color-add__button--save {
  margin-left: auto;
}

.form-default {
  background-color: #dedede;
  border-radius: 8px;
  padding: 4px;
}

.form-default__container {
  background-color: #fff;
  border-radius: 4px;
  padding: 16px;
  box-shadow: 18px 18px 24px rgba(0, 0, 0, .1);
}

.form-default__tabs {
  margin-bottom: 4px;
  padding: 0;
}

.form-default__tabs .ctrl-tabs__button {
  padding-left: 4px;
  padding-right: 4px;
  font-size: 12px;
}

.form-default__header {
  border-bottom: 1px solid #dedede;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 8px;
  display: flex;
}

.form-default__title {
  letter-spacing: -1px;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

.form-default__actions {
  flex-shrink: 0;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.form-default__actions-button {
  flex-shrink: 0;
  margin-left: 8px;
}

.form-default__actions-button:first-child {
  margin-left: 0;
}

.form-default__grid {
  flex-wrap: wrap;
  margin: -8px;
  display: flex;
}

.form-default__item {
  width: calc(50% - 16px);
  margin: 8px;
}

.form-default__item--full {
  width: calc(100% - 16px);
}

.form-default__item--grow {
  flex-grow: 1;
  width: 100px;
}

.form-default__item--text {
  white-space: nowrap;
  width: auto;
  margin-left: 0;
}

.form-default__item--indent {
  margin-top: 24px;
}

.form-default__lgroup {
  flex-grow: 1;
}

.form-default__lgroup .form-default__lgroup {
  margin-left: 44px;
  position: relative;
}

.form-default__lgroup .form-default__lgroup:before {
  box-sizing: border-box;
  content: "";
  border-bottom: 1px solid #dedede;
  border-left: 1px solid #dedede;
  border-bottom-left-radius: 4px;
  width: 8px;
  height: 40px;
  position: absolute;
  top: -10px;
  left: -10px;
}

.form-default__line {
  align-items: center;
  width: 100%;
  display: flex;
}

.form-default__line-part {
  flex-shrink: 0;
  margin: 8px;
}

.form-default__line-part--id {
  width: 70px;
  position: relative;
}

.form-default__line-part--id .loader__figure {
  transform: scale(.3);
}

.form-default__line-part--name {
  flex-grow: 1;
  flex-shrink: 1;
}

.form-default__line-part--name .ctrl-textfield {
  font-weight: 500;
}

.form-default__line-part--subcontractor {
  width: 160px;
}

.form-default__line-part--location {
  width: 130px;
}

.form-default__line-part--date {
  width: 115px;
}

.form-default__line-part--actions {
  width: 56px;
  display: flex;
}

.form-default__line-label {
  letter-spacing: -.5px;
  opacity: .5;
  font-size: 10px;
  line-height: 16px;
}

.form-default__line-id {
  white-space: nowrap;
  color: #8f8d8d;
  letter-spacing: -.5px;
  font-size: 14px;
  line-height: 20px;
}

.form-default__line-button-remove {
  color: #fa380d;
  margin-left: 8px;
}

.form-default__line-button-add {
  color: #8f8d8d;
  display: block;
}

.form-default__text {
  letter-spacing: -.5px;
  opacity: .5;
  padding-top: 21px;
  font-size: 16px;
  line-height: 44px;
}

.form-default__date-range {
  align-items: flex-end;
  display: flex;
  position: relative;
}

.form-default__date-range .form-default__item:nth-child(2) {
  position: relative;
}

.form-default__date-range .form-default__item:nth-child(2):before {
  content: "—";
  text-align: center;
  color: #dedede;
  width: 16px;
  font-size: 10px;
  line-height: 1.6;
  position: absolute;
  top: calc(50% - 8px);
  right: 100%;
}

.form-default__date-range-clear {
  position: absolute;
  top: 11px;
  right: 8px;
}

.form-default__phone {
  width: 50%;
  display: flex;
}

.form-default__phone .form-default__item:first-child {
  flex-shrink: 0;
  width: 80px;
  margin-right: 4px;
}

.form-default__phone .form-default__item:nth-child(2) {
  flex-grow: 1;
  width: auto;
  margin-left: 4px;
}

.form-default__button {
  width: 100%;
}

.import-csv {
  background-color: #f5f5f5;
  background-image: linear-gradient(193.05deg, #fff 0%, #f5f5f5 100%);
  border: 1px solid #fff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 24px 24px 36px rgba(0, 0, 0, .07);
}

.import-csv__icon {
  color: #053fe3;
  background-color: #f5f5f5;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  padding: 12px;
  display: block;
}

.import-csv__title {
  letter-spacing: -1px;
  text-align: center;
  margin-top: 7px;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.import-csv__dnd {
  margin-top: 16px;
}

.import-csv__actions {
  text-align: center;
  margin-top: 16px;
}

.import-csv__button-download {
  letter-spacing: -.5px;
  color: #053fe3;
  font-size: 12px;
  line-height: 18px;
  text-decoration: none;
}

.profile-tasks {
  padding-top: 12px;
}

.profile-tasks__grid {
  margin-top: 24px;
}

.profile-tasks__item {
  margin-top: 16px;
}

.profile-tasks__item:first-child {
  margin-top: 0;
}

.company-preview {
  background-color: #dedede;
  border-radius: 8px;
  padding: 4px;
}

.company-preview__container {
  background-color: #fff;
  border-radius: 4px;
  padding: 16px;
  box-shadow: 18px 18px 24px rgba(0, 0, 0, .1);
}

.company-preview {
  letter-spacing: -.5px;
  font-size: 14px;
  line-height: 20px;
}

.company-preview__name {
  letter-spacing: -1.5px;
  text-align: center;
  margin-top: 16px;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}

.company-preview__info {
  flex-wrap: wrap;
  justify-content: center;
  margin: 12px -12px -4px;
  display: flex;
}

.company-preview__info-item {
  align-items: center;
  min-width: 0;
  margin: 4px 12px;
  display: flex;
}

.company-preview__info-item--link {
  color: #053fe3;
  text-decoration: none;
}

.company-preview__info-icon {
  color: #053fe3;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-top: -2px;
  margin-right: 4px;
}

.company-preview__info-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.company-preview__description {
  border-top: 1px solid #f5f5f5;
  margin-top: 16px;
  padding: 16px;
}

.lava {
  letter-spacing: -.5px;
  flex-wrap: wrap;
  font-size: 12px;
  line-height: 18px;
  display: flex;
}

.lava__label {
  opacity: .5;
  margin-top: 2px;
  margin-bottom: 2px;
}

.lava__value {
  text-align: right;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: auto;
}

.lava__value--link {
  color: #053fe3;
  text-decoration: none;
}

html.no-touchevents .lava__value--link:hover {
  text-decoration: underline;
}

.lava__value--users {
  color: #01964e;
  align-items: center;
  display: flex;
}

.lava__value--new {
  color: #053fe3;
}

.lava__value--new .lava__icon {
  color: inherit;
}

.lava__note {
  letter-spacing: -.5px;
  opacity: .25;
  font-size: 10px;
  line-height: 16px;
  display: block;
}

.lava__icon {
  color: #dedede;
  width: 16px;
  height: 16px;
  margin-top: -2px;
  margin-right: 8px;
}

.lava__icon-old {
  color: #dedede;
  width: 16px;
  height: 16px;
  margin-top: 3px;
  margin-left: 8px;
  margin-right: 8px;
}

.lava__icon-old + .lava__value {
  margin-left: 0;
}

.lava-list__item {
  border-top: 1px solid #f5f5f5;
  padding-top: 14px;
  padding-bottom: 14px;
}

.lava-list__item:first-child {
  border-top-color: rgba(0, 0, 0, 0);
}

.plan-price {
  white-space: nowrap;
  border-collapse: collapse;
  width: 100%;
}

.plan-price__cell {
  padding: 0;
}

.plan-price__cell--th {
  text-align: left;
  font-weight: 500;
}

.plan-price__cell--action {
  text-align: center;
  width: 20px;
  font-weight: 500;
}

.plan-price__cell--contacts, .plan-price__cell:last-child {
  text-align: right;
}

.plan-price__link {
  color: inherit;
  text-decoration: underline;
}

html.no-touchevents .plan-price__link:hover {
  text-decoration: none;
}

.plan-price--view-tooltip {
  letter-spacing: -.5px;
  min-width: 241px;
  font-size: 12px;
  line-height: 18px;
}

.plan-price--view-tooltip .plan-price__icon {
  color: #dedede;
  width: 16px;
  height: 16px;
  margin-top: -2px;
  margin-right: 2px;
}

.plan-price--view-tooltip .plan-price__cell {
  padding-top: 12px;
}

.plan-price--view-tooltip .plan-price__cell--th {
  color: #dedede;
  padding-top: 0;
}

.plan-price--view-landing {
  letter-spacing: -.03125rem;
  font-size: .75rem;
  line-height: 1rem;
}

.plan-price--view-landing .plan-price__icon {
  color: #dedede;
  width: 1rem;
  height: 1rem;
  margin-top: -.125rem;
  margin-right: .125rem;
}

.plan-price--view-landing .plan-price__row.is-active .plan-price__cell {
  background-color: rgba(5, 63, 227, .3);
  position: relative;
}

.plan-price--view-landing .plan-price__row.is-active .plan-price__cell:first-child:before, .plan-price--view-landing .plan-price__row.is-active .plan-price__cell:last-child:before {
  content: "";
  background-color: inherit;
  width: 6px;
  height: 100%;
  position: absolute;
  top: 0;
}

.plan-price--view-landing .plan-price__row.is-active .plan-price__cell:first-child:before {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: -6px;
}

.plan-price--view-landing .plan-price__row.is-active .plan-price__cell:last-child:before {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  right: -6px;
}

.plan-price--view-landing .plan-price__cell {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.plan-price--view-landing .plan-price__cell--th {
  opacity: .5;
  padding-top: 0;
}

.plan-price--view-landing .plan-price__cell--action {
  color: #8f8d8d;
}

.plan-price--view-landing .plan-price__cell--summ {
  font-weight: 500;
}

.plan-price--view-landing .plan-price__link {
  color: #053fe3;
}

.plan-current {
  background-color: #f5f5f5;
  background-image: linear-gradient(193.05deg, #fff 0%, #f5f5f5 100%);
  border: 1px solid #fff;
  border-radius: 8px;
  padding: 24px 16px;
  box-shadow: 24px 24px 36px rgba(0, 0, 0, .07);
}

.plan-current__title {
  letter-spacing: -1px;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.plan-current__counter {
  letter-spacing: -.5px;
  color: #053fe3;
  font-size: 12px;
  line-height: 18px;
}

.plan-current__body {
  margin-top: 8px;
}

.plan-current__item {
  border-top: 1px solid #f5f5f5;
  padding-top: 14px;
  padding-bottom: 14px;
}

.plan-current__item:first-child {
  border-top-color: rgba(0, 0, 0, 0);
}

.plan-current__actions {
  margin-top: 10px;
}

.plan-current__button-continue {
  width: 100%;
  box-shadow: 18px 18px 24px rgba(0, 0, 0, .1);
}

.plan-current__more {
  text-align: center;
  margin-top: 16px;
  position: relative;
}

.plan-current__button-more {
  letter-spacing: -.5px;
  color: #053fe3;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  padding: 0;
  font-size: 12px;
  line-height: 18px;
  text-decoration: none;
}

html.no-touchevents .plan-current__button-more:hover {
  text-decoration: underline;
}

.plan-current__tooltip {
  letter-spacing: -.5px;
  text-align: left;
  color: #fff;
  background-color: rgba(30, 27, 27, .9);
  border-radius: 8px;
  padding: 16px 12px;
  font-size: 14px;
  line-height: 20px;
  position: absolute;
  bottom: calc(100% + 9px);
  left: -30px;
  right: -30px;
}

.plan-current__tooltip:before {
  content: "";
  border: 6px solid rgba(0, 0, 0, 0);
  border-top: 5px solid rgba(30, 27, 27, .9);
  border-bottom-width: 5px;
  position: absolute;
  top: 100%;
  left: calc(50% - 6px);
}

.plan-current__tooltip-actions {
  text-align: center;
  margin-top: 24px;
}

.plan-current__button-features {
  color: #fa380d;
  text-decoration: none;
}

html.no-touchevents .plan-current__button-features:hover {
  text-decoration: underline;
}

.license__header {
  border-bottom: 1px solid #dedede;
  padding-top: 11px;
  padding-bottom: 17px;
}

.license__title {
  letter-spacing: -1px;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.license__body {
  margin-top: 16px;
}

.teleport {
  text-align: right;
  letter-spacing: -.5px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 14px;
  line-height: 20px;
}

.teleport a {
  color: #053fe3;
  text-decoration: none;
}

html.no-touchevents .teleport a:hover {
  text-decoration: underline;
}

.chat-system {
  letter-spacing: -.5px;
  background-color: #fff;
  border-radius: 50px;
  justify-content: center;
  padding: 8px;
  font-size: 12px;
  line-height: 18px;
  display: flex;
}

.chat-system__name {
  color: #053fe3;
  font-weight: 500;
}

.chat-date {
  letter-spacing: -.5px;
  text-align: center;
  border: 1px solid #dedede;
  border-radius: 50px;
  padding: 7px;
  font-size: 12px;
  line-height: 18px;
}

.chat-message {
  align-items: flex-start;
  display: flex;
}

.chat-message__info {
  flex-shrink: 0;
  margin-right: 8px;
}

.chat-message__time {
  letter-spacing: -.5px;
  opacity: .5;
  text-align: center;
  margin-top: 3px;
  font-size: 10px;
  line-height: 16px;
}

.chat-message__content {
  background-color: #fff;
  border-radius: 4px;
  padding: 8px 16px;
  position: relative;
}

.chat-message__content:before {
  content: "";
  border: 8px solid rgba(0, 0, 0, 0);
  border-top-color: #fff;
  position: absolute;
  top: 0;
  left: -8px;
}

.chat-message__quote {
  border-left: 2px solid rgba(2, 63, 227, .5);
  margin: 0 0 4px;
  padding: 4px 0 2px 8px;
  display: inline-block;
}

.chat-message__quote-name {
  letter-spacing: -.4px;
  color: #01964e;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.chat-message__quote-text {
  color: #023fe3;
  opacity: .5;
}

.chat-message__quote-edited {
  letter-spacing: -.5px;
  opacity: .5;
  text-align: right;
  font-size: 12px;
  line-height: 18px;
}

.chat-message__name {
  letter-spacing: -.4px;
  color: #01964e;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.chat-message__text {
  letter-spacing: -.5px;
  font-size: 16px;
  line-height: 24px;
}

.chat-message__video-box {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  margin: -8px -16px 8px;
  padding-top: 57.2727%;
  position: relative;
  overflow: hidden;
}

.chat-message__video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.chat-message__image-box {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  margin: -8px -16px 8px;
  overflow: hidden;
}

.chat-message__image {
  width: 100%;
  max-width: 100%;
  height: auto;
  display: block;
}

.chat-message__edited {
  letter-spacing: -.5px;
  opacity: .5;
  text-align: right;
  font-size: 12px;
  line-height: 18px;
}

.chat-message--media .chat-message__content {
  flex-grow: 1;
}

.chat-message--media .chat-message__content:before {
  content: none;
}

.chat-message--deleted .chat-message__text {
  opacity: .5;
}

.chat-message--sent {
  flex-direction: row-reverse;
}

.chat-message--sent .chat-message__info {
  margin-left: 8px;
  margin-right: 0;
}

.chat-message--sent .chat-message__content:before {
  left: auto;
  right: -8px;
}

.chat-new {
  letter-spacing: -.4px;
  color: #053fe3;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  padding-bottom: 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.chat {
  filter: drop-shadow(24px 24px 36px rgba(0, 0, 0, .07));
  background-color: #f5f5f5;
  border: 1px solid #fff;
  border-radius: 8px;
  padding: 16px;
}

.chat > * {
  margin-top: 16px;
  margin-bottom: 16px;
}

.chat > :first-child {
  margin-top: 0;
}

.chat > :last-child {
  margin-bottom: 0;
}

.chat .chat-new {
  margin-top: 24px;
  margin-bottom: 24px;
}

.ctrl-check-worker__field {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.ctrl-check-worker__field:checked ~ .ctrl-check-worker__icon {
  --w-check-color: #01964e;
  --w-check-bgcolor: #e6f4ed;
}

.ctrl-check-worker__icon {
  border: 1px solid #bebebe;
  border-color: var(--w-check-color, #bebebe);
  color: var(--w-check-color, #bebebe);
  width: 16px;
  height: 16px;
  background-color: var(--w-check-bgcolor);
  -o-transition-property: color, border-color, background-color;
  -o-transition-duration: .125s;
  -o-transition-timing-function: ease;
  border-radius: 50%;
  padding: 5px;
  transition-property: color, border-color, background-color;
  transition-duration: .125s;
  transition-timing-function: ease;
  display: block;
}

.ctrl-check-worker__label {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.worker-accepted__info {
  align-items: flex-start;
  display: flex;
}

.worker-accepted__info-part--avatar {
  flex-shrink: 0;
  margin-right: 12px;
}

.worker-accepted__info-part--text {
  flex-grow: 1;
}

.worker-accepted__info-part--check {
  flex-shrink: 0;
  margin-left: 8px;
}

.worker-accepted__name {
  letter-spacing: -.4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  display: flex;
}

.worker-accepted__icon-status {
  color: #dedede;
  flex-shrink: 0;
  margin-left: 4px;
}

.worker-accepted__icon-status--success path:first-child {
  fill: #01964e;
}

.worker-accepted__icon-status--warning path:nth-child(-n+2) {
  fill: #f90;
}

.worker-accepted__icon-status--error {
  color: #fa380d;
}

.worker-accepted__trade {
  letter-spacing: -.5px;
  opacity: .5;
  font-size: 10px;
  line-height: 16px;
}

.worker-accepted__status {
  letter-spacing: -.5px;
  font-size: 10px;
  line-height: 16px;
}

.worker-accepted__companies {
  letter-spacing: -.5px;
  margin-top: 8px;
  margin-left: 48px;
  font-size: 12px;
  line-height: 18px;
}

.worker-accepted__companies .te-orgs {
  flex-wrap: wrap;
  margin: -2px;
  display: flex;
}

.worker-accepted__companies .te-orgs__item {
  background-color: #fff;
  flex-shrink: 0;
  margin: 2px;
}

.worker-accepted__tasks-info {
  letter-spacing: -.5px;
  margin-left: 48px;
  font-size: 12px;
  line-height: 18px;
}

.worker-accepted__tasks-info .report-qty-tasks__line-item {
  border-color: #f5f5f5;
}

.assignees-list__header {
  border-bottom: 1px solid #dedede;
  padding-bottom: 8px;
  display: flex;
}

.assignees-list__title {
  letter-spacing: -.5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.assignees-list__actions {
  flex-shrink: 0;
  margin-left: auto;
}

.assignees-list__body {
  margin-top: 24px;
}

.assignees-list__item {
  border-top: 1px solid #dedede;
  padding-top: 16px;
  padding-bottom: 16px;
}

.assignees-list__item:first-child {
  border-top-width: 0;
  padding-top: 0;
}

.ctrl-btn-download {
  vertical-align: middle;
  display: inline-block;
}

.ctrl-btn-download__text {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.ctrl-btn-download__image {
  width: 100%;
  max-width: 100%;
  height: auto;
  display: block;
}

.download-app {
  -webkit-backdrop-filter: blur(14px);
  backdrop-filter: blur(14px);
  background: rgba(245, 245, 245, .9);
  border: 1px solid #fff;
  border-radius: 16px;
  padding: 16px;
  box-shadow: 24px 24px 36px rgba(0, 0, 0, .07);
}

.download-app__title {
  letter-spacing: -.5px;
  text-align: center;
  margin-bottom: 2px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.download-app__grid {
  margin: -6px;
  display: flex;
}

.download-app__link {
  flex: 50%;
  max-width: 50%;
  margin: 6px;
}

.company-finish {
  text-align: center;
}

.company-finish__title {
  letter-spacing: -1.5px;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}

.company-finish__description {
  margin-top: 24px;
}

.company-finish__roles {
  text-align: left;
  flex-direction: column;
  min-width: 320px;
  margin-top: 24px;
  margin-bottom: 60px;
  display: inline-flex;
}

.company-finish__roles-item {
  border-top: 1px solid #f5f5f5;
  flex-grow: 1;
  flex-shrink: 0;
  margin-top: 12px;
  padding-top: 12px;
  display: flex;
}

.company-finish__roles-item:first-child {
  border-top-width: 0;
  margin-top: 0;
  padding-top: 0;
}

.company-finish__roles-company {
  margin-right: 20px;
}

.company-finish__roles-role {
  text-align: right;
  white-space: nowrap;
  flex-grow: 1;
}

.company-finish__download {
  box-sizing: border-box;
  max-width: 320px;
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
}

.company-finish__actions {
  margin-top: 24px;
}

.company-finish__actions-divider {
  margin-top: 12px;
  margin-bottom: 12px;
}

.company-finish__button-logout {
  letter-spacing: -.5px;
  font-size: 16px;
  line-height: 24px;
}

.slide-panel {
  z-index: 42001;
  background: rgba(30, 27, 27, .9);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.slide-panel__item {
  -o-transition-property: width;
  -o-transition-duration: .125s;
  -o-transition-timing-function: ease;
  background-color: #fff;
  width: 856px;
  height: 100%;
  margin-left: auto;
  transition-property: width;
  transition-duration: .125s;
  transition-timing-function: ease;
  display: flex;
  position: relative;
}

.slide-panel__content {
  scrollbar-width: none;
  overflow-x: hidden;
  overflow-y: auto;
}

.slide-panel__content::-webkit-scrollbar {
  display: none;
}

.slide-panel__content--main {
  flex-grow: 1;
  padding: 24px 36px;
}

.slide-panel__content--aside {
  box-sizing: border-box;
  -o-transition-property: width;
  -o-transition-duration: .125s;
  -o-transition-timing-function: ease;
  background-color: #f5f5f5;
  flex-direction: column;
  flex-shrink: 0;
  width: 287px;
  padding: 24px;
  transition-property: width;
  transition-duration: .125s;
  transition-timing-function: ease;
  display: flex;
}

.slide-panel__header {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 24px;
  display: flex;
}

.slide-panel__title {
  letter-spacing: -1px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.slide-panel__button-second {
  margin-left: auto;
}

.slide-panel__button-second + .slide-panel__button-main {
  margin-left: 16px;
}

.slide-panel__button-main {
  min-width: 110px;
  margin-left: auto;
}

.slide-panel__nav {
  width: 100%;
  margin-top: 8px;
}

.slide-panel__body > *, .slide-panel__tab > * {
  margin-bottom: 24px;
}

.slide-panel__body > :last-child, .slide-panel__tab > :last-child {
  margin-bottom: 0;
}

.slide-panel__card {
  margin-bottom: 16px;
}

.slide-panel__history {
  margin-top: auto;
}

.slide-panel__assignees {
  margin-top: 30px;
}

.slide-panel__button-close {
  color: #fff;
  opacity: .5;
  position: absolute;
  top: 36px;
  right: calc(100% + 36px);
}

.slide-panel--size-m .slide-panel__item {
  width: 1000px;
}

.slide-panel--size-m .slide-panel__content--aside {
  width: 360px;
}

.slide-panel--size-xs .slide-panel__item {
  width: 440px;
}

.task-info {
  box-sizing: border-box;
  letter-spacing: -.5px;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  outline: none;
  align-items: flex-start;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 12px;
  line-height: 18px;
  display: flex;
}

.task-info__icon {
  opacity: .5;
  color: #053fe3;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.task-info__id {
  white-space: nowrap;
  opacity: .5;
  margin-right: 8px;
}

.task-info__name {
  text-align: left;
  flex-grow: 1;
  margin-right: 8px;
  font-weight: 500;
}

.task-info__status {
  background-color: #f5f5f5;
  border-radius: 8px;
  flex-shrink: 0;
  width: 115px;
  margin-left: auto;
  padding: 4px 8px;
}

.task-info__dependency {
  white-space: nowrap;
  text-align: right;
  flex-shrink: 0;
}

.task-info__dependency-status {
  font-weight: 500;
}

.task-info__dependency-time {
  opacity: .6;
}

.task-info__button-edit {
  color: #817f7f;
  margin-top: 5px;
  margin-left: 8px;
}

.task-info__button-edit .ctrl-btn-clear__icon {
  width: 16px;
  height: 16px;
}

.task-info--archived {
  opacity: .5;
}

.ctrl-droplist {
  position: relative;
}

.ctrl-droplist__toggle {
  color: #817f7f;
  width: 16px;
  height: 16px;
  display: block;
}

.ctrl-droplist__toggle .ctrl-btn-clear__icon {
  width: 100%;
  height: 100%;
}

.ctrl-droplist__content {
  z-index: 100;
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  list-style-type: none;
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  box-shadow: 18px 18px 24px rgba(0, 0, 0, .1);
}

.ctrl-droplist__content-button {
  -o-transition-property: background-color;
  -o-transition-duration: .125s;
  -o-transition-timing-function: ease;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  align-items: center;
  width: 100%;
  min-width: 140px;
  padding: 8px 16px;
  transition-property: background-color;
  transition-duration: .125s;
  transition-timing-function: ease;
  display: flex;
}

.ctrl-droplist__content-button--delete {
  --icon-color: #fa380d;
}

html.no-touchevents .ctrl-droplist__content-button:hover {
  background-color: rgba(222, 222, 222, .3);
}

.ctrl-droplist__content-text {
  letter-spacing: -.5px;
  white-space: nowrap;
  margin-right: auto;
  font-size: 12px;
  line-height: 18px;
}

.ctrl-droplist__content-icon {
  width: 16px;
  height: 16px;
  color: var(--icon-color);
  margin-left: 8px;
}

.ctrl-inline-field {
  align-items: flex-start;
  display: inline-flex;
}

.ctrl-inline-field__button {
  color: #235dff;
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  margin-left: -1px;
}

.ctrl-inline-field__button .ctrl-btn-clear__icon {
  width: 100%;
  height: 100%;
}

.ctrl-inline-field__button--clear {
  color: #817f7f;
  margin-left: 7px;
}

.ctrl-inline-field__field-box {
  flex-grow: 1;
  margin-left: 7px;
}

.ctrl-inline-field__field {
  letter-spacing: -.5px;
  resize: none;
  border-width: 0;
  outline: none;
  width: 100%;
  height: 18px;
  padding: 0;
  font-size: 12px;
  line-height: 18px;
  display: block;
}

.ctrl-inline-field__field::-webkit-input-placeholder {
  color: #dedede;
}

.ctrl-inline-field__field::placeholder {
  color: #dedede;
}

.ctrl-inline-field__field:-ms-input-placeholder {
  color: #dedede;
}

.ctrl-inline-field__field::placeholder {
  color: #dedede;
}

.checklist__list {
  list-style-type: none;
}

.checklist__item {
  margin-top: 14px;
  display: flex;
}

.checklist__item:first-child {
  margin-top: 0;
}

.checklist__item-check {
  margin-right: auto;
}

.checklist__item-actions {
  flex-shrink: 0;
  margin-left: 10px;
}

.checklist__actions {
  margin-top: 14px;
}

.checklist .ctrl-inline-field {
  width: 100%;
  display: flex;
}

.task-bind {
  background-color: #dedede;
  border-radius: 8px;
  padding: 4px;
}

.task-bind__container {
  background-color: #fff;
  border-radius: 4px;
  padding: 16px;
  box-shadow: 18px 18px 24px rgba(0, 0, 0, .1);
}

.task-bind__header {
  border-bottom: 1px solid #dedede;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 8px;
  display: flex;
}

.task-bind__title {
  letter-spacing: -1px;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

.task-bind__title-number {
  letter-spacing: -.5px;
  vertical-align: text-bottom;
  margin-left: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.task-bind__actions {
  margin-left: auto;
}

.task-bind__button {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #053fe3;
  border-radius: 44px;
  padding: 5px 16px 5px 10px;
}

.task-bind__list {
  margin-top: -8px;
  margin-bottom: -16px;
  list-style-type: none;
}

.task-bind__item {
  border-bottom: 1px solid #f5f5f5;
}

.task-bind__item:last-child {
  border-bottom-width: 0;
}

.task-bind__button .z-ctrl-btn__icon {
  width: 12px;
  height: 12px;
  margin-right: 2px;
}

.task-bind .form-default {
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
}

.task-bind .form-default__container {
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0;
}

.task-bind .form-default__header {
  border-bottom-width: 0;
  padding-bottom: 0;
}

.task-bind .form-default__title {
  letter-spacing: -.5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.task-bind__list + .form-default {
  border-top: 1px solid #f5f5f5;
  margin-top: 16px;
  padding-top: 16px;
}

.task-bind__clear {
  text-align: center;
  opacity: .6;
  letter-spacing: -.5px;
  padding: 8px;
  font-size: 14px;
  line-height: 20px;
}

.aka-table--import .aka-table__cell {
  box-sizing: content-box;
  flex-grow: 0;
  flex-shrink: 0;
}

.aka-table--import .aka-table__cell:first-child {
  padding-left: 36px;
}

.aka-table--import .aka-table__cell:last-child {
  padding-right: 36px;
}

.aka-table--import .aka-table__cell--id, .aka-table--import .aka-table__cell--cost, .aka-table--import .aka-table__cell--start, .aka-table--import .aka-table__cell--due, .aka-table--import .aka-table__cell--wbs, .aka-table--import .aka-table__cell--type {
  width: 100px;
}

.aka-table--import .aka-table__cell--sub, .aka-table--import .aka-table__cell--location, .aka-table--import .aka-table__cell--description, .aka-table--import .aka-table__cell--assigned {
  width: 150px;
}

.aka-table--import .aka-table__cell--name {
  width: 350px;
}

.video {
  padding-top: 56.4286%;
  position: relative;
}

.video__frame {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.tabs-nav {
  display: flex;
}

.tabs-nav__button {
  letter-spacing: -.5px;
  color: #1e1b1b;
  opacity: .5;
  -o-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: .125;
  -o-transition-duration: .125;
  transition-duration: .125;
  -o-transition-timing-function: ease-in-out;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  flex-grow: 1;
  flex-basis: 10%;
  padding: 19px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  transition-timing-function: ease-in-out;
}

.tabs-nav__button.is-active, .tabs-nav__button.is-completed {
  opacity: 1;
}

.message-complete {
  text-align: center;
  max-width: 520px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 80px;
  padding-bottom: 80px;
}

.message-complete__title {
  letter-spacing: -1.5px;
  font-size: 36px;
  font-weight: 400;
  line-height: 48px;
}

.message-complete__description {
  letter-spacing: -1px;
  font-size: 18px;
  line-height: 28px;
}

.message-complete__image {
  margin-top: 24px;
  display: block;
}

.message-complete__total {
  margin-top: 16px;
}

.message-complete__actions {
  justify-content: center;
  align-items: center;
  margin-top: 36px;
  display: flex;
}

.message-complete__button-box {
  margin-left: 24px;
  position: relative;
}

.message-complete__button-box .drop-panel--from-parent {
  top: auto;
  bottom: 100%;
}

.message-complete__button-box:first-child {
  margin-left: 0;
}

.message-complete__button {
  min-width: 180px;
}

.ctrl-file {
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 18px;
  justify-content: space-between;
  align-items: center;
  min-width: 160px;
  padding: 6px 10px 6px 16px;
  display: flex;
  box-shadow: 18px 18px 24px rgba(0, 0, 0, .1);
}

.ctrl-file__name {
  letter-spacing: -.5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
}

.ctrl-file__button-remove {
  color: #fa380d;
  margin-left: 8px;
}

.ctrl-upload-csv {
  box-sizing: border-box;
  color: #053fe3;
  background-color: #fff;
  border: 1px dashed #053fe3;
  border-radius: 8px;
  align-items: center;
  min-height: 78px;
  padding: 20px 22px;
  display: flex;
  box-shadow: 18px 18px 24px rgba(0, 0, 0, .1);
}

.ctrl-upload-csv__text {
  letter-spacing: -1px;
  flex-shrink: 0;
  margin-right: auto;
  font-size: 18px;
  line-height: 28px;
}

.ctrl-upload-csv__actions {
  display: flex;
}

.ctrl-upload-csv__button {
  flex-direction: row-reverse;
  width: 120px;
  margin-left: 8px;
}

.ctrl-upload-csv__button .z-ctrl-btn__icon {
  margin-left: auto;
  margin-right: 0;
}

.ctrl-upload-csv__button:first-child {
  margin-left: 0;
}

.ctrl-upload-csv__button-back {
  color: #053fe3;
  margin-left: -8px;
  margin-right: 8px;
}

.ctrl-upload-csv__button-back .ctrl-btn-clear__icon {
  width: 16px;
  height: 16px;
}

.ctrl-upload-csv__file {
  margin-left: 24px;
}

.ctrl-upload-csv__form {
  flex-grow: 1;
  align-items: center;
  margin-top: -4px;
  margin-bottom: -4px;
  margin-left: 24px;
  display: flex;
}

.ctrl-upload-csv__form-field {
  flex-grow: 1;
}

.ctrl-upload-csv__form-button {
  flex-shrink: 0;
  min-width: 90px;
  margin-left: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.ctrl-upload-csv--dnd {
  color: #fff;
  background-color: #053fe3;
  border-color: #fff;
  justify-content: center;
}

.ctrl-upload-csv--dnd .ctrl-upload-csv__text {
  margin-left: 24px;
  margin-right: 0;
}

.ctrl-upload-csv--file, .ctrl-upload-csv--link {
  color: #1e1b1b;
}

.ctrl-import-procore {
  box-sizing: border-box;
  background-color: #fff;
  border: 1px dashed #053fe3;
  border-radius: 8px;
  min-height: 78px;
  padding: 20px 22px;
  box-shadow: 18px 18px 24px rgba(0, 0, 0, .1);
}

.ctrl-import-procore__logo {
  width: 200px;
  display: block;
}

.ctrl-import-procore__description, .ctrl-import-procore__button {
  margin-top: 16px;
}

.form-compare {
  max-width: 620px;
  margin-left: auto;
  margin-right: auto;
}

.form-compare__title {
  letter-spacing: -1.5px;
  font-size: 36px;
  font-weight: 400;
  line-height: 48px;
}

.form-compare__upload {
  margin-top: 8px;
}

.form-compare__default {
  margin: 16px -8px -8px;
  display: flex;
}

.form-compare__body {
  border-top: 1px solid #dedede;
  margin-top: 24px;
  padding-top: 24px;
}

.form-compare__control {
  align-items: center;
  margin-top: 24px;
  display: flex;
}

.form-compare__control:first-child {
  margin-top: 0;
}

.form-compare__item--default {
  flex-grow: 1;
  flex-basis: 10%;
  margin: 8px;
}

.form-compare__item--compare {
  flex-shrink: 0;
  width: 256px;
}

.form-compare__name {
  letter-spacing: -1px;
  flex-grow: 1;
  font-size: 18px;
  line-height: 28px;
}

.form-compare__name span {
  color: #fa380d;
}

.form-compare__icon-compare {
  flex-shrink: 0;
  margin-right: 80px;
}

.form-compare__divider {
  opacity: .25;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
}

.form-compare__divider:before, .form-compare__divider:after {
  content: "";
  background-color: currentColor;
  flex-shrink: 1;
  flex-basis: 50%;
  height: 1px;
}

.form-compare__divider:before {
  margin-right: 1em;
}

.form-compare__divider:after {
  margin-left: 1em;
}

.compare-preview__title {
  letter-spacing: -1.5px;
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
}

.compare-preview__description {
  letter-spacing: -.5px;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
}

.compare-preview__card {
  margin-top: 26px;
  margin-bottom: 26px;
}

.compare-grid {
  height: 100%;
  display: flex;
  overflow: hidden;
}

.compare-grid > * {
  padding: 36px;
  overflow-x: hidden;
  overflow-y: auto;
}

.compare-grid__content {
  flex-grow: 1;
}

.compare-grid__aside {
  box-sizing: border-box;
  background-color: #f5f5f5;
  flex-direction: column;
  width: 337px;
  display: flex;
}

.compare-grid__button-help {
  border-radius: 18px;
  flex-direction: row-reverse;
  width: 100%;
  margin-top: auto;
  padding-top: 5px;
  padding-bottom: 5px;
}

.compare-grid__button-help .ctrl-btn__icon {
  margin-left: auto;
  margin-right: 0;
}

.progressbar {
  box-sizing: border-box;
  background: #fff;
  border-radius: 14px;
  padding: 10px;
  box-shadow: 18px 18px 24px rgba(0, 0, 0, .1);
}

.progressbar__container {
  background: #f5f5f5;
  border-radius: 4px;
  display: block;
  overflow: hidden;
}

.progressbar__line {
  background-color: #01964e;
  height: 8px;
  display: block;
}

.legend-compare {
  background: #f5f5f5;
  border-radius: 8px;
  justify-content: space-around;
  align-items: center;
  padding: 8px 16px;
  display: flex;
}

.legend-compare__item {
  letter-spacing: -.5px;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  display: flex;
}

.legend-compare__item--new {
  color: #01964e;
}

.legend-compare__item--new .legend-compare__icon {
  box-sizing: border-box;
  color: #fff;
  background-color: #01964e;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  padding: 1px;
}

.legend-compare__item--error {
  color: #fa380d;
}

.legend-compare__item--edit {
  color: #053fe3;
}

.legend-compare__item--edit .legend-compare__icon {
  box-sizing: border-box;
  color: #fff;
  background-color: #053fe3;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  padding: 1px;
}

.legend-compare__icon {
  width: 16px;
  height: 16px;
  margin-top: -1px;
  margin-right: 8px;
}

.card-company {
  background-color: #dedede;
  border-radius: 8px;
  padding: 4px;
}

.card-company__container {
  background-color: #fff;
  border-radius: 4px;
  align-items: center;
  padding: 16px;
  display: flex;
  box-shadow: 18px 18px 24px rgba(0, 0, 0, .1);
}

.card-company__avatar {
  box-sizing: border-box;
  border: 1px solid #dedede;
  flex-shrink: 0;
  width: 72px;
  height: 72px;
  margin-right: 16px;
}

.card-company__title {
  letter-spacing: -1.5px;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}

.card-company__workers {
  letter-spacing: -.5px;
  opacity: .5;
  font-size: 14px;
  line-height: 20px;
}

.card-company-ultra {
  background-color: #dedede;
  border-radius: 8px;
  padding: 4px;
}

.card-company-ultra__container {
  background-color: #fff;
  border-radius: 4px;
  padding: 16px;
  box-shadow: 18px 18px 24px rgba(0, 0, 0, .1);
}

.card-company-ultra__container--company {
  background-image: linear-gradient(193.05deg, #fff 0%, #f5f5f5 100%);
  border: 1px solid #fff;
}

.card-company-ultra__container--info {
  margin-top: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.card-company-ultra__avatar {
  box-sizing: border-box;
  width: 72px;
  height: 72px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 18px 18px 24px rgba(0, 0, 0, .1);
}

.card-company-ultra__title {
  letter-spacing: -1px;
  text-align: center;
  margin-top: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

.card-company-ultra__info {
  letter-spacing: -.5px;
  justify-content: center;
  margin-top: 8px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
}

.card-company-ultra__info-item {
  align-items: center;
  margin-left: 12px;
  margin-right: 12px;
  display: flex;
}

.card-company-ultra__info-icon {
  color: #dedede;
  width: 16px;
  height: 16px;
  margin-top: -2px;
  margin-right: 8px;
}

.card-company-ultra__info-icon--verified {
  color: #053fe3;
}

.card-company-ultra__actions {
  justify-content: center;
  margin-top: 16px;
  display: flex;
}

.card-company-ultra__button {
  letter-spacing: -.4px;
  min-width: 168px;
  margin-left: 8px;
  margin-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.card-company-ultra__button .ctrl-btn__text {
  padding-top: 1px;
}

.plan-select__item {
  border-top: 1px solid #f5f5f5;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
}

.plan-select__item:first-child {
  border-top-width: 0;
}

.plan-select__icon {
  color: #dedede;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-top: 4px;
  margin-right: 8px;
}

.plan-select__info {
  margin-right: auto;
}

.plan-select__small {
  font-weight: 300;
  font-size: inherit;
  color: #dedede;
}

.plan-select__label {
  letter-spacing: -.5px;
  margin-top: 3px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.plan-select__note {
  letter-spacing: -.5px;
  opacity: .5;
  font-size: 10px;
  line-height: 16px;
}

.plan-select__field {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.plan-select__field:checked ~ .plan-select__visual {
  background-color: #053fe3;
}

.plan-select__field:checked ~ .plan-select__visual:before {
  opacity: 1;
  background-color: #fff;
}

.plan-select__field:disabled ~ .plan-select__visual {
  border-color: #dedede;
}

.plan-select__field:disabled ~ .plan-select__visual:before {
  opacity: 1;
  background-color: #fff;
}

.plan-select__value {
  letter-spacing: -.5px;
  margin-top: 3px;
  font-size: 14px;
  line-height: 20px;
}

.plan-select__value--link {
  color: #053fe3;
  text-decoration: none;
}

html.no-touchevents .plan-select__value--link:hover {
  text-decoration: underline;
}

.plan-select__visual {
  box-sizing: border-box;
  -o-transition-property: background-color;
  -o-transition-duration: .125s;
  -o-transition-timing-function: ease;
  background-color: #f5f5f5;
  border: 1px solid #053fe3;
  border-radius: 50%;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-left: 8px;
  transition-property: background-color;
  transition-duration: .125s;
  transition-timing-function: ease;
  position: relative;
}

.plan-select__visual:before {
  content: "";
  opacity: .25;
  -o-transition-property: background-color, opacity;
  width: 8px;
  height: 8px;
  transition-property: background-color, opacity;
  -webkit-transition-duration: inherit;
  -o-transition-duration: inherit;
  transition-duration: inherit;
  -webkit-transition-timing-function: inherit;
  -o-transition-timing-function: inherit;
  transition-timing-function: inherit;
  background-color: #053fe3;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.plan-info {
  background-color: #dedede;
  border-radius: 8px;
  padding: 4px;
}

.plan-info__container {
  background-color: #fff;
  border-radius: 4px;
  padding: 16px 24px 10px;
  box-shadow: 18px 18px 24px rgba(0, 0, 0, .1);
}

.plan-info__icon {
  width: 60px;
  height: 60px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.plan-info__title {
  letter-spacing: -1px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

.plan-calc__title {
  letter-spacing: -.0625rem;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.2;
}

.plan-calc__form {
  justify-content: space-between;
  display: flex;
}

.plan-calc__form-item {
  width: 160px;
  margin-right: 20px;
}

.plan-calc__form-result {
  letter-spacing: -.0625em;
  text-align: center;
  flex-grow: 1;
  margin-top: 29px;
  font-size: 16px;
}

.plan-calc__form-result small {
  opacity: .5;
  font-size: .75em;
  display: block;
}

.plan-table {
  border-collapse: collapse;
  letter-spacing: -.5px;
  white-space: nowrap;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
}

.plan-table__row.is-active .plan-table__cell {
  background-color: rgba(5, 63, 227, .3);
  border-top-color: rgba(0, 0, 0, 0);
  position: relative;
}

.plan-table__row.is-active .plan-table__cell:first-child:before, .plan-table__row.is-active .plan-table__cell:last-child:before {
  content: "";
  background-color: inherit;
  width: 20px;
  height: 100%;
  position: absolute;
  top: 0;
}

.plan-table__row.is-active .plan-table__cell:first-child:before {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: -20px;
}

.plan-table__row.is-active .plan-table__cell:last-child:before {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  right: -20px;
}

.plan-table__cell {
  vertical-align: top;
  border-top: 1px solid #f5f5f5;
  padding: 12px 0;
}

.plan-table__cell--th {
  text-align: left;
  opacity: .5;
  border-top-width: 0;
  font-weight: 500;
}

.plan-table__cell--th:nth-child(2) {
  position: relative;
}

.plan-table__cell--th:nth-child(2):after {
  content: "+";
  position: absolute;
  right: 10px;
}

.plan-table__cell--money, .plan-table__cell--contacts, .plan-table__cell--actions {
  padding-left: 20px;
}

.plan-table__cell--contacts {
  text-align: center;
}

.plan-table__main {
  margin-top: 2px;
  display: flex;
}

.plan-table__main-icon {
  color: #dedede;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-top: 1px;
  margin-right: 8px;
}

.plan-table__main-info {
  flex-grow: 1;
}

.plan-table__main-qty {
  font-weight: 500;
  display: block;
}

.plan-table__main-addition {
  letter-spacing: -.5px;
  opacity: .5;
  font-size: 10px;
  line-height: 16px;
  display: block;
}

.plan-table__money {
  margin-top: 2px;
  display: inline-block;
}

.plan-table__link {
  color: #053fe3;
  text-decoration: none;
}

html.no-touchevents .plan-table__link:hover {
  text-decoration: underline;
}

.plan-table__ctrl {
  position: relative;
}

.plan-table__ctrl-field {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.plan-table__ctrl-field:checked ~ .plan-table__ctrl-visual {
  background-color: #053fe3;
}

.plan-table__ctrl-field:checked ~ .plan-table__ctrl-visual:before {
  opacity: 1;
  background-color: #fff;
}

.plan-table__ctrl-field:disabled ~ .plan-table__ctrl-visual {
  border-color: #dedede;
}

.plan-table__ctrl-field:disabled ~ .plan-table__ctrl-visual:before {
  opacity: 1;
  background-color: #fff;
}

.plan-table__ctrl-visual {
  box-sizing: border-box;
  -o-transition-property: background-color;
  -o-transition-duration: .125s;
  -o-transition-timing-function: ease;
  background-color: #f5f5f5;
  border: 1px solid #053fe3;
  border-radius: 50%;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  transition-property: background-color;
  transition-duration: .125s;
  transition-timing-function: ease;
  position: relative;
}

.plan-table__ctrl-visual:before {
  content: "";
  opacity: .25;
  -o-transition-property: background-color, opacity;
  width: 8px;
  height: 8px;
  transition-property: background-color, opacity;
  -webkit-transition-duration: inherit;
  -o-transition-duration: inherit;
  transition-duration: inherit;
  -webkit-transition-timing-function: inherit;
  -o-transition-timing-function: inherit;
  transition-timing-function: inherit;
  background-color: #053fe3;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.form-plan {
  width: 516px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 24px;
  padding-bottom: 60px;
}

.form-plan__title {
  letter-spacing: -1.5px;
  text-align: center;
  font-size: 36px;
  font-weight: 400;
  line-height: 48px;
}

.form-plan__description {
  letter-spacing: -1px;
  opacity: .5;
  text-align: center;
  font-size: 18px;
  line-height: 28px;
}

.form-plan__card-info, .form-plan__plans, .form-plan__calc, .form-plan__summary {
  margin-top: 16px;
}

.form-plan__calc .plan-calc__title {
  letter-spacing: -1.5px;
  font-size: 24px;
  font-weight: 400;
}

.form-plan__actions {
  text-align: center;
  margin-top: 24px;
}

.form-plan__actions.is-active {
  z-index: 42;
  position: relative;
}

.form-plan__actions.is-active:before {
  content: "";
  background-color: rgba(30, 27, 27, .9);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.form-plan__actions.is-active .form-plan__button-cancel, .form-plan__actions.is-active .form-plan__tooltip {
  position: relative;
}

.form-plan__actions.is-active .form-plan__tooltip {
  width: 420px;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, -20px);
}

.form-plan__button-cancel {
  letter-spacing: -.5px;
  color: rgba(29, 27, 27, .5);
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 8px;
  padding: 5px 15px;
  font-size: 12px;
  line-height: 18px;
}

.form-plan__tooltip {
  background: #fff;
  border-radius: 16px;
  padding: 24px 24px 16px;
}

.form-plan__tooltip:before {
  content: "";
  border: 6px solid rgba(0, 0, 0, 0);
  border-top: 5px solid #fff;
  border-bottom-width: 5px;
  position: absolute;
  top: 100%;
  left: calc(50% - 6px);
}

.form-plan__tooltip-description {
  letter-spacing: -.5px;
  opacity: .75;
  font-size: 14px;
  line-height: 20px;
}

.form-plan__tooltip-description a {
  color: #053fe3;
  text-decoration: none;
}

html.no-touchevents .form-plan__tooltip-description a:hover {
  text-decoration: underline;
}

.form-plan__tooltip-actions {
  border-top: 1px solid #f5f5f5;
  margin-top: 16px;
  padding-top: 16px;
}

.form-plan__tooltip-button {
  width: 136px;
  margin-left: 8px;
  margin-right: 8px;
}

.form-plan__payment {
  min-height: 534px;
  display: flex;
}

.form-plan__logo-stripe {
  flex-shrink: 0;
  width: 284px;
  height: 135px;
  margin: auto;
  display: block;
}

.form-plan--small {
  padding-bottom: 24px;
}

.form-plan--only {
  padding-top: 60px;
}

.form-plan--payment {
  padding-top: 0;
  padding-bottom: 0;
}

.plan-select-ultra {
  background-color: #f5f5f5;
  padding: 12px;
  display: flex;
}

.plan-select-ultra__item {
  box-sizing: border-box;
  height: 148px;
  color: inherit;
  background-color: #fff;
  border-radius: 4px;
  flex-direction: column;
  flex: 1 0 100px;
  margin: 4px;
  padding: 16px;
  text-decoration: none;
  display: flex;
  position: relative;
  box-shadow: 18px 18px 24px rgba(0, 0, 0, .1);
}

.plan-select-ultra__header {
  align-items: center;
  display: flex;
}

.plan-select-ultra__icon {
  color: #dedede;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-top: -1px;
  margin-right: 8px;
}

.plan-select-ultra__label {
  letter-spacing: -.4px;
  margin-right: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.plan-select-ultra__value {
  letter-spacing: -.5px;
  margin-left: auto;
  font-size: 12px;
  line-height: 18px;
}

.plan-select-ultra__body {
  align-items: center;
  margin-top: auto;
  display: flex;
}

.plan-select-ultra__upgrade {
  letter-spacing: -.4px;
  color: #053fe3;
  margin-top: auto;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.plan-select-ultra__note {
  letter-spacing: -.5px;
  opacity: .5;
  font-size: 10px;
  line-height: 16px;
}

.plan-select-ultra__field {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.plan-select-ultra__field:checked ~ .plan-select-ultra__visual {
  background-color: #053fe3;
}

.plan-select-ultra__field:checked ~ .plan-select-ultra__visual:before {
  opacity: 1;
  background-color: #fff;
}

.plan-select-ultra__field:disabled ~ .plan-select-ultra__visual {
  border-color: #dedede;
}

.plan-select-ultra__field:disabled ~ .plan-select-ultra__visual:before {
  opacity: 1;
  background-color: #fff;
}

.plan-select-ultra__visual {
  box-sizing: border-box;
  -o-transition-property: background-color;
  -o-transition-duration: .125s;
  -o-transition-timing-function: ease;
  background-color: #f5f5f5;
  border: 1px solid #053fe3;
  border-radius: 50%;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-left: auto;
  transition-property: background-color;
  transition-duration: .125s;
  transition-timing-function: ease;
  position: relative;
}

.plan-select-ultra__visual:before {
  content: "";
  opacity: .25;
  -o-transition-property: background-color, opacity;
  width: 8px;
  height: 8px;
  transition-property: background-color, opacity;
  -webkit-transition-duration: inherit;
  -o-transition-duration: inherit;
  transition-duration: inherit;
  -webkit-transition-timing-function: inherit;
  -o-transition-timing-function: inherit;
  transition-timing-function: inherit;
  background-color: #053fe3;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.onboarding-nav-z {
  letter-spacing: -.5px;
  color: #8f8d8d;
  background-color: #fff;
  border: 2px solid #fff;
  font-size: 12px;
  line-height: 18px;
  list-style-type: none;
  display: flex;
  box-shadow: 24px 24px 36px rgba(0, 0, 0, .07);
}

.onboarding-nav-z__item {
  box-sizing: border-box;
  text-align: center;
  -o-transition-property: color, background-color;
  -o-transition-duration: .125s;
  -o-transition-timing-function: ease;
  flex-grow: 1;
  flex-basis: 10px;
  padding: 14px;
  transition-property: color, background-color;
  transition-duration: .125s;
  transition-timing-function: ease;
}

.onboarding-nav-z__item:first-child {
  border-top-left-radius: inherit;
}

.onboarding-nav-z__item:last-child {
  border-top-right-radius: inherit;
}

.onboarding-nav-z__item.is-completed, .onboarding-nav-z__item.is-active {
  color: #01964e;
  background-color: rgba(1, 150, 78, .1);
}

.onboarding-nav-z__number:before {
  content: "Step: ";
}

.onboarding-nav-z__name {
  display: none;
}

.ctrl-radios {
  letter-spacing: -.5px;
  font-size: 16px;
  line-height: 24px;
}

.ctrl-radios__grid {
  flex-wrap: wrap;
  margin: -5px -32px;
  display: flex;
}

.ctrl-radios__item {
  flex-shrink: 0;
  margin: 5px 32px;
  display: flex;
  position: relative;
}

.ctrl-radios__field {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.ctrl-radios__field:checked ~ .ctrl-radios__visual {
  --visual-bg: #053fe3;
  --dot-bg: #fff;
}

.ctrl-radios__field:disabled ~ .ctrl-radios__visual {
  --visual-bg: rgba(181, 181, 181, .4);
  --dot-bg: #b5b5b5;
  --visual-border: #b5b5b5;
}

.ctrl-radios__field:disabled ~ .ctrl-radios__label {
  --label-color: #b5b5b5;
}

.ctrl-radios__visual {
  box-sizing: border-box;
  border: 1px solid #053fe3;
  border-color: var(--visual-border, #053fe3);
  background-color: #f5f5f5;
  background-color: var(--visual-bg, #f5f5f5);
  -o-transition-property: background-color;
  -o-transition-timing-function: ease;
  -o-transition-duration: .125s;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  transition-property: background-color;
  transition-duration: .125s;
  transition-timing-function: ease;
  position: relative;
}

.ctrl-radios__visual:before {
  content: "";
  border-radius: inherit;
  background-color: rgba(5, 63, 227, .25);
  background-color: var(--dot-bg, rgba(5, 63, 227, .25));
  -o-transition-property: inherit;
  width: 33.3333%;
  height: 33.3333%;
  transition-property: inherit;
  -webkit-transition-timing-function: inherit;
  -o-transition-timing-function: inherit;
  transition-timing-function: inherit;
  -webkit-transition-duration: inherit;
  -o-transition-duration: inherit;
  transition-duration: inherit;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.ctrl-radios__label {
  color: var(--label-color);
  margin-top: 1px;
  margin-bottom: -1px;
}

.onboarding-finish {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.onboarding-finish__container {
  padding-top: 100%;
  position: relative;
}

.onboarding-finish__container:before {
  content: "";
  background-image: url("../images/backgrounds/background-4@2x.jpg");
  background-position: 50%;
  background-repeat: repeat;
  background-size: cover;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.onboarding-finish__media {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 4px;
  width: 122.699%;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate3d(-50%, -50%, 0)rotate(-3.87deg);
  box-shadow: 18px 18px 24px rgba(0, 0, 0, .1);
}

.onboarding-finish__media .picture__image {
  width: 100%;
  max-width: 100%;
  height: auto;
  display: block;
}

.company-short {
  letter-spacing: -.5px;
  text-align: left;
  font-size: 18px;
  line-height: 1.5;
}

.company-short__header {
  align-items: flex-start;
  display: flex;
}

.company-short__media {
  flex-shrink: 0;
  width: 70px;
  height: 70px;
  margin-right: 24px;
}

.company-short__group {
  margin-top: 4px;
}

.company-short__name {
  letter-spacing: -1.28px;
  font-size: 36px;
  font-weight: 500;
  line-height: 1.1;
}

.company-short__workers {
  opacity: .6;
}

.company-short__description {
  margin-top: 32px;
}

.company-short__description p {
  margin-top: 18px;
}

.company-short__description p:first-child {
  margin-top: 0;
}

.company-short__description mark {
  color: #053fe3;
  background-color: rgba(0, 0, 0, 0);
}

.company-short__actions {
  margin-top: 32px;
}

.company-short__button {
  width: 100%;
}

.company-short--size-s {
  width: 330px;
  margin-left: auto;
  margin-right: auto;
}

.company-short--view-card {
  background-color: #dedede;
  border-radius: 8px;
  padding: 4px;
}

.company-short--view-card .company-short__container {
  background-color: #fff;
  border-radius: 4px;
  padding: 16px;
  box-shadow: 18px 18px 24px rgba(0, 0, 0, .1);
}

.form-onboarding-z {
  background-color: #fff;
  border-radius: 1rem;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  box-shadow: 24px 24px 36px rgba(0, 0, 0, .07);
}

.form-onboarding-z__nav {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.form-onboarding-z__container {
  max-width: 516px;
  margin-left: auto;
  margin-right: auto;
  padding: 48px 24px;
}

.form-onboarding-z__header {
  text-align: center;
}

.form-onboarding-z__title {
  letter-spacing: -1.2px;
  font-size: 22px;
  font-weight: 400;
  line-height: 34px;
}

.form-onboarding-z__company {
  margin: 24px -10px;
  display: inline-block;
}

.form-onboarding-z__company .company-short__media {
  margin-right: 16px;
}

.form-onboarding-z__company .company-short__name {
  letter-spacing: -1.5px;
  font-size: 24px;
  line-height: 36px;
}

.form-onboarding-z__company .company-short__workers {
  letter-spacing: -.5px;
  font-size: 16px;
  line-height: 24px;
}

.form-onboarding-z__description {
  letter-spacing: -.5px;
  font-size: 14px;
  line-height: 20px;
}

.form-onboarding-z__description a {
  color: #053fe3;
  white-space: nowrap;
  font-weight: 500;
  text-decoration: none;
}

.form-onboarding-z__description b {
  font-weight: 500;
}

.form-onboarding-z__text {
  text-align: center;
  letter-spacing: -.5px;
  font-size: 14px;
  line-height: 20px;
}

.form-onboarding-z__text a {
  color: #053fe3;
}

.form-onboarding-z__forgot {
  letter-spacing: -.5px;
  font-size: 14px;
  line-height: 20px;
}

.form-onboarding-z__forgot a {
  color: #053fe3;
  text-decoration: underline;
}

.form-onboarding-z__grid {
  flex-wrap: wrap;
  margin: 28px -8px -12px;
  display: flex;
}

.form-onboarding-z__item {
  width: calc(100% - 16px);
  margin: 12px 8px;
}

.form-onboarding-z__item .ctrl-upload {
  display: block;
}

.form-onboarding-z__item .ctrl-upload__avatar {
  margin-left: auto;
  margin-right: auto;
}

.form-onboarding-z__item .ctrl-upload__body {
  margin-top: 16px;
  margin-left: 0;
}

.form-onboarding-z__item--with-line {
  margin-top: 24px;
  padding-top: 33px;
  position: relative;
}

.form-onboarding-z__item--with-line:before {
  content: "";
  background-color: #f5f5f5;
  width: 100vw;
  height: 1px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.form-onboarding-z__item--actions {
  margin-top: 28px;
}

.form-onboarding-z__item--actions .ctrl-btn {
  width: 100%;
}

.form-onboarding-z__image-complete {
  width: 350px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.form-onboarding-z__image-complete .picture__image {
  width: 100%;
  max-width: 100%;
  height: auto;
  display: block;
}

.form-onboarding-z__footer {
  border-top: 1px solid #f5f5f5;
  padding: 16px;
}

.form-onboarding-z__footer-note {
  letter-spacing: -.5px;
  text-align: center;
  color: #a5a5a5;
  flex-grow: 1;
  margin-top: 16px;
  font-size: 12px;
  line-height: 18px;
}

.form-onboarding-z__footer-note:first-child {
  margin-top: 0;
}

.form-onboarding-z__footer-note a {
  color: #053fe3;
  white-space: nowrap;
}

.form-onboarding-z__footer-actions--next {
  margin-top: 16px;
}

.form-onboarding-z__footer-actions--next:first-child {
  margin-top: 0;
}

.form-onboarding-z__footer-note + .form-onboarding-z__footer-actions--next {
  margin-left: 16px;
}

.form-onboarding-z__footer-text {
  text-align: center;
  letter-spacing: -.5px;
  flex-grow: 1;
  font-size: 14px;
  line-height: 20px;
}

.form-onboarding-z__footer-text a {
  color: #053fe3;
  white-space: nowrap;
  text-decoration: none;
}

.form-onboarding-z__footer-button {
  width: 100%;
}

.form-onboarding-z--without-nav .form-onboarding-z__container {
  padding-top: 60px;
}

.form-onboarding-z--without-footer .form-onboarding-z__container {
  padding-bottom: 60px;
}

.popup {
  box-sizing: border-box;
  z-index: 42002;
  background: rgba(30, 27, 27, .9);
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 34px 44px;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
}

.popup__item {
  background-color: #fff;
  border-radius: 16px;
  flex-direction: column;
  width: 100%;
  max-width: 1400px;
  max-height: 100%;
  margin: auto;
  display: flex;
  position: relative;
}

.popup__item--video {
  max-width: 1280px;
}

.popup__item--size-m {
  max-width: 854px;
}

.popup__header {
  flex-shrink: 0;
  padding: 32px 36px 25px;
}

.popup__header .onboarding-nav-z {
  line-height: 25px;
}

.popup__title {
  letter-spacing: -1.3px;
  font-size: 36px;
  font-weight: 400;
  line-height: 48px;
}

.popup__description {
  letter-spacing: -1px;
  font-size: 18px;
  line-height: 28px;
}

.popup__nav {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  margin: -32px -36px -25px;
  position: relative;
  box-shadow: 24px 24px 36px rgba(0, 0, 0, .07);
}

.popup__body {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
}

.popup__body .form-onboarding-z {
  border-radius: 0;
}

.popup__footer {
  border-top: 1px solid #f5f5f5;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  padding: 16px;
  display: flex;
}

.popup__footer--center {
  justify-content: center;
}

.popup__button {
  min-width: 166px;
  margin-left: 16px;
}

.popup__button--back {
  margin-right: auto;
}

.popup__button:first-child {
  margin-left: 0;
}

.popup__button:only-child {
  margin-left: auto;
  margin-right: auto;
}

.popup__progress {
  width: 530px;
  margin-left: 16px;
  margin-right: auto;
}

.popup__progress:first-child {
  margin-left: auto;
}

.popup__legend {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0;
  margin-left: 16px;
  margin-right: auto;
  padding: 0;
}

.popup__legend .legend-compare__item {
  margin-left: 48px;
}

.popup__legend .legend-compare__item:first-child {
  margin-left: 0;
}

.popup__button-close {
  color: #fff;
  position: absolute;
  top: 0;
  left: calc(100% + 10px);
}

.popup__video {
  border-radius: inherit;
  overflow: hidden;
}

.popup ~ .react-select__menu-portal {
  z-index: 250000;
}

.popup ~ .react-popper {
  z-index: 250001;
}

.ctrl-share-task {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 24px 16px;
  position: relative;
}

.ctrl-share-task__title {
  text-align: center;
  letter-spacing: -1.5px;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
}

.ctrl-share-task__form {
  margin-top: 24px;
}

.ctrl-share-task__item {
  position: relative;
}

.ctrl-share-task__item .ctrl-textfield {
  text-overflow: ellipsis;
  color: #053fe3;
  background-color: #fff;
  padding: 14px 160px 14px 16px;
  font-weight: 500;
}

.ctrl-share-task__button-copy {
  position: absolute;
  top: 8px;
  right: 8px;
}

.confirmation {
  box-sizing: border-box;
  z-index: 42002;
  background: rgba(30, 27, 27, .4);
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 34px;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.confirmation__item {
  width: 100%;
  max-width: 320px;
  max-width: var(--item-width, 320px);
  background-color: #fff;
  border-radius: 16px;
  flex-direction: column;
  margin: auto;
  display: flex;
  position: relative;
}

.confirmation__item--video {
  max-width: 1280px;
}

.confirmation__header {
  padding: 24px 24px 16px;
  padding: var(--header-indents, 24px 24px 16px);
  text-align: center;
  text-align: var(--title-align, center);
  position: relative;
}

.confirmation__title {
  letter-spacing: -1.5px;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
}

.confirmation__description {
  letter-spacing: -.5px;
  font-size: 14px;
  line-height: 20px;
}

.confirmation__form {
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
}

.confirmation__form-item {
  margin-top: 16px;
}

.confirmation__form-item:first-child {
  margin-top: 0;
}

.confirmation__share {
  margin-left: 24px;
  margin-right: 24px;
}

.confirmation__footer {
  padding: 16px;
  padding: var(--footer-indents, 16px);
  border-top-width: 1px;
  border-top-width: var(--footer-border, 1px);
  border-top-style: solid;
  border-top-color: #f5f5f5;
  justify-content: center;
  display: flex;
}

.confirmation__button {
  max-width: 50%;
  max-width: var(--button-width, 50%);
  flex: 50%;
  margin-left: 16px;
}

.confirmation__button:first-child {
  margin-left: 0;
}

.confirmation__button-close {
  color: #fff;
  opacity: .9;
  position: absolute;
  bottom: 100%;
  left: 100%;
}

.confirmation--type-share {
  --item-width: 550px;
  --title-align: left;
  --header-indents: 24px;
  --footer-border: 0;
  --footer-indents: 24px;
  --button-width: 100%;
}

.confirmation--type-share .confirmation__title {
  letter-spacing: -1px;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

.faq-micro__button-toggle {
  box-sizing: border-box;
  letter-spacing: -.5px;
  background-color: #fff;
  border-width: 0;
  outline: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
}

.faq-micro__button-toggle-text {
  padding-top: 2px;
}

.faq-micro__button-toggle-icon {
  -o-transition-property: transform;
  -o-transition-duration: .125s;
  -o-transition-timing-function: ease-in-out;
  transition-property: transform;
  transition-duration: .125s;
  transition-timing-function: ease-in-out;
}

.faq-micro__button-toggle.is-active .faq-micro__button-toggle-icon {
  transform: rotate(-180deg);
}

.faq-micro__button-toggle.is-active + .faq-micro__container {
  max-height: 400px;
}

.faq-micro__container {
  -o-transition-property: max-height;
  -o-transition-duration: .375s;
  -o-transition-timing-function: ease-in-out;
  max-height: 0;
  transition-property: max-height;
  transition-duration: .375s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
}

.faq-micro__list {
  letter-spacing: -.5px;
  background-color: #f5f5f5;
  border: 1px solid #fff;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 15px 23px 23px;
  font-size: 14px;
  line-height: 20px;
  list-style-type: none;
}

.faq-micro__list-item {
  margin-top: 16px;
}

.faq-micro__list-item:first-child {
  margin-top: 0;
}

.faq-micro__list-link {
  color: #053fe3;
  text-decoration: none;
}

html.no-touchevents .faq-micro__list-link:hover {
  text-decoration: underline;
}

.help {
  z-index: 42000;
  position: fixed;
  bottom: 24px;
  right: 24px;
}

.help__button-toggle {
  color: #fff;
  background-color: #053fe3;
  border-radius: 50%;
  padding: 12px;
}

.help__container {
  opacity: 0;
  visibility: hidden;
  -o-transition-property: opacity, visibility, transform;
  -o-transition-duration: .125s;
  -o-transition-timing-function: ease-in-out;
  background-color: #f5f5f5;
  border-radius: 8px;
  width: 270px;
  min-height: 32px;
  transition-property: opacity, visibility, transform;
  transition-duration: .125s;
  transition-timing-function: ease-in-out;
  position: absolute;
  bottom: calc(100% + 8px);
  right: 0;
  overflow: hidden;
  transform: translateY(-10px);
  box-shadow: 18px 18px 24px rgba(0, 0, 0, .1);
}

.help__body {
  color: #fff;
  background-color: rgba(30, 27, 27, .9);
  padding: 24px;
}

.help__title {
  letter-spacing: -1.5px;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}

.help__description {
  letter-spacing: -.5px;
  margin-top: 16px;
  font-size: 14px;
  line-height: 20px;
}

.help__description p {
  margin-top: 1em;
}

.help__description p:first-child {
  margin-top: 0;
}

.help__description span {
  color: #ffd600;
}

.help__actions {
  margin-top: 24px;
}

.help__button-main {
  width: 100%;
}

.help__button-close {
  color: #fa380d;
  position: absolute;
  top: 8px;
  right: 8px;
}

.help__button-close .ctrl-btn-clear__icon {
  width: 16px;
  height: 16px;
}

.help.is-active .help__container {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.header-actions {
  align-items: center;
  display: flex;
}

.header-actions .ctrl-btn {
  padding: 8px 12px;
  font-weight: 400;
}

.header-actions .ctrl-btn .ctrl-btn__text {
  padding-top: 1px;
  padding-bottom: 0;
  padding-right: 3px;
}

.header-actions .y-ctrl-btn {
  padding: 7px 12px;
  font-size: 16px;
}

.header-actions__button-back {
  margin-right: auto;
}

.header-actions__button-main {
  margin-left: auto;
}

.table-inline {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;
  line-height: 16px;
}

.table-inline__cell {
  box-sizing: border-box;
  text-align: left;
  border-top: 1px solid rgba(30, 27, 27, .25);
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 8px;
}

.table-inline__cell--th {
  white-space: nowrap;
  border-top-width: 0;
  font-weight: 500;
}

.table-inline__cell--check {
  width: 20px;
  padding-right: 4px;
}

.table-inline__cell--name, .table-inline__cell--assignee {
  text-overflow: ellipsis;
  max-width: 90px;
  overflow: hidden;
}

.table-inline__cell--actions {
  width: 16px;
}

.table-inline__cell:last-child {
  padding-right: 0;
}

.table-inline__field {
  box-sizing: border-box;
  border: 1px solid #053fe3;
  border-radius: 2px;
  width: 100%;
  margin-top: -3px;
  margin-bottom: -3px;
  padding: 2px;
  font-size: 12px;
  line-height: 16px;
}

.table-inline__icon {
  color: rgba(30, 27, 27, .2);
  width: 16px;
  height: 16px;
  display: block;
}

.table-inline__icon--success {
  color: #01964e;
}

.table-inline__button-add {
  color: #053fe3;
  display: block;
}

.side {
  -o-transition-property: width;
  -o-transition-timing-function: ease;
  -o-transition-duration: .25s;
  flex-shrink: 0;
  width: 450px;
  transition-property: width;
  transition-duration: .25s;
  transition-timing-function: ease;
  overflow: hidden;
}

.side__container {
  box-sizing: border-box;
  background-color: #f5f5f5;
  width: 450px;
  height: calc(100vh - 121px);
  padding: 24px;
  overflow-x: hidden;
  overflow-y: auto;
}

.side__header {
  margin-top: -5px;
  margin-bottom: 16px;
}

.side__nav, .side__item {
  margin-bottom: 24px;
}

.side__item:last-child {
  margin-bottom: 0;
}

.side__box {
  box-sizing: border-box;
  background-color: #fff;
  padding: 24px;
  box-shadow: 18px 18px 24px rgba(0, 0, 0, .1);
}

.side.is-hidden {
  width: 0;
}

.screen {
  align-items: flex-start;
  min-width: 1200px;
  display: flex;
}

.screen__header {
  box-sizing: border-box;
  z-index: 42000;
  flex-shrink: 0;
  height: 100vh;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.screen__content {
  box-sizing: border-box;
  -ms-grid-row-align: stretch;
  flex-direction: column;
  flex-grow: 1;
  align-self: stretch;
  min-width: 0;
  padding: 24px;
  display: flex;
}

.screen__grid {
  flex-grow: 1;
}

.screen__panel {
  flex-shrink: 0;
  min-height: 42px;
  margin-top: -5px;
  margin-bottom: 16px;
}

.screen__panel .panel__title {
  letter-spacing: -1.5px;
  align-items: center;
  font-size: 24px;
  line-height: 36px;
  display: flex;
}

.screen__panel .panel__title .panel__button-settings {
  vertical-align: baseline;
  vertical-align: initial;
  margin-left: 24px;
}

.screen__panel .panel__title .panel__view {
  vertical-align: baseline;
  vertical-align: initial;
}

.screen__panel .panel__button {
  min-width: 0;
}

.screen__panel .ctrl-btn {
  min-width: 130px;
  padding: 8px 12px;
  font-weight: 400;
}

.screen__panel .ctrl-btn .ctrl-btn__text {
  padding-top: 1px;
  padding-bottom: 0;
  padding-right: 3px;
}

.screen__panel-actions {
  flex-shrink: 0;
  align-items: center;
  margin-bottom: 16px;
  display: flex;
}

.screen--size-full {
  align-items: stretch;
}

.screen--size-full .screen__content {
  flex-direction: column;
  padding: 24px 24px 0;
  display: flex;
}

.screen--size-full .screen__grid {
  flex-grow: 1;
  margin-left: -24px;
  margin-right: -24px;
}

.screen--size-full .screen-grid__content {
  display: flex;
}

.screen--size-full .screen-grid__content .gantt-grid {
  flex-grow: 1;
  height: auto;
}

.screen--size-full .screen-grid__content .gantt-grid .aka-table--gantt .aka-table__cell--th {
  height: 112px;
}

.ctrl-btn-download-app {
  box-sizing: border-box;
  color: #fff;
  text-align: left;
  background-color: #053fe3;
  border: 1px solid #235dff;
  border-radius: 8px;
  align-items: center;
  padding: 8px 16px 8px 8px;
  text-decoration: none;
  display: inline-flex;
}

.ctrl-btn-download-app__icon-app {
  background-color: tomato;
  border-radius: 8px;
  flex-shrink: 0;
  width: 56px;
  height: 56px;
  margin-right: 12px;
  overflow: hidden;
}

.ctrl-btn-download-app__info {
  flex-grow: 1;
}

.ctrl-btn-download-app__name {
  letter-spacing: -.5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: block;
}

.ctrl-btn-download-app__description {
  letter-spacing: -.5px;
  color: #dedede;
  font-size: 10px;
  line-height: 16px;
  display: block;
}

.ctrl-btn-download-app__icon {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-left: 12px;
}

.app-task-status {
  position: relative;
}

.app-task-status__overlap {
  display: none;
  display: var(--show-overlap, none);
  background-color: rgba(0, 0, 0, .5);
  width: 100vw;
  height: 110vh;
  position: absolute;
  bottom: 0;
  right: 0;
}

.app-task-status__toggle {
  text-align: left;
  color: #fff;
  background-color: #8e8c8c;
  background-color: var(--status-bgcolor, #8e8c8c);
  border-width: 0;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 18px;
  display: flex;
  position: relative;
}

.app-task-status__toggle-content {
  flex-grow: 1;
}

.app-task-status__toggle-label {
  letter-spacing: -.5px;
  opacity: .75;
  font-size: 12px;
  line-height: 18px;
  display: block;
}

.app-task-status__toggle-status .status-element__icon {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  box-shadow: 0 12px 16px rgba(0, 0, 0, .15);
}

.app-task-status__toggle-status .status-element__name {
  color: #fff;
  letter-spacing: -.5px;
  margin-top: 2px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.app-task-status__toggle-icon {
  width: 24px;
  height: 24px;
  transform: rotate(0);
  -webkit-transform: rotate(var(--icon-rotate, 0));
  -ms-transform: rotate(var(--icon-rotate, 0));
  transform: rotate(var(--icon-rotate, 0));
  -o-transition-property: transform;
  -o-transition-duration: .125s;
  -o-transition-timing-function: ease;
  flex-shrink: 0;
  margin-left: 8px;
  transition-property: transform;
  transition-duration: .125s;
  transition-timing-function: ease;
}

.app-task-status__viewport {
  display: none;
  display: var(--show-viewport, none);
  color: #1e1b1b;
  width: 200%;
  position: absolute;
  bottom: 100%;
  right: 0;
}

.app-task-status__list {
  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  list-style-type: none;
  overflow: hidden;
}

.app-task-status__list-item {
  border-top: 1px solid #dedede;
}

.app-task-status__list-item:first-child {
  border-top-width: 0;
}

.app-task-status__list-button {
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  align-items: center;
  width: 100%;
  padding: 18px 24px;
  display: flex;
}

.app-task-status__list-button .status-element__icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.app-task-status__list-button .status-element__name {
  letter-spacing: -.5px;
  margin-top: 2px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.app-task-status--assigned, .app-task-status--in-progress {
  --status-bgcolor: #053fe3;
}

.app-task-status--blocked {
  --status-bgcolor: #fa380d;
}

.app-task-status--re-work, .app-task-status--rework {
  --status-bgcolor: #f90;
}

.app-task-status--done, .app-task-status--verified, .app-task-status--approved {
  --status-bgcolor: #01964e;
}

.app-task-status.is-open {
  --show-viewport: block;
  --show-overlap: block;
  --icon-rotate: 180deg;
}

.app-actions {
  color: #fff;
  background-color: #000;
  display: flex;
}

.app-actions__form {
  box-sizing: border-box;
  z-index: 101;
  flex-shrink: 0;
  width: 50%;
  padding: 18px;
  position: relative;
}

.app-actions__form .ctrl-form__label {
  letter-spacing: -.5px;
  font-size: 12px;
  line-height: 18px;
}

.app-actions__form .ctrl-form .ctrl-textfield {
  letter-spacing: -.5px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-top-width: 0;
  border-bottom-color: rgba(196, 196, 196, .2);
  border-left-width: 0;
  border-right-width: 0;
  border-radius: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.app-actions__form .ctrl-form .ctrl-textfield:focus {
  border-bottom-color: rgba(196, 196, 196, .6);
}

.app-actions__form .ctrl-form__button {
  color: #fff;
  opacity: .5;
  padding: 2px;
  right: 0;
}

.app-actions__status {
  flex-shrink: 0;
  width: 50%;
}

.app-header {
  box-sizing: border-box;
  z-index: 100;
  color: #fff;
  background-color: #000;
  align-items: center;
  width: 100%;
  padding: 16px 24px;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.app-header__logo {
  flex-shrink: 0;
  margin-right: auto;
}

.app-header__logo .logo__image {
  width: 51px;
  height: 28px;
}

.app-header__button-download {
  max-width: 250px;
  margin-left: 16px;
}

.app-header__actions {
  z-index: 100;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
}

.app-task-progress {
  background-color: #dedede;
  height: 2px;
}

.app-task-progress__bar {
  background-color: #053fe3;
  height: 100%;
  position: relative;
}

.app-task-progress__dot {
  box-sizing: border-box;
  background-color: inherit;
  border: 2px solid #fff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translate3d(-50%, -50%, 0);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, .1);
}

.app-task-progress__number {
  letter-spacing: 0;
  color: #fff;
  background-color: #053fe3;
  border-radius: 4px;
  padding: 4px 8px;
  position: absolute;
  bottom: calc(100% + 11px);
  left: 100%;
  transform: translateX(-50%);
}

.app-task-progress__number:before {
  content: "";
  border: 4px solid rgba(0, 0, 0, 0);
  border-top-color: #053fe3;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.ctrl-btn-more-app {
  box-sizing: border-box;
  letter-spacing: -.5px;
  color: #053fe3;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  width: 100%;
  padding: 10px;
  font-size: 12px;
  line-height: 18px;
  display: block;
}

.app-task-info__header {
  background-color: #f5f5f5;
  margin: -24px -24px 32px;
  padding: 16px 24px;
}

.app-task-info__actions {
  justify-content: flex-end;
  margin-top: -8px;
  display: flex;
}

.app-task-info__actions-button {
  margin-left: 8px;
}

.app-task-info__header + .app-task-info__actions {
  margin-top: -16px;
}

.app-task-info__project {
  letter-spacing: -.5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.app-task-info__company {
  letter-spacing: -.5px;
  font-size: 12px;
  line-height: 18px;
}

.app-task-info__id {
  letter-spacing: -.5px;
  color: rgba(30, 27, 27, .25);
  font-size: 10px;
  line-height: 16px;
}

.app-task-info__name {
  letter-spacing: -1.5px;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}

.app-task-info__date {
  letter-spacing: -.5px;
  margin-top: 24px;
  font-size: 14px;
  line-height: 20px;
  display: block;
}

.app-task-info__due {
  letter-spacing: -.5px;
  color: #fa380d;
  font-size: 10px;
  line-height: 16px;
}

.app-task-info__location {
  letter-spacing: -.5px;
  margin-top: 19px;
  font-size: 14px;
  line-height: 20px;
}

.app-task-info__description {
  margin-top: 19px;
}

.app-task-info__description-title {
  letter-spacing: -.5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.app-task-info__description-text {
  color: rgba(30, 27, 27, .5);
  margin-top: 8px;
}

.app-task-info__button-more {
  margin-top: 24px;
}

.app-task-info__additional {
  padding-top: 24px;
}

.app-task-info__additional-title {
  letter-spacing: -.5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.app-task-info__additional-group {
  margin-top: 16px;
}

.app-task-info__additional-item {
  -ms-flex-wrap: var(--item-wrap);
  flex-wrap: var(--item-wrap);
  padding-top: 16px;
  padding-top: var(--indent-top, 16px);
  padding-bottom: 16px;
  padding-bottom: var(--indent-bottom, 16px);
  border-top-width: 1px;
  border-top-width: var(--border-width, 1px);
  border-top-style: solid;
  border-top-color: #f5f5f5;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.app-task-info__additional-item:first-child {
  --indent-top: 0;
  --border-width: 0;
}

.app-task-info__additional-item:last-child {
  --indent-bottom: 0;
}

.app-task-info__additional-item--progress {
  --item-wrap: wrap;
}

.app-task-info__additional-label {
  margin-right: 20px;
}

.app-task-info__additional-progress {
  width: 100%;
  margin-top: 52px;
}

.app-task-info__additional.is-hidden {
  display: none;
}

.app-task-history__item {
  box-sizing: border-box;
  min-height: 66px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 40px;
  position: relative;
}

.app-task-history__item-header {
  letter-spacing: -.5px;
  color: rgba(30, 27, 27, .25);
  align-items: center;
  font-size: 12px;
  line-height: 18px;
  display: flex;
}

.app-task-history__item-date {
  margin-right: 20px;
}

.app-task-history__item-user {
  margin-left: auto;
}

.app-task-history__item-body {
  margin-top: 4px;
}

.app-task-history__item-icon {
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.app-task-history__item:before {
  content: "";
  content: var(--line, "");
  background-color: #dedede;
  width: 1px;
  position: absolute;
  top: 32px;
  bottom: 8px;
  left: 12px;
  transform: translateX(-50%);
}

.app-task-history__item:last-child {
  --line: none;
}

.ctrl-check-app {
  font-size: 0;
  line-height: 0;
}

.ctrl-check-app__container {
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.ctrl-check-app__field {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.ctrl-check-app__field:checked ~ .ctrl-check-app__icon {
  color: #fff;
  background-color: #053fe3;
  border-color: #053fe3;
}

.ctrl-check-app__field:checked ~ .ctrl-check-app__label {
  color: rgba(30, 27, 27, .5);
  text-decoration-line: line-through;
}

.ctrl-check-app__icon {
  box-sizing: border-box;
  color: rgba(5, 63, 227, .2);
  background-color: #f5f5f5;
  border: 1px solid #235dff;
  border-radius: 4px;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  display: block;
}

.ctrl-check-app__label {
  letter-spacing: -.5px;
  word-break: break-word;
  margin-bottom: -1px;
  margin-left: 24px;
  padding-top: 1px;
  font-size: 16px;
  line-height: 24px;
}

.app-task-checklist__item {
  border-top: 1px solid #f5f5f5;
  margin-left: -24px;
  margin-right: -24px;
  padding: 16px 24px;
}

.app-task-checklist__item:last-child {
  padding-bottom: 0;
}

.app-task-checklist .ctrl-check {
  display: flex;
}

.app-task-checklist .ctrl-check__box {
  margin-left: 0;
  margin-right: 24px;
}

.app-task-checklist .ctrl-check__icon {
  border-radius: 4px;
  width: 24px;
  height: 24px;
}

.app-task-checklist .ctrl-check__label {
  letter-spacing: -.5px;
  word-break: break-word;
  font-size: 16px;
  line-height: 24px;
}

.app-task-user {
  letter-spacing: -.5px;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  line-height: 18px;
  display: flex;
}

.app-task-user__avatar {
  width: 64px;
  height: 64px;
}

.app-task-user__name, .app-task-user__trade {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
}

.app-task-user__name {
  color: #053fe3;
  margin-top: 4px;
}

.app-task-user__trade {
  color: rgba(30, 27, 27, .5);
}

.app-task-assignees__grid {
  flex-wrap: wrap;
  margin: -8px;
  display: flex;
}

.app-task-assignees__item {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 33.3333%;
  padding: 8px;
}

.app-task-gallery__grid {
  flex-wrap: wrap;
  margin: -4px;
  display: flex;
}

.app-task-gallery__item {
  flex-shrink: 0;
  margin: 4px;
}

.app-task-gallery__media {
  border-radius: 16px;
  width: 72px;
  height: 72px;
  display: block;
  overflow: hidden;
}

.app-card__title {
  letter-spacing: -.5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.app-card__body {
  margin-top: 16px;
}

.app-task-download__button-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
  display: none;
}

.app-task-download__button-toggle .ctrl-btn-download-app__icon {
  transform: rotate(-90deg);
  -webkit-transform: rotate(var(--icon-rotate, -90deg));
  -ms-transform: rotate(var(--icon-rotate, -90deg));
  transform: rotate(var(--icon-rotate, -90deg));
  -o-transition-property: transform;
  -o-transition-delay: .125s;
  -o-transition-timing-function: ease;
  transition-property: transform;
  transition-delay: .125s;
  transition-timing-function: ease;
}

.app-task-download__media {
  margin-top: -24px;
  margin-left: -24px;
  margin-right: -24px;
  display: block;
}

.app-task-download__media .picture__image {
  width: 100%;
  max-width: 100%;
  height: auto;
  display: block;
}

.app-task-download__body {
  padding-top: 16px;
}

.app-task-download__title {
  letter-spacing: -1px;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.app-task-download__list {
  letter-spacing: -.5px;
  margin-top: 16px;
  font-size: 14px;
  line-height: 20px;
  list-style-type: none;
}

.app-task-download__list-item {
  align-items: flex-start;
  margin-top: 16px;
  display: flex;
}

.app-task-download__list-item:first-child {
  margin-top: 0;
}

.app-task-download__list-icon {
  color: #053fe3;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.app-task-download__list-text {
  margin-bottom: -2px;
  padding-top: 2px;
}

.app-task-download__actions {
  margin-top: 24px;
}

.app-task-download__button {
  width: 100%;
  display: block;
}

.app-task-download.is-open {
  --view: block;
  --icon-rotate: 90deg;
}

.app-task {
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 82px;
}

.app-task__grid {
  margin-top: -16px;
}

.app-task__item {
  box-sizing: border-box;
  letter-spacing: -.5px;
  background-color: #fff;
  margin-top: 16px;
  padding: 24px;
  font-size: 16px;
  line-height: 24px;
  box-shadow: 18px 18px 24px rgba(0, 0, 0, .1);
}

.error-404 {
  text-align: center;
  background-image: url("../images/backgrounds/background-5.jpg");
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover;
  flex-grow: 1;
  padding-bottom: 40px;
  position: relative;
  overflow: hidden;
}

.error-404__box {
  position: relative;
}

.error-404__number {
  width: 460px;
  height: 355px;
  display: block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.error-404__title {
  text-align: center;
  letter-spacing: .012em;
  text-transform: uppercase;
  font-family: TungstenNarrow, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 96px;
  font-weight: 700;
  line-height: .87;
}

.error-404__description {
  text-align: center;
  margin-top: 24px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.55556;
}

.error-404__actions {
  width: 260px;
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
}

.error-404__button {
  width: 100%;
}

.social {
  justify-content: center;
  display: flex;
}

.social__item {
  color: inherit;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  margin-left: 30px;
  padding: 0;
  text-decoration: none;
}

.social__item:first-child {
  margin-left: 0;
}

.social__name {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.footer {
  color: #fff;
  background-color: #1e1b1b;
  padding-top: 24px;
  padding-bottom: 80px;
}

.footer__container {
  border-top: 1px solid #fa380d;
  padding-top: 40px;
}

.footer__logo {
  opacity: .5;
  width: 76px;
  height: 24px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.footer__nav {
  letter-spacing: -1px;
  margin-top: 40px;
  font-size: 18px;
  line-height: 28px;
  list-style-type: none;
}

.footer__nav-item {
  text-align: center;
  margin-top: 24px;
}

.footer__nav-item:first-child {
  margin-top: 0;
}

.footer__nav-link {
  color: #dedede;
  text-decoration: none;
}

.footer__social {
  margin-top: 40px;
}

.screen-app {
  box-sizing: border-box;
  background-color: #dedede;
  min-height: 100vh;
}

.ctrl-undo {
  z-index: 420000;
  transform: translateY(100px);
  -webkit-transform: translateY(var(--itemTranslate, 100px));
  -ms-transform: translateY(var(--itemTranslate, 100px));
  transform: translateY(var(--itemTranslate, 100px));
  color: #fff;
  -o-transition-property: transform;
  -o-transition-timing-function: cubic-bezier(0, 1.7, 1, 1);
  -o-transition-duration: .25s;
  background-color: #053fe3;
  border-radius: 4px;
  align-items: center;
  width: -webkit-fit-content;
  width: fit-content;
  padding: 6px 12px;
  transition-property: transform;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(0, 1.7, 1, 1);
  display: flex;
  position: fixed;
  bottom: 24px;
  left: 294px;
  box-shadow: 18px 18px 24px rgba(0, 0, 0, .1);
}

.ctrl-undo__visual {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  position: relative;
}

.ctrl-undo__visual-graph {
  fill: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-90deg);
}

.ctrl-undo__visual-graph-line {
  stroke-width: 1px;
  stroke: currentColor;
  stroke-dasharray: 29;
  stroke-dashoffset: 0;
  stroke-dashoffset: var(--lineSize, 0);
  -o-transition-property: stroke-dashoffset;
  -o-transition-timing-function: linear;
  transition-property: stroke-dashoffset;
  transition-timing-function: linear;
  -webkit-transition-duration: 0;
  -o-transition-duration: 0;
  transition-duration: 0;
  -webkit-transition-duration: var(--lineTime, 0);
  -o-transition-duration: var(--lineTime, 0);
  transition-duration: var(--lineTime, 0);
}

.ctrl-undo__visual-timer {
  letter-spacing: -.5px;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-right: .5px;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  position: relative;
}

.ctrl-undo__button-undo {
  letter-spacing: -.5px;
  color: currentColor;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  padding: 1px 0 0;
  font-size: 16px;
  line-height: 1;
}

html.no-touchevents .ctrl-undo__button-undo:hover {
  opacity: .7;
  -o-transition-property: opacity;
  -o-transition-timing-function: ease;
  -o-transition-duration: .125s;
  transition-property: opacity;
  transition-duration: .125s;
  transition-timing-function: ease;
}

.ctrl-undo__button-close {
  color: currentColor;
  opacity: .7;
  margin-left: 16px;
  margin-right: -6px;
}

.ctrl-undo.is-shown {
  --itemTranslate: 0;
}

.ctrl-undo.is-count {
  --lineSize: -29;
  --lineTime: 5s;
}

@media (min-width: 375px) {
  .page:after {
    content: "XXS";
  }
}

@media (min-width: 420px) {
  .page:after {
    content: "XS";
  }

  .box {
    max-width: 23.4375rem;
  }
}

@media (min-width: 576px) {
  .page:after {
    content: "S";
  }
}

@media (min-width: 768px) {
  .page:after {
    content: "M";
  }

  .box {
    max-width: 73.7778rem;
    padding-left: 1.33333rem;
    padding-right: 1.33333rem;
  }

  .ctrl-btn--rem {
    letter-spacing: -.0555556rem;
    padding: 1.05556rem 3.16667rem;
    font-size: 1rem;
    line-height: 1.55556rem;
  }

  .ctrl-btn--rem .ctrl-btn__icon {
    margin-right: .444444rem;
  }

  .ctrl-btn--rem.ctrl-btn--rounded {
    border-radius: 1.88889rem;
  }

  .plan-price--view-landing {
    letter-spacing: -.0277778rem;
    font-size: .777778rem;
    line-height: 1.11111rem;
  }

  .plan-price--view-landing .plan-price__icon {
    width: 1.33333rem;
    height: 1.33333rem;
    margin-top: -.111111rem;
    margin-right: .444444rem;
  }

  .plan-price--view-landing .plan-price__cell--summ {
    width: 8.88889rem;
  }

  .download-app__title {
    letter-spacing: -1px;
    font-size: 18px;
    line-height: 28px;
  }

  .plan-calc__title {
    letter-spacing: -.0833333rem;
    font-size: 1.33333rem;
  }

  .onboarding-nav-z {
    letter-spacing: -.5px;
    font-size: 14px;
    line-height: 20px;
  }

  .onboarding-nav-z__number:before {
    content: none;
  }

  .onboarding-nav-z__name {
    margin-left: 8px;
    display: inline;
  }

  .onboarding-finish {
    width: 326px;
  }

  .form-onboarding-z__title {
    letter-spacing: -1.2px;
    font-size: 36px;
    line-height: 48px;
  }

  .form-onboarding-z__company {
    min-width: 330px;
    margin-left: auto;
    margin-right: auto;
  }

  .form-onboarding-z__description {
    letter-spacing: -1px;
    font-size: 18px;
    line-height: 28px;
  }

  .form-onboarding-z__item .ctrl-upload {
    display: flex;
  }

  .form-onboarding-z__item .ctrl-upload__avatar {
    margin-right: 0;
  }

  .form-onboarding-z__item .ctrl-upload__body {
    margin-top: 0;
    margin-left: 10px;
  }

  .form-onboarding-z__footer {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .form-onboarding-z__footer-note {
    margin-top: 0;
    margin-left: 16px;
  }

  .form-onboarding-z__footer-note:first-child {
    margin-left: 186px;
  }

  .form-onboarding-z__footer-actions {
    flex-shrink: 0;
    min-width: 170px;
  }

  .form-onboarding-z__footer-actions--next {
    margin-top: 0;
    margin-left: auto;
  }

  .form-onboarding-z__footer-actions--finish {
    margin-left: auto;
    margin-right: auto;
  }

  .form-onboarding-z__footer-text {
    text-align: right;
    margin-bottom: 0;
    margin-right: 24px;
  }

  .app-task-status {
    --icon-rotate: 180deg;
  }

  .app-task-status__viewport {
    width: 320px;
    top: calc(100% - 10px);
    bottom: auto;
    right: 28px;
  }

  .app-task-status__viewport:before {
    content: "";
    border: 8px solid #fff;
    border-color: rgba(0, 0, 0, 0) #fff #fff rgba(0, 0, 0, 0);
    position: absolute;
    bottom: 100%;
    right: 0;
  }

  .app-task-status__list {
    border-top-right-radius: 0;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    box-shadow: 24px 24px 36px rgba(0, 0, 0, .07);
  }

  .app-task-status.is-open {
    --show-overlap: none;
    --icon-rotate: 0;
  }

  .app-header__logo .logo__image {
    width: 67px;
    height: 36px;
  }

  .app-header__button-download {
    display: none;
  }

  .app-header__actions {
    width: 380px;
    margin-top: -16px;
    margin-bottom: -16px;
    margin-right: -24px;
    position: static;
  }

  .app-task-download__button-toggle {
    display: flex;
  }

  .app-task-download__viewport {
    display: none;
    display: var(--view, none);
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .app-task-download__media {
    margin-top: 0;
  }

  .app-task {
    max-width: 1170px;
    padding-bottom: 88px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .app-task__grid {
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 0;
    display: flex;
  }

  .app-task__part {
    flex-shrink: 0;
    width: calc(50% - 8px);
  }

  .app-task__item--download {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: calc(50% - 24px);
    max-width: 250px;
    margin-top: 0;
    padding: 0;
    position: fixed;
    bottom: 0;
    right: 16px;
  }

  .error-404 {
    background-image: url("../images/backgrounds/background-5@2x.jpg");
    padding-top: 40px;
    padding-bottom: 80px;
  }

  .error-404__number {
    width: 581px;
    height: 449px;
  }

  .error-404__title {
    margin-top: 40px;
  }

  .error-404__description {
    font-size: 24px;
  }

  .social__item {
    margin-left: 20px;
  }

  .footer {
    border-top: 1px solid #fa380d;
    padding-top: 46px;
    padding-bottom: 46px;
  }

  .footer__container {
    border-top-width: 0;
    justify-content: space-between;
    align-items: center;
    padding-top: 0;
    display: flex;
  }

  .footer__logo {
    opacity: 1;
    margin-left: 0;
    margin-right: 0;
  }

  .footer__nav {
    margin-top: 0;
    display: flex;
  }

  .footer__nav-item {
    margin-top: 0;
    margin-left: 20px;
    margin-right: 20px;
  }

  .footer__social {
    margin-top: 0;
  }
}

@media (min-width: 992px) {
  .page:after {
    content: "L";
  }

  .react-datepicker__day-name, .react-datepicker__day {
    margin-left: 7px;
    margin-right: 7px;
  }

  .plan-calc__form-item {
    width: 250px;
  }

  .app-task-assignees__item {
    width: 120px;
  }

  .app-task {
    padding-bottom: 96px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .app-task__grid {
    padding-top: 8px;
  }

  .app-task__part--main-info {
    width: 370px;
  }

  .app-task__part--info {
    flex-grow: 1;
    margin-left: 30px;
  }
}

@media (min-width: 1200px) {
  .page:after {
    content: "XL";
  }
}

@media (min-width: 1329px) {
  .page:after {
    content: "XXL";
  }
}

@media (min-width: 1500px) {
  .rpt-tasks-summary__cost-grid {
    -ms-grid-columns: (auto) [5];
    grid-template-columns: repeat(5, auto);
  }

  .rpt-tasks-summary__cost-item--date {
    grid-column: auto;
  }
}
