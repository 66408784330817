.infoLinksList__label {
  letter-spacing: -.4px;
  color: #787676;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}

.infoLinksList__item {
  margin-bottom: 12px;
}

.infoLinksList__link {
  letter-spacing: -.4px;
  color: #053fe3;
  word-break: break-word;
  background-color: #e6ecfc;
  border-radius: 9px;
  padding: 10px;
  font-size: 16px;
  line-height: 1.375;
  text-decoration: none;
  display: block;
}

.infoLinksList__link:hover {
  text-decoration: underline;
}

.infoLinksList__note {
  letter-spacing: -.4px;
  color: #a5a4a4;
  font-size: 14px;
  line-height: 1.14286;
}
