.formOnboardingZ__app {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.formOnboardingZ__app :global .form-onboarding-z__text {
  padding: 5px 0;
}

.formOnboardingZ__downloadLinks :first-child {
  margin-right: 16px;
}
