.convertDayPopup {
  cursor: pointer;
  background: #fff;
  border: 5px solid #e6ebfc;
  border-radius: 20px;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  width: 291px;
  max-height: 40px;
  padding: 12px 16px;
  display: flex;
}

.convertDayPopup button span {
  color: #1e1b1b;
  font-family: Graphik LCG;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
