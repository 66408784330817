.baselineSettings__group {
  border-top: .0625rem solid #dedede;
  padding: 1rem;
}

.baselineSettings__group:first-child {
  border-top-width: 0;
  padding-top: 0;
}

.baselineSettings__item {
  margin-top: 1rem;
}

.baselineSettings__item_actions {
  display: flex;
}

.baselineSettings__item:first-child {
  margin-top: 0;
}

.baselineSettings__datepickerWrap {
  max-width: 18.75rem;
}

.baselineSettings__title {
  letter-spacing: -.025rem;
  font-size: .75rem;
  font-weight: 500;
  line-height: 1.33333;
}

.baselineSettings__formName {
  flex-direction: column;
  align-items: center;
  gap: .5rem;
  display: flex;
}

.baselineSettings__formNameInput {
  height: 2.25rem;
  font-size: .75rem !important;
}

.baselineSettings__formNameButton {
  color: #053fe3;
  opacity: 1;
  cursor: pointer;
  border-color: rgba(5, 63, 227, .25);
  flex-shrink: 0;
  height: 36px;
  margin-left: .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.baselineSettings__formNameButton:hover {
  color: #053fe3;
}

.baselineSettings__button {
  flex-grow: 1;
  flex-basis: 30%;
  margin-left: .5rem;
}

.baselineSettings__buttonHide {
  color: #fa380d !important;
  border-color: #fa380d !important;
}

.baselineSettings__button:first-child {
  margin-left: 0;
}

.baselineSettings :global .app-task-progress {
  border-radius: .25rem;
  height: .5rem;
}

.baselineSettings :global .app-task-info__additional-progress {
  margin-top: 1rem;
  margin-bottom: 1.875rem;
}

.baselineSettings :global .app-task-progress__bar {
  border-radius: .25rem;
}

.baselineSettings :global .app-task-progress__bar:after {
  background-color: #053fe3;
}

.baselineSettings :global .app-task-progress__dot {
  box-sizing: content-box;
  background-color: #fff;
  border: .25rem solid rgba(5, 63, 227, .25);
  width: 1rem;
  height: 1rem;
  top: -.5rem;
  box-shadow: 0 .25rem .5rem rgba(0, 0, 0, .1);
}

.baselineSettings :global .app-task-progress__number {
  display: none;
}

.baselineSettings :global .app-task-progress__points {
  text-align: center;
  letter-spacing: -.03125rem;
  color: rgba(30, 27, 27, .5);
  margin-top: .875rem;
  font-size: .625rem;
  line-height: 1.6;
  display: flex;
}

.baselineSettings :global .app-task-progress__point {
  text-align: left;
  flex-grow: 1;
  flex-basis: .0625rem;
  position: relative;
}

.baselineSettings :global .app-task-progress__point:before {
  content: "";
  background: rgba(5, 63, 227, .25);
  width: .0625rem;
  height: .375rem;
  position: absolute;
  bottom: calc(100% + .25rem);
  left: 0;
  transform: translateX(-50%);
}

.baselineSettings :global .app-task-progress__point:first-child .app-task-progress__pointName {
  margin-left: -.625rem;
  transform: translateX(0);
}

.baselineSettings :global .app-task-progress__point:last-child {
  flex-grow: 0;
  flex-basis: 0;
  width: 0;
}

.baselineSettings :global .app-task-progress__point:last-child .app-task-progress__pointName {
  transform: translateX(-100%);
}

.baselineSettings :global .app-task-progress__point_single {
  position: absolute;
  right: 0;
}

.baselineSettings :global .app-task-progress__point_single .app-task-progress__pointName {
  margin-left: 0 !important;
}

.baselineSettings :global .app-task-progress__pointName {
  width: -webkit-max-content;
  width: max-content;
  position: absolute;
  transform: translateX(-50%);
}
