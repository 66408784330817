.assigneesWrapper {
  flex-direction: column;
  height: 100%;
  display: flex;
}

.assigneesList {
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
}

.assigneesList__body {
  flex: 1;
}

.assigneesList__bodyInner {
  overflow-x: hidden;
}

.assigneesList__bodyInner::-webkit-scrollbar {
  display: none;
}

.assigneesList .assigneesListLoader {
  background-color: rgba(0, 0, 0, 0);
}
