.ctrlDrop {
  position: relative;
}

.ctrlDrop__header {
  align-items: center;
  margin-top: 8px;
  margin-left: 8px;
  margin-right: 8px;
  display: flex;
}

.ctrlDrop__title {
  letter-spacing: -.4px;
  color: #a5a4a4;
  margin-right: 20px;
  font-size: 12px;
  line-height: 1.33333;
}

.ctrlDrop__buttonReset {
  letter-spacing: -.4px;
  color: #fa380d;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  margin-left: auto;
  padding: 0;
  font-size: 12px;
  line-height: 1.33333;
}

.ctrlDrop__buttonClose {
  margin-left: auto;
}

.ctrlDrop__header + .ctrlDrop__body {
  margin-top: 12px;
}

.ctrlDrop__button {
  display: flex;
}

.ctrlDrop_disabled {
  opacity: .2;
  pointer-events: none;
}

.ctrlDrop__viewport {
  box-sizing: border-box;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #e9e8e8;
  border-radius: 12px;
  min-width: 100%;
  padding: 6px;
  display: none;
  position: absolute;
  top: calc(100% + 7px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, .08);
}

.ctrlDrop__viewport_position_center {
  right: 50%;
  transform: translateX(50%);
}

.ctrlDrop__viewport_position_left {
  left: 0;
}

.ctrlDrop__viewport_position_right {
  right: 0;
}

.ctrlDrop__viewport_style_popup {
  border: 4px solid rgba(5, 63, 227, .1);
  border-radius: 16px;
  padding: 0;
  overflow: hidden;
  box-shadow: 24px 24px 36px rgba(0, 0, 0, .07);
}

.ctrlDrop__viewport_style_popup .ctrlDrop__header {
  margin: 0;
  padding: 16px;
}

.ctrlDrop__viewport_style_popup .ctrlDrop__title {
  color: #1e1b1b;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}

.ctrlDrop__viewport_style_popup .ctrlDrop__body {
  margin-top: 0;
}

.ctrlDrop__viewport_in-page {
  opacity: 0;
  visibility: hidden;
  z-index: -999;
  display: block;
}

.ctrlDrop__content {
  width: 260px;
}

.ctrlDrop__toggled > .ctrlDrop__viewport {
  display: block;
}

.ctrlDrop__toggled > .ctrlDrop__viewport_in-page {
  opacity: 1;
  visibility: visible;
  z-index: 999;
}

.ctrlDrop__footer {
  background-color: #fff;
  border-top: 1px solid #e9e8e8;
  border-bottom-right-radius: 11px;
  border-bottom-left-radius: 11px;
  margin: 12px -6px -5px;
  padding: 12px;
}

.ctrlDrop_color_second .ctrlDrop__viewport {
  background-color: #fbfbfb;
}
