.h-spacer-1 {
  width: 2px;
  min-width: 2px;
  height: 0;
}

.v-spacer-1 {
  width: 0;
  height: 2px;
  min-height: 2px;
}

.h-spacer-2 {
  width: 4px;
  min-width: 4px;
  height: 0;
}

.v-spacer-2 {
  width: 0;
  height: 4px;
  min-height: 4px;
}

.h-spacer-3 {
  width: 8px;
  min-width: 8px;
  height: 0;
}

.v-spacer-3 {
  width: 0;
  height: 8px;
  min-height: 8px;
}

.h-spacer-4 {
  width: 12px;
  min-width: 12px;
  height: 0;
}

.v-spacer-4 {
  width: 0;
  height: 12px;
  min-height: 12px;
}

.h-spacer-5 {
  width: 16px;
  min-width: 16px;
  height: 0;
}

.v-spacer-5 {
  width: 0;
  height: 16px;
  min-height: 16px;
}

.h-spacer-6 {
  width: 20px;
  min-width: 20px;
  height: 0;
}

.v-spacer-6 {
  width: 0;
  height: 20px;
  min-height: 20px;
}

.h-spacer-7 {
  width: 24px;
  min-width: 24px;
  height: 0;
}

.v-spacer-7 {
  width: 0;
  height: 24px;
  min-height: 24px;
}

.h-spacer-8 {
  width: 32px;
  min-width: 32px;
  height: 0;
}

.v-spacer-8 {
  width: 0;
  height: 32px;
  min-height: 32px;
}

.h-spacer-9 {
  width: 40px;
  min-width: 40px;
  height: 0;
}

.v-spacer-9 {
  width: 0;
  height: 40px;
  min-height: 40px;
}

.h-spacer-10 {
  width: 48px;
  min-width: 48px;
  height: 0;
}

.v-spacer-10 {
  width: 0;
  height: 48px;
  min-height: 48px;
}

.h-spacer-11 {
  width: 64px;
  min-width: 64px;
  height: 0;
}

.v-spacer-11 {
  width: 0;
  height: 64px;
  min-height: 64px;
}

.h-spacer-12 {
  width: 80px;
  min-width: 80px;
  height: 0;
}

.v-spacer-12 {
  width: 0;
  height: 80px;
  min-height: 80px;
}

.h-spacer-13 {
  width: 96px;
  min-width: 96px;
  height: 0;
}

.v-spacer-13 {
  width: 0;
  height: 96px;
  min-height: 96px;
}

.h-spacer-14 {
  width: 160px;
  min-width: 160px;
  height: 0;
}

.v-spacer-14 {
  width: 0;
  height: 160px;
  min-height: 160px;
}
