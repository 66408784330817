.galleryEmpty {
  box-sizing: border-box;
  background-color: rgba(30, 27, 27, .02);
  border: 1px solid #e9e8e8;
  border-radius: 7px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: inline-flex;
  position: relative;
}

.galleryEmpty:after {
  content: "";
  z-index: 0;
  background-image: url("./empty.svg");
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.galleryEmpty_placeholder:after {
  opacity: .2;
}
