.fieldInline {
  box-sizing: border-box;
  white-space: nowrap;
  letter-spacing: -.0625rem;
  color: #1e1b1b;
  text-overflow: ellipsis;
  border: .0625rem solid rgba(0, 0, 0, 0);
  border-radius: .5625rem;
  outline: 0 solid rgba(0, 0, 0, 0);
  width: 100%;
  padding: .3125rem .375rem;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.55556;
  transition-property: border-color, background-color, outline-color, outline-width;
  transition-duration: .125s;
  transition-timing-function: ease-in-out;
  display: inline;
}

.fieldInline::placeholder {
  color: #787676;
}

.fieldInline:hover {
  background-color: #f4f3f3;
}

.fieldInline:focus {
  background-color: #fff;
  border-color: #d2d1d1;
  outline: 3px solid #e6ecfc;
}

.fieldInline_textarea {
  resize: none;
}

.fieldInline_bgcolor_second {
  background-color: #fbfbfb;
}

.fieldInline_size_s {
  letter-spacing: -.025rem;
  height: 2.5rem;
  min-height: 2.5rem;
  padding: .5rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
}

.fieldInline_size_l, .fieldInline__pretext {
  letter-spacing: -.09375rem;
  height: 3.25rem;
  min-height: 3.25rem;
  padding: .5rem;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.5;
}
