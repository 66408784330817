.panel__actionsResponsive {
  margin-bottom: 16px;
}

@media only screen and (min-width: 1300px) {
  .panel__actionsResponsive {
    display: none;
  }
}

.panel__actionsDesktop {
  display: none;
}

@media only screen and (min-width: 1300px) {
  .panel__actionsDesktop {
    display: block;
  }
}

.panel__actions {
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}
