.action-button--danger {
  background: #fa380d;
  border-color: #fa380d;
}

.action-button--success {
  color: #fff;
  background-color: #21a032;
  border-color: #21a032;
}
