.noData {
  white-space: break-spaces;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

:global .gantt_grid.is-blocked:after {
  content: "Processing...";
  opacity: .7;
  z-index: 10;
  background: #fff;
  justify-content: center;
  align-items: center;
  transition: all 1.5s ease-in-out;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
