.contentList > * {
  margin-top: 4px;
}

.contentList > :first-child {
  margin-top: 0;
}

.exportTasks__container {
  position: relative;
}

.exportTasks__viewport {
  box-sizing: border-box;
  z-index: 100;
  background-color: #fff;
  border: 1px solid #e9e8e8;
  border-radius: 12px;
  min-width: 100%;
  padding: 12px 6px 6px;
  display: none;
  position: absolute;
  top: 0;
  box-shadow: 0 6px 16px rgba(0, 0, 0, .08);
}

.exportTasks__viewport .is-active {
  display: block;
}
