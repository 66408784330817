.Member {
  background-color: #fff;
  border: 1px solid #e9e8e8;
  border-radius: 9px;
  margin-bottom: 12px;
}

.Member__header {
  align-items: center;
  padding: 12px;
  display: flex;
}

.Member__avatar {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

.Member__info {
  min-width: 0;
  margin-right: 8px;
}

.Member__info:last-child {
  margin-right: 0;
}

.Member__name {
  letter-spacing: -.4px;
  color: #1e1b1b;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-items: center;
  max-width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  overflow: hidden;
}

.Member__trade {
  letter-spacing: -.4px;
  color: #787676;
  margin-top: 2px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33333;
}

.Member__orgWrap {
  flex-wrap: wrap;
  margin: -4px;
  display: flex;
}

.Member__org {
  box-sizing: border-box;
  color: #4b4949;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid #e9e8e8;
  border-radius: 9px;
  flex-shrink: 0;
  max-width: 102px;
  margin: 4px;
  padding: 4px 7px;
  font-size: 12px;
  overflow: hidden;
}

.Member__actions {
  flex-shrink: 0;
  margin-left: auto;
}

.Member__body {
  background: rgba(30, 27, 27, .02);
  border-top: 1px solid #e9e8e8;
  padding: 12px;
}
