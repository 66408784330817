.contentSection {
  padding-top: 50px;
  padding-bottom: 50px;
  overflow: hidden;
}

@media only screen and (min-width: 650px) {
  .contentSection {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.contentSection__container {
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media only screen and (min-width: 1024px) {
  .contentSection__container {
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
  }

  .contentSection__container_reverse {
    flex-direction: row-reverse;
  }
}

.contentSection__title {
  color: #111827;
  text-transform: capitalize;
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  line-height: 36px;
}

@media only screen and (min-width: 1024px) {
  .contentSection__title {
    text-align: left;
  }

  .contentSection__title_hideOnDesktop {
    display: none;
  }
}

.contentSection__title_hideOnMobile {
  display: none;
}

@media only screen and (min-width: 1024px) {
  .contentSection__title_hideOnMobile {
    display: block;
  }
}

.contentSection__descriptionBlock {
  flex-direction: column;
  width: auto;
  margin-left: 0;
  display: flex;
}

@media only screen and (min-width: 1024px) {
  .contentSection__descriptionBlock {
    align-items: start;
    width: 400px;
    margin-top: 115px;
    margin-left: 200px;
  }
}

.contentSection__descriptionBlock_reverse {
  width: auto;
  margin-left: 0;
}

@media only screen and (min-width: 576px) {
  .contentSection__descriptionBlock_reverse {
    width: auto;
    margin-left: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .contentSection__descriptionBlock_reverse {
    max-width: 500px;
    margin-left: 0;
  }
}

.contentSection__description {
  color: #374151;
  text-align: center;
  margin-top: 24px;
  font-size: 16px;
  line-height: 32px;
}

@media only screen and (min-width: 1024px) {
  .contentSection__description {
    text-align: left;
  }
}

.contentSection__actions {
  text-align: center;
  margin-top: 48px;
}

@media only screen and (min-width: 1024px) {
  .contentSection__actions {
    text-align: left;
  }
}
