.nameColumn {
  align-items: center;
  display: flex;
}

.nameColumn__photo {
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: 8px;
  overflow: hidden;
}

.nameColumn :global .avatar {
  width: 32px;
  height: 32px;
}

.nameColumn__name {
  white-space: nowrap;
  flex-grow: 1;
}
