.comments-timeline {
  grid-template-rows: min-content auto;
  grid-template-columns: 100%;
  gap: .5rem;
  min-height: calc(100vh - 348px);
  display: grid;
  position: relative;
}

.comments-timeline--list {
  scrollbar-width: none;
  max-height: calc(100vh - 378px);
  overflow-y: auto;
}

.comments-timeline-form-action {
  flex-direction: column;
  justify-content: flex-end;
  align-self: end;
  display: flex;
}

.icon-rotate-backward svg {
  transition: transform .2s ease-in-out;
  transform: rotate(0);
}

.icon-rotate-forward svg {
  transition: transform .2s ease-in-out;
  transform: rotate(495deg);
}
