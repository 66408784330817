.image-loading {
  width: 50px;
  height: 50px;
}

.image-gallery-container {
  align-items: flex-start;
  display: flex;
}

.image-gallery-container-expanded {
  flex-direction: column;
  width: 100%;
  margin-bottom: .3125rem;
}

.image-container {
  flex-shrink: 0;
  width: 50px;
  margin-right: 10px;
  transition: all .3s;
  position: relative;
  overflow: hidden;
}

.image-container-expanded {
  aspect-ratio: 1;
  flex-direction: column;
  width: 100%;
  height: 18.75rem;
  margin-bottom: .625rem;
}

.image-empty {
  width: 4.6875rem;
  height: 4.6875rem;
}

.image-content {
  aspect-ratio: 1;
  cursor: pointer;
  object-fit: cover;
  border-radius: .3125rem;
  width: 100%;
  height: 100%;
}

.image-overlay {
  color: #fff;
  pointer-events: none;
  background: rgba(0, 0, 0, .5);
  border-radius: .3125rem;
  justify-content: center;
  align-items: center;
  font-size: .75rem;
  font-weight: bold;
  display: flex;
  position: absolute;
  top: 0;
  bottom: .1875rem;
  left: 0;
  right: 0;
}

.image-navigation {
  color: #fff;
  background: rgba(0, 0, 0, .5);
  justify-content: center;
  align-items: center;
  padding: .3125rem;
  display: flex;
  position: absolute;
  bottom: .625rem;
  left: 0;
  right: 0;
}

.image-navigation-icon {
  fill: #fff;
  cursor: pointer;
  padding: 0 .625rem;
}

.image-navigation-icon:hover {
  opacity: .8;
}

.image-navigation span {
  margin: 0 .625rem;
  font-size: .75rem;
}

.image-controls {
  background: rgba(0, 0, 0, .5);
  border-top-left-radius: .3125rem;
  border-top-right-radius: .3125rem;
  justify-content: space-between;
  padding: .3125rem;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.image-controls.hide {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}
