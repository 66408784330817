.CommentsDate {
  letter-spacing: -.4px;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  line-height: 1.33333;
  display: flex;
}

.CommentsDate__textAfterDate {
  color: #a5a4a4;
  margin-right: 6px;
}

.CommentsDate__date {
  color: #787676;
  flex-shrink: 0;
}

.CommentsDate:before, .CommentsDate:after {
  content: "";
  background: rgba(30, 27, 27, .05);
  border-radius: 1px;
  flex-grow: 1;
  flex-basis: 10px;
  height: 1px;
}

.CommentsDate:before {
  margin-right: 12px;
}

.CommentsDate:after {
  margin-left: 12px;
}
