.ctrlAppDetect__button {
  flex-shrink: 0;
  width: 100px;
  display: block;
}

@media only screen and (min-width: 576px) {
  .ctrlAppDetect__button {
    width: 120px;
  }
}

.ctrlAppDetect :global .ctrl-btn-app__text {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.ctrlAppDetect :global .ctrl-btn-app__image {
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: block;
}

.ctrlAppDetect__link {
  color: inherit;
  letter-spacing: -.4px;
  white-space: nowrap;
  font-size: 14px;
  line-height: 1.2;
  text-decoration: none;
  transition-property: color;
  transition-duration: .125s;
  transition-timing-function: ease;
}

.ctrlAppDetect__link:hover {
  color: #b91c1c;
}
