.ctrlTextfield {
  box-sizing: border-box;
  color: #fff;
  background-color: #000;
  border: 1px solid #000;
  border-radius: 6px;
  outline: none;
  width: 100%;
  padding: 8px 20px;
  font-size: 16px;
  line-height: 32px;
  display: block;
}

.ctrlTextfield::placeholder {
  color: #9aa3ae;
  opacity: 1;
}
