.counter {
  color: #053fe3;
  box-sizing: border-box;
  border: 1px solid rgba(5, 63, 227, .25);
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  width: 160px;
  height: 36px;
  padding: 8px 8px 8px 12px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  position: relative;
}

.counter[aria-disabled="true"] {
  color: rgba(5, 63, 227, .1);
  border: 1px solid rgba(5, 63, 227, .1);
  font-weight: 100;
}

.count {
  margin: 0 12px;
}
