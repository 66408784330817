.snapshot {
  cursor: pointer;
  align-items: center;
  padding: 12px 16px;
  display: flex;
}

.snapshot__cell {
  letter-spacing: -.4px;
  color: #1e1b1b;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-shrink: 0;
  margin-left: 16px;
  font-size: 12px;
  line-height: 1.33333;
  overflow: hidden;
}

.snapshot__cell_date {
  width: 142px;
}

.snapshot__cell_worker {
  width: 173px;
}

.snapshot__cell_name {
  flex-grow: 1;
  flex-shrink: 1;
}

.snapshot__cell:first-child {
  margin-left: 0;
}

.snapshot__fieldNameBox {
  margin: -3px;
}

.snapshot__buttonRemove {
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
  padding: 0;
}

.snapshot_isActive {
  background-color: rgba(5, 63, 227, .1);
}
