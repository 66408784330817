.projectPreviewCard__button {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.projectPreviewCard__popup header {
  margin-top: 1rem;
}

@media only screen and (min-width: 1024px) {
  .projectPreviewCard__popup {
    width: 50%;
  }
}

.projectPreviewCard__progress-bar {
  justify-content: center;
  align-items: center;
  max-width: 11.25rem;
  min-height: 9.375rem;
  margin: 0 auto;
  display: flex;
}

@media only screen and (min-width: 650px) {
  .projectPreviewCard__progress-bar {
    max-width: 25rem;
  }
}

.projectPreviewCard__progress-bar :global .progressbar {
  border-radius: unset;
  box-shadow: none;
  padding: unset;
  flex: 1;
  margin-right: .5rem;
  display: flex;
  position: relative;
}

.projectPreviewCard__progress-bar :global .progressbar .progressbar__container {
  background: #fbfbfb;
  border-radius: 8px;
  flex: 1;
  height: .25rem;
  display: flex;
}

.projectPreviewCard__progress-bar :global .progressbar:after {
  content: "";
  pointer-events: none;
  border: .0625rem solid #000;
  border-radius: .5rem;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  position: absolute;
  top: -.125rem;
  left: -.125rem;
}
