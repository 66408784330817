.projectsList :global .projects-list__grid {
  grid-template-columns: repeat(auto-fill, minmax(245px, 1fr));
  gap: 24px;
  margin: 0;
  display: grid;
}

.projectsList :global .projects-list__item {
  width: auto;
  margin: 0;
}
