.container {
  flex-direction: row;
  justify-content: space-between;
  min-width: 11.125rem;
  display: flex;
  position: relative;
}

.container_disabled {
  pointer-events: none;
  justify-content: flex-start;
}

.row {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.input {
  color: #053fe3;
  font-variant-numeric: tabular-nums;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  width: 2.5rem;
  margin-left: .125rem;
  font-size: .8125rem;
}

.input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.input .editable-input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.input_error {
  color: #fa380d;
}

.input:focus {
  box-shadow: 0 0 .3125rem rgba(0, 0, 0, .1);
}

.button {
  color: rgba(5, 63, 227, .25);
  margin-left: .5rem;
}

.text {
  letter-spacing: -.03125rem;
  text-align: center;
  padding-left: .5rem;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.25rem;
}

.text .value {
  color: #053fe3;
}

.text .value_error {
  color: #fa380d;
}

.label {
  cursor: pointer;
}

.error {
  color: #fa380d;
  letter-spacing: 0;
  text-align: center;
  min-width: 7.625rem;
  font-size: .625rem;
  position: absolute;
  bottom: -.875rem;
  left: 0;
}
