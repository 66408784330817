.CtrlCheckGroup__title {
  letter-spacing: -.4px;
  color: #787676;
  margin-bottom: 12px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33333;
}

.CtrlCheckGroup__grid {
  flex-direction: column;
  display: flex;
}

.CtrlCheckGroup__item {
  margin-bottom: 24px;
}

.CtrlCheckGroup__item:last-child {
  margin-bottom: 0;
}

.CtrlCheckGroup_direction_line .CtrlCheckGroup__grid {
  flex-flow: wrap;
  margin: -7px -14px;
}

.CtrlCheckGroup_direction_line .CtrlCheckGroup__item {
  margin: 7px 14px;
}
