.upsell__upsell_container {
  background-color: #fff;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 18px 18px 24px rgba(0, 0, 0, .1);
}

.upsell__upsell_title {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}

.upsell__upsell_image {
  max-width: 100%;
  max-height: 100%;
}

.upsell__upsell_image_title {
  text-align: left;
  margin-top: 16px;
  margin-left: 8px;
  font-size: 18px;
}

.upsell__upsell_image_ul {
  text-align: left;
  margin-top: 16px;
  margin-left: 8px;
  font-size: 12px;
}

.upsell__upsell_image_title_li {
  text-align: left;
  margin-left: 24px;
  list-style-type: none;
}

.upsell__upsell_ctrl_btn {
  letter-spacing: -.5px;
  text-align: center;
  white-space: nowrap;
  color: #053fe3;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  -o-transition-property: border-color, background-color;
  -o-transition-duration: .125s;
  -o-transition-timing-function: ease-in-out;
  background-color: #aaccfa;
  border: 2px solid #053fe3;
  border-radius: 8px;
  outline: none;
  align-items: center;
  width: 100%;
  margin-bottom: 32px;
  padding: 11px 14px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-decoration: none;
  transition-property: border-color, background-color;
  transition-duration: .125s;
  transition-timing-function: ease-in-out;
}
