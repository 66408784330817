.colorsList__label {
  letter-spacing: -.4px;
  color: #787676;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}

.colorsList__grid {
  flex-wrap: wrap;
  margin: -6px;
  display: flex;
}

.colorsList__item {
  flex-shrink: 0;
  margin: 6px;
}
