.CtrlSwitch {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.CtrlSwitch__field {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.CtrlSwitch__field:checked ~ .CtrlSwitch__visual {
  background-color: #053fe3;
  border-color: #053fe3;
}

.CtrlSwitch__field:checked ~ .CtrlSwitch__visual:before {
  left: 16px;
}

.CtrlSwitch__visual {
  box-sizing: border-box;
  background-color: #e9e8e8;
  border: 2px solid #e9e8e8;
  border-radius: 20px;
  width: 36px;
  height: 20px;
  margin-right: 8px;
  transition-property: border-color, background-color;
  transition-duration: .125s;
  transition-timing-function: ease-in-out;
  position: relative;
}

.CtrlSwitch__visual:before {
  content: "";
  width: 16px;
  height: 16px;
  transition-property: left;
  transition-duration: inherit;
  transition-timing-function: inherit;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.CtrlSwitch__label {
  letter-spacing: -.4px;
  padding-top: 3px;
  font-size: 16px;
  line-height: 1;
}

.CtrlSwitch_view_control {
  background-color: #fff;
  border: 1px solid #e9e8e8;
  border-radius: 16px;
  padding: 5px 11px 5px 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .08);
}
