.filterDropdown__input {
  width: 130px;
}

.filterDropdown__input_size {
  height: 36px;
  font-size: 12px !important;
}

.filterDropdown__formControl + .filterDropdown__formControl {
  margin-bottom: 5px;
}

.filterDropdown__buttonOption {
  padding: 8px 7px;
}
