.contentTabs__nav {
  background-color: #f3f4f6;
  border-radius: 6px;
  justify-content: center;
  max-width: 560px;
  height: 56px;
  margin: 0 auto 24px;
  padding: 2px;
  display: flex;
}

.contentTabs__navItem {
  flex-grow: 1;
  margin-right: 2px;
}

.contentTabs__navItem:last-child {
  margin-right: 0;
}

.contentTabs__navItem:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.contentTabs__navItem:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
