.subcontractorTab {
  background-color: #fff;
}

.subcontractorTab__header {
  background-color: rgba(30, 27, 27, .02);
  border-bottom: 1px solid #e9e8e8;
  padding: 24px 20px;
}

.subcontractorTab__body {
  padding: 24px 20px;
}

.subcontractorTab__part {
  border-bottom: 1px solid #e9e8e8;
  margin-bottom: 24px;
  padding-bottom: 24px;
}

.subcontractorTab__part:last-child {
  border-bottom-width: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.subcontractorTab__member {
  border-top: 1px solid #e9e8e8;
  margin: 0 24px;
  padding: 24px 0;
}
