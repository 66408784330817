.comment-form {
  z-index: 100;
  background-color: #fff;
  border: .125rem solid #e9e8e8;
  border-radius: .5rem;
  max-height: 50rem;
  margin: 0 1.25rem;
  padding: 1rem;
  position: absolute;
  left: 0;
  right: 0;
  overflow-y: auto;
  transform: translateY(calc(-100% - 8px));
}

.comment-form form {
  width: 100%;
}

.comment-form--input-container {
  margin: .625rem 0;
}

.comment-form--input-container input[name="comment"], .comment-form--input-container input[name="referToDate"], .comment-form--input-container input[name="comment"]:hover, .comment-form--input-container input[name="comment"]:focus, .comment-form--input-container input[name="referToDate"]:hover, .comment-form--input-container input[name="referToDate"]:focus {
  background: #fff;
}

.comment-form .comment-input-container {
  flex-direction: row;
  display: flex;
}

.comment-form .comment-form--date-picker-checkbox-container {
  gap: 1.25rem;
  display: flex;
}

.comment-form .comment-form--date-picker-checkbox-container .checked {
  background-color: #4dda60;
}

.comment-form .comment-form--date-picker-checkbox-container :global .ctrl-toggle__field:focus ~ .ctrl-toggle__visual {
  outline: 1px solid #053fe3;
}

.comment-form .comment-form--submit-button {
  background-color: #053fe3;
  align-self: flex-end;
  min-width: 2.5rem;
  min-height: 2.5rem;
  margin: 0 0 0 .625rem;
}

.comment-form .comment-form--submit-button div {
  margin-right: unset;
}

.comment-form .comment-form--submit-button svg {
  color: #fff;
  position: relative;
  top: .125rem;
  left: .0625rem;
}

.comment-form .comment-form--submit-button:hover svg, .comment-form .comment-form--submit-button:focus svg {
  color: #fff;
}

.comment-form .file-list-container {
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: .5rem;
  width: 100%;
  margin: .625rem 0 0;
  font-size: .75rem;
  display: flex;
  overflow-x: auto;
}

.comment-form .file-list-container .pill {
  flex-shrink: 0;
}

.comment-form .files {
  display: none;
}

.comment-form .file-upload-button {
  background: #fff;
  width: 100%;
}

.visible {
  opacity: 1;
  visibility: visible;
  background-color: #f4f3f3;
  transition: opacity .3s ease-in-out, visibility linear, height .3s ease-in-out;
}

.react-datepicker-overrides {
  background-color: #f4f3f3;
  border: .0625rem solid rgba(5, 63, 227, .25);
  border-radius: .25rem;
  padding: .5rem;
  box-shadow: .3125rem .3125rem .3125rem #d3d3d3;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.label-icon-container {
  cursor: help;
  display: inline-block;
}

.label-icon-container > svg {
  margin-left: .25rem;
}

.error-message {
  background: unset;
  left: unset;
  bottom: unset;
  color: #fa380d;
  font-size: .625rem;
}

.error-message:before, .error-message-hidden {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

:global .filteredDate {
  background-color: rgba(33, 160, 50, .1);
  font-weight: 500;
  color: #21a032 !important;
}
