.ctrlColorPreview {
  box-sizing: border-box;
  letter-spacing: -1px;
  color: #fff;
  background-color: #d3d3d3;
  border-radius: 9px;
  justify-content: center;
  align-items: center;
  width: 68px;
  height: 68px;
  padding: 5px;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.55556;
  display: flex;
}

.ctrlColorPreview_size_s {
  min-width: 36px;
  max-width: 36px;
  min-height: 36px;
  max-height: 36px;
  font-size: 12px;
}
