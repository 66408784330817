.gridHoverStyle, .gridSelection, .timelineSelection {
  background-color: #fff3a1;
}

.gantt_grid_scale .gantt_grid_head_cell {
  color: #a6a6a6;
  border-top: none !important;
  border-right: none !important;
}

.gantt_grid_data .gantt_cell {
  color: #454545;
  border-right: none;
}

.gantt_task_link .gantt_link_arrow_right {
  border-width: 6px;
  margin-top: -3px;
}

.gantt_task_link .gantt_link_arrow_left {
  border-width: 6px;
  margin-top: -3px;
  margin-left: -6px;
}

.gantt_task_link .gantt_link_arrow_down, .gantt_task_link .gantt_link_arrow_up {
  border-width: 6px;
}

.gantt_task_line .gantt_task_progress_drag {
  width: 16px;
  height: 10px;
  margin-left: -8px;
  bottom: -4px;
}

.chartHeaderBg {
  background-color: #fff;
}

.gantt_task .gantt_task_scale .gantt_scale_cell {
  color: #a6a6a6;
  border-right: 1px solid #ebebeb;
}

.gantt_row.gantt_project, .gantt_row.odd.gantt_project {
  background-color: #edffef;
}

.gantt_task_row.gantt_project, .gantt_task_row.odd.gantt_project {
  background-color: #f5fff6;
}

.gantt_task_line.gantt_project {
  background-color: #65c16f;
  border: 1px solid #3c9445;
}

.gantt_task_line.gantt_project .gantt_task_progress {
  background-color: #46ad51;
}

.buttonBg {
  background: #fff;
}

.gantt_cal_light .gantt_btn_set {
  margin: 5px 10px;
}

.gantt_btn_set.gantt_cancel_btn_set {
  color: #454545;
  background: #fff;
  border: 1px solid #cecece;
}

.gantt_btn_set.gantt_save_btn_set {
  text-shadow: 0 -1px #248a9f;
  color: #fff;
  background: #3db9d3;
}

.gantt_btn_set.gantt_delete_btn_set {
  text-shadow: 0 -1px #a60;
  color: #fff;
  background: #ec8e00;
}

.gantt_cal_light_wide {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.gantt_cal_light_wide .gantt_cal_larea {
  border-left: none !important;
  border-right: none !important;
}

.gantt_popup_button.gantt_ok_button {
  text-shadow: 0 -1px #248a9f;
  color: #fff;
  background: #3db9d3;
  border-width: 0;
  font-weight: 700;
}

.gantt_popup_button.gantt_cancel_button {
  color: #454544;
  font-weight: 700;
}

.gantt_popup_title {
  background-color: #fff;
}

.gantt_popup_shadow {
  box-shadow: 3px 3px 3px rgba(0, 0, 0, .07);
}

.gantt_qi_big_icon.icon_edit {
  color: #454545;
  background: #fff;
}

.gantt_qi_big_icon.icon_delete {
  text-shadow: 0 -1px #a60;
  color: #fff;
  background: #ec8e00;
  border-width: 0;
}

.gantt_tooltip {
  color: #454545;
  border-top: 1px solid rgba(0, 0, 0, .07);
  border-left: 1px solid rgba(0, 0, 0, .07);
  font-size: 8pt;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, .07);
}

.gantt_container, .gantt_tooltip {
  background-color: #fff;
  font-family: Arial;
}

.gantt_container {
  white-space: nowrap;
  border: 1px solid #cecece;
  font-size: 13px;
  position: relative;
  overflow: hidden;
}

.gantt_touch_active {
  overscroll-behavior: none;
}

.gantt_task_scroll {
  overflow-x: scroll;
}

.gantt_grid, .gantt_task {
  vertical-align: top;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.gantt_grid_scale, .gantt_task_scale {
  color: #6b6b6b;
  box-sizing: border-box;
  border-bottom: 1px solid #cecece;
  font-size: 12px;
}

.gantt_grid_scale, .gantt_task_scale, .gantt_task_vscroll {
  background-color: #fff;
}

.gantt_scale_line {
  box-sizing: border-box;
  border-top: 1px solid #cecece;
}

.gantt_scale_line:first-child {
  border-top: none;
}

.gantt_grid_head_cell {
  vertical-align: top;
  text-align: center;
  cursor: default;
  box-sizing: border-box;
  height: 100%;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  user-select: none;
  border-right: 1px solid #cecece;
  line-height: 33px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.gantt_scale_line {
  clear: both;
}

.gantt_grid_data {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.gantt_row {
  -webkit-user-select: none;
  -moz-user-select: none;
  -moz-user-select: -moz-none;
  position: relative;
}

.gantt_add, .gantt_grid_head_add {
  cursor: pointer;
  -moz-opacity: .3;
  opacity: .3;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NTQ3MjMyMENDNkI0MTFFMjk4MTI5QTg3MDhFNDVDQTkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NTQ3MjMyMERDNkI0MTFFMjk4MTI5QTg3MDhFNDVDQTkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo1NDcyMzIwQUM2QjQxMUUyOTgxMjlBODcwOEU0NUNBOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo1NDcyMzIwQkM2QjQxMUUyOTgxMjlBODcwOEU0NUNBOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PshZT8UAAABbSURBVHjaYrTdeZmBEsCER+4wEP+H4sPkGGCDg020ARR7gb4GIAcYDKMDdPnDyAbYkGG5DVW9cIQMvUdBBAuUY4vDz8iAcZinA2zgCHqAYQMseAywJcYFAAEGAM+UFGuohFczAAAAAElFTkSuQmCC");
  background-position: 50%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: relative;
}

.gantt_grid_head_cell.gantt_grid_head_add {
  -moz-opacity: .6;
  opacity: .6;
  top: 0;
}

.gantt_grid_head_cell.gantt_grid_head_add:hover {
  -moz-opacity: 1;
  opacity: 1;
}

.gantt_grid_data .gantt_row.odd:hover, .gantt_grid_data .gantt_row:hover {
  background-color: #fff3a1;
}

.gantt_grid_data .gantt_row.odd:hover .gantt_add, .gantt_grid_data .gantt_row:hover .gantt_add {
  -moz-opacity: 1;
  opacity: 1;
}

.gantt_row, .gantt_task_row {
  background-color: #fff;
  border-bottom: 1px solid #ebebeb;
}

.gantt_row.odd, .gantt_task_row.odd {
  background-color: #fff;
}

.gantt_cell, .gantt_grid_head_cell, .gantt_row, .gantt_scale_cell, .gantt_task_cell, .gantt_task_row {
  box-sizing: border-box;
}

.gantt_grid_head_cell, .gantt_scale_cell {
  line-height: inherit;
}

.gantt_grid_scale .gantt_grid_column_resize_wrap {
  cursor: col-resize;
  width: 13px;
  margin-left: -7px;
  position: absolute;
}

.gantt_grid_column_resize_wrap .gantt_grid_column_resize {
  background-color: #cecece;
  width: 1px;
  height: 100%;
  margin: 0 auto;
}

.gantt_task_grid_row_resize_wrap {
  cursor: row-resize;
  width: 100%;
  height: 13px;
  margin-top: -7px;
  position: absolute;
  left: 0;
}

.gantt_task_grid_row_resize_wrap .gantt_task_grid_row_resize {
  background-color: #ebebeb;
  width: 100%;
  height: 1px;
  margin: 0 auto;
  position: relative;
  top: 6px;
}

.gantt_drag_marker {
  pointer-events: none;
}

.gantt_drag_marker.gantt_grid_resize_area, .gantt_drag_marker.gantt_row_grid_resize_area {
  box-sizing: border-box;
  background-color: rgba(232, 232, 232, .5);
  width: 100%;
  height: 100%;
}

.gantt_drag_marker.gantt_grid_resize_area {
  border-left: 1px solid #cecece;
  border-right: 1px solid #cecece;
}

.gantt_drag_marker.gantt_row_grid_resize_area {
  pointer-events: none;
  border-top: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
}

.gantt_row {
  display: flex;
}

.gantt_row > div {
  flex-grow: 0;
  flex-shrink: 0;
}

.gantt_cell {
  vertical-align: top;
  white-space: nowrap;
  border-right: 1px solid #ebebeb;
  height: 100%;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 13px;
  overflow: hidden;
}

.gantt_cell_tree {
  flex-wrap: nowrap;
  display: flex;
}

.gantt_grid_data .gantt_last_cell, .gantt_grid_scale .gantt_last_cell, .gantt_task .gantt_task_scale .gantt_scale_cell.gantt_last_cell, .gantt_task_bg .gantt_last_cell {
  border-right-width: 0;
}

.gantt_task .gantt_task_scale .gantt_scale_cell.gantt_last_cell {
  border-right-width: 1px;
}

.gantt_task_bg {
  overflow: hidden;
}

.gantt_scale_cell {
  white-space: nowrap;
  text-align: center;
  border-right: 1px solid #cecece;
  height: 100%;
  display: inline-block;
  overflow: hidden;
}

.gantt_task_cell {
  border-right: 1px solid #ebebeb;
  height: 100%;
  display: inline-block;
}

.gantt_layout_cell.gantt_ver_scroll {
  z-index: 1;
  background-color: rgba(0, 0, 0, 0);
  width: 0;
  height: 1px;
  position: absolute;
  right: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.gantt_ver_scroll > div {
  width: 1px;
  height: 1px;
}

.gantt_hor_scroll {
  clear: both;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 0;
  overflow-x: scroll;
  overflow-y: hidden;
}

.gantt_layout_cell .gantt_hor_scroll {
  position: absolute;
}

.gantt_hor_scroll > div {
  width: 5000px;
  height: 1px;
}

.gantt_tree_icon, .gantt_tree_indent {
  flex-grow: 0;
  flex-shrink: 0;
}

.gantt_tree_indent {
  width: 15px;
  height: 100%;
}

.gantt_tree_content, .gantt_tree_icon {
  vertical-align: top;
}

.gantt_tree_icon {
  background-position: 50%;
  background-repeat: no-repeat;
  width: 28px;
  height: 100%;
}

.gantt_tree_content {
  white-space: nowrap;
  min-width: 0;
  height: 100%;
}

.gantt_tree_icon.gantt_open {
  cursor: pointer;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAArklEQVQ4T2NkoBJgpJI5DEPAoFOnTv0/c+YMQR+bmJgwmJmZwX2E4bVp06b9j4yMZODg4MBp2I8fPxiWL1/OkJWVNUAGcXJyMnz//h3uQrJdRLFBIAPQAchlJLsIFuCMjIwM////B5sJMoRkg2CuIdtrQcHBDOxsbHBfCQgIMHz48AHO//nrF8O6tWsJR7+7uzsDIxMTznT0/98/hp07d+I3iGopm2DewKFg8OV+AJWkfRMrTobLAAAAAElFTkSuQmCC");
  width: 18px;
}

.gantt_tree_icon.gantt_close {
  cursor: pointer;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAkUlEQVQ4T2NkoBJgpJI5DEPAoFOnTv0/c+YMQR+bmJgwmJmZwX2E4bVp06b9j4yMZODg4MBp2I8fPxiWL1/OkJWVNeIN4uTkxAin79+/M5AcRtgCHGQIyQbhijaiDQoKDmZgZ2PDGf0/f/1iWLd2LeHod3d3Z2BkYsJp0P9//xh27tyJ3yCqpWyCeQOHgsGX+wEZpW4T5LCxKwAAAABJRU5ErkJggg==");
  width: 18px;
}

.gantt_tree_icon.gantt_blank {
  width: 18px;
}

.gantt_tree_icon.gantt_folder_open {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAs0lEQVQ4T62T0Q2EIBBEpQlzuaaMsQoqooqLsSljbMLLmMxmUXBR4U+Qt7Mzi2sqLVeJ00SgEMKWAnvvzYLyAyHfT5sU2fXDJSwCAXK8MI0/UTkva7IIFJsg3NSwnKdFoKtAWOQ1CN7CEqeTotE5L7QyJhmBcklZM4ZgTiAr3iOU3kD93ppO5SkMjB1EeXdBWoSkRql3YeIRe+cGvktS056JR9wsmeBUkujCfNXWCPC8GugPqn5ii/hV+FoAAAAASUVORK5CYII=");
}

.gantt_tree_icon.gantt_folder_closed {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAfElEQVQ4T2NkoBJgpJI5DCgGTZ8+/T82gzMzMwlaCFcAM0RKQgyrI/0Dg/EahmIQyBB0DRvXr4W78tmLV1gtAbmYoEEgnciG4QpTogzCFyEwSyg2CBS2oCAZNQh3cA+hMAJ5AlcKxuVBlOgnNgVjMwyUrQjmamKLGaoZBAAOTFyLnFFW4wAAAABJRU5ErkJggg==");
}

.gantt_tree_icon.gantt_file {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAeElEQVQ4T2NkoBJgRDZn+vTp/wmZm5mZiaIHph7DICkJMUJmMfgHBmMYhtUgbAo3rl+L4lp0NUQbBPI2umuRDaPIIFAYwAyjv0HoMQALM5JdhG4QLMxGDcKdyIdoGIE89OzFK4KZF5Rl8EY/QROQFGA1iBQD0NUCAJVjcxO0naAQAAAAAElFTkSuQmCC");
}

.gantt_grid_head_cell .gantt_sort {
  background-position: 50%;
  background-repeat: no-repeat;
  width: 7px;
  height: 13px;
  position: absolute;
  top: 8px;
  right: 5px;
}

.gantt_grid_head_cell .gantt_sort.gantt_asc {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAANCAYAAABlyXS1AAAARUlEQVR4nGNgQAKGxib/GbABkIS7b8B/DAUwCRiGK0CXwFBAb1DfP/U/LszwHwi2X7qFgUEArBtdAVwCBmAKMCSQFSDzAWXXaOHsXeqkAAAAAElFTkSuQmCC");
}

.gantt_grid_head_cell .gantt_sort.gantt_desc {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAANCAYAAABlyXS1AAAARUlEQVR42mNgQAL1/VP/M2ADIIntF2/9x1AAlrh0C47hCmA60DFYwX88gIFGwNDY5D8uDFbg7hvwHx2jmIBTAlkB0e4BAEjlaNtBWJPnAAAAAElFTkSuQmCC");
}

.gantt_inserted, .gantt_updated {
  font-weight: 700;
}

.gantt_deleted {
  text-decoration: line-through;
}

.gantt_invalid {
  background-color: #ffe0e0;
}

.gantt_error {
  color: red;
}

.gantt_status {
  opacity: 0;
  background: rgba(156, 156, 156, .1);
  padding: 5px 10px;
  transition: opacity .2s;
  position: absolute;
  top: 1px;
  right: 1px;
}

.gantt_status.gantt_status_visible {
  opacity: 1;
}

#gantt_ajax_dots span {
  opacity: 0;
  background-repeat: no-repeat;
  transition: opacity .2s;
}

#gantt_ajax_dots span.gantt_dot_visible {
  opacity: 1;
}

.gantt_column_drag_marker {
  opacity: .8;
  pointer-events: none;
  border: 1px solid #cecece;
}

.gantt_grid_head_cell_dragged {
  opacity: .3;
  border: 1px solid #cecece;
}

.gantt_grid_target_marker {
  background-color: #ffa011;
  width: 2px;
  height: 100%;
  position: absolute;
  top: 0;
  transform: translateX(-1px);
}

.gantt_grid_target_marker:after, .gantt_grid_target_marker:before {
  content: "";
  border: 6px solid rgba(0, 0, 0, 0);
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  left: -5px;
}

.gantt_grid_target_marker:before {
  border-top-color: #ffa011;
}

.gantt_grid_target_marker:after {
  border-bottom-color: #ffa011;
  bottom: 0;
}

.gantt_message_area {
  z-index: 1000;
  width: 250px;
  position: fixed;
  right: 5px;
}

.gantt-info {
  z-index: 10000;
  min-width: 120px;
  margin: 5px 5px 10px;
  padding: 4px 4px 4px 20px;
  font-family: Arial;
  transition: all .5s;
}

.gantt-info.hidden {
  border-width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.gantt_modal_box {
  text-align: center;
  z-index: 20000;
  background: #fff;
  border: 1px solid #cecece;
  border-radius: 6px;
  width: 250px;
  min-width: 250px;
  font-family: Arial;
  display: inline-block;
  position: fixed;
  overflow: hidden;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, .07);
}

.gantt_popup_title {
  border-width: 0;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.gantt_button, .gantt_popup_button {
  background: #fff;
  border: 1px solid #cecece;
  border-radius: 4px;
  height: 30px;
  margin: 0 5px;
  line-height: 30px;
  display: inline-block;
}

.gantt-info, .gantt_button, .gantt_popup_button {
  -webkit-user-select: none;
  user-select: none;
  -moz-user-select: -moz-none;
  cursor: pointer;
}

.gantt_popup_text {
  overflow: hidden;
}

.gantt_popup_controls {
  border-radius: 6px;
  padding: 10px;
}

.gantt_popup_button {
  min-width: 100px;
}

div.dhx_modal_cover {
  cursor: default;
  filter: progid:DXImageTransform. Microsoft. Alpha(opacity= 20);
  opacity: .2;
  z-index: 19999;
  zoom: 1;
  background-color: #000;
  border: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.gantt-info img, .gantt_modal_box img {
  float: left;
  margin-right: 20px;
}

.gantt-alert-error, .gantt-confirm-error {
  border: 1px solid red;
}

.gantt_button input, .gantt_popup_button div {
  box-sizing: content-box;
  vertical-align: top;
  border-radius: 4px;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.gantt_popup_title {
  border-bottom: 1px solid #cecece;
  height: 40px;
  font-size: 20px;
  line-height: 40px;
}

.gantt_popup_text {
  color: #000;
  border-radius: 6px;
  min-height: 30px;
  margin: 15px 15px 5px;
  font-size: 14px;
}

.gantt-error, .gantt-info {
  color: #000;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 0;
  font-size: 14px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, .07);
}

.gantt-info div {
  background-color: #fff;
  border: 1px solid #cecece;
  border-radius: 3px;
  padding: 5px 10px;
}

.gantt-error {
  background-color: #d81b1b;
  border: 1px solid #ff3c3c;
}

.gantt-error div {
  color: #fff;
  background-color: #d81b1b;
  border: 1px solid #940000;
}

.gantt-warning {
  background-color: #ff9000;
  border: 1px solid #ffa633;
}

.gantt-warning div {
  color: #fff;
  background-color: #ff9000;
  border: 1px solid #b36500;
}

.gantt_data_area div, .gantt_grid div {
  -ms-touch-action: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.gantt_data_area {
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  user-select: none;
  position: relative;
  overflow: hidden;
}

.gantt_links_area {
  position: absolute;
  top: 0;
  left: 0;
}

.gantt_side_content, .gantt_task_content, .gantt_task_progress {
  line-height: inherit;
  height: 100%;
  overflow: hidden;
}

.gantt_task_content {
  color: #fff;
  cursor: pointer;
  white-space: nowrap;
  text-align: center;
  width: 100%;
  font-size: 12px;
  position: absolute;
  top: 0;
}

.gantt_task_progress {
  text-align: center;
  z-index: 0;
  background: #299cb4;
}

.gantt_task_progress_wrapper {
  border-radius: inherit;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.gantt_task_line {
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -moz-user-select: -moz-none;
  background-color: #3db9d3;
  border: 1px solid #2898b0;
  border-radius: 2px;
  position: absolute;
}

.gantt_task_line.gantt_drag_move div {
  cursor: move;
}

.gantt_touch_move, .gantt_touch_progress .gantt_touch_resize {
  transform-origin: 50%;
  transform: scale(1.02, 1.1);
}

.gantt_touch_progress .gantt_task_progress_drag, .gantt_touch_resize .gantt_task_drag {
  transform-origin: 50%;
  transform: scaleY(1.3);
}

.gantt_side_content {
  white-space: nowrap;
  color: #6e6e6e;
  font-size: 11px;
  position: absolute;
  top: 0;
}

.gantt_side_content.gantt_left {
  padding-right: 20px;
  right: 100%;
}

.gantt_side_content.gantt_right {
  padding-left: 20px;
  left: 100%;
}

.gantt_side_content.gantt_link_crossing {
  top: auto;
  bottom: 8.75px;
}

.gantt_link_arrow, .gantt_task_link .gantt_line_wrapper {
  cursor: pointer;
  position: absolute;
}

.gantt_line_wrapper div {
  background-color: #ffa011;
}

.gantt_task_link:hover .gantt_line_wrapper div {
  box-shadow: 0 0 5px #ffa011;
}

.gantt_task_link div.gantt_link_arrow {
  background-color: rgba(0, 0, 0, 0);
  border-style: solid;
  width: 0;
  height: 0;
}

.gantt_link_control {
  width: 20px;
  position: absolute;
  top: 0;
}

.gantt_link_control div {
  cursor: pointer;
  box-sizing: border-box;
  vertical-align: middle;
  background-color: #f0f0f0;
  border: 1px solid #929292;
  border-radius: 6.5px;
  width: 13px;
  height: 13px;
  margin-top: -7.5px;
  display: none;
  position: relative;
  top: 50%;
}

.gantt_link_control.task_right div.gantt_link_point {
  margin-left: 7px;
}

.gantt_link_control div:hover {
  background-color: #fff;
}

.gantt_link_control.task_left {
  left: -20px;
}

.gantt_link_control.task_right {
  right: -20px;
}

.gantt_link_target .gantt_link_control div, .gantt_task_line.gantt_drag_move .gantt_link_control div, .gantt_task_line.gantt_drag_move .gantt_task_drag, .gantt_task_line.gantt_drag_move .gantt_task_progress_drag, .gantt_task_line.gantt_drag_progress .gantt_link_control div, .gantt_task_line.gantt_drag_progress .gantt_task_drag, .gantt_task_line.gantt_drag_progress .gantt_task_progress_drag, .gantt_task_line.gantt_drag_resize .gantt_link_control div, .gantt_task_line.gantt_drag_resize .gantt_task_drag, .gantt_task_line.gantt_drag_resize .gantt_task_progress_drag, .gantt_task_line.gantt_selected .gantt_link_control div, .gantt_task_line.gantt_selected .gantt_task_drag, .gantt_task_line.gantt_selected .gantt_task_progress_drag, .gantt_task_line:hover .gantt_link_control div, .gantt_task_line:hover .gantt_task_drag, .gantt_task_line:hover .gantt_task_progress_drag {
  display: block;
}

.gantt_link_source, .gantt_link_target {
  box-shadow: 0 0 3px #3db9d3;
}

.gantt_link_target.link_finish_allow, .gantt_link_target.link_start_allow {
  box-shadow: 0 0 3px #ffbf5e;
}

.gantt_link_target.link_finish_deny, .gantt_link_target.link_start_deny {
  box-shadow: 0 0 3px #e87e7b;
}

.link_finish_allow .gantt_link_control.task_end_date div, .link_start_allow .gantt_link_control.task_start_date div {
  background-color: #ffbf5e;
  border-color: #ffa011;
}

.link_finish_deny .gantt_link_control.task_end_date div, .link_start_deny .gantt_link_control.task_start_date div {
  background-color: #e87e7b;
  border-color: #dd3e3a;
}

.gantt_link_arrow_right {
  border-width: 4px 0 4px 6px;
  border-left-color: #ffa011;
  border-top-color: rgba(0, 0, 0, 0) !important;
  border-bottom-color: rgba(0, 0, 0, 0) !important;
  border-right-color: rgba(0, 0, 0, 0) !important;
}

.gantt_link_arrow_left {
  border-width: 4px 6px 4px 0;
  border-right-color: #ffa011;
  margin-top: -1px;
  border-top-color: rgba(0, 0, 0, 0) !important;
  border-bottom-color: rgba(0, 0, 0, 0) !important;
  border-left-color: rgba(0, 0, 0, 0) !important;
}

.gantt_link_arrow_up {
  border-width: 0 4px 6px;
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #ffa011;
  border-top-color: rgba(0, 0, 0, 0) !important;
  border-left-color: rgba(0, 0, 0, 0) !important;
  border-right-color: rgba(0, 0, 0, 0) !important;
}

.gantt_link_arrow_down {
  border-width: 4px 6px 0 4px;
  border-top-color: #ffa011;
  border-bottom-color: rgba(0, 0, 0, 0) !important;
  border-left-color: rgba(0, 0, 0, 0) !important;
  border-right-color: rgba(0, 0, 0, 0) !important;
}

.gantt_task_drag, .gantt_task_progress_drag {
  cursor: ew-resize;
  display: none;
  position: absolute;
}

.gantt_task_drag.task_right {
  cursor: e-resize;
}

.gantt_task_drag.task_left {
  cursor: w-resize;
}

.gantt_task_drag {
  z-index: 1;
  width: 8px;
  height: 100%;
  top: -1px;
}

.gantt_task_drag.task_left {
  left: -7px;
}

.gantt_task_drag.task_right {
  right: -7px;
}

.gantt_task_progress_drag {
  z-index: 1;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAYAAAB24g05AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MkY3Rjk0RUVDMkYzMTFFMkI1OThEQTA3ODU0OTkzMEEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MkY3Rjk0RUZDMkYzMTFFMkI1OThEQTA3ODU0OTkzMEEiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoyRjdGOTRFQ0MyRjMxMUUyQjU5OERBMDc4NTQ5OTMwQSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoyRjdGOTRFREMyRjMxMUUyQjU5OERBMDc4NTQ5OTMwQSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PobPBzIAAADkSURBVHjaYpk2bRoDDsAExL1QdjEQ/8OmiAWHZk4gXqymqhQM4ty6fU8OSMUA8XdiDBAB4k0a6iqWRga6EKcwMQXduHlnL5DpB8Rv0J2JDFSA+JiOtgZcMwiA2CAxkBxUDVYDLEAKgIpV9XQ0MZwFEgPJAZnHoWpRDAgC4n2W5saiQKfjClQGkBxQDciL+6B6wAbkA/EqJwdrTkUFOQZCAKQGpBbIXA3SCzJggo+XK7OEuBgDsQCkFqgHrBfsBT5eHgZSAUwP2IBfv36TbABMDygdtK1Zv6UESLORaAbIhG6AAAMAKN8wE24DXWcAAAAASUVORK5CYII=");
  background-position: bottom;
  background-repeat: no-repeat;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  bottom: -4px;
}

.gantt_task_progress_drag:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAYAAAB24g05AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAs0lEQVQoz6WMPW7CQBgFJxZaiZ60qcgdwjVMmzu8gpwhDULabXyBdHAGuzRHivQiQZovigS2+Jtu95t5T03TMITtCtjEc5VSOgx5k5F4CnxJWgKUUl5sv6eUvk/daiCeAe1fDCCpBtq4jQ/YngO9pMWpGH99OOcDtt8ifmWEuO3D/R+wXQOdpGcuIGkGdNFQ2RawlTTlSsLd2RY55+O95JyPFQ/y8MAE+CylfADpxvYHWP8CXj+JR4wdKHYAAAAASUVORK5CYII=");
}

.gantt_link_tooltip {
  color: #444;
  background-color: #fff;
  border-top: 1px dotted #cecece;
  border-left: 1px dotted #cecece;
  padding: 6px;
  font-family: Tahoma;
  font-size: 8pt;
  line-height: 20px;
  box-shadow: 3px 3px 3px #888;
}

.gantt_link_direction {
  transform-origin: 0 0;
  z-index: 2;
  border: 0 #ffa011;
  border-bottom: 2px dashed #ffa011;
  height: 0;
  margin-left: 1px;
  position: absolute;
}

.gantt_grid_data .gantt_row.gantt_selected, .gantt_grid_data .gantt_row.odd.gantt_selected, .gantt_task_row.gantt_selected {
  background-color: #fff3a1;
}

.gantt_task_row.gantt_selected .gantt_task_cell {
  border-right-color: #ffec6e;
}

.gantt_task_line.gantt_selected {
  box-shadow: 0 0 5px #299cb4;
}

.gantt_task_line.gantt_project.gantt_selected {
  box-shadow: 0 0 5px #46ad51;
}

.gantt_task_line.gantt_milestone {
  visibility: hidden;
  box-sizing: content-box;
  background-color: #d33daf;
  border: 0 solid #61164f;
}

.gantt_task_line.gantt_milestone div {
  visibility: visible;
}

.gantt_task_line.gantt_milestone .gantt_task_content {
  background: inherit;
  border: inherit;
  border-radius: inherit;
  box-sizing: border-box;
  border-width: 1px;
  transform: rotate(45deg);
}

.gantt_task_line.gantt_task_inline_color {
  border-color: #999;
}

.gantt_task_line.gantt_task_inline_color .gantt_task_progress {
  opacity: .2;
  background-color: #363636;
}

.gantt_task_line.gantt_task_inline_color.gantt_project.gantt_selected, .gantt_task_line.gantt_task_inline_color.gantt_selected, .gantt_task_link.gantt_link_inline_color:hover .gantt_line_wrapper div {
  box-shadow: 0 0 5px #999;
}

.gantt_critical_task {
  background-color: #e63030;
  border-color: #9d3a3a;
}

.gantt_critical_task .gantt_task_progress {
  background-color: rgba(0, 0, 0, .4);
}

.gantt_critical_link .gantt_line_wrapper > div {
  background-color: #e63030;
}

.gantt_critical_link .gantt_link_arrow {
  border-color: #e63030;
}

.gantt_btn_set:focus, .gantt_cell:focus, .gantt_grid_head_cell:focus, .gantt_popup_button:focus, .gantt_qi_big_icon:focus, .gantt_row:focus {
  box-shadow: inset 0 0 1px 1px #4d90fe;
}

.gantt_split_parent, .gantt_split_subproject {
  opacity: .1;
  pointer-events: none;
}

.gantt_rollup_child .gantt_link_control, .gantt_rollup_child:hover .gantt_link_control {
  display: none;
}

.gantt_unselectable, .gantt_unselectable div {
  -webkit-user-select: none;
  -moz-user-select: none;
  -moz-user-select: -moz-none;
}

.gantt_cal_light {
  -webkit-tap-highlight-color: transparent;
  color: #6b6b6b;
  z-index: 10001;
  background: #fff;
  border: 1px solid #cecece;
  border-radius: 6px;
  width: 550px;
  height: 250px;
  font-family: Arial;
  font-size: 12px;
  position: absolute;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, .07);
}

.gantt_cal_light_wide {
  width: 650px;
}

.gantt_cal_light select {
  border: 1px solid #cecece;
  margin: 0;
  padding: 2px;
  font-family: Arial;
  font-size: 13px;
}

.gantt_cal_ltitle {
  -moz-border-radius-topleft: 6px;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topright: 6px;
  -moz-border-radius-bottomright: 0;
  border-radius: 6px 6px 0 0;
  padding: 7px 10px;
  overflow: hidden;
}

.gantt_cal_ltitle, .gantt_cal_ltitle span {
  white-space: nowrap;
}

.gantt_cal_lsection {
  color: #727272;
  padding: 12px 0 5px 10px;
  font-weight: 700;
}

.gantt_cal_lsection .gantt_fullday {
  float: right;
  vertical-align: top;
  cursor: pointer;
  margin-right: 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.gantt_cal_lsection {
  font-size: 13px;
}

.gantt_cal_ltext {
  padding: 2px 10px;
  overflow: hidden;
}

.gantt_cal_ltext textarea {
  box-sizing: border-box;
  resize: none;
  border: 1px solid #cecece;
  width: 100%;
  height: 100%;
  font-family: Arial;
  font-size: 13px;
  overflow-x: hidden;
  overflow-y: auto;
  outline: none !important;
}

.gantt_section_constraint [data-constraint-time-select] {
  margin-left: 20px;
}

.gantt_time {
  font-weight: 700;
}

.gantt_cal_light .gantt_title {
  padding-left: 10px;
}

.gantt_cal_larea {
  background-color: #fff;
  border: 1px solid #cecece;
  border-left: none;
  border-right: none;
  height: 1px;
  overflow: hidden;
}

.gantt_btn_set {
  float: left;
  box-sizing: border-box;
  cursor: pointer;
  background: #fff;
  border: 0 solid #cecece;
  border-radius: 4px;
  height: 32px;
  margin: 10px 7px 5px 10px;
  padding: 5px 15px 5px 10px;
  font-weight: 700;
}

.gantt_hidden {
  display: none;
}

.gantt_btn_set div {
  float: left;
  vertical-align: middle;
  background-repeat: no-repeat;
  height: 22px;
  font-size: 13px;
  line-height: 22px;
}

.gantt_save_btn {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MTk1OUU5RDFDMzA0MTFFMkExMUZBQTdDNDAzOUE5RjMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MTk1OUU5RDJDMzA0MTFFMkExMUZBQTdDNDAzOUE5RjMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxOTU5RTlDRkMzMDQxMUUyQTExRkFBN0M0MDM5QTlGMyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoxOTU5RTlEMEMzMDQxMUUyQTExRkFBN0M0MDM5QTlGMyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PjDroXYAAAEXSURBVHjaYvz//z8DJYCRUgPIAUxAbAnEHiAHMIBcQCwGaRYXF3e6evXqoffv39/dv38/CymaGSUkJBzv3LlzCsj///fv3wdAihkkIQnEvkAshU8zLy+v7a1bt06ANP/79+87kDIAy505cybq06dPr3p7ezuwGQLTfOPGjWP/ESAZLg8kPKBO+g01RBJNszWyZqC6uSgWgIg/f/4shxnS2dnZBjMEqNkSFGBImi8CKTYMA4BYCGjIczRDHC5dunQQSfN7IKWI4UUkjjdMMdCwnw8ePLjwHxV4Yw1gZA5Q47z/2EELzhhCE+ABGvIQWSeQvwcU38QaAML2wHj+C/X3MyAlijeB4ZBoBOIPQGxJKIVSnBsBAgwABddBclWfcZUAAAAASUVORK5CYII=");
  width: 21px;
  margin-top: 2px;
}

.gantt_cancel_btn {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MDkzMDA3MzlDMzA0MTFFMjg2QTVFMzFEQzgwRkJERDYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MDkzMDA3M0FDMzA0MTFFMjg2QTVFMzFEQzgwRkJERDYiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDowOTMwMDczN0MzMDQxMUUyODZBNUUzMURDODBGQkRENiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDowOTMwMDczOEMzMDQxMUUyODZBNUUzMURDODBGQkRENiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PmYuYOUAAAEdSURBVHjaYvz//z8DJYAFXWDlypU8QKoIiD2A2AwqfAqIdwBxX3h4+Bdk9YzILgBqtgdS84FYEYeF94E4EWjIQZgAE5LmQCB1AKoZZKMPEAtAMYh9GSp3AKjWD8UFQAEhIPshEIOc3wHENUBb/qJ57SyQMoJyPwKxElDNO1gYFEE17wMKVmIJlzNQzeegrjaA6qmBecEbSvfh0GwMxGeBhoPoemQ9MAO0kEIbl2YTqPAFKK2IbMB3AjabYIkRZmQD7kNpMyI0G0PpO8gGbIUFJj7NQDk2INWIrIcJKfBAKcwJqvkcDs0TgFgXGo19KCkRmpDWQdWDEk0NUoCBoq0FqhkE/IEWbKJKUmZEz43QzFSKIzN1481M5ACAAAMAlfl/lCwRpagAAAAASUVORK5CYII=");
  width: 20px;
  margin-top: 2px;
}

.gantt_delete_btn {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MjFENzI3NUNDMzA0MTFFMjhBNjJGQTc3MUIyQzYzNEYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MjFENzI3NURDMzA0MTFFMjhBNjJGQTc3MUIyQzYzNEYiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoyMUQ3Mjc1QUMzMDQxMUUyOEE2MkZBNzcxQjJDNjM0RiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoyMUQ3Mjc1QkMzMDQxMUUyOEE2MkZBNzcxQjJDNjM0RiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PmUD0gAAAABvSURBVHjaYvz//z8DIyMjAxYQicReji4J0ofKQNP8HwmgGQbXB8IsWGwDSSwDuioKjY9uBthVjFAXYHUGAQA2kYmBUoAUBpGk0LAwgBvwH+YX4mkwptgLowYMRgOITUyYKRFIN/wnDjQgJySAAAMApryKzL8wjfUAAAAASUVORK5CYII=");
  width: 20px;
  margin-top: 2px;
}

.gantt_cal_cover {
  z-index: 10000;
  opacity: .1;
  width: 100%;
  height: 100%;
  filter: progid:DXImageTransform. Microsoft. Alpha(opacity= 10);
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.gantt_custom_button {
  cursor: pointer;
  float: right;
  text-align: center;
  border: 1px solid #cecece;
  border-radius: 4px;
  width: 90px;
  height: 21px;
  margin-top: -5px;
  margin-right: 10px;
  padding: 0 3px;
  font-family: Arial;
  font-size: 13px;
  font-weight: 400;
}

.gantt_custom_button div {
  cursor: pointer;
  float: none;
  vertical-align: middle;
  height: 21px;
  line-height: 21px;
}

.gantt_custom_button div:first-child {
  display: none;
}

.gantt_cal_light_wide {
  width: 580px;
  padding: 2px 4px;
}

.gantt_cal_light_wide .gantt_cal_larea {
  box-sizing: border-box;
  border: 1px solid #cecece;
}

.gantt_cal_light_wide .gantt_cal_lsection {
  float: left;
  text-align: right;
  border: 0;
  width: 80px;
  height: 20px;
  padding: 5px 10px 0 0;
}

.gantt_cal_light_wide .gantt_wrap_section {
  border-bottom: 1px solid #ebebeb;
  padding: 10px 0;
  position: relative;
  overflow: hidden;
}

.gantt_cal_light_wide .gantt_section_time {
  padding-right: 0;
  overflow: hidden;
  height: 20px !important;
  padding-top: 2px !important;
}

.gantt_cal_light_wide .gantt_cal_ltext {
  padding-right: 0;
}

.gantt_cal_light_wide .gantt_cal_larea {
  width: 100%;
  padding: 0 10px;
}

.gantt_cal_light_wide .gantt_section_time {
  background: none;
}

.gantt_cal_light_wide .gantt_cal_checkbox label {
  padding-left: 0;
}

.gantt_cal_light_wide .gantt_cal_lsection .gantt_fullday {
  float: none;
  cursor: pointer;
  margin-right: 0;
  font-weight: 700;
}

.gantt_cal_light_wide .gantt_custom_button {
  margin-top: 2px;
  position: absolute;
  top: 0;
  right: 0;
}

.gantt_cal_light_wide .gantt_repeat_right {
  margin-right: 55px;
}

.gantt_cal_light_wide.gantt_cal_light_full {
  width: 738px;
}

.gantt_cal_wide_checkbox input {
  margin-top: 8px;
  margin-left: 14px;
}

.gantt_cal_light input {
  font-size: 13px;
}

.gantt_section_time {
  white-space: nowrap;
  background-color: #fff;
  padding: 2px 10px 5px;
  padding-top: 2px !important;
}

.gantt_section_time .gantt_time_selects {
  float: left;
  height: 25px;
}

.gantt_section_time .gantt_time_selects select {
  border: 1px solid #cecece;
  height: 23px;
  padding: 2px;
}

.gantt_duration {
  float: left;
  white-space: nowrap;
  width: 100px;
  height: 23px;
  margin-left: 20px;
  line-height: 23px;
}

.gantt_duration .gantt_duration_dec, .gantt_duration .gantt_duration_inc, .gantt_duration .gantt_duration_value {
  box-sizing: border-box;
  text-align: center;
  vertical-align: top;
  border: 1px solid #cecece;
  height: 100%;
}

.gantt_duration .gantt_duration_value {
  border-left-width: 0;
  border-right-width: 0;
  width: 40px;
  padding: 3px 4px;
}

.gantt_duration .gantt_duration_value.gantt_duration_value_formatted {
  width: 70px;
}

.gantt_duration .gantt_duration_dec, .gantt_duration .gantt_duration_inc {
  background: #fff;
  width: 20px;
  padding: 1px;
}

.gantt_duration .gantt_duration_dec {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.gantt_duration .gantt_duration_inc {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-right: 4px;
}

.gantt_resources {
  height: auto;
  max-height: 150px;
  overflow-y: auto;
}

.gantt_resource_row {
  cursor: pointer;
  border-bottom: 1px solid #ebebeb;
  padding: 10px 0;
  display: block;
}

.gantt_resource_row input[type="checkbox"]:not(:checked) {
  opacity: .5;
}

.gantt_resource_row input[type="checkbox"]:not(:checked) ~ div {
  opacity: .5;
}

.gantt_resource_toggle {
  vertical-align: middle;
}

.gantt_resources_filter .gantt_resources_filter_input {
  box-sizing: border-box;
  padding: 1px 2px;
}

.gantt_resources_filter .switch_unsetted {
  vertical-align: middle;
}

.gantt_resource_cell {
  display: inline-block;
}

.gantt_resource_cell.gantt_resource_cell_checkbox {
  vertical-align: middle;
  width: 24px;
  min-width: 24px;
  max-width: 24px;
}

.gantt_resource_cell.gantt_resource_cell_label {
  vertical-align: middle;
  width: 40%;
  max-width: 40%;
}

.gantt_resource_cell.gantt_resource_cell_value {
  vertical-align: middle;
  width: 30%;
  max-width: 30%;
}

.gantt_resource_cell.gantt_resource_cell_value input, .gantt_resource_cell.gantt_resource_cell_value select {
  vertical-align: middle;
  box-sizing: border-box;
  width: 80%;
  padding: 1px 2px;
}

.gantt_resource_cell.gantt_resource_cell_unit {
  vertical-align: middle;
  width: 10%;
  max-width: 10%;
}

.gantt_resource_early_value {
  opacity: .8;
  font-size: .9em;
}

.gantt_cal_quick_info {
  z-index: 300;
  -o-transition: left .5s ease, right .5s;
  background-color: #fff;
  border: 1px solid #cecece;
  border-radius: 6px;
  width: 300px;
  transition: left .5s, right .5s;
  position: absolute;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, .07);
}

.gantt_no_animate {
  -o-transition: none;
  transition: none;
}

.gantt_cal_quick_info.gantt_qi_left .gantt_qi_big_icon {
  float: right;
}

.gantt_cal_qi_title {
  -moz-border-radius-topleft: 6px;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topright: 6px;
  -moz-border-radius-bottomright: 0;
  color: #454545;
  background-color: #fff;
  border-bottom: 1px solid #cecece;
  border-radius: 6px 6px 0 0;
  padding: 5px 0 8px 12px;
}

.gantt_cal_qi_tdate {
  font-size: 14px;
  font-weight: 700;
}

.gantt_cal_qi_tcontent {
  font-size: 13px;
}

.gantt_cal_qi_content {
  color: #454545;
  padding: 16px 8px;
  font-size: 13px;
  overflow: hidden;
}

.gantt_cal_qi_controls {
  -moz-border-radius-topleft: 0;
  -moz-border-radius-bottomleft: 6px;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 6px;
  border-radius: 0 0 6px 6px;
  padding-left: 7px;
}

.gantt_cal_qi_controls .gantt_menu_icon {
  background-repeat: no-repeat;
  margin-top: 6px;
}

.gantt_cal_qi_controls .gantt_menu_icon.icon_edit {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAB3RJTUUH3QYFCjI5ZQj5bAAAAFNJREFUOMvt0zEOACAIA0DkwTymH8bJTRTKZGJXyaWEKPKTCQAH4Ls37cItcDUzsxHNDLZNhCq7Gt1wh9ErV7EjyGAhyGLphlnsClWuS32rn0czAV+vNGrM/LBtAAAAAElFTkSuQmCC");
  width: 20px;
}

.gantt_cal_qi_controls .gantt_menu_icon.icon_delete {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MjFENzI3NUNDMzA0MTFFMjhBNjJGQTc3MUIyQzYzNEYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MjFENzI3NURDMzA0MTFFMjhBNjJGQTc3MUIyQzYzNEYiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoyMUQ3Mjc1QUMzMDQxMUUyOEE2MkZBNzcxQjJDNjM0RiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoyMUQ3Mjc1QkMzMDQxMUUyOEE2MkZBNzcxQjJDNjM0RiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PmUD0gAAAABvSURBVHjaYvz//z8DIyMjAxYQicReji4J0ofKQNP8HwmgGQbXB8IsWGwDSSwDuioKjY9uBthVjFAXYHUGAQA2kYmBUoAUBpGk0LAwgBvwH+YX4mkwptgLowYMRgOITUyYKRFIN/wnDjQgJySAAAMApryKzL8wjfUAAAAASUVORK5CYII=");
  width: 20px;
}

.gantt_qi_big_icon {
  vertical-align: middle;
  cursor: pointer;
  background: #fff;
  border: 1px solid #cecece;
  border-radius: 4px;
  min-width: 60px;
  margin: 5px 9px 8px 0;
  padding: 0 10px 0 5px;
  font-size: 13px;
  font-weight: 700;
  line-height: 32px;
}

.gantt_cal_qi_controls div {
  float: left;
  text-align: center;
  height: 32px;
  line-height: 32px;
}

.gantt_tooltip {
  z-index: 50;
  white-space: nowrap;
  padding: 10px;
  position: absolute;
}

.gantt_resource_marker {
  text-align: center;
  color: #fff;
  font-size: 14px;
  position: absolute;
}

.gantt_resource_marker_ok {
  background: rgba(78, 208, 134, .75);
}

.gantt_resource_marker_overtime {
  background: rgba(255, 133, 133, .69);
}

.gantt_histogram_label {
  z-index: 1;
  width: 100%;
  height: 100%;
  font-size: 13px;
  font-weight: 700;
  position: absolute;
}

.gantt_histogram_fill {
  background-color: rgba(41, 157, 180, .2);
  width: 100%;
  position: absolute;
  bottom: 0;
}

.gantt_histogram_hor_bar {
  height: 1px;
  margin-top: -1px;
}

.gantt_histogram_hor_bar, .gantt_histogram_vert_bar {
  background: #299db4;
  margin-left: -1px;
  position: absolute;
}

.gantt_histogram_vert_bar {
  width: 1px;
}

.gantt_histogram_cell {
  text-align: center;
  color: #000;
  font-size: 13px;
  position: absolute;
}

.gantt_marker {
  text-align: center;
  box-sizing: border-box;
  background-color: rgba(255, 0, 0, .4);
  width: 2px;
  height: 100%;
  position: absolute;
  top: 0;
}

.gantt_marker .gantt_marker_content {
  background: inherit;
  color: #fff;
  opacity: .8;
  padding: 5px;
  font-size: 12px;
  line-height: 12px;
  position: absolute;
}

.gantt_marker_area {
  position: absolute;
  top: 0;
  left: 0;
}

.gantt_grid_editor_placeholder {
  position: absolute;
}

.gantt_grid_editor_placeholder > div, .gantt_grid_editor_placeholder input, .gantt_grid_editor_placeholder select {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.gantt_row_placeholder div {
  opacity: .5;
}

.gantt_row_placeholder .gantt_add, .gantt_row_placeholder .gantt_file {
  display: none;
}

.gantt_drag_marker.gantt_grid_dnd_marker {
  background-color: rgba(0, 0, 0, 0);
  transition: all .1s;
}

.gantt_grid_dnd_marker_line {
  background-color: #3498db;
  width: 100%;
  height: 4px;
}

.gantt_grid_dnd_marker_line:before {
  box-sizing: border-box;
  content: "";
  pointer-events: none;
  background: #fff;
  border: 3px solid #3498db;
  border-radius: 6px;
  width: 12px;
  height: 12px;
  margin-top: -4px;
  margin-left: -11px;
  line-height: 1px;
  display: block;
  position: absolute;
}

.gantt_grid_dnd_marker_folder {
  pointer-events: none;
  box-sizing: border-box;
  background: none;
  width: 100%;
  height: 100%;
  position: absolute;
  box-shadow: inset 0 0 0 2px #3f98db;
}

.gantt_overlay_area {
  display: none;
}

.gantt_overlay, .gantt_overlay_area {
  height: inherit;
  width: inherit;
  position: absolute;
  top: 0;
  left: 0;
}

.gantt_click_drag_rect {
  background-color: rgba(52, 152, 219, .3);
  outline: 1px solid #3f98db;
  position: absolute;
  top: 0;
  left: 0;
}

.gantt_timeline_move_available, .gantt_timeline_move_available * {
  cursor: move;
}

.gantt_rtl .gantt_grid {
  text-align: right;
}

.gantt_rtl .gantt_cell, .gantt_rtl .gantt_row {
  flex-direction: row-reverse;
}

.gantt_layout_content {
  box-sizing: border-box;
  width: 100%;
  overflow: auto;
}

.gantt_layout_cell {
  box-sizing: border-box;
  position: relative;
}

.gantt_layout_cell > .gantt_layout_header {
  color: #fff;
  box-sizing: border-box;
  background: #33aae8;
  padding: 5px 10px;
  font-size: 17px;
}

.gantt_layout_header.collapsed_x {
  background: #a9a9a9;
}

.gantt_layout_header.collapsed_x .gantt_header_arrow:before {
  content: "⇧";
}

.gantt_layout_header.collapsed_y {
  background: #a9a9a9;
}

.gantt_layout_header.collapsed_y .gantt_header_arrow:before {
  content: "⇩";
}

.gantt_layout_header {
  cursor: pointer;
}

.gantt_layout_header .gantt_header_arrow {
  float: right;
  text-align: right;
}

.gantt_layout_header .gantt_header_arrow:before {
  content: "⇦";
}

.gantt_layout_header.vertical .gantt_header_arrow:before {
  content: "⇧";
}

.gantt_layout_outer_scroll_vertical .gantt_layout_content {
  overflow-y: hidden;
}

.gantt_layout_outer_scroll_horizontal .gantt_layout_content {
  overflow-x: hidden;
}

.gantt_layout_x > .gantt_layout_cell {
  vertical-align: top;
  display: inline-block;
}

.gantt_layout_x {
  white-space: nowrap;
}

.gantt_resizing {
  opacity: .7;
  background: #f2f2f2;
}

.gantt_layout_cell_border_right.gantt_resizer {
  border-right: 0;
  overflow: visible;
}

.gantt_resizer {
  cursor: e-resize;
  position: relative;
}

.gantt_resizer_y {
  cursor: n-resize;
}

.gantt_resizer_stick {
  z-index: 9999;
  background: #33aae8;
  width: 100%;
  position: absolute;
  top: 0;
}

.gantt_resizer_x .gantt_resizer_x {
  z-index: 1;
  width: 20px;
  height: 100%;
  margin-left: -10px;
  position: absolute;
  top: 0;
  left: 0;
}

.gantt_resizer_y .gantt_resizer_y {
  z-index: 1;
  width: 100%;
  height: 20px;
  position: absolute;
  top: -10px;
  left: 0;
}

.gantt_resizer_error {
  background: #cd5c5c !important;
}

.gantt_layout_cell_border_left {
  border-left: 1px solid #cecece;
}

.gantt_layout_cell_border_right {
  border-right: 1px solid #cecece;
}

.gantt_layout_cell_border_top {
  border-top: 1px solid #cecece;
}

.gantt_layout_cell_border_bottom {
  border-bottom: 1px solid #cecece;
}

.gantt_layout_cell_border_transparent {
  border-color: rgba(0, 0, 0, 0);
}

.gantt_window {
  z-index: 999999999;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
}

.gantt_window_content {
  position: relative;
}

.gantt_window_content_header {
  color: #fff;
  background: #39c;
  border-bottom: 2px solid #fff;
  height: 33px;
  padding: 10px 10px 0;
  position: relative;
}

.gantt_window_content_header_text {
  padding-left: 10%;
}

.gantt_window_content_header_buttons {
  position: absolute;
  top: 10px;
  right: 10px;
}

.gantt_window_content_header_buttons:hover {
  color: #000;
  cursor: pointer;
}

.gantt_window_content_resizer {
  text-align: center;
  cursor: nw-resize;
  z-index: 999;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAMAAAAMCGV4AAAABlBMVEUAAAAAAAClZ7nPAAAAAXRSTlMAQObYZgAAABZJREFUeAFjIAUwUshlpJDLSIhLGAAACQ4AFk79JaMAAAAASUVORK5CYII=");
  width: 15px;
  height: 15px;
  line-height: 15px;
  position: absolute;
  bottom: 0;
  right: -1px;
}

.gantt_window_content_frame {
  z-index: 9999;
  background: rgba(0, 0, 0, .1);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.gantt_window_drag {
  cursor: pointer !important;
}

.gantt_window_resizing {
  overflow: visible;
}

.gantt_window_resizing_body {
  overflow: hidden !important;
}

.gantt_window_modal {
  z-index: 9999;
  background: rgba(0, 0, 0, .1);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.gantt_cal_light, .gantt_cal_quick_info, .gantt_container, .gantt_message_area, .gantt_modal_box, .gantt_tooltip {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gantt_noselect {
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  user-select: none;
}

.gantt_noselect .gantt_grid_data .gantt_row.odd:hover, .gantt_noselect .gantt_grid_data .gantt_row:hover {
  background-color: unset;
}

.gantt_drag_marker {
  z-index: 1;
  white-space: nowrap;
  font-family: Arial;
  font-size: 13px;
  position: absolute;
  top: -1000px;
  left: -1000px;
}

.gantt_drag_marker .gantt_tree_icon.gantt_blank, .gantt_drag_marker .gantt_tree_icon.gantt_close, .gantt_drag_marker .gantt_tree_icon.gantt_open, .gantt_drag_marker .gantt_tree_indent {
  display: none;
}

.gantt_drag_marker, .gantt_drag_marker .gantt_row.odd {
  background-color: #fff;
}

.gantt_drag_marker .gantt_row {
  border-top: 1px solid #d2d2d2;
  border-left: 1px solid #d2d2d2;
}

.gantt_drag_marker .gantt_cell {
  border-color: #d2d2d2;
}

.gantt_row.gantt_over, .gantt_task_row.gantt_over {
  background-color: #0070fe;
}

.gantt_row.gantt_transparent .gantt_cell {
  opacity: .7;
}

.gantt_task_row.gantt_transparent {
  background-color: #f8fdfd;
}

.gantt_popup_button.gantt_delete_button {
  text-shadow: 0 -1px #248a9f;
  color: #fff;
  background: #3db9d3;
  border-width: 0;
  font-weight: 700;
}

.gantt_container_resize_watcher {
  z-index: -1;
  pointer-events: none;
  box-sizing: border-box;
  opacity: 0;
  background: none;
  border: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
