.issuePanel {
  border-left: 1px solid #cecece;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.issuePanel__body {
  overflow: auto;
}

.issuePanel__viewport {
  background-color: #fff;
  flex-direction: column;
  width: 24.625rem;
  display: flex;
}

.issuePanel__header {
  border-bottom: .0625rem solid #e9e8e8;
  margin: 0 0 .625rem;
  padding: 1.5rem 1.25rem .75rem;
}

.issuePanel__header__actions {
  justify-content: space-between;
  align-items: center;
  height: 2.25rem;
  margin: 0 0 .625rem;
  padding: 0 .5rem;
  display: flex;
}

.issuePanel__comments {
  padding: 1rem 1.25rem;
}
