.MembersList {
  background-color: #fff;
  padding: 24px 20px;
}

.MembersList__header {
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 12px;
  display: flex;
}

.MembersList__title {
  letter-spacing: -.4px;
  color: #787676;
  flex-grow: 1;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}

.MembersList__count {
  color: #a5a4a4;
  margin-left: 6px;
}

.MembersList__headerActions {
  flex-shrink: 0;
  margin-left: 20px;
}

.MembersList_view_search {
  background-color: rgba(30, 27, 27, .02);
  flex-grow: 1;
}

.MembersList_disabled {
  opacity: .5;
}
