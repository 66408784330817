.completionAmountSlider {
  position: relative;
}

.completionAmountSlider .completionAmountSlider__bar {
  position: relative;
  overflow: hidden;
  background-color: unset !important;
}

.completionAmountSlider .completionAmountSlider__bar:after {
  content: "";
  background-color: #21a032;
  width: 100%;
  height: 100%;
  transition: transform 25ms linear;
  display: block;
  transform: translateX(calc(var(--offset)  - 100%)) !important;
}

.completionAmountSlider .completionAmountSlider__dot, .completionAmountSlider .completionAmountSlider__number {
  transition: transform 25ms linear;
  transform: translateX(calc(var(--offset)  - 50%)) !important;
  left: 0 !important;
}

.completionAmountSlider_inline .completionAmountSlider__dot {
  transform: translate(calc(var(--offset)  - 50%), -50%) !important;
}

.completionAmountSlider_inline .completionAmountSlider__number {
  bottom: 0;
  transform: translate(calc(var(--offset)  - 50%), -16px) !important;
}
