.mobileAuthStep {
  color: #fff;
  background-color: #1e1b1b;
  overflow: hidden;
}

.mobileAuthStep__logo {
  width: 64px;
  margin-left: auto;
  margin-right: auto;
}

.mobileAuthStep__box {
  box-sizing: border-box;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 48px;
  display: flex;
}

.mobileAuthStep__logo {
  flex-shrink: 0;
  margin-bottom: 16px;
}

.mobileAuthStep__panel {
  color: #1e1b1b;
  background-color: #f5f5f5;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  margin-top: auto;
  padding: 24px;
}

.mobileAuthStep__content {
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
}
