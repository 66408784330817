.historyList__group {
  margin-top: 24px;
}

.historyList__group:first-child {
  margin-top: 0;
}

.historyList__group_isHidden {
  display: none;
}

.historyList__item {
  margin-top: 24px;
  position: relative;
}

.historyList__item:before {
  content: "";
  background: #e9e8e8;
  border-radius: 1px;
  width: 1px;
  height: 12px;
  position: absolute;
  bottom: calc(100% + 6px);
  left: 17px;
}

.historyList__item:first-of-type:before {
  content: none;
}
